import OpObject from '@/visual-events/model/OpObject'

/**
 * OpGroup is identical to the OpObject
 * 
 * Its purpose is to make working with groups syntactically clearer.
 * Especially this allows for using instanceof OpGroup for discriminate different kinds
 * of OpObjects.
 * 
 * Like any other OpObject the OpGroup has a transform. But usually you should not
 * make use of this. Transform each leaf in the tree structure, instead.
 * 
 * Copy an OpGroup will only make a shallow copy, not copy the tree!
 */
export default class OpGroup extends OpObject {

    constructor (type, name) {
        super (type, name);
    }

    copy () {

        const copy = new OpGroup(this.type, this.name);

        copy.visible = this.visible;
        copy.pickable = this.pickable;
        copy.setTransform(this.transform);

        return copy;
    }
}