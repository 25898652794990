import theApp from '@/frame/Application';

//nur wegen XOpGroup
import OpFactory from '@/visual-events/model/OpFactory'
import BlockUtils from '@/visual-events/actions/BlockUtils'
import Geometry from '@/visual-events/model/Geometry';
import { Matrix4 } from 'three';
import { rad2Deg } from '@/frame/Useful'

/**
 * while creating a symbol grid in the PlaceBlock action the PlaceSymbolsList helps to 
 * build, arrange and rearrange the op tree dynamically
 *  
 *            block
 *   row    row    row    row
 *  s s s  s s s  s s s  s s s
 * 
 * In order to minimize allocation and deallocation of data items, the symbols and groups
 * for reuse are kept in this.symbols and this.groups    
 * 
 */
export default class PlaceSymbolsList {

    constructor (target) {
        this.target = target;
        this.prototype = null;
        this.block = BlockUtils.createBlockGroup();
        this.groups = [];
        this.symbols = [];
        // working matrix
        this.t = new Matrix4();
    }

    contains(op) {
        const find = this.symbols.filter(child => child === op);   
        if(find.length > 0) return true;
            return false;
    }

    /**
     * set or replace the prototype, i.e. the symbol, of which the block
     * is made up
     * @param {*} op 
     */
    setPrototype (op) {
        if (this.prototype !== op) {
            this.prototype = op;
            const cntY = this.groups.length;
            if (cntY > 0) {
                const cnt = this.symbols.length;
                this.adaptSymbols(0);
                this.adaptSymbols(cnt);
                this.attachSymbolsToRows(cnt / cntY, cntY)
            }
        }
    }

    /**
     * adapt the pools of symbols and groups to the required overall number of items
     * and rearrange the tree structure according ot
     * @param {*} grid 
     * @returns whether the list of symbols or group has actually been changed
     */
    adaptSymbolList(grid) {

        if (this.prototype === null)
            return;

        const changed = this.groups.length !== grid.getCntY() || this.symbols.length !== grid.getCntY() * grid.getCntX();

        this.adaptSymbols(grid.getCntY() * grid.getCntX());
        this.adaptGroups(grid.getCntY());
        this.attachSymbolsToRows(grid.getCntX(), grid.getCntY());

        return changed;
    }

    /**
     * add or remove symbols to resp. from the pool of prototype copies
     */
    adaptSymbols(newCnt) {
        const cnt = this.symbols.length;

        if (newCnt > cnt) {
            for (let i = cnt; i < newCnt; i++) {
                const op = this.prototype.copy();
                this.symbols.push(op);
            }
        } else if (newCnt < cnt) {
            for (let i = cnt; i > newCnt; i--) {
                const op = this.symbols.pop();
                op.removeFromParent();
            }
        }
    }

    /**
     * add or remove groups to resp. from the pool of rows
     */
    adaptGroups(newCnt) {
        const cnt = this.groups.length;

        if (newCnt > cnt) {
            for (let i = cnt; i < newCnt; i++) {
                const op = OpFactory.createGroup(`row`);
                this.groups.push(op);
                this.block.add(op);
            }
        } else if (newCnt < cnt) {
            for (let i = cnt; i > newCnt; i--) {
                const op = this.groups.pop();
                op.removeFromParent();
            }
        }
    }

    /**
     * distribute the symbols to the row groups
     * @param {*} grid 
     */
    attachSymbolsToRows(cntX, cntY) {
        for (let j = 0; j < cntY; j++)
            for (let i = 0; i < cntX; i++)
                this.groups[j].add(this.symbols[j * cntX + i]); 
    }

    /**
     * set the placing coordinates in all symbols
     * @param {*} grid 
     */
    adaptSymbolPositions(grid) {
        for (let j = 0; j < grid.getCntY(); j++) {
            for (let i = 0; i < grid.getCntX(); i++) {
                const [x, y] = grid.getCoord(i,j);
                const op = this.symbols[j * grid.getCntX() + i];
                const [u, v] = grid.getVectorX(i,j);
                const a = rad2Deg(Math.atan2(v,u));
                if (op) {
                    Geometry.makePlacingTransform(this.t, x, y, 0, a);
                    op.setTransform(this.t);
                }
            }
        }
    }

    /**
     * renumber symbols (seat numbers) and groups (row numbers)
     * @param {*} grid 
     * @param {*} renumberTool 
     */
    adaptSymbolNumbering(grid, renumberTool) {
        renumberTool.applyOnBlock(grid, this.block);
    }

    /**
     * reset the symbols list into the inial empty state
     * 
     * delete the symbols, if the group has no parent, i.e. it is not in the model op tree
     * or elsewhere
     */
    resetSymbolList() {
        if (!this.block.parent) {
            this.adaptSymbols(0);
            this.adaptGroups(0);
        }
    
        this.prototype = null;
        this.symbols = [];
        this.groups = [];
        this.block = BlockUtils.createBlockGroup();
    }

    addToModel() {
        this.target.add(this.block);
        theApp.model.changed2d = true; //op;
    }

    removeFromModel() {
        this.target.remove(this.block);
        theApp.model.changed2d = true; //op;
    }
}