import Action from './Action';
import CommandLineScanner from './CommandLineScanner';
import CommandPool from './CommandPool';
import Logger from './Logger';

const logger = new Logger('ActDefault');

export default class ActDefault extends Action {
  constructor () {
    super();
  }

  actionBreak (event) {
    Action.stopAll();
  }

  actionCommand (event) {
    const commandLine = event.commandLine; // Todo:commandline syntax auswerten, um Kommand und Argumente zu trennen
    const scanner = new CommandLineScanner(commandLine);

    const cmd = scanner.getCommand();

    const command = CommandPool.root.findCommand(cmd);

    if (command != null) {
      command.execute(...scanner.getArgs());
    } else {
      // TODO: Fehler/Warnungen Konzept incl. GUI
      // show a message
      logger.error(`Befehl ${cmd} nicht gefunden`);
    }
    return null;
  }
}
