<template>
    <div class="panel-table-circle-setting">
      <fieldset class="ve-row mb-3">
          <InputNumber label="Anzahl Stühle" class="col-sm-6" :min="0" :max="20" :step="1" v-model="numberOfChair" @update:modelValue="onChangeNumberOfChair" />
          <InputNumber label="Freiräume" class="col-sm-6" :min="0" :max="10" :step="1" v-model="freeSpaces" @update:modelValue="onChangeFreeSpaces" />
      </fieldset>
      <hr class="hr" />
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import InputNumber from '@/vuejs/components/InputNumber.vue';
import Settings from '@/visual-events/data/Settings';

export default {
  name: 'PanelTableCircleSetting',
  components: {
    InputNumber,
  },
  data () {
    return {
      numberOfChair: Settings.get('furniture.numberOfChair', 6),
      freeSpaces: Settings.get('furniture.freeSpaces', 0),
    }
  },
  mounted () {
    theApp.addDialog('PanelTableCircleSetting', this)
  },
  unmounted () {
    theApp.removeDialog('PanelTableCircleSetting')
  },
  methods: {
    update(action) {
      this.numberOfChair = action.numberOfChair;
      this.freeSpaces = action.freeSpaces;
    },   
    onChangeNumberOfChair() {
        theApp.executeValueEvent("numberOfChair", this.numberOfChair);
        Settings.set('furniture.numberOfChair', this.numberOfChair)
    },
    onChangeFreeSpaces() {
        theApp.executeValueEvent("freeSpaces", this.freeSpaces);
        Settings.set('furniture.freeSpaces', this.freeSpaces)
    },
  }
}
</script>

<style scoped>
</style>
