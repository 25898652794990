<template>
  <div class="home-grid">
    <div class="coupled-2d-3d">
      <WebGLRenderCanvas v-bind:viewName="'2D Ansicht'" style="border: black 1px dotted;">2D-Ansicht</WebGLRenderCanvas>
    </div>
    <div class="dlg-place-block">
      <div class="col-sm-12">
          <fieldset>
              <div>
                  <input class="col-sm-3" type="text" v-model="parentPath">
                  <input class="col-sm-3" type="text" v-model="fileName">
                  <button class="col-sm-2" @click="onSave()">Speichern</button>
              </div>
          </fieldset>
      </div>
      <DlgPlaceBlockOld></DlgPlaceBlockOld>
      <WebGLRenderCanvas v-bind:viewName="'Favorites'" style="height: 50vh" class="border">Symbole</WebGLRenderCanvas>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WebGLRenderCanvas from '@/vuejs/components/WebGLRenderCanvas.vue';
import DlgPlaceBlockOld from '@/vuejs/components/DlgPlaceBlockOld.vue';
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import Logger from '@/frame/Logger';

const logger = new Logger('Planer2D');

export default {
  name: 'Planer2DOld',
  props: {
    model: String,
    symbols: String,
    variant: String,
    action: String
  },

  data() {
        return {
          parentPath: 'Test',
          fileName: 'drawing.svg'
        }
  },
  components: {
    WebGLRenderCanvas,
    DlgPlaceBlockOld
  },
  methods: {
    onSave() {
        theApp.executeCommand(`.VisualEvents.saveSVG ${this.parentPath} ${this.fileName}`)
    },
    load() {
      const urlModel = Settings.get('.urlFileService') + "/files/$tenant/models/" + this.model + '/' + this.variant;
      //TODO:bessere Methode finden als action-aufruf after load
      logger.log(`.VisualEvents.loadSVG ${urlModel} ${this.action}`);
      theApp.sendCommand(`.VisualEvents.loadSVG ${urlModel} ${this.action}`);

      const pathFavorites = `$tenant/models/${this.model}/${this.symbols}`;
      logger.log('.VisualEvents.loadFavorites ' + pathFavorites);
      theApp.sendCommand('.VisualEvents.loadFavorites ' + pathFavorites)
      //theApp.sendCommand(this.action)
    },
    save() {
      const urlModel = Settings.get('.urlFileService') + "/files/$tenant/models/" + this.model + '/' + this.variant;
      theApp.sendCommand('.VisualEvents.saveSVG ' + urlModel);
    }
  },
  mounted () {
    // visualEvents.init();
    this.load();
  },
  updated () {
    this.load();
  }
}
</script>

<style scoped>
* {
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}
.home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.home-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow-x: hidden;
}

.coupled-2d-3d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.dlg-place-block {
  height: 100%;
}
</style>
