<template>
  <div class="panel-circle">
    <div class="sticky-top col-12 fw-bold">Stuhl</div>
    <div class="ve-row">
      <Div class="col-sm-12 ps-1 mb-0 mt-2  col-form-label">Größen</Div>
      <div class="col-6 mb-1">
        <select class="form-select col-sm-6" v-model="optionSize" @change="onChangeOptionSize($event)">
          <option key="0" value=0>Freie Eingabe</option>
          <option key="1" value=500>50 cm</option>
          <option key="2" value=600>60 cm</option>
          <option key="3" value=700>70 cm</option>
        </select>
      </div>
    </div>
    <div v-show="showInput">
      <InputNumberWithUnit class="col-6" unit="cm" label="Durchmesser" :scale="10" :min="1" :max="200" :step="1"
        :digits="1" v-model="diameter" @update:modelValue="onChangeDiameter" />
    </div>
    <div class="mt-3 ms-2 mb-0">Farbe</div>
    <div class="mt-0 ms-2 mb-3">
      <InputButtonColor label="" v-model="color" @update:modelValue="onChangeColor" />
      <hr class="hr" />
    </div>
  </div>
</template>

<script>
import theApp from '@/frame/Application';
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue';
import Settings from '@/visual-events/data/Settings';

export default {
  name: 'PanelChairCircle',
  components: {
    InputNumberWithUnit,
    InputButtonColor,
  },
  data() {
    return {
      optionSize: this.findOptionSize(Settings.get('furniture.chairDiameter', 500)),
      diameter: Settings.get('furniture.chairDiameter', 500),
      color: Settings.get('furniture.chairColor', '#FFFFFF'),
      showInput: this.optionSize === 0
    }
  },
  mounted() {
    theApp.addDialog('PanelChairCircle', this)
  },
  unmounted() {
    theApp.removeDialog('PanelChairCircle')
  },
  methods: {
    update(action) {
      this.diameter = action.diameter
      this.color = action.color
      this.optionSize = this.findOptionSize(this.diameter)
      this.showInput = this.optionSize === 0
    },
    onChangeOptionSize(event) {
      if (parseInt(event.target.value) !== 0) {
        this.diameter = parseInt(event.target.value);
        this.onChangeDiameter()
        this.showInput = false;
      } else {
        this.showInput = true;
      }
    },
    onChangeDiameter() {
      theApp.executeValueEvent("diameter", this.diameter)
      Settings.set('furniture.chairDiameter', this.diameter)
    },
    onChangeColor() {
      theApp.executeValueEvent("color", this.color)
      Settings.set('furniture.chairColor', this.color)
    },
    findOptionSize(diameter) {
      switch (diameter) {
        case 500:
        case 600:
        case 700:
          return diameter
        default:
          return 0
      }
    }
  }
}
</script>

<style scoped></style>
