import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText';
import * as THREE from 'three';

export default class VariantChairBanquet extends Variant {
    constructor (width, height, color) {
        const opts = {
            width: width,
            height: height,
            color: color
        }
        super ('CHAIR_BANQUET', 1, 'standard chair banquet', opts);
        this.t = new THREE.Matrix4();
    }

    createSymbol () {
        const width = this.json.opts.width;
        const height = this.json.opts.height;
        const color = this.json.opts.color;

        const banquet = OpFactory.createChair(-0.5 * width, 0, width, height);
        banquet.style.fill = color;
        const opSymbol = OpFactory.createSymbol(`${this.description}`);
        opSymbol.add(banquet);

        const opText = OpFactory.createText('?', 100, undefined, TextAnchor.middle, BaseLine.middle);
        opText.pickable = false;
        opText.setVisibility(false);
        opSymbol.add(opText);
        opSymbol.setAttribute('$seatNo', '?');
        opText.attachToAttribute(opSymbol, '$seatNo');
        this.t.makeTranslation(0.0, height / 2.0, 0);
        opText.setTransform(this.t);

        return opSymbol;
    }
}