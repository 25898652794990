<template>
    <div class="panel-rectangle border-bottom p-3">
        <fieldset class="ve-row">
            <InputNumberWithUnit class="col-6" label="Breite" unit="cm" :scale="10" :min=1 :digits="1" v-model="width" @update:modelValue="onChangeWidth" />
            <InputNumberWithUnit class="col-6" label="Höhe"  unit="cm" :scale="10" :min=1 :digits="1" v-model="height" @update:modelValue="onChangeHeight" />
            <InputNumberWithUnit class="col-6" unit='°' label="Winkel" :periodic="true" v-model="angle" @update:modelValue="onChangeAngle" />
        </fieldset>
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import Settings from '@/visual-events/data/Settings'

export default {
  name: 'PanelRectangle',
  components: {
    InputNumberWithUnit,
  },
  data () {
    return {
        width: 0,
        height: 0,
        angle: 0
    }
  },
  mounted () {
    theApp.addDialog('PanelRectangle', this)
  },
  unmounted () {
    theApp.removeDialog('PanelRectangle')
  },
  methods: {
    update(action) {
        this.angle = action.angle
        this.height = action.height
        this.width = action.width
    },    
    onChangeWidth () {
        theApp.executeValueEvent("width", this.width)
        Settings.set('rectangle.width', this.width)
    },
    onChangeHeight () {
        theApp.executeValueEvent("height", this.height)
        Settings.set('rectangle.height', this.height)
    },
    onChangeAngle () {
        theApp.executeValueEvent("angle", this.angle)
        Settings.set('rectangle.angle', this.angle)
    }
  }
}
</script>

<style scoped>
</style>
