import CADdySVGExporter from '@/visual-events/loader/CADdySVGExporter';
import { Matrix4} from 'three';
import OpSymbol from '@/visual-events/model/OpSymbol';
import OpReference from '@/visual-events/model/OpReference';
import OpGroup from '@/visual-events/model/OpGroup';
import OpMesh from '@/visual-events/model/OpMesh';
import OpShapePath from '@/visual-events/model/OpShapePath';
import OpText from '@/visual-events/model/OpText';
import OpPoint from '@/visual-events/model/OpPoint';

import Logger from '@/frame/Logger';
const logger = new Logger('CADdyJsonExporter');

const toFixed2 = mat4 => mat4.elements.map(n => Number(n.toFixed(2)))

export default class CADdyJsonExporter {
    
    constructor () {
        this.indent = '';
        this.indentStack = [];

        this.t = new Matrix4();
    }

    /**
     * build a stringified json according to model.ve
     * 
     * TODO: write function for partial models; possibly OpModel as OpObject
     * @param {*} model 
     * @returns json text ready to save in a file model.ve
     */
    write(model) {
        logger.log('write')
        
        const symbols = model.getReferencedSymbols();
        const drawing = model.drawing[0];
        const space = model.space;

        const json = {
            symbols: this.writeSymbols (symbols),
            drawing: this.writeOpObject (drawing),
            space: this.writeOpObject(space)
        }
        return JSON.stringify(json);
    }

    writeSymbols (symbols) {
        logger.log('writeSymbols')
        const json = {};
        for (const op of symbols)
            json[op.symbolId] = this.writeOpObject(op);

        return json;
    }

    writeOpObject (op) {

        // store transforms in row major order
        this.t.copy(op.transform);
        this.t.transpose();

        if (op instanceof OpSymbol) {

            logger.log(`${this.indent}OpSymbol ${op.id} ${op.name} ${op.symbolId}`);
            logger.log(`${this.indent}${toFixed2(op.transform)}`);
            const obj = {
                id: op.id,
                type: 'OpSymbol',
                name: op.name,
                visible: op.visible,
                pickable: op.pickable,
                transform: [...this.t.elements],
                attributes: op.attributes,
                children: this.writeChildren(op),
                symbolId: op.symbolId
            }
            return obj;

        } else if (op instanceof OpReference) {

            logger.log(`${this.indent}OpReference ${op.id} ${op.name} ${op.symbolId}`);
            logger.log(`${this.indent}${JSON.stringify(toFixed2(op.transform))}`);

            const obj = {
                id: op.id,
                type: 'OpReference',
                name: op.name,
                visible: op.visible,
                pickable: op.pickable,
                transform: [...this.t.elements],
                attributes: op.attributes,
                children: this.writeChildren(op),
                symbolId: op.symbolId
            }
            return obj;

        } else if (op instanceof OpGroup) {

            logger.log(`${this.indent}OpGroup ${op.id} ${op.name}`);
            logger.log(`${this.indent}${JSON.stringify(toFixed2(op.transform))}`);

            const obj = {
                id: op.id,
                type: 'OpGroup',
                name: op.name,
                visible: op.visible,
                pickable: op.pickable,
                transform: [...this.t.elements],
                attributes: op.attributes,
                children: this.writeChildren(op)
            }
            return obj;

        } else if (op instanceof OpMesh) {

            logger.log(`${this.indent}OpMesh ${op.id} ${op.name} ${op.url}`);
            logger.log(`${this.indent}${JSON.stringify(toFixed2(op.transform))}`);

            const obj = {
                id: op.id,
                type: 'OpMesh',
                name: op.name,
                visible: op.visible,
                pickable: op.pickable,
                transform: [...this.t.elements],
                attributes: op.attributes,
                children: this.writeChildren(op),
                url: op.url
            }
            return obj;
        } else if (op instanceof OpShapePath) {
            logger.log(`${this.indent}'OpShapePath' ${op.id} ${op.name} ${op.url}`);
            logger.log(`${this.indent}${JSON.stringify(toFixed2(op.transform))}`);

            const svgExporter = new CADdySVGExporter(); //TODO: extract buildPath from SVGExporter into e.g.SVGUtils; parse, too
            const d = svgExporter.buildPath(op.path);

            const obj = {
                id: op.id,
                type: 'OpShapePath',
                name: op.name,
                visible: op.visible,
                pickable: op.pickable,
                transform: [...this.t.elements],
                attributes: op.attributes,
                children: this.writeChildren(op),
                path: d,
                style: op.style
            }
            return obj;

        } else if (op instanceof OpText) {
            logger.log(`${this.indent}OpText ${op.id} ${op.name} ${op.url}`);
            logger.log(`${this.indent}${JSON.stringify(toFixed2(op.transform))}`);

            const obj = {
                id: op.id,
                type: 'OpText',
                name: op.name,
                visible: op.visible,
                pickable: op.pickable,
                transform: [...this.t.elements],
                attributes: op.attributes,
                children: this.writeChildren(op),
                text: op.text,
                fontSize: op.fontSize,
                textAnchor: op.textAnchor,
                baseLine: op.baseLine,
                style: op.style,
                xref: op.xref
            }

            return obj;

        } else if (op instanceof OpPoint) {

            logger.log(`${this.indent}OpPoint ${op.id} ${op.name}`);
            logger.log(`${this.indent}${JSON.stringify(toFixed2(op.transform))}`);

            const obj = {
                id: op.id,
                type: 'OpPoint',
                name: op.name,
                visible: op.visible,
                pickable: op.pickable,
                transform: [...this.t.elements],
                attributes: op.attributes,
                children: this.writeChildren(op)
            }
            return obj;

        }
    }

    writeChildren (op) {
        const a = [];

        this.pushIndentation();

        for (const child of op.children)
            a.push(this.writeOpObject(child));

        this.popIndentation();

        return a;
    }

    /**
     * increase or decrease indentation
     */
    pushIndentation() {
        this.indentStack.push(this.indent);
        this.indent += '  ';
    }

    popIndentation() {
        this.indent = this.indentStack.pop();
    }
}