import { Matrix4 } from 'three';
import Observer from '@/frame/Observer';
import Geometry from '@/visual-events/model/Geometry';
import theApp from '@/frame/Application';

export default class Link2DTo3D extends Observer {

    static t = new Matrix4();

    constructor(op2D, op3D, hint) {

        super();

        this.op2D = op2D;
        this.op3D = op3D;
        this.hint = hint;

        // working matrix
        this.t = new Matrix4();
    }

    /**
     * on 'setTransform' equalize the transform of the 2D and 3D counterparts
     * with exception of the height, i.e. z in the translational part.
     * 
     * Z is calculated according to the hint, which defines, how the height is to calculate:
     * - ground level in a special area of the plan
     * - height information attached to the item 
     * 
     * TODO: Anstatt direkt zu transformieren, Instanz einbauen, die sich die gewünsche Transformation merkt
     * und diese asynchron erledigen; die letzte gewinnt
     * @param {*} notification 
     * @returns 
     */
    onNotify (notification) {
        switch(notification.name) {
            case 'setTransform': {
                const sender = notification.data.sender;
                if (sender === this.op2D) {
                    this.t.copy(this.op2D.transform);
                    Geometry.setHeight(this.t, this.determineHeight(this.op3D, this.hint)); 
                    if (!this.t.equals(this.op3D.transform)) {
                        this.op3D.setTransform(this.t);
                        theApp.model.modifiedIn3D.push(this.op3D);
                    }
                }
                else if (sender === this.op3D) {
                    this.t.copy(this.op3D.transform);
                    Geometry.setHeight(this.t, 0); // in 2D always z=0
                    if (!this.t.equals(this.op2D.transform)) {
                        this.op2D.setTransform(this.t);
                        theApp.model.changed2d = true;
                    }
                }
                break;
            }
            case 'setVisibility': {
                const sender = notification.data.sender;
                if (sender === this.op2D) {
                    if(this.op2D.visible != this.op3D.visible) {
                        this.op3D.setVisibility(this.op2D.visible);
                        theApp.model.modifiedIn3D.push(this.op3D);
                        // theApp.model.changed3d = true;
                    }
                }
                else if (sender === this.op3D) {
                    if(this.op2D.visible != this.op3D.visible) {
                        this.op2D.setVisibility(this.op3D);
                        theApp.model.modifiedIn2D.push(this.op2D);
                    }
                }
                break;
            }
        } 
    }  

    /**
     * determine the height of the 3D object
     */
    determineHeight (op3D, hint) {
        // not implemented
        return 0.0;
    }
}