import OpFactory from '@/visual-events/model/OpFactory';
import OpReference from '@/visual-events/model/OpReference'
import OpGroup from '@/visual-events/model/OpGroup'
import BlockUtils from '@/visual-events/actions/BlockUtils';
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';

const isGroup = op => op instanceof OpGroup;

/**
 * synchronizes the adding and removal of 3D objects linked to 2D symbols
 * 
 * s. also Link2DTo3D
 */
export default class Synchronizer3D {

    constructor() {

    }

    /**
     * find the corresponding 3D block to block2D
     * @param {*} block2D 
     * @returns 3D block root, null, if no such exists
     */
    find3DBlock(block2D) {
        let result = null;

        let iterateModel = children => {
            children.some(op2D => {
                if (op2D instanceof OpReference) {
                    const link = Event3DPlacing.findLink2DTo3D(op2D);
                    if (link) {
                        const block = BlockUtils.findBlockGroup(link.op3D);
                        if (block) {
                            result = block;
                            return true;
                        }
                    }
                } else if (isGroup(op2D))
                    return iterateModel(op2D.children);
                return false;
            });
        }
        
        iterateModel(block2D.children);

        return result;
    }

    /**
     * walk through the tree below root op and collect all existings 2D 3D links
     * and fill the map with op2D.symbolId -> op3D.symbolId
     * @param {*} op 
     * @param {*} map 
     */
    fillMap(op, map) {
        if (op instanceof OpReference) {
            const link = Event3DPlacing.findLink2DTo3D(op);
            if (link)
                map[link.op2D.symbolId] = link.op3D.symbolId;
        } else {
            for (const child of op.children)
                this.fillMap(child, map);
        }
    }

    /**
     * ensure, that the 3D OpObject tree below root3D is according to the 2D Symbol tree in root2D
     * 
     * - add resp. remove 3D counterparts
     * - maintain the Link2DTo3D connections
     * - build a corresponding group structure
     * 
     * map provides the information, which 3D symbolId to take for a given 2D symbolId
     * 
     * @param {*} map 
     * @param {*} root2D 
     * @param {*} root3D 
     */

    apply(root2D, root3D, map) {
        this.addMissing3DReferences(root2D, root3D, map);
        this.removeObsolete3DReferences(root2D, root3D);
    }

    addMissing3DReferences(root2D, root3D, map) {
        for (const op2D of root2D.children) {
            if (op2D instanceof OpReference) {
                const link = Event3DPlacing.findLink2DTo3D(op2D);
                if (!link) {
                    if (map[op2D.symbolId]) {
                        const op3D = OpFactory.createReference(op2D.name, map[op2D.symbolId]);
                        const hint = ''; //TODO: Synchronizer3D.apply: welcher hint? auch in die Map?
                        Event3DPlacing.link2DTo3D(op2D, op3D, hint);
                        op2D.setTransform(op2D.transform); // notify
                        root3D.add(op3D);
                    }
                }
                    
            } else if (isGroup(op2D)) {
                let opGroup3D = root3D.children.find(op => isGroup(op) && op.name === op2D.name);
                if (!opGroup3D) {
                    opGroup3D = OpFactory.createGroup(op2D.name);
                    root3D.add(opGroup3D);
                }
                this.addMissing3DReferences(op2D, opGroup3D, map);
            }
        }
    }

    removeObsolete3DReferences(root2D, root3D) {
        const deleteList = [];
        for (const op3D of root3D.children) {
            if (op3D instanceof OpReference) {
                const link = Event3DPlacing.findLink2DTo3D(op3D);
                if (link) {
                    if (!root2D.findOpObjectById(link.op2D.id)) {
                        Event3DPlacing.unlink2DFrom3D(link.op2D, op3D);
                        deleteList.push(op3D);
                    }
                }
            } else if (isGroup(op3D))
                this.removeObsolete3DReferences(root2D, op3D);
        }

        for (const op3D of deleteList) 
            op3D.removeFromParent();

        //TODO: removeObsolete3DReferences, remove empty groups, memory leaks???!!
    }
}