import theApp from '@/frame/Application'

import Command from '@/frame/Command';
import CommandPool from '@/frame/CommandPool';
import ModelIO from '@/visual-events/model/ModelIO';
import UtilSettings from '@/visual-events/data/UtilSettings';
import Settings from '@/visual-events/data/Settings';

import Logger from '@/frame/Logger';

const logger = new Logger('CommandPoolIO');

/**
 * this command pool contains commands for loading and saving 
 * 
 * general rules:
 * - the url may contain or not contain a filename and/or extension
 *   defaults are 'model' as name and 've' as format
 *   whether other formats, such as 'svg', 'gltf' etc. are applicable depends
 *   on the situation.
 * - referenced symbols are only loaded, if not yet available
 * - be careful with clearing symbols. This should not be done, if they are referenced in OpReferences
 * - all commands are synchronious, i.e. the app will only proceed when loading and saving are completed or 
 *   aborted in case of error. Usually a wait cursor or progress bar will appear, and messages in case of
 *   errors
 * - existing views on the affected model update automatically
 * - all obsolete resources are disposed
 * 
 * examples:
 * 
 * let id be the op id of theApp.model.drawings[0];
 * 
 * .io.load { root: drawing, url: 'drawing.svg' }
 *    load the svg into the current drawing
 * 
 */
export default class CommandPoolIO extends CommandPool {
    constructor() {
        super(CommandPool.root, 'io');
        this.addCommands([
            new Command('clear', args => this.doClear(args)),
            new Command('load', args => this.doLoad(args)),
            new Command('loadInto', args => this.doLoadInto(args)),
            new Command('save', args => this.doSave(args)),
            new Command('saveAs', args => this.doSaveAs(args)),
            new Command('clearSymbols', args => this.doClearSymbols(args)),
            new Command('loadSymbols', args => this.doLoadSymbols(args)),
            new Command('saveAll', args => this.doSaveAll(args)),
        ]);
    }

    /**
     * .io.clear  { root: OpObject|id (optional), url: Url (optional) }
     * 
     * delete the current model and optionally load a template from the url
     * 
     * The difference to load is, that the model does not have the template url. 
     * The url is empty until stored for the first time.
     * 
     * @param {*} args 
     */
    doClear(args) {
        logger.log(`.io.clear ${JSON.stringify(args)}`);
        const opts = args.length > 1 ? args[1] : {};
        ModelIO.doClearSymbols(opts);
    }

    /**
     * .io.load { root: OpObject|id (optional), url: Url }
     * 
     * delete the current model and load the model from url.
     * 
     * The model remembers the url.
     * 
     * @param {*} args 
     */
    doLoad(args) {
        logger.log(`.io.load ${JSON.stringify(args)}`);
        const opts = args.length > 1 ? args[1] : {};
        ModelIO.doLoad(opts);
    }

    /**
     * .io.loadInto { root: OpObject|id (optional), url: Url }
     * 
     * load from url and insert into the root 
     * 
     * The model's url does not change. The inserted root remembers the url
     * 
     * @param {*} args 
     */
    doLoadInto(args) {
        logger.log(`.io.loadInto ${JSON.stringify(args)}`);
        const opts = args.length > 1 ? args[1] : {};
        ModelIO.doLoadInto(opts);
    }
    
    /**
     * .io.save { root: OpObject|id (optional), url: Url (optional) }
     * 
     * saves the current model.
     * 
     * If url is set, save into this fle, but do not change the root's url.
     * Otherwise save into the root's url.
     * If the root has not been assigned an url, the option url is mandatory
     * and the root will remember this url. 
     * 
     * @param {*} args 
     */
    doSave(args) {
        logger.log(`.io.save ${JSON.stringify(args)}`);
        const opts = args.length > 1 ? args[1] : {};
        ModelIO.doSave(opts);
    }

    /**
     * .io.saveAs { root: OpObject|id (optional), url: Url }
     * 
     * saves the current model to the url
     * 
     * The model's url changes.
     * 
     * @param {*} args 
     */
    doSaveAs(args) {
        logger.log(`.io.saveAs ${JSON.stringify(args)}`);
        const opts = args.length > 1 ? args[1] : {};
        ModelIO.doSaveAs(opts);
    }

    /**
     * .io.clearSymbols { symbols: [] of OpSymbol|symbolId (optional) }
     * 
     * removes symbols from the OpSymbols, all if not listed explicitly
     * 
     * @param {*} args 
     */
    doClearSymbols(args) {
        logger.log(`.io.clearSymbols ${JSON.stringify(args)}`);
        const opts = args.length > 1 ? args[1] : {};
        ModelIO.doClearSymbols(opts);
    }

    /**
     * .io.loadSymbols { url: Url (optional) symbols: [] of OpSymbol|symbolId (optional) }
     * 
     * loads symbols into the the OpSymbols, all in the url or only the listed.
     * If no url is set, take the current model's url.
     * 
     * @param {*} args 
     */
    doLoadSymbols(args) {
        logger.log(`.io.loadSymbols ${JSON.stringify(args)}`);
        const opts = args.length > 1 ? args[1] : {};
        ModelIO.doLoadSymbols(opts);
    }

    /**
     * .io.saveAll
     * 
     * saves the current model AND the settings
     * TODO: this is only a preliminary solution for the problem, that
     * the automatical save at the end of the app does not work.
     * remove this asap
     * 
     * @param {*} args 
     */
    doSaveAll(args) {
        logger.log(`.io.saveAll`);
        ModelIO.doSave(Settings.get('.model'));
        UtilSettings.write(Settings.get('.pathSettings'));
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
    }

}