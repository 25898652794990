import theApp from '@/frame/Application';
import Action from '@/frame/Action';
import { CommandEvent } from '@/frame/Event.js';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectAndDispatch from '@/visual-events/actions/FltSelectAndDispatch';
import Geometry from '@/visual-events/model/Geometry';
import SelectGrafic from '@/visual-events/actions/SelectGrafic';
import { nextValue, rad2Deg } from '@/frame/Useful';
import * as THREE from 'three';

import Logger from '@/frame/Logger';

const logger = new Logger('FltSelectGrafic');

const State = Object.freeze({
    EDIT: 2,            // wait for picking SelectGrafic items or other objects in the plan
    DRAG_GEOPOINT: 3,   // while dragging at a geometry point
    DRAG_IN_2D: 4,      // while moving around the selected objects
    DRAG_IN_ROTATE: 5   // while rotating the selected objects
});

const toFixed2 = mat4 => mat4.elements.map(n => Number(n.toFixed(2)))

/**
 * show a selection frame covering the box around the selected objects, geometry points 
 * and icons for a list of selected objects in order to provide functionality such as
 * - move by dragging the whole selection
 * - rotate the whole selection by dragging the rotation icon
 * - modify the objects' size by dragging at geometry points
 * - copy and delete with icons
 */
export default class FltSelectGrafic extends Action {

    constructor (box, transform) {
        super();

        logger.log(`constructor({${box.min.x} ${box.max.x} x ${box.min.y} ${box.max.y}, ${toFixed2(transform)}})`);

        this.doDragAndDrop = true;
 
        this.box = box.clone();
        this.transform = transform.clone();

        // current mouse position
        this.position = new THREE.Vector3(0, 0, 0);
  
        // working matrix
        this.t = new THREE.Matrix4();
  
        this.p0 = new THREE.Vector3();
        this.q0 = new THREE.Vector3();
        // differential transform drag & drop
        this.tdiff = new THREE.Matrix4();
        // rotation while drag&drop
        this.rotation = Geometry.getRotationAngle(transform);
  
        // rotation with icon
        this.rotation0 = 0;
        this.center = new THREE.Vector3(0, 0, 0);

        // the temporary grafic is added to root2D
        this.view2D = theApp.findViewByName('2D Ansicht');
        this.root2D = this.view2D.getRoot().children[0];

        this.selectGrafic = new SelectGrafic();

        this.state = State.EDIT;
    }

    useBoxPoints (use=true) { this.selectGrafic.useBoxPoints(use); return this; }
    useDeleteIcon (use=true) { this.selectGrafic.useDeleteIcon(use); return this; }
    useCopyIcon (use=true) { this.selectGrafic.useCopyIcon(use); return this; }
    useGroupIcon (use=true) { this.selectGrafic.useGroupIcon(use); return this; }
    useSaveGroupIcon (use=true) { this.selectGrafic.useSaveGroupIcon(use); return this; }
    useRotateIcon (use=true) { this.selectGrafic.useRotateIcon(use); return this; }
    useReflectHorizontalIcon (use=true) { this.selectGrafic.useReflectHorizontalIcon(use); return this; }
    useReflectVerticalIcon (use=true) { this.selectGrafic.useReflectVerticalIcon(use); return this; }
    sendBreakOnVoid (use=true) { this.selectGrafic.sendBreakOnVoid(use); return this; }
    useDragAndDrop (use=true) { this.doDragAndDrop = use; return this; }

    /**
     * in certain use cases the calling action decides that the box and transform 
     * must be modified. 
     * 
     * Example:
     * When dragging the box point of a circle the box must be forced afterwards to become
     * a square. 
     * 
     * To achieve this, use the update method in the context of the command implementation
     * of '.select.dragBoxPoint'
     * @param {*} box (optional)
     * @param {*} transform (optional)
     */
    update(box, transform) {
        if (box)
            this.box = box;

        if (transform)
            this.transform.copy(transform);

        this.selectGrafic.adapt(this.box, this.transform);
        theApp.model.changed2d = true; //op;
    }

    applyTransform(tdiff) {
        this.transform.multiply(tdiff);
        this.selectGrafic.applyTransform(tdiff);
        theApp.model.changed2d = true; //op;
    }

    /**
     * if the caller needs information about the current transform or box
     */
    getTransform () {
        return this.selectGrafic.getTransform();
    }

    getRotationAngle () {
        return this.selectGrafic.getRotationAngle();
    }

    getCenter (center) {
        this.selectGrafic.getCenter(center);
    }

     /**
     * check if a PointEvent hits the selection area
     * @param {*} event 
     * @returns true, if inside, false otherwise
     */
    hitsArea (event) {
        return this.selectGrafic.hitsArea(event);
    }

    /**
     * in copy selection the copy is shifted 
     * In this case just shift the SelectGrafic, too.
     * @param {*} dist 
     */
    shift (dist) {
        this.selectGrafic.shift(dist);
    }

    actionStart () {
        logger.log(`actionStart`);
        this.showTemporaryGrafic(this.box, this.transform);
        this.addFilter(new FltSelectAndDispatch().first(this.selectGrafic));
        this.adaptCursor();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        this.resetCursor();
        this.clearTemporaryGrafic();
    }

    actionKey (event) { 
        logger.log(`actionKey`);
        if (this.selectGrafic) {
            const commandEvent = this.selectGrafic.actionKey(event);
            if (commandEvent)
                return this.actionCommand(commandEvent);
        }
        switch (this.state) {
            case State.EDIT:
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_ROTATE:
                 // ignore
                break;
            case State.DRAG_IN_2D: {
                const raw = event.raw;
                if (raw.type === 'keydown' && raw.key === 'ArrowRight')
                    this.rotation += 15;
                if (raw.type === 'keydown' && raw.key === 'ArrowLeft')
                    this.rotation -= 15;

                Geometry.calcDragAndDropTransformation(this.t, this.q0, this.rotation, this.position);

                Geometry.calcDifference(this.tdiff,  this.transform, this.t);

                this.adaptTemporaryGrafic();

                return new CommandEvent('.select.applyTransform', this.tdiff);
            }
        }
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);
        switch (this.state) {
            case State.EDIT:
                // ignore
                break;
            case State.DRAG_GEOPOINT: {

                this.position.x = event.p[0];
                this.position.y = event.p[1];

                // initial cursor position
                this.p0.set(this.position.x, this.position.y, 0);
    
                Geometry.getRelativeCoords(this.selectGrafic.getTransform(), this.p0, this.q0);

                const box = this.selectGrafic.box.clone();
                switch (this.dragMode)
                {
                    case 'left':
                        box.min.x = this.q0.x;
                        break;
                    case 'right':
                        box.max.x = this.q0.x;
                        break;
                    case 'top':
                        box.max.y = this.q0.y;
                        break;
                    case 'bottom':
                        box.min.y = this.q0.y;
                        break;
                    case 'rightTop':
                        box.max.x = this.q0.x;
                        box.max.y = this.q0.y;
                        break;
                    case 'leftTop':
                        box.min.x = this.q0.x;
                        box.max.y = this.q0.y;
                        break;
                    case 'rightBottom':
                        box.max.x = this.q0.x;
                        box.min.y = this.q0.y;
                        break;
                    case 'leftBottom':
                        box.min.x = this.q0.x;
                        box.min.y = this.q0.y;
                        break;
                }

                this.box.copy(box);
                this.adaptTemporaryGrafic();

                //inform calling action
                return new CommandEvent('.select.dragBoxPoint', this.dragMode, this.box);
            }
            case State.DRAG_IN_2D: {
                this.position.x = event.p[0];
                this.position.y = event.p[1];

                Geometry.calcDragAndDropTransformation(this.t, this.q0, this.rotation, this.position);

                Geometry.calcDifference(this.tdiff,  this.selectGrafic.getTransform(), this.t);

                this.adaptTemporaryGrafic();

                this.transform.copy(this.selectGrafic.getTransform());

                //inform calling action
                return new CommandEvent('.select.applyTransform', this.tdiff);
            }
            case State.DRAG_IN_ROTATE: {
                this.position.x = event.p[0];
                this.position.y = event.p[1];

                this.rotation = rad2Deg(Math.atan2(this.position.y - this.center.y, this.position.x - this.center.x)) - this.rotation0;
                if(!event.raw.shiftKey)
                    this.rotation = nextValue(this.rotation, 15);
                
                Geometry.calcDragAndDropTransformation(this.t, this.q0, this.rotation, this.center);

                Geometry.calcDifference(this.tdiff,  this.selectGrafic.getTransform(), this.t);

                this.adaptTemporaryGrafic();

                this.transform.copy(this.selectGrafic.getTransform());

                //inform calling action
                return new CommandEvent('.select.applyTransform', this.tdiff);
            }
        }
        return null;
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.EDIT:
                // ignore
                break;
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_2D:
            case State.DRAG_IN_ROTATE: {
                this.state = State.EDIT;
                this.addFilter(new FltSelectAndDispatch().first(this.selectGrafic));
                break;
            }
        }

        this.adaptCursor();
        this.handleCameraControlsEnableState();

        return null;
    }

    actionCommand(event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.EDIT: {
                switch (cmd) {
                    case '.select.delete':
                    case '.select.copy':{
                        // just propagate to calling action
                        return event;
                    }
                    case '.select.rotate': {
                        const p  = event.args[0];
                        this.position.x = p[0];
                        this.position.y = p[1];
        
                        // box center is center of the roation
                        this.selectGrafic.getCenter(this.center);

                        this.p0.set(this.center.x, this.center.y, 0);
            
                        Geometry.getRelativeCoords(this.selectGrafic.getTransform(), this.p0, this.q0);

                        const angle = Geometry.getRotationAngle(this.transform);
                        const rotation = this.selectGrafic.getRotationAngle();
                        this.rotation0 = rotation - angle + 90;

                        this.state = State.DRAG_IN_ROTATE;
                        this.addFilter(new FltPointDef());
                        this.adaptCursor();
                        return null;
                    }
                    case '.select.saveGroup':{
                        theApp.executeCommand('.planer.saveGroup')
                        return null
                    }
                    case '.select.leftBottom':
                    case '.select.bottom':
                    case '.select.rightBottom':
                    case '.select.right':
                    case '.select.rightTop':
                    case '.select.top':
                    case '.select.leftTop':
                    case '.select.left': {
                        this.state = State.DRAG_GEOPOINT;
                        this.addFilter(new FltPointDef());
                        this.dragMode = cmd.substring(8,cmd.length);
                        this.adaptCursor();
                        this.handleCameraControlsEnableState();

                        return null;
                    }

                    case '.select.area': {
                        if (this.doDragAndDrop) {
                            const p  = event.args[0];
                            this.position.x = p[0];
                            this.position.y = p[1];
            
                            // initial cursor position
                            this.p0.set(this.position.x, this.position.y, 0);
                
                            Geometry.getRelativeCoords(this.selectGrafic.getTransform(), this.p0, this.q0);
                            this.rotation = this.selectGrafic.getRotationAngle();
    
                            this.state = State.DRAG_IN_2D;
                            this.addFilter(new FltPointDef());
                            this.adaptCursor();
                            this.handleCameraControlsEnableState();
                        }
                        return null;
                    }
                }
            } // case EDIT
        } // switch state

        return event;
    }

    showTemporaryGrafic () {
        switch (this.state) {
            case State.EDIT: {
                this.root2D.add(this.selectGrafic.create(this.box, this.transform));
                theApp.model.changed2d = true; //op;
                break;
            }
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_2D:
            case State.DRAG_IN_ROTATE:
                // does not happen
                break;
        }
    }

    clearTemporaryGrafic () {
        this.selectGrafic?.dispose();
        this.selectGrafic = null;
        theApp.model.changed2d = true; //op;
    }

    adaptTemporaryGrafic () {
        switch (this.state) {
            case State.EDIT:
            case State.DRAG_GEOPOINT:
            {
                this.selectGrafic.adapt(this.box, this.transform);
                theApp.model.changed2d = true; //op;
                break;
            }
            case State.DRAG_IN_2D:
            case State.DRAG_IN_ROTATE:
            {
              this.selectGrafic.applyTransform(this.tdiff);
              theApp.model.changed2d = true; //op;
              break;
            }
        }
    }

    /**
     * while dragging disable the CameraControls
     */
    handleCameraControlsEnableState() {
        switch (this.state) {
            case State.DRAG_IN_2D: 
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_ROTATE:
            {
                this.view2D.disableCameraControls();
                break;
            }
            default: {
                this.view2D.enableCameraControls();
                break;
            }
        }
    }

    adaptCursor () {
        switch(this.state) {
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_2D:
            case State.DRAG_IN_ROTATE: {
                theApp.findDialogByName('2D Ansicht')?.setCursor('grabbing');
                break;
            }
            case State.EDIT: {
                theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
                break;
            }
       }
    }

    resetCursor () {
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
    }

}