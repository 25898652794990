import * as THREE from 'three';

import Geometry from '@/visual-events/model/Geometry';
import OpPoint from '@/visual-events/model/OpPoint';
import { THREE_TO_OP } from '@/visual-events/model/OpCoordinates.js';

import Logger from '@/frame/Logger';

const logger = new Logger('CameraUtils');

const f = n => Number(n.toFixed(2))
const toFixed2 = mat4 => mat4.elements.map(n => Number(n.toFixed(2)))

export default class CameraUtils {

    /**
     * create the initial camera position for a view
     * 
     * intended to be added to the root of a drawing or a space
     * 
     * view.getRoot().add(OpPoint);
     * 
     * Remark: 
     * The position and direction is implicitly given by the transform, and thus
     * need not be stored into the json attribute.
     *  
     * But 'target' is more than the direction, into which the transform directs. 
     * Namely for the initial state of the OrbitControl 'target' is the focus point, 
     * around which the user position orbits. The direction in transform does not contain
     * the information, in which distance the target is. So 'target' must be stored in
     * the json attribute.
     * 
     * @param {*} eye 
     * @param {*} target 
     * @param {*} up (optional, default is z-axis) //TODO: camera etc in three.js coordinates not in VisualEvent coordinates
     * @returns OpPoint with $initialCameraPosition
     */
    static defineInitialCameraPosition (eye, target, up) {

        const op = new OpPoint('initial camera position', eye, target, up);

        return CameraUtils.updateCameraPosition (op, eye, target, up);
    }

    /**
     * update the the position and target information in the OpPoint
     * 
     * @param {*} op 
     * @param {*} eye 
     * @param {*} target 
     * @param {*} up 
     * @returns OpPoint with $initialCameraPosition
     */
    static updateCameraPosition (op, eye, target, up = new THREE.Vector3( 0, 1, 0)) {
        op.transform.makeTranslation(eye);
        op.transform.lookAt(eye, target, up);

        logger.log(`update ${toFixed2(op.transform)}`)

        const json = {
            version: 1,
            target: {
                x: target.x,
                y: target.y,
                z: target.z
            }
        }

        op.setAttribute('$initialCameraPosition', json);

        return op;
    }

    /**
     * find the initial camera position in the subtree 'root', i.e. a drawing or a space
     * 
     * @param {*} root 
     * @returns OpPoint with $initialCameraPosition
     */
    static findInitialCameraPosition(root) {
        const found = root.children.find (op => op instanceof OpPoint && op.getAttribute('$initialCameraPosition'));
        if (found) 
            return found;

        for (const child of root.children) {
            const found = CameraUtils.findInitialCameraPosition(child);
            if (found)
                return found;
        }

        return null;
    }

    /**
     * add or update the initial camera position and target of the view
     * 
     * TODO: currently only implemented for the 3D view with an orbit control
     * @param {*} view 
     * @returns 
     */
    static storeInitialCameraPosition (view) {

        if (!view.root || !view.camera || !view.controls)
            return;

        this.logCamera(view, 'initial camera position');

        const eye = view.camera.position.clone();
        const target = view.controls.target.clone();

        eye.applyMatrix4(THREE_TO_OP);
        target.applyMatrix4(THREE_TO_OP);

        const opPoint = CameraUtils.findInitialCameraPosition(view.root);
        if (opPoint)
            CameraUtils.updateCameraPosition(opPoint, eye, target);
        else 
            view.root.add(CameraUtils.defineInitialCameraPosition(eye, target));
    }

    /**
     * restore the formerly stored initial camera position and target in the view
     * 
     * TODO: currently only implemented for the 3D view with an orbit control
     * @param {*} view 
     * @returns 
     */
    static restoreInitialCameraPosition (view) {

        if (!view.root || !view.camera || !view.controls)
            return false;

        const opPoint = CameraUtils.findInitialCameraPosition(view.root);
        if (opPoint) {

            const eye = new THREE.Vector3(...Geometry.getTranslation(opPoint.transform));
            const value = opPoint.getAttribute('$initialCameraPosition.target');
            const target = new THREE.Vector3(value.x, value.y, value.z);

            if (!target)
                return false; // internal error

            view.updateCamera(eye, target);

            CameraUtils.logCamera(view, 'restored initial camera position');

            return true;
        }

        return false;
    }

    /**
     * debug output for the current camera and orbit controls state
     * @param {*} camera 
     * @param {*} controls 
     * @param {*} mes 
     */
    static logCamera (view, mes) {

        if (!view.camera || !view.controls)
            return;

        const eye = view.camera.position;
        const target = view.controls.target;
        if (eye && target)
            logger.log(`${mes} ${f(eye.x)},${f(eye.y)},${f(eye.z)} -> ${f(target.x)},${f(target.y)},${f(target.z)}`);
        logger.log(`${toFixed2(view.camera.matrixWorld)}`);
    }

    /**
     * convenience method to calculate a centered eye position in the model 
     * 
     * assuming
     * - the 2D plan box is reasonable
     * - the average person has his/her eye in 165 cm
     * 
     * @param {*} view 
     * @returns eye
     */
    static calculateCenteredEyePosition (model) {

        // HACK: determination of start position relies on the drawing consisting of three sketchboards, 
        // the second of which is the architectural plan and knows the scale.
        const plan = model.drawing[0]?.children[1];
        if (!plan)
            return;

        const boundingBox = Geometry.computeBox(plan);
        const eye = new THREE.Vector3();
        boundingBox.getCenter(eye);
        eye.z = 1500;

        return eye;
    }
}