<template>
    <div class="panel-rectangle border-bottom p-3">
        <fieldset class="ve-row">
            <div Class="ve-FloorPlanSVG pe-auto p-3 mb-5 bg-body-tertiary rounded" style="width: 64px; height: 64px;">
                <svg @click="onPlaceDoor('ONE_WING')" width=" 72" height="72" viewBox="0 0 72 72 " :fill=fill1
                    xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_230_285)">
                        <rect width="64" height="64" rx="5" transform="matrix(-1 0 0 1 68 0)" :fill=fill1 />
                        <rect x="-0.5" y="0.5" width="63" height="63" rx="4.5" transform="matrix(-1 0 0 1 67 0)"
                        stroke="none" />
                    </g>
                    <rect x="-1" y="1" width="38" height="7"  transform="matrix(-1 0 0 1 55 48)" :fill=fill1
                        :stroke=stroke1 stroke-width="2" />
                    <path
                        d="M18.0009 8.9991C33.5 8.9991 43.5008 18.9977 45.5043 21.0029C47.7156 23.2161 55.9873 34 55.9873 48.0123"
                        :stroke=stroke1 stroke-width="2" />
                    <line x1="18" y1="48" x2="18" y2="8" :stroke=stroke1 stroke-width="2" />
                    <defs>
                        <filter id="filter0_d_230_285" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_230_285" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_230_285" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div Class="ve-FloorPlanSVG pe-auto p-3 mb-5 bg-body-tertiary rounded" style="width: 64px; height: 64px;">
                <svg @click="onPlaceDoor('TWO_WING')" width="72" height="72" viewBox="0 0 72 72" :fill=fill2
                    xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_266_433)">
                        <rect x="-0.5" y="0.5" width="62.2111" height="62.095" rx="4.5"
                            :fill=fill2 transform="matrix(-1 0 0 1 66.2324 0.210083)"  stroke="none" />
                        <rect x="-1" y="1" width="47.2308" height="5.87692" :fill=fill2  transform="matrix(-1 0 0 1 59.1074 41.3539)"
                            :stroke=stroke2 stroke-width="2" />
                        <path
                            d="M13.8463 17.7229C21.7229 17.7229 28.4581 24.4654 29.5998 25.6C30.86 26.8522 36.4922 33.4769 36.4922 41.3538"
                            :stroke=stroke2 stroke-width="2" />
                        <line x1="12.8457" y1="41.3539" x2="12.8457" y2="16.7385" :stroke=stroke2 stroke-width="2" />
                        <path
                            d="M59.1386 17.7233C51.262 17.7233 45.0621 24.4316 43.9203 25.5662C42.6601 26.8184 36.4924 33.477 36.4924 41.3539"
                            :stroke=stroke2 stroke-width="2" />
                        <line x1="60.1074" y1="41.3539" x2="60.1074" y2="16.7385" :stroke=stroke2 stroke-width="2" />
                    </g>
                    <defs>
                        <filter id="filter0_d_266_433" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_266_433" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_266_433" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div @click="onPlaceDoor('SWING')" Class="ve-FloorPlanSVG pe-auto p-3 mb-5 bg-body-tertiary rounded" style="width: 64px; height: 64px;">
                <svg width="72" height="72" viewBox="0 0 72 72" :fill=fill3 xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_266_455)">
                        <rect width="64" height="64" rx="5" transform="matrix(-1 0 0 1 68 0)" :fill=fill3 />
                        <rect x="-0.5" y="0.5" width="63" height="63" rx="4.5" transform="matrix(-1 0 0 1 67 0)"
                        stroke="none" />
                    </g>
                    <line x1="60" y1="57" x2="60" y2="7" :stroke=stroke3 stroke-width="2" />
                    <line x1="12" y1="7" x2="12" y2="57" :stroke=stroke3 stroke-width="2" />
                    <path d="M12 8C16.5 8 23 9 29 15C35 21 36 28.5 36 32C36 35.5 34.5 43.5 29 49C23.5 54.5 17.5 56 12.5 56"
                        :stroke=stroke3 stroke-width="2" />
                    <path d="M60 8C55.5 8 49 9 43 15C37 21 36 28.5 36 32C36 35.5 37.5 43.5 43 49C48.5 54.5 54.5 56 59.5 56"
                        :stroke=stroke3 stroke-width="2" />
                    <line x1="13" y1="32" x2="59" y2="32" :stroke=stroke3 stroke-width="2" />
                    <defs>
                        <filter id="filter0_d_266_455" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_266_455" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_266_455" result="shape" />
                        </filter>
                    </defs>
                </svg>

            </div>
        </fieldset>
        <fieldset class="ve-row">
            <InputNumberWithUnit title=" " class="col-6" label="Breite" unit="cm" default="100" :scale="10" :min=1 :digits="1"
                v-model="width" @update:modelValue="onChangeWidth" />
        </fieldset>
    </div>
</template>

<script>
import theApp from '@/frame/Application'
import Settings from '@/visual-events/data/Settings'
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue'
import {DoorType} from '@/visual-events/actions/VariantDoor'

export default {
    name: "PanelDoor",
    components: {
        InputNumberWithUnit
    },
    data() {
        const id = Settings.get('floorplan.door.id', DoorType.ONE_WING);
        return {
            id:  id,
            width: Settings.getOption('floorplan.door.variants', id, 'width', 980),
            pivot: Settings.getOption('floorplan.door.variants', id, 'pivot'),
            fill1:"white",
            fill2:"white",
            fill3:"white",
            stroke1:"black",
            stroke2:"black",
            stroke3:"black",
        }
    },
    mounted() {
        theApp.addDialog('PanelDoor', this)
    },
    unmounted() {
        theApp.removeDialog('PanelDoor')
    },
    methods: {
    update(action) {
        this.width = action.width,
        this.id = action.id
    },    
    onChangeWidth () {
        theApp.executeValueEvent("door.width", this.width)
        Settings.setValue('floorplan.door.variants', this.id, 'width', this.width)
    },
    onPlaceDoor (type) {
        this.id = DoorType[type];
        theApp.executeValueEvent("door.id", this.id)
        Settings.set('floorplan.door.id', this.id)
        theApp.executeCommand('.floorplan.placeDoor')
    },
    setActiveButton(id) {
            switch (id) {
                case DoorType.ONE_WING:{
                    this.fill1 = "#f5f5f5"
                    this.fill2 = "white"
                    this.fill3 = "white"
                    this.stroke1 = "#009aa3"
                    this.stroke2 = "black"
                    this.stroke3 = "black"
                    break;
                }
                case DoorType.TWO_WING:{
                    this.fill2 = "#f5f5f5"
                    this.fill1 = "white"
                    this.fill3 = "white"
                    this.stroke1 = "black"
                    this.stroke2 = "#009aa3"
                    this.stroke3 = "black"
                    break;
                }
                case DoorType.SWING:{
                    this.fill3 = "#f5f5f5"
                    this.fill2 = "white"
                    this.fill1 = "white"
                    this.stroke1 = "black"
                    this.stroke2 = "black"
                    this.stroke3 = "#009aa3"
                    break;
                }
            }
        },
        setDeactiveButton () {
            this.fill1 = "white"
            this.fill2 = "white"
            this.fill3 = "white"
            this.stroke1 = "black"
            this.stroke2 = "black"
            this.stroke3 = "black"
        }
  }
}
</script>

<style>
SVG {
        cursor: pointer;
    }
</style>