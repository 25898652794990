
import { Vector3, Matrix4 } from 'three';
import Geometry from '@/visual-events/model/Geometry';
import theApp from '@/frame/Application';
import CameraUtils from '@/visual-events/model/CameraUtils';
import GrfUnreal from '@/visual-events/view/GrfUnreal';
import Lighting from '@/visual-events/view/Lighting';
import PixelStreamingWrapper from '@/visual-events/pixelstreaming/PixelStreamingWrapper.js';
import View from '@/frame/View';

import Logger from '@/frame/Logger';
const logger = new Logger('VisualEventsUnrealView');

export default class VisualEventsUnrealView extends View {
  constructor (name, model) {
    super(name, model);
    
    this.root = null;

    // the exact knowledge about how to build and update the scene is extracted to GrfXXX objects
    this.grf = null

    this.pixelStreamingWrapper = null;

    this.startPositionSent = false;
    this.aspectRatioSent = false;
  }

  onWindowResize (factor = 1) {
    logger.log('onWindowResize');
    if (this.pixelStreamingWrapper && this.grf) {
      var { width, height } = this.calcSize();
      width *= factor;
      height *= factor;
      const aspectRatio = width / height;
      this.grf.emitUIInteractionSetAspectRatio(width, height);
    }
  }

  getRoot () {
    return this.root;
  }
  
  setRoot (op) {
    this.root = op;
  }

  init () {
    logger.log('init()');

    this.pixelStreamingWrapper = new PixelStreamingWrapper();

    this.grf = new GrfUnreal(this.pixelStreamingWrapper);

    this.pixelStreamingWrapper.init();

    this.pixelStreamingWrapper.addPlayStreamRejectedHandler(() => theApp.findDialogByName(this.name)?.setClickToPlay(true));

    this.pixelStreamingWrapper.addResponseHandler(this.handleResponse.bind(this));
  
    return false;
  }

  play () {
    this.pixelStreamingWrapper.play();
    theApp.findDialogByName(this.name)?.setClickToPlay(false);
  }

  isVideoReady () {
    const view = theApp.findDialogByName(this.name);
    if (!view)
        return false;

    return view.isVideoReady();
  }
  
  render (time) {
    logger.log('render(time)');

    if (this.isVideoReady() && this.root) {

        if (!this.startPositionSent) {
            this.sendStartPosition();
            this.startPositionSent = true;
        }
    
        if (!this.aspectRatioSent) {
            this.onWindowResize();
            this.aspectRatioSent = true;
        }
    
        this.grf.updateScene(this);
    }
  }

  dispose () {
    //TODO: release Pixelstreaming?
    this.initialized = false;
  }

  disposeScene() {
    //not implemented
  }

  clearLight () {
    this.grf?.emitUIInteractionClearLights();
  }
  
  clearScene () {
    //not implemented
  }

  updateScene (time) {
    //logger.log(`updateScene`, time);
    return this.grf.updateScene(this);
  }

  addLight() {
    if (theApp.model.light)
        Lighting.addLightUnreal(this.grf, theApp.model.light);
  }

  initCameraPosition() {
    // not implemented
  }

  /**
   * inform Unreal about the camera start position
   * 
   * Either get the predefined initial camera position 
   * or calculate the center of the 2D plan (not always reliable)
   */
  sendStartPosition () {
    const opCamera = CameraUtils.findInitialCameraPosition(this.root);
    if (opCamera) {
        this.grf?.emitUIInteractionSetCamera(opCamera.transform);
    } else {
        // a person of 1.65 m looks into y-axis direction, i.e. 90 degree
        const eye = CameraUtils.calculateCenteredEyePosition(this.model);
        const t = new Matrix4();
        Geometry.makePlacingTransform(t, eye.x , eye.y, eye.z, 90);
        this.grf?.emitUIInteractionSetCamera(t);
    }
  }

  setSunSkyTime(time) {
    this.grf?.emitUIInteractionSetSunSkyTime(time);
  }

  setSunSkyDay(day) {
    this.grf?.emitUIInteractionSetSunSkyDay(day);
  }

  setSunSkyMonth(month) {
    this.grf?.emitUIInteractionSetSunSkyMonth(month);
  }

  handleResponse(responseString) {
    logger.log('handleResponse()', responseString)
    try {
      const response = JSON.parse(responseString);

      switch (response.command) {
        case "finishedLoadingMesh":
          if (this.grf?.hasFinishedLoading(response.opts.name))
            theApp.findDialogByName('main')?.setShowOverlay(false);
          break;
        default:
          logger.log(`Command ${response.command} not implemented`)
          break;
      }
    } catch (err) {
      logger.error(err);
    }
  }
}