<template>
  <div class="container-fluid">
    <div class="ve-row justify-content-center mb-3">
        <div class="col-sm-12 d-flex justify-content-center">
            <span class="display-1">Saalplaneditor</span>
        </div>
    </div>
    <div class="ve-row justify-content-center mt-3">
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Buchungssystem</h5>
                    <p class="card-text"> Einstein/Stuhlreihen<br>Jörg Mitscherling</p>
                    <a :href="`#/booking?eventId=5&cartId=2&action=.VisualEvents.booking`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Buchungssystem</h5>
                    <p class="card-text">Einstein/Stuhlreihen<br>Michael Heinen</p>
                    <a :href="`#/booking?eventId=5&cartId=2&action=.VisualEvents.booking`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>

        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Buchungsplaner</h5>
                    <p class="card-text">Erstellen Sie einen neuen Buchungsplan in einem leeren Festzelt</p>
                    <a :href="`#/planerTicketing?action=.Ticketing.waitForUserInput&model=%24tenant%2Fmodels%2Fzeltplan`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>

        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Eventplaner</h5>
                    <p class="card-text">Erstellen Sie einen neuen Bestuhlungsplanung in einem leeren Festzelt</p>
                    <a :href="`#/planer?action=.Ticketing.waitForUserInput&model=%24tenant%2Fmodels%2FLeer`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>

        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Eventplaner Unreal - Zeltplan</h5>
                    <p class="card-text">Erstellen Sie eine neue Bestuhlungsplanung in einem leeren Festzelt'</p>
                    <a :href="`#/planer?action=.Ticketing.waitForUserInput&model=%24tenant%2Fmodels%2FGruppenraum&useUnreal=true`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>

        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Eventplaner (Unreal)</h5>
                    <p class="card-text">Erstellen Sie eine neue Bestuhlungsplanung in einem leeren Saal 'Einstein'</p>
                    <a :href="`#/planer?eventId=13&action=.Ticketing.waitForUserInput&model=einstein&useUnreal=true`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>

        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Eventplaner (Three - Demoraum)</h5>
                    <p class="card-text">Erstellen Sie eine neue Bestuhlungsplanung in einem leeren Saal 'Einstein'</p>
                    <a :href="`#/planer?eventId=13&action=.Ticketing.waitForUserInput`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>

        <!-- <div class="col-sm-auto">
            <div class="card model-card" style="width: 18rem;">
                <img :src="`images/Bankett.png`" height="180">
                <div class="card-body">
                    <h5 class="card-title">Einstein - Bankett (alt)</h5>
                    <p class="card-text">Bankettbestuhlung im Hotel Einstein</p>
                    <p class="card-text"><small class="text-muted">Vor 3 Minuten zuletzt bearbeitet</small></p>
                    <a :href="`#/planerOld?model=einstein&symbols=Tischgruppen&variant=Bankett&action=.VisualEvents.move3DObject`" class="btn btn-primary stretched-link">Öffnen</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto">
            <div class="card model-card" style="width: 18rem;">
                <img :src="`images/Tischgruppen.png`" height="180">
                <div class="card-body">
                    <h5 class="card-title">Einstein - Parlamentarisch (alt)</h5>
                    <p class="card-text">Kongress Bestuhlung im Hotel Einstein</p>
                    <p class="card-text"><small class="text-muted">Vor 10 Minuten zuletzt bearbeitet</small></p>
                    <a :href="`#/planerOld?model=einstein&symbols=Tischgruppen&variant=Parlamentarisch&action=.VisualEvents.move3DObject`" class="btn btn-primary stretched-link">Öffnen</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto">
            <div class="card model-card" style="width: 18rem;">
                <img :src="`images/Stuhlreihen.png`" height="180">
                <div class="card-body">
                    <h5 class="card-title">Einstein - Stuhlreihen (alt)</h5>
                    <p class="card-text">Kongress Bestuhlung im Hotel Einstein</p>
                    <p class="card-text"><small class="text-muted">Vor 10 Minuten zuletzt bearbeitet</small></p>
                    <a :href="`#/planerOld?model=einstein&symbols=Tischgruppen&variant=Stuhlreihen&action=.VisualEvents.move3DObject`" class="btn btn-primary stretched-link">Öffnen</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Eventplaner (alt)</h5>
                    <p class="card-text">Erstellen Sie einen neue Bestuhlungsplanung in einem leeren Saal 'Einstein'</p>
                    <a :href="`#/planer2DOld?model=einstein&symbols=Tischgruppen&variant=Tagungsraum&action=.VisualEvents.placeBlock`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div>
        <div class="col-sm-auto d-flex align-items-end">
            <div class="card model-card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Inventarverwaltung</h5>
                    <p class="card-text">Stellen Sie Ihr Inventar zusammen</p>
                    <a :href="`#/inventory`"  class="btn btn-primary stretched-link">Weiter</a>
                </div>
            </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import { AwsSrpClient } from 'aws-srp-client'
import Settings from '@/visual-events/data/Settings'

export default {
  name: 'Home', 
  methods: {
    login () {
        const client =new AwsSrpClient('eu-central-1', Settings.get('.userPool'), Settings.get('.appClient'));
        client.AuthenticateUser('developer@datasolid.de', 'Aa1!Aa1!')
        .then(result => {
            if (result && result.Success && !result.NewPasswordRequired) {
                if (result.AuthenticationResult)
                    Settings.set('.userToken', result.AuthenticationResult.IdToken)
            }
        })
    }
  },
  mounted () {
    this.login()
  },

}

</script>

<style scoped>
    .model-card:hover {
        box-shadow: 0 0 2px black;
    }
</style>
