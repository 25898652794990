<template>
    <div class="button-panel">
        <div
            v-for="(value, key) in buttons"
            :key="key"
            :class="['tab-button', { active: activeButton === key }]"
            @click=onClickButton(key)
            :name="value.name"
            :title="value.name"
        >
            <i v-if="useFontAwsomeIcon(value.icon.type)" :class="['ve-sidenav-icon', value.icon.class]"></i>
            <SVGIcon class="fs-1" v-if="useSVGIcon(value.icon.type)" :iconName="value.icon.name" />
            <p class="ve-sidenav-icon-text">{{ value.name }}</p>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import Action from '@/frame/Action';
import Settings from '@/visual-events/data/Settings';
import SVGIcon from '@/vuejs/components/SVGIcon.vue';

export default {
    name: 'SideNav',
    data () {
        return {
            activeButton: Settings.get('nav.startButton'),
            buttons: Settings.get('nav.buttons')
        }
    },
    components: {
        SVGIcon
    },
    mounted () {
        theApp.addDialog('SideNav', this);
    },
    unmounted () {
        theApp.removeDialog('SideNav');
    },
    methods: {
        setActiveButton(key) {
            this.activeButton = key
        },
        onClickButton(key) {
            this.activeButton = key

            const button = this.buttons[key]
            if (button && button.command)
                theApp.executeCommand(button.command)
            else
                Action.stopAll()
        },
        useFontAwsomeIcon(key) {
            return key === 'fa'
        },
        useSVGIcon(key) {
            return key === 'svg'
        },
        update() {
            this.buttons = Settings.get('nav.buttons');
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/ve-variables.scss';
.button-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    background: $sidenav-bg-color;
    border-right: $sidenav-border;
}
.tab-button {
    width: $sidenav-button-width;
    height: $sidenav-button-height;
    border: none;
    border-bottom: $sidenav-button-border;
    text-align: center;
    background-color: $sidenav-button-bg-color;
    align-content: center;
}
.tab-button:hover {
    color: $sidenav-button-highlight;
    fill: $sidenav-button-highlight;
}
.tab-button.active {
    color: $sidenav-button-highlight;
    fill: $sidenav-button-highlight;
}
.ve-sidenav-icon-text {
    font-size: 12px; 
    margin-top: 5px; 
    overflow-x: hidden;
    @if $sidenav-button-show-text {
        display: block;
    } @else {
        display: none;
    }
}
.ve-sidenav-icon {
    @if $sidenav-button-show-text == false {
        font-size: 24px;
    }
}

</style>