<template>
  <div class="panel-rectangle">
    <fieldset class="ve-row">
      <label class="ms-3 col-12 ps-1 mb-0 mt-2 col-form-label ">Größen</label>
      <div class="col-sm-6">
        <select class="form-select col-sm-6" v-model="optionSize" @change="onChangeOptionSize($event)">
          <option key="0" value=0>Freie Eingabe</option>
          <option key="1" value=500>50 x 50 cm</option>
          <option key="2" value=600>60 x 60 cm</option>
          <option key="3" value=700>70 x 70 cm</option>
        </select>
      </div>
      <div v-show="showInput" class="ve-row">
        <InputNumberWithUnit label="Breite" class="col-sm-6" unit="cm" :scale="10" :min="1" :max="200" :step="1"
          :digits="1" v-model="height" @update:modelValue="onChangeHeight" />
        <InputNumberWithUnit label="Tiefe" class="col-sm-6" unit="cm" :scale="10" :min="1" :max="200" :step="1"
          :digits="1" v-model="width" @update:modelValue="onChangeWidth" />
      </div>
      <div class="mt-3 ms-2 mb-0">Farbe</div>
      <div class="mt-0 ms-2 mb-3">
        <InputButtonColor label="" v-model="color" @update:modelValue="onChangeColor" />
        <hr class="hr" />
      </div>
    </fieldset>
  </div>
</template>

<script>
import theApp from '@/frame/Application';

import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue';
import Settings from '@/visual-events/data/Settings';

export default {
  name: 'PanelChairRectangle',
  components: {
    InputNumberWithUnit,
    InputButtonColor,
  },
  data() {
    return {
      optionSize: this.findOptionSize(Settings.get('furniture.chairWidthX', 500), Settings.get('furniture.chairWidthY', 500)),
      height: Settings.get('furniture.chairWidthY', 500),
      width: Settings.get('furniture.chairWidthX', 500),
      color: Settings.get('furniture.chairColor', '#FFFFFF'),
      showInput: this.optionSize === 0
    }
  },
  mounted() {
    theApp.addDialog('PanelChairRectangle', this)
  },
  unmounted() {
    theApp.removeDialog('PanelChairRectangle')
  },
  methods: {
    update(action) {
      this.height = action.height
      this.width = action.width
      this.color = action.color
      this.optionSize = this.findOptionSize(this.width, this.height)
      this.showInput = this.optionSize === 0
    },
    onChangeOptionSize(event) {
      if (parseInt(event.target.value) !== 0) {
        this.height = parseInt(event.target.value);
        this.width = parseInt(event.target.value);

        this.onChangeHeight();
        this.onChangeWidth();
        this.showInput = false;
      } else {
        this.showInput = true;
      }
    },
    onChangeHeight() {
      theApp.executeValueEvent("height", this.height)
      Settings.set('furniture.chairWidthY', this.height)
    },
    onChangeWidth() {
      theApp.executeValueEvent("width", this.width)
      Settings.set('furniture.chairWidthX', this.width)
    },
    onChangeColor() {
      theApp.executeValueEvent("color", this.color)
      Settings.set('furniture.chairColor', this.color)
    },
    findOptionSize(widthX, widthY) {
      if (widthX !== widthY)
        return 0

      switch (widthX) {
        case 500:
        case 600:
        case 700:
          return widthX
        default:
          return 0
      }
    }
  }
}
</script>

<style scoped></style>
