import * as THREE from 'three';
import Logger from '@/frame/Logger';

const logger = new Logger('Pick');

export default class Pick {

    /**
     * in the view pick OpObjects touched by MouseEvent or TouchEvent raw
     * @param {*} view 
     * @param {*} raw 
     * @param {*} pickable 
     * @returns 
     */
    static pick(view, raw, pickable = []) {

        //HACK: avoid crashs with view, which have no camera, namely VisualEventsUnrealView
        if (!view.camera )
          return null;

        const opRoot = view.getRoot();
        if (!opRoot)
            return null;

        let raycaster = new THREE.Raycaster();
        const relative = view.getRelative(raw);
        raycaster.setFromCamera(relative, view.camera);

        const hits = [];
        const intersects = raycaster.intersectObjects(view.scene.children, true);

        intersects.forEach(intersect => {
            const mesh = intersect.object;
            const op = Pick.find(opRoot, mesh);
            if (op && 
                op.isPickable() && 
                (pickable.length < 1 || pickable.includes(op.type)))
                    hits.push(op);
        });

        //const picked = hits.map(o=>`${o.id} ${o.type}`);
        //logger.log(`pick ${picked}`);

        return hits;
    }

    /**
     * find in OpObject tree root the OpObject, which belongs to the picked mesh
     * @param {*} root 
     * @param {*} mesh 
     * @returns 
     */
    static find(root, mesh) {
        let opId = mesh.userData['pickId'];
        let parent = mesh.parent;
        while (!opId && parent) {
            opId = parent.userData['pickId'];
            parent = parent.mesh;
        }
        return opId ? root.findOpObjectById(opId) : null;
    }
}