<template>
  <div class="panel-draw">
      <div class="d-flex flex-column h-100 w-100">
          <div class="h-100">
              <div class="ve-panel-header">
                  {{ header }}
              </div>

              <div v-if="showIcons" class="col-sm-12">
                <IconBarDraw/>
              </div>

              <div v-if="tab === 'rectangle'">
                <PanelDrawRectangle/>
              </div>
              <div v-else-if="tab === 'circle'">
                <PanelDrawCircle/>
              </div>
              <div v-else-if="tab === 'polygon'">
                  <PanelDrawPolygon/> 
              </div>
              <div v-else-if="tab === 'text'">
                  <PanelDrawText/> 
              </div>
              <div v-else>
                  <PanelStyle/> 
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import theApp from '@/frame/Application';

import IconBarDraw from '@/vuejs/components/IconBarDraw.vue'
import PanelStyle from '@/vuejs/components/PanelStyle.vue'
import PanelDrawRectangle from '@/vuejs/components/PanelDrawRectangle.vue'
import PanelDrawCircle from '@/vuejs/components/PanelDrawCircle.vue'
import PanelDrawPolygon from '@/vuejs/components/PanelDrawPolygon.vue'
import PanelDrawText from '@/vuejs/components/PanelDrawText.vue'

export default {
name: 'PanelDraw',
components: {
  IconBarDraw,
  PanelStyle,
  PanelDrawRectangle,
  PanelDrawCircle,
  PanelDrawPolygon,
  PanelDrawText
},
data () {
  return {
      tab: 'rectangle',
      showIcons: true,
      header: 'Formen / Texte'
  }
},
mounted () {
  theApp.addDialog('PanelDraw', this)
},
updated () {
  theApp.addDialog('PanelDraw', this)
},
unmounted () {
  theApp.removeDialog('PanelDraw')
},
methods: {
  setTab (tab) {
      this.tab = tab;
      if (tab === '')
        this.header = 'Auswahl bearbeiten'
  },
  onExecute (command) {
      theApp.executeCommand(`${command}`)
  },
}
}
</script>

<style scoped>
.panel-draw {
  height: 100%;
  width: 100%;
}
</style>