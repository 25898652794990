<template>
  <div class="form-group mt-0">
      <label v-if="label" :for="this.$.uid" class="form-label">{{label}}</label>
      <input class="ve-color-button" type="color" list="selectableColors" :id="this.$.uid" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
      <datalist id="selectableColors">
        <option value="#000000"></option>
        <option value="#444444"></option>
        <option value="#888888"></option>
        <option value="#BBBBBB"></option>
        <option value="#FFFFFF"></option>
        <option value="#f99694"></option>
        <option value="#fac98b"></option>
        <option value="#f5f89d"></option>
        <option value="#b5f7a6"></option>
        <option value="#9bf5d2"></option>
        <option value="#83edf9"></option>
        <option value="#88b1f9"></option>
        <option value="#aa9af7"></option>
        <option value="#f7aef4"></option>
        <option value="#f4ccf2"></option>
      </datalist>
  </div>
</template>

<script>

export default {
  name: 'InputButtonColor',
  props: {
    label: String,
    modelValue: String
  },
  emits: ['update:modelValue']
}
</script>

<style>
.ve-color-button {
    border-style: none;
    display: block;
    padding: 0px;
}
</style>
