import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText';
//import OpText from '@/visual-events/model/OpText';
import * as THREE from 'three';

export default class VariantChairCircle extends Variant {
    constructor (diameter, color) {
        const opts = {
            diameter: diameter,
            color: color
        }
        super ('CHAIR_CIRCLE', 1, 'standard chair circle', opts);
        this.t = new THREE.Matrix4();
    }

    createSymbol () {
        const diameter = this.json.opts.diameter;
        const color = this.json.opts.color;
        
        const circle = OpFactory.createCircle(0.0, diameter / 2.0, diameter / 2.0);
        circle.style.fill = color;
        const opSymbol = OpFactory.createSymbol(`${this.description}`);
        opSymbol.add(circle);

        const opText = OpFactory.createText('?', 100, undefined, TextAnchor.middle, BaseLine.middle);
        opText.pickable = false;
        opText.setVisibility(false);
        opSymbol.add(opText);
        opSymbol.setAttribute('$seatNo', '?');
        opText.attachToAttribute(opSymbol, '$seatNo');
        this.t.makeTranslation(0.0, diameter / 2.0, 0);
        opText.setTransform(this.t);
 
        return opSymbol;
    }
}