<template>
    <div class="panel-table-rectangle-setting">
      <fieldset class="ve-row mb-3">
          <InputNumber label="Oberseite" class="col-sm-6" :min="0" :max="10" :step="1" v-model="chairTop" @update:modelValue="onChangeChairTop" />
          <InputNumber label="Unterseite" class="col-sm-6" :min="0" :max="10" :step="1" v-model="chairBottom" @update:modelValue="onChangeChairBottom" />
          <InputNumber label="Links" class="col-sm-6" :min="0" :max="10" :step="1" v-model="chairLeft" @update:modelValue="onChangeChairLeft" />
          <InputNumber label="Rechts" class="col-sm-6" :min="0" :max="10" :step="1" v-model="chairRight" @update:modelValue="onChangeChairRight" />
      </fieldset>
      <hr class="hr" />
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import InputNumber from '@/vuejs/components/InputNumber.vue';
import Settings from '@/visual-events/data/Settings';

export default {
  name: 'PanelTableRectangleSetting',
  components: {
    InputNumber,
  },
  data () {
    return {
        chairTop: Settings.get('furniture.chairTop', 1),
        chairBottom: Settings.get('furniture.chairBottom', 1),
        chairLeft: Settings.get('furniture.chairBottom', 1),
        chairRight: Settings.get('furniture.chairRight', 1),
    }
  },
  mounted () {
    theApp.addDialog('PanelTableRectangleSetting', this)
  },
  unmounted () {
    theApp.removeDialog('PanelTableRectangleSetting')
  },
  methods: {
    update(action) {
        this.chairTop = action.chairTop;
        this.chairBottom = action.chairBottom;
        this.chairLeft = action.chairLeft;
        this.chairRight = action.chairRight;
    },   
    onChangeChairTop() {
        theApp.executeValueEvent("chairTop", this.chairTop)
        Settings.set('furniture.chairTop', this.chairTop)
    },
    onChangeChairBottom() {
        theApp.executeValueEvent("chairBottom", this.chairBottom)
        Settings.set('furniture.chairBottom', this.chairBottom)
    },
    onChangeChairLeft() {
        theApp.executeValueEvent("chairLeft", this.chairLeft)
        Settings.set('furniture.chairLeft', this.chairLeft)
    },
    onChangeChairRight() {
        theApp.executeValueEvent("chairRight", this.chairRight)
        Settings.set('furniture.chairRight', this.chairRight)
    },
  }
}
</script>

<style scoped>
</style>
