import theApp from '@/frame/Application';
import CADdyJsonExporter from '@/visual-events/loader/CADdyJsonExporter';
import CADdyJsonLoader from '@/visual-events/loader/CADdyJsonLoader';
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';
import FileService from '@/visual-events/RestAPI/FileService';
import OpUtils from '@/visual-events/model/OpUtils';
import VisualEvents3DView from '@/visual-events/view/VisualEvents3DView';

import OpGroup from '@/visual-events/model/OpGroup';
import OpSymbol from '@/visual-events/model/OpSymbol';
import CADdy2DLoader from '@/visual-events/loader/CADdy2DLoader';
import CADdy3DLoader from '@/visual-events/loader/CADdy3DLoader';
import Lighting from '@/visual-events/view/Lighting';
import Settings from '@/visual-events/data/Settings';


import Logger from '@/frame/Logger';
import CADdyGLTFExporter from '../loader/CADdyGLTFExporter';

const logger = new Logger('ModelIO');

/**
* static API for loading and saving OpObject trees from and to urls
*      
* TODO: devide into combinable sub tasks
* - load file to json, save json to file
* - parse json and build OpObject trees, resp. build json from OpObject subtrees
 * - integrate into model or model portions
 * - additional tasks:
 *      views should be updated
 *      resources disposed of, cleanly
 * 
 * TODO: decide the call parameters, variable argument list or opts, or named parameters
*/
export default class ModelIO {

     /**
      * delete the current model and optionally load a template from the url
      * 
      * The difference to load is, that the model does not have the template url. 
      * The url is empty until stored for the first time.
      * 
      * (not symbols?)
      * 
      * remark: .io.new is not a good name, because it is not possible to declare a method ModelIO.new.
      * @param {*} opts 
      */
     static async doClear(opts) {
        logger.log(`.io.clear ${JSON.stringify(opts)}`);

        //TODO: ModelIO.doClear only preliminary implementation
        ModelIO.clear();

        return;
    }

    /**
     * delete the current model and load the model from url.
     * 
     * The model remembers the url.
     * 
     * @param {*} opts 
     */
    static async doLoad(pathModel) {
        logger.log(`.io.load ${JSON.stringify(pathModel)}`);

        //HACK: if threejs load GLTF files, if Unreal not; consider general concept of lazy evaluation of meshes only in the grafic
        const view3D = theApp.findViewByName('3D Ansicht');
        const withMeshes = view3D instanceof VisualEvents3DView;

        //TODO: ModelIO.doLoad only preliminary implementation
        try {
            await ModelIO.load(pathModel, withMeshes);
        } catch (err) {
            // if model.ve cannot be loaded, try the old web export files drawing.svg and model.json
        }

        view3D.initCameraPosition();
        view3D.addLight();

        return;
    }

    /**
     * load from url and insert into the root 
     * 
     * The model's url does not change. The inserted root remembers the url
     * 
     * @param {*} opts 
     */
    static async doLoadInto(opts) {
        logger.log(`.io.loadInto ${JSON.stringify(opts)}`);
        //TODO: ModelIO.doLoadInto not implemented
        return;
    }
    
    /**
     * saves the current model.
     * 
     * If url is set, save into this file, but do not change the root's url.
     * Otherwise save into the root's url.
     * If the root has not been assigned an url, the option url is mandatory
     * and the root will remember this url. 
     * 
     * @param {*} opts 
     */
    static async doSave(pathModel) {
        logger.log(`.io.save ${JSON.stringify(pathModel)}`);

        //TODO: ModelIO.doSave only preliminary implementation
        await ModelIO.save(pathModel);
    }

    /**
     * saves the current model to the url
     * 
     * The model's url changes.
     * 
     * @param {*} opts 
     */
    static async doSaveAs(opts) {
        logger.log(`.io.saveAs ${JSON.stringify(opts)}`);
        //TODO: ModelIO.doSaveAs not implemented
    }

    /**
     * removes symbols from the OpSymbols, all if not listed explicitly
     * 
     * @param {*} opts 
     */
    static doClearSymbols(opts) {
        logger.log(`.io.clearSymbols ${JSON.stringify(opts)}`);
        //TODO: ModelIO.doClearSymbols not implemented
    }

    /**
     * loads symbols into the the OpSymbols, all in the url or only the listed.
     * If no url is set, take the current model's url.
     * 
     * @param {*} opts 
     */
    static async doLoadSymbols(opts) {
        logger.log(`.io.loadSymbols ${JSON.stringify(opts)}`);
        //TODO: ModelIO.doLoadSymbols not implemented
    }   


    /**
     * delete all OpObjects below root
     * 
     * If root is undefined, delete the complete Model.
     * 
     * Resources, such as three.js are disposed
     * 
     * does not delete the OpSymbols
     * @param {*} root 
     */
    static clear (root) {
        if (!root) {
            OpUtils.clear(theApp.drawings[0]);
            OpUtils.clear(theApp.space);
        }
        else 
            OpUtils.clear(root);
        theApp.model.changed2d = true;
        theApp.model.changed3d = true;
    }


    /**
     * load model.ve from pathModel into the model
     * 
     * 
     * @param {*} pathModel 
     */
    static async load (pathModel, withMeshes) {
        logger.log(`load3D(${pathModel})`);
    
        try {
            const fileService = new FileService();
            const urlModel = fileService.getDownloadUrl(pathModel, 'model.ve');

            const json = await fileService.download(urlModel);
    
            // parse json and build the OpObject trees
            const loader = new CADdyJsonLoader(withMeshes);
            const drawing = await loader.getDrawing(json);
            const space = await loader.getSpace(json);
        
            const symbols = [];
            for (const symbolId in json.symbols)
            symbols.push(await loader.getSymbol(json, symbolId));
    
            //TODO: dispose of all meshes
        
            // add to model (attention: do not simple exchange space; the.model.space might be displayed in views!)
            logger.log('integrate into model');
        
            logger.log(`integrate ${symbols?.length} symbols`)
            for (const symbol of symbols) {
                if (!theApp.model.symbols.get(symbol.symbolId)) {
                    logger.log(`add ${symbol?.symbolId}`);
                    theApp.model.symbols.add(symbol);
                } else {
                    logger.log(`found ${symbol.symbolId}`);
                }
            }
        
            OpUtils.deleteSelection(theApp.model.drawing[0].children);
            logger.log('add children to drawing')
            for (const child of drawing.children) 
                theApp.model.drawing[0].add(child);
            
            OpUtils.deleteSelection(theApp.model.space.children);
            logger.log('add children to space')
            for (const child of space.children) 
                theApp.model.space.add(child);
        
            logger.log('build up 2D to 3D links')
            //TODO: find better solution to build up 2D 3D links after load
            Event3DPlacing.buildLinks2DTo3D(theApp.model.drawing[0]);


            //HACK: as soon as the starting point for the camera and the light definition is part of the OpObject model
            // remove this; invent something better than view.setRoot
            const view2d = theApp.findViewByName('2D Ansicht');
            const view3d = theApp.findViewByName('3D Ansicht');

            view2d?.setRoot(theApp.model.drawing[0]);
            view3d?.setRoot(theApp.model.space);

            if (theApp.findMountedViewByName('3D Ansicht')) {
                logger.log('initCameraPosition')
                view3d.initCameraPosition();
                logger.log('addLight')
                await ModelIO.loadLight(pathModel);
                theApp.model.changedLight = true;
            }
            
            theApp.model.changed2d = true;
            theApp.model.changed3d = true;

        } catch (err) {
            console.log(`ModelIO.log Exception: ${err.message}`)
            throw Error('ModelIO.load failure', { cause: err });
        }
    }

    /**
     * save the model into pathModel/model.ve 
     * 
     * @param {*} pathModel 
     */
    static async save(pathModel) {
        logger.log(`save3D(${pathModel})`);

        const selectGrafic = ModelIO.hideSelect();
    
        try {
            const exporter = new CADdyJsonExporter();
            const json = exporter.write(theApp.model);

            const gltfExporter = new CADdyGLTFExporter();
            const gltf = await gltfExporter.write(theApp.model.space);
        
            const fileService = new FileService();
            
            await fileService.requestUploadUrl(pathModel, 'model.ve').then(url => fileService.upload(url, json, 'application/json'));

            for (const key in gltf) {
                const gltfJson = JSON.stringify(gltf[key]);
                await fileService.requestUploadUrl(pathModel, `${key}.gltf`).then(url => fileService.upload(url, gltfJson, 'binary/octet-stream'))
            }
        } catch (err) {
            ModelIO.unhideSelect(selectGrafic);
            throw Error('ModelIO.save failure', { cause: err });
        }

        ModelIO.unhideSelect(selectGrafic);
    }

    /**
     * check, whether meshes should be loaded from GLTF etc. or not
     * 
     * Three.js views need the meshes, but Unreal views need not. 
     * Loading the meshes would be a waste of time and resources.
     * 
     * @returns true, if meshes must be loaded
     */
    static needsMeshes () {
        const view3d = theApp.findViewByName('3D Ansicht');
        return view3d instanceof VisualEvents3DView;
    }



    static async loadWebExport (pathModel, withMeshes) {

        theApp.findDialogByName('main')?.setShowOverlay(true);

        await ModelIO.loadSVG (pathModel);

        let view3d = theApp.findViewByName('3D Ansicht');
        theApp.findDialogByName('3D Ansicht')?.setCursor('wait');

        const urlModel = `${Settings.get('.urlFileService')}/files/${pathModel}`;

        try {
            await CADdy3DLoader.load3D(theApp.model, urlModel, !withMeshes);

            logger.log('VisualEvents::init loaded 3D');
    
            if (view3d) {
                view3d.setRoot(theApp.model.space);
    
                theApp.model.changed3d = true;
        
                if (theApp.findMountedViewByName('3D Ansicht')) {
                    view3d.initCameraPosition();
                    if (theApp.model.light) {
                        Lighting.addLight(view3d, theApp.model.light);
                        theApp.model.changedLight = true;
                    }
                }
        
                theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
            }
    
            //TODO: find better solution to build up 2D 3D links after load
            Event3DPlacing.buildLinks2DTo3D(theApp.model.drawing[0]);
        } catch (err) {
            // 3D could not be loaded
        }
    }

    static async loadSVG (pathModel) {
        let drawingUrl = pathModel + '/drawing.svg';
        logger.log(`loadSVG(${drawingUrl})`);
    
        let view2d = theApp.findViewByName('2D Ansicht');
        theApp.findDialogByName('2D Ansicht')?.setCursor('wait');
    
        const fileService = new FileService();
        const urlDrawing = fileService.getDownloadUrl(pathModel, 'drawing.svg')
    
        const loader = new CADdy2DLoader(this);
        loader.requestHeader = FileService.requestHeader();
        const opObjects = await loader.loadAsync(urlDrawing);
          
        logger.log('VisualEvents::init loaded 2D');
        const symbols = opObjects.filter(op => op instanceof OpSymbol);
        symbols.forEach(s => {
            theApp.model.symbols.add(s);
        });
    
        const drawings = opObjects.filter(op => !(op instanceof OpSymbol));
        theApp.model.drawing = drawings; //replace!
    
        view2d.setRoot(drawings[0]);
          
        theApp.model.changed2d = true;
    
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
    
        // HACK: nothing pickable but first sketchboard 'Planung'
        for (let i = 1; i < drawings[0].children.length; i++)
          drawings[0].children[i].pickable = false;
      }
    
      
    /**
     * 
     * @param {*} pathModel 
     */
    static async loadLight(pathModel) {

        const fileService = new FileService();
        const urlLight = fileService.getDownloadUrl(pathModel, 'light.json');
        const json = await fileService.download(urlLight);
        const light = JSON.parse(json);
        theApp.model.light = light;
    }


    /**
     * remove SelectGrafic before storing
     * Otherwise it comes into the file
     * 
     * HACK: consider a better general solution in similiar cases, e.g. export SVGExport
     * s. other HACK in FltPlaceBlock. Perhaps Action.stopAll(always before saving anything?)
     * @returns 
     */
    static hideSelect () {
        const view2D = theApp.findViewByName('2D Ansicht');
        const root2D = view2D?.getRoot()?.children[0];

        if (root2D) {
            for(const child of root2D.children) {
                if (child instanceof OpGroup && child.name === 'Selection') {
                    child.removeFromParent();
                    return child;
                }
            }
        }

        return null;
    }

    static unhideSelect (selectGrafic) {
        if (selectGrafic) {
            const view2D = theApp.findViewByName('2D Ansicht');
            const root2D = view2D?.getRoot()?.children[0];
    
            if (root2D)
                root2D.add(selectGrafic);
        }
    }


}