import * as THREE from 'three';

import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import BlockUtils from './BlockUtils';
import { BreakEvent, CommandEvent } from '@/frame/Event.js';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Geometry from '@/visual-events/model/Geometry';
import OpUtils from '@/visual-events/model/OpUtils';
import Settings from '@/visual-events/data/Settings';
import Synchronizer3D from '@/visual-events/actions/Synchronizer3D';
import Logger from '@/frame/Logger';

const logger = new Logger('ActEditSelection');

const State = Object.freeze({
    EDIT: 1
  });
  
export default class ActEditSelection extends Action {
    constructor(args) {
      super();

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.view3D = theApp.findViewByName('3D Ansicht');
      this.root3D = this.view3D?.getRoot();

      // 2D 3D coupling
      this.synchronizer3D = new Synchronizer3D();

      this.group = false;
      this.objects = [];
      if (args.length>1) {
        this.objects = args[1];
        this.objects = this.completeBlocks();
      }

      this.state = State.EDIT;
    }

    actionStart () {
        logger.log(`actionStart`);
        this.addFltSelectGrafic();
        this.connectToGUI();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        this.resetCursor();
        this.disconnectFromGUI();
    }

    actionCommand(event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.EDIT: {
                 switch (cmd) {
                    case '.select.delete': {
                        this.deleteSelection();                        
                        return new BreakEvent();
                    }
                    case '.select.copy': {
                        this.copySelection();
                        return null;
                    }
                    case '.select.applyTransform': {
                        const tdiff = event.args[0];
                        for (const op of this.objects)
                            op.applyTransform(tdiff);
                        return null;
                    }
                    case '.select.group': {
                        const references = BlockUtils.createGeometryGroup(this.objects);
                        this.root2D.add(references);

                        return new CommandEvent('.Ticketing.editBlock', [references]);
                    }
                }
            } // case EDIT
        } // switch state

        return event;
    }

    /**
     * if there are items of blocks in the selection add all to it
     */
    completeBlocks() {
        const collect = new Set();
        const blocks = new Set();
        for (const op of this.objects) {
            const block = BlockUtils.findBlockGroup(op);
            if (block)  {
                if(!blocks.has(block)) {
                    blocks.add(block);
                    for (const row of block.children) 
                        for (const item of row.children)
                            collect.add(item);
                }
            } else 
                collect.add(op)
        }

        return [...collect];
    }

    /**
     * on delete command delete the selected objects 
     * and go into State.SELECT
     */
    deleteSelection () {

        // delete the 3D blocks first. Otherwise find3DBlock will not work!
        const blocks2D = BlockUtils.getBlocks(this.objects);
        const map = {};
        this.synchronizer3D.fillMap(this.root2D, map);
        for (const block2D of blocks2D) {
            const block3D = this.synchronizer3D.find3DBlock(block2D);
            if (block3D)
                OpUtils.deleteSelection([block3D]);
        }
        theApp.model.changed3d = true;

        OpUtils.deleteSelection(this.objects);
        theApp.model.changed2d = true;

        this.objects = [];
    }

    copySelection () {
        const dist = Settings.get('selection.grafic.iconDist', 300);
        const blocks2D = BlockUtils.getBlocks(this.objects);
        let copiedObjects = []
        
        for (const block2D of blocks2D) {
            const objects = OpUtils.copySelection([block2D], this.root2D, dist);
            const copiedBlock2D = BlockUtils.findBlockGroup(objects[0]);

            const block3D = this.synchronizer3D.find3DBlock(block2D);
            if (block3D) {
                const map = {};
                this.synchronizer3D.fillMap(block2D, map);

                const copiedBlock3D = block3D.copy();
                copiedBlock3D.attributes = {...block3D.attributes};
                this.root3D.add(copiedBlock3D);

                this.synchronizer3D.apply(copiedBlock2D, copiedBlock3D, map);
            }

            copiedObjects = [...copiedObjects, ...objects];
        }

        this.objects = copiedObjects;

        this.disconnectFromGUI();
        this.state = State.EDIT;
        this.getFilter().shift(dist);

        theApp.model.changed2d = true;
        theApp.model.changed3d = true;
    }

    groupGeometry(){
        const references = BlockUtils.createGeometryGroup(this.objects);
        this.root2D.add(references.op2D);

        if(this.root3D){
            this.root3D.add(references.op3D);
        }
        return new CommandEvent('.Ticketing.editBlock', [references.op2D]);
    }

    /**
     * while editing FltSelectGrafic does most of the job
     */
    addFltSelectGrafic () {
        if (this.objects.length === 1) {
            const box = Geometry.computeBox(this.objects[0]);
            const transform = this.objects[0].transform;
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon().useBoxPoints());
        } else if (this.objects.length > 1) {
            const box = Geometry.computeBoxUnion(this.objects);
            const transform = new THREE.Matrix4(); // identity -> axis parallel
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon());
        }
    }

    resetCursor () {
        theApp.findDialogByName('main')?.setCursor(undefined);
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(Settings.get('selection.noDrag') ? undefined : 'Select');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}    
