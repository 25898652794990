import * as THREE from 'three';
import theApp from '@/frame/Application';
import { nextTick } from 'vue'

import Action from '@/frame/Action';
import CommandLineScanner from '@/frame/CommandLineScanner';
import { BreakEvent } from '@/frame/Event.js';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Geometry from '@/visual-events/model/Geometry';
import Settings from '@/visual-events/data/Settings';
import OpFactory from '@/visual-events/model/OpFactory';
import ShapeUtils from '@/visual-events/model/ShapeUtils';
import OpUtils from '@/visual-events/model/OpUtils';

import Logger from '@/frame/Logger';

const logger = new Logger('ActPolygon');

const State = Object.freeze({
    FIRSTPOS: 0,
    NEXTPOS: 1,
    EDIT: 2,
  });

export default class ActPolygon extends Action {
    constructor(args) {
      super();

      this.args = args;

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.useStroke = Settings.get('style.useStroke', true);
      this.stroke = Settings.get('style.stroke', '#000000');
      this.strokeWidth = Settings.get('style.strokeWidth');
      this.useFill = Settings.get('style.useFill', true);
      this.fill = Settings.get('style.fill', '#222222');
    //   this.angle = Settings.get('polygon.angle');


      this.closed = Settings.get('polygon.closed', true);
      this.points = [];

      // working matrix
      this.t = new THREE.Matrix4();
      this.tdiff = new THREE.Matrix4();
      this.c = new THREE.Vector3();

      // angle while rotating
      this.angle = 0.0;

      this.position = new THREE.Vector3(0, 0, 0);

      this.objects = [];
      if (args.length > 1)
          this.objects = args[1];

      this.grafic = null; // temporary grafic

      this.state = this.objects.length > 0 ? State.EDIT : State.FIRSTPOS;
    }

    actionStart () {
        logger.log(`actionStart`);
        if (this.state === State.EDIT)
            this.addFltSelectGrafic();
        else 
            this.addFilter(new FltPointDef());
        
        this.evaluateSelection(); 
        this.connectToGUI();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        this.resetCursor();
        this.clearTemporaryGrafic();
        this.disconnectFromGUI();
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.FIRSTPOS: {
                this.points[0] = [event.p[0], event.p[1]];
                this.points[1] = [event.p[0], event.p[1]];

                this.showTemporaryGrafic();
                
                this.disconnectFromGUI();
                this.state= State.NEXTPOS;
                this.connectToGUI();
                break;
            }

            case State.NEXTPOS: {
                const nextindex = this.points.length;
                let pos = [event.p[0], event.p[1]];
                if (nextindex>=2 && !event.raw.shiftKey)
                    pos = this.getRasteredPosition(pos, this.points[nextindex-2])

                this.points[nextindex-1] = pos;
                this.points[nextindex] = pos;

                this.adaptTemporaryGrafic();
                break;
            }

            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }
        this.handleCameraControlsEnableState();
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);
        switch (this.state) {
            case State.FIRSTPOS:
                break;

            case State.NEXTPOS: {
                const nextindex = this.points.length;
                let pos = [event.p[0], event.p[1]];
                if (nextindex>=2 && !event.raw.shiftKey)
                    pos = this.getRasteredPosition(pos, this.points[nextindex-2])

                this.points[nextindex-1] = pos;
                this.adaptTemporaryGrafic();
                break;
            }

            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }
        return null;
    }

    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'useStroke') {
            this.useStroke = event.value;
            this.applyStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'stroke') {
            this.stroke = event.value;
            this.applyStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'strokeWidth') {
            this.strokeWidth = event.value;
            this.applyStyle({ strokeWidth: this.strokeWidth });
            done = true;
        }

        if (event.attribute === 'useFill') {
            this.useFill = event.value;
            this.applyStyle({ useFill: this.useFill })
            this.applyStyle({ fillOpacity: (this.useFill ? 1 : 0.01) });
            done = true;
        }

        if (event.attribute === 'fill') {
            this.fill = event.value;
            this.applyStyle({ fill: (this.useFill ? this.fill : '#0000000') });
            done = true;
        }

        if (event.attribute === 'closed') {
            this.closed = event.value;
            const panel = theApp.findDialogByName('PanelStyle');
            panel?.setShowFillStyle(this.closed);
            this.adaptTemporaryGrafic();
            done = true;
        }

        if (event.attribute === 'angle') {
            this.angle = event.value;
            this.adaptAngle();
            this.adaptTemporaryGrafic();
            done = true;
        }

        return done ? null : event;
    }

    actionBreak (event) {
        switch (this.state) {
            case State.FIRSTPOS: {
                this.handleCameraControlsEnableState();
                return event;
            }

            case State.NEXTPOS: {
                const nextindex = this.points.length;
                this.points.splice(nextindex-1);
                this.adaptTemporaryGrafic();

                const op = this.finishPolygon();

                // proceed with editing
                this.objects.push(op);
                this.disconnectFromGUI();
                this.state = State.EDIT;
                this.addFltSelectGrafic();
                this.connectToGUI();
                
                break;
            }

            case State.EDIT: {
                return event;
            }
        }
    }

    actionCommand (event) {
        const scanner = new CommandLineScanner(event.commandLine);

        const cmd = scanner.getCommand();

        switch (cmd) {
            // from SelectGrafic
            case '.select.delete': {
                this.deleteSelection();
                return new BreakEvent();
            }
            case '.select.copy': {
                logger.log('.select.copy')
                this.copySelection();
                this.connectToGUI();
                return null;
            }
            case '.select.applyTransform': {
                const tdiff = event.args[0];
                for (const op of this.objects)
                    op.applyTransform(tdiff);
                this.angle = this.objects.length == 1 ? Geometry.getRotationAngle(this.objects[0].transform) : this.getFilter().getRotationAngle();
                theApp.findDialogByName('PanelDrawPolygon')?.update(this);
                return null;
            }
        }

        return event;
    }

    /**
     * on delete command delete the selected objects 
     * and go into State.SELECT
     */
    deleteSelection () {
        OpUtils.deleteSelection(this.objects);
        theApp.model.changed2d = true;

        // State change to State.FIRST_POINT
        this.objects = [];
        this.clearTemporaryGrafic();
        this.disconnectFromGUI();
        this.state = State.FIRSTPOS;
        this.addFilter(new FltPointDef());
        this.connectToGUI();
        this.handleCameraControlsEnableState();
    }

    copySelection () {
        const dist = Settings.get('selection.grafic.iconDist', 300);
        this.objects = OpUtils.copySelection (this.objects, this.root2D, dist);

        this.getFilter().shift(dist);

        theApp.model.changed2d = true;
    }

    /**
     * while editing FltSelectGrafic does most of the job
     */
    addFltSelectGrafic () {
        if (this.objects.length === 1) {
            const box = Geometry.computeBox(this.objects[0]);
            const transform = this.objects[0].transform;
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon());
        } else if (this.objects.length > 1) {
            const box = Geometry.computeBoxUnion(this.objects);
            const transform = new THREE.Matrix4(); // identity -> axis parallel
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon());
        }
    }

    applyStyle (optsStyle) {
        switch (this.state) {
            case State.EDIT:
                this.adaptSelectedObjects(optsStyle);
                break;
            default:
                this.adaptTemporaryGrafic(optsStyle);
                break;
        }
    }

    adaptSelectedObjects (optsStyle) {
        if (optsStyle) {
            this.objects.forEach(op => op.setStyle(optsStyle));
            theApp.model.changed2d = true;
        }
    }

    getRasteredPosition(pos, poslast) {
        let vecdiff = new THREE.Vector2(pos[0]-poslast[0], pos[1]-poslast[1]);
        let angle = vecdiff.angle();
        let length = vecdiff.length();
        angle = angle*180.0/Math.PI;
        angle = Math.floor((angle+2.5)/5.0)*5.0;
        angle = angle/180.0*Math.PI;
        return [poslast[0] + length*Math.cos(angle), poslast[1] + length*Math.sin(angle)];
    }

    finishPolygon () {
        const op = this.grafic;
        op.setStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
        this.grafic = null; // clear temporary grafic
        this.root2D.add(op);
        return op;
    }

    evaluateSelection () {

        //TODO: wenn unterschiedliche Styles, dann was???
        this.angle = this.objects.length == 1 ? Geometry.getRotationAngle(this.objects[0].transform) : 0;
        this.objects.forEach(op => {
            const style = op.style;
            this.useStroke = style.stroke !== 'none';
            this.stroke = style.stroke;
            this.strokeWidth = style.strokeWidth;
            this.useFill =  style.fill !== 'none';
            this.fill = style.fill;
        });
    }

    showTemporaryGrafic () {
        switch (this.state) {
            case State.FIRSTPOS: {
                if (this.points.length==1)
                    this.grafic = OpFactory.createLine(this.points[0][0], this.points[0][1], this.points[0][0], this.points[0][1]);
                else if (this.points.length==2)
                    this.grafic = OpFactory.createLine(this.points[0][0], this.points[0][1], this.points[1][0], this.points[1][1]);
                else
                    this.grafic = OpFactory.createPolyline(this.points);

                const style = {
                    fill: this.useFill ? this.fill : 'none',
                    fillOpacity: 1,
                    stroke: this.stroke,
                    strokeLineCap: 'but',
                    strokeLineJoin: 'miter',
                    strokeMiterLimit: 4,
                    strokeOpacity: 0,
                    strokeWidth: this.strokeWidth
                };
                this.grafic.setStyle(style);
                this.root2D.add(this.grafic);
                theApp.model.changed2d = true; //op;
                break;
            }

            case State.NEXTPOS:
                break;

            case State.EDIT: 
                // does not happen
                break;     
        }
    }

    adaptTemporaryGrafic (optsStyle) {
        switch (this.state) {
            case State.FIRSTPOS:
                break;

            case State.NEXTPOS: {
                const op = this.grafic;
                const path = ShapeUtils.createPolygonPath(this.points, this.closed);
                op.setShapePath(path);

                if (optsStyle)
                    op.setStyle(optsStyle);
                if (op.style.stroke=='none')
                    op.setStyle({ stroke: this.stroke});

                theApp.model.changed2d = true; //op;
                break;
            }

            case State.EDIT:
                // does not happen
                break;
        }
    }

    clearTemporaryGrafic () {
        switch (this.state) {
            case State.FIRSTPOS: 
            case State.NEXTPOS: {
                if (this.grafic) {
                    this.root2D.remove(this.grafic);
                    this.grafic = null;
                    theApp.model.changed2d = true; //op;
                }
            }   
        }
    }

    adaptAngle () {
        this.getFilter().getCenter(this.c);
        const diff = this.angle - this.getFilter().getRotationAngle();
        Geometry.makeRotationZ(this.tdiff, this.c, diff);
        this.objects.forEach(op => { 
            op.applyTransform(this.tdiff);
        });
        theApp.model.changed2d = true;
        
        if (this.objects.length === 1)
            this.getFilter().update(this.objects[0].computeBox(), this.objects[0].transform);
        else
            this.getFilter().applyTransform(this.tdiff);
    }

    handleCameraControlsEnableState() {
        // while dragging disable the CameraControls
        switch (this.state) {
            case State.FIRSTPOS: {
                this.view2D.disableCameraControls();
                break;
            }
            case State.NEXTPOS: {
                this.view2D.disableCameraControls();
                break;
            }
            default: {
                this.view2D.enableCameraControls();
                break;
            }
        }
    }

    adaptCursor () {
        switch(this.state) {
            case State.DRAG_IN_2D: {
                theApp.findDialogByName('2D Ansicht')?.setCursor('grabbing');
                break;
            }
            case State.EDIT: {
                theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
                break;
            }
       }
    }

    resetCursor () {
        theApp.findDialogByName('main')?.setCursor(undefined);
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Draw');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PanelDraw');
        nextTick(() => {
            // access updated DOM
            const panelDraw = theApp.findDialogByName('PanelDraw');
            if (panelDraw) {
                panelDraw.header = this.state === State.EDIT  ? "Polygon bearbeiten" : "Polygon erzeugen"
                panelDraw.showIcons = this.state === State.FIRSTPOS;
                panelDraw.setTab('polygon');
                nextTick(() => {
                    const panelDrawPolygon =  theApp.findDialogByName('PanelDrawPolygon');
                    panelDrawPolygon.editMode = this.state === State.EDIT;
                    panelDrawPolygon.nextMode = this.state === State.NEXTPOS;
                    panelDrawPolygon.update(this);
                    const panelStyle = theApp.findDialogByName('PanelStyle');
                    panelStyle.update(this);
                    panelStyle.setShowFillStyle(this.closed);
                    const panelPolygon = theApp.findDialogByName('PanelPolygon');
                    panelPolygon.update(this);
                })
            }
        })
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}
