<template>
    <svg v-if="defs" class="ve-svg-icon" ><use :href="defsUrl"></use></svg>
    <div v-else :id="`icon-${iconName}-${this.$.uid}`" class="ve-svg-icon"></div>
</template>

<script>
/* ========================================================================
For future reference:

    <svg v-if="defs" class="ve-svg-icon" ><use :href="defsUrl"></use></svg>

    is the how it used to work. Every symbol was packed into a 
    'symbol-defs.svg' and referenced via the <use> tag. 
    
    At the time <use> didn't support importing SVGs from external domains,
    meaning the file had to be located on the server that imports our
    application. Which is unacceptable if we want to ship it to customers.

    For that to work, we would need to use a polyfill that loads the file
    seperately and injects it into the DOM.

    Based on that approach we've made our own component that loads a single
    icon as SVG and loads it into the DOM. <use> would still require
    a slightly different approach

    Also, see old implementation down below
======================================================================== */
import Settings from '@/visual-events/data/Settings'

export default {
    name: 'SVGIcon',
    props: {
        defs: String,
        iconName: String,
        size: Number
    },
    computed: {
        defsUrl: {
            get () {
                return `${Settings.get('.urlAssets')}/icons/${defs}.svg#${this.iconName}${this.size ? `-${this.size}` : ''}`;
            }
        },
        iconUrl: {
            get () {
                return `${Settings.get('.urlAssets')}/icons/${this.iconName}${this.size ? `-${this.size}` : ''}.svg`;
            }
        },
        iconUrlDefault: {
            get () {
                return `${Settings.get('.urlAssets')}/icons/${this.iconName}.svg`;
            }
        }
    },
    methods: {
        injectSvg(svgAsText) {
            const domParser = new DOMParser();
            const svgDoc = domParser.parseFromString(svgAsText, 'image/svg+xml')
            const svgElement = svgDoc.childNodes.item(0);
            svgElement.style.width = `${this.size}px`;
            svgElement.style.height = `${this.size}px`;
            document.getElementById(`icon-${this.iconName}-${this.$.uid}`).appendChild(svgElement);
        },
        fetchSvg(url) {
            return new Promise((resolve, reject) => {
                fetch(url)
                .then(response => response.text())
                .then(svgAsText => {
                    resolve(svgAsText);
                })
                .catch((reason) => {
                    reject(reason);
                })
            });
        } 
    },
    mounted() {
        this.fetchSvg(this.iconUrlDefault)
        .then(svgAsText => {
            this.injectSvg(svgAsText);
        })
        // .catch(() => {
        //     this.fetchSvg(this.iconUrlDefault)
        //     .then(svgAsText => {
        //         this.injectSvg(svgAsText);
        //     })
        // })
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/ve-variables.scss';
.ve-svg-icon {
  display: inline-block;
//   width: 1em;
//   height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  line-height: 0;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */
</style>

<!--
    <template>
        <svg class="ve-svg-icon" ><use :href="url"></use></svg>
    </template>

    <script>
    export default {
        name: 'SVGIcon',
        props: {
            iconName: String
        },
        computed: {
            url: {
                get () {
                    return `icons/symbol-defs.svg#${this.iconName}`;
                }
            }
        }
    }
    </script>

    <style scoped lang="scss">
    @import '../../assets/scss/ve-variables.scss';
    .ve-svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    }

    /* ==========================================
    Single-colored icons can be modified like so:
    .icon-name {
    font-size: 32px;
    color: red;
    }
    ========================================== */
    </style>
-->