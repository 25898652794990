import Action from '@/frame/Action';
import theApp from '@/frame/Application';
import FltPick from '@/visual-events/actions/FltPick';
import { PickFlags } from '@/visual-events/actions/FltPick';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';
import Logger from '@/frame/Logger';

const logger = new Logger('ActMove3DObject');

const State = Object.freeze({
  SELECT: 0,
  MOVE: 1
});


/**
 * TODO: deprecate, remove from project as soon as no example uses it any more
 */
  
export default class ActMove3DObject extends Action {
    constructor(args) {
      super();

      this.args = args;
      this.pointStart = null;
      this.objects3D = [];
      this.objects2D = [];
      this.state = State.SELECT;

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];
    }

    actionStart () {
        logger.log('actionStart');

        this.addPickFilter();

        this.view2D.addCameraControls();
        this.view2D.fitToCanvas();
        
        return true;
    }

    actionDestroy () {
        logger.log('actionDestroy');
        
        switch (this.state) {
            case State.SELECT:
                break;
            case State.MOVE:
                break;
        }
        
        this.view2D.removeCameraControls();
    }

    actionBreak () {
        logger.log('actionBreak');

        switch (this.state) {
            case State.SELECT:
                break;
            case State.MOVE:
                //TODO: reset positions
                this.objects2D = [];
                this.objects3D = [];
                return false;
        }

        this.handleCameraControlsEnableState();

        return true;
    }

    actionSelection (event) {
        logger.log('actionSelection');

        switch (this.state) {
            case State.SELECT: {

                this.objects2D.push(...event.objects);
                this.pointStart = this.objects2D[0].transform.elements.slice(12);
                logger.log(`${this.pointStart}`);

                const op2D = this.objects2D[0];
                const op3D = Event3DPlacing.findPlacingCounterpart(op2D); 

                if (op3D) {
                    this.objects3D.push(op3D); 

                    this.removeFilter();
                    this.addFilter(new FltPointDef());
                    this.state = State.MOVE;
                }
                //TODO: else show error message ? or ignore?

                break;
            }
            case State.MOVE:
                // never happens
                break;
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionPoint (event) {
        logger.log(`actionPoint ${this.state}`);

        switch (this.state) {
            case State.SELECT:
                break;
            case State.MOVE:
                if (event.view.name === '2D Ansicht') {
                    let x = event.p[0];
                    let y = event.p[1];

                    this.move3D(this.objects3D[0], x, y, 0);
                    this.move2D(this.objects2D[0], x, y, 0);

                    this.objects2D = [];
                    this.objects3D = [];
    
                    this.addPickFilter();

                    this.state = State.SELECT;
                }
                break;
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionDynamic (event) {
        //logger.log('actionDynamic');

        switch (this.state) {
            case State.SELECT:
            break;

            case State.MOVE: {
                if (this.pointStart && event.view.name == '2D Ansicht') {
                    let x = event.p[0];
                    let y = event.p[1];

                    this.move3D(this.objects3D[0], x, y, 0);
                    this.move2D(this.objects2D[0], x, y, 0);
                }
                break;
            }
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    //TODO: OpObject interfaces: modify, usw.
    move2D(op, x, y) {
        logger.log(`move2D(${op.id}, ${x}, ${y})`)
        op.transform.setPosition(x, y, 0);
        theApp.model.changed2d = true; //op;
    }

    move3D(op, x, y, z) {
        op.transform.setPosition(x, y, 0);
        theApp.model.changed3d = true;
    } 

    addPickFilter() {
        let pick_flags = PickFlags.IGNORE_MSL | PickFlags.NO_DRAG | PickFlags.NO_HIGHLIGHT;
        const filter = new FltPick(pick_flags);
        this.addFilter(filter);
    }

    /**
     * during drag state disable the CameraControls
     */
    handleCameraControlsEnableState() {
        switch (this.state) {
            case State.MOVE: {
                this.view2D.disableCameraControls();
                break;
            }
            default: {
                this.view2D.enableCameraControls();
                break;
            }
        }
    }
}