import Command from '@/frame/Command';
import CommandPool from '@/frame/CommandPool';

import theApp from '@/frame/Application';
import Action from '@/frame/Action';
import Settings from '@/visual-events/data/Settings';

import ActMove3DObject from '@/visual-events/actions/ActMove3DObject';
import ActPlaceBlock from '@/visual-events/actions/ActPlaceBlock';
import ActBooking from '@/visual-events/actions/ActBooking';
import CADdy2DLoader from '@/visual-events/loader/CADdy2DLoader';
import CADdy3DLoader from '@/visual-events/loader/CADdy3DLoader';
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';
import OpFactory from '@/visual-events/model/OpFactory';
import OpSymbol from '@/visual-events/model/OpSymbol';
import CADdySVGExporter from '@/visual-events/loader/CADdySVGExporter';
import FileService from '@/visual-events/RestAPI/FileService';
import Ticketing from '@/visual-events/ticketing/Ticketing';
import UtilSettings from '@/visual-events/data/UtilSettings';
import UtilLogs from '@/visual-events/data/UtilLogs';
import VisualEvents3DView from '@/visual-events/view/VisualEvents3DView';
import Logger from '@/frame/Logger';
import VisualEventsUnrealView from '@/visual-events/view/VisualEventsUnrealView';

const logger = new Logger('CommandPoolVisualEvents');

export default class CommandPoolVisualEvents extends CommandPool {
  constructor () {
    super(CommandPool.root, 'VisualEvents');
    this.addCommands([
      new Command('load', args => this.doLoad(args)),
      new Command('save', args => this.doSave(args)),
      new Command('loadSVG', args => this.doLoadSVG(args)),
      new Command('saveSVG', args => this.doSaveSVG(args)),
      new Command('loadEmpty', args => this.doLoadEmpty(args)),
      new Command('createItems', args => this.doCreateItems(args)),
      new Command('loadFavorites', args => this.doLoadFavorites(args)),
      new Command('saveFavorites', args => this.doSaveFavorites(args)),
      new Command('move3DObject', args => this.doMove3DObject(args)),
      new Command('placeBlock', args => this.doPlaceBlock(args)),
      new Command('booking', args => this.doBooking(args)),
      new Command('readSettings', args => this.doReadSettings(args)),
      new Command('writeSettings', args => this.doWriteSettings(args)),
      new Command('logDrawing', args => this.doLogDrawing(args)),
      new Command('logSymbols', args => this.doLogSymbols(args)),
      new Command('logSymbol', args => this.doLogSymbol(args)),
      new Command('logSpace', args => this.doLogSpace(args))
    ]);
  } 

  async loadSVG (pathModel) {
    let drawingUrl = pathModel + '/drawing.svg';
    logger.log(`loadSVG(${drawingUrl})`);

    let view2d = theApp.findViewByName('2D Ansicht');
    theApp.findDialogByName('2D Ansicht')?.setCursor('wait');

    const fileService = new FileService();
    const urlDrawing = fileService.getDownloadUrl(pathModel, 'drawing.svg')

    const loader = new CADdy2DLoader(this);
    loader.requestHeader = FileService.requestHeader();
    const opObjects = await loader.loadAsync(urlDrawing);
      
    logger.log('VisualEvents::init loaded 2D');
    const symbols = opObjects.filter(op => op instanceof OpSymbol);
    symbols.forEach(s => {
        theApp.model.symbols.add(s);
    });

    const drawings = opObjects.filter(op => !(op instanceof OpSymbol));
    theApp.model.drawing = drawings; //replace!

    view2d.setRoot(drawings[0]);
      
    theApp.model.changed2d = true;

    theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);

    // HACK: nothing pickable but first sketchboard 'Planung'
    for (let i = 1; i < drawings[0].children.length; i++)
      drawings[0].children[i].pickable = false;
  }

  async saveSVG (pathModel) {
    let urlDrawing = pathModel + '/drawing.svg';
    logger.log(`saveSVG(${urlDrawing})`);

    const view2d = theApp.findViewByName('2D Ansicht');
    const drawing = view2d.root;

    const exporter = new CADdySVGExporter();
    const svg = exporter.write(theApp.model.symbols.getReferencedSymbols(drawing), drawing);

    const fileService = new FileService();
    await fileService.requestUploadUrl(pathModel, 'drawing.svg').then(url => fileService.upload(url, svg, 'image/svg+xml'));
  }

  doLoadSVG (args) {
    logger.log('doLoadSVG');
    
    const pathModel = args[1];
    const afterLoadAction = args.slice(2).join(' ');

    this.loadSVG(pathModel, afterLoadAction).then ( () => {
      if (afterLoadAction)
        theApp.executeCommand(afterLoadAction);
    });
  }

  doSaveSVG (args) {
    logger.log('doSaveSVG');
    
    if (args.length == 2) {
      const pathModel = args[1];

      this.saveSVG(pathModel);
    }

    if (args.length == 3) {
      const pathModel = args[1];
      const filename = args[2];

      this.saveSVG(pathModel, filename);
    }
  }

  doCreateItems (args) {
    logger.log('doCreateItems');

    if (args.length == 2) {
      const eventId = args[1];
      Ticketing.createBookableItems(eventId);
    }
  }

  doLoad (args) {
    logger.log('doLoad');
    
    let pathModel = args[1];
    let afterLoadAction = args.slice(2).join(' ');

    theApp.findDialogByName('main')?.setShowOverlay(true);

    this.loadSVG(pathModel, null);

    let view3d = theApp.findViewByName('3D Ansicht');
    theApp.findDialogByName('3D Ansicht')?.setCursor('wait');

    const urlModel = `${Settings.get('.urlFileService')}/files/${pathModel}`;

    const withoutMeshes = !(view3d instanceof VisualEvents3DView);

    CADdy3DLoader.load3D(theApp.model, urlModel, withoutMeshes).then(() => {
      logger.log('VisualEvents::init loaded 3D');

      if (view3d) {
        view3d.setRoot(theApp.model.space);

        theApp.model.changed3d = true;
  
        if (theApp.findMountedViewByName('3D Ansicht')) {
          view3d.initCameraPosition();
          view3d.addLight();
        }
  
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
      }
      theApp.findDialogByName('main')?.setShowOverlay(false);

      //TODO: find better solution to build up 2D 3D links after load
      Event3DPlacing.buildLinks2DTo3D(theApp.model.drawing[0]);
      
      if (afterLoadAction)
        theApp.sendCommand(afterLoadAction);
    });
  }

  doLoadEmpty () {
    const drawing = OpFactory.createDrawing('Zeichnung', 210, 297, '0.000000 0.000000 297.000000 210.000000');
    for (const name of ['Planung', 'Grundriss', 'Normblatt']) {
        const skb = OpFactory.createSketchboard(name);
        drawing.add(skb);
    }
    const space =  OpFactory.createSpace('3D Space');
    theApp.model.drawing = [ drawing ];
    theApp.model.space = space;
    theApp.model.light = [];

    let view2d = theApp.findViewByName('2D Ansicht');
    view2d.setRoot(theApp.model.drawing[0]);


    let view3d = theApp.findViewByName('3D Ansicht');
    view3d.setRoot(theApp.model.space);
    if (theApp.findMountedViewByName('3D Ansicht')) {
        view3d.updateScene();
        view3d.initCameraPosition();
        view3d.addLight();
      }
  }

  doLoadFavorites (args) {
    logger.log('doLoadFavorites');

    let path = args[1];
    const urlFavorites = Settings.get('.urlFileService') + "/files/" + path + "/drawing.svg";

    let panel = theApp.findViewByName('Favorites');
    theApp.findDialogByName('Favorites')?.setCursor('wait');

    const loader = new CADdy2DLoader(this);
    loader.requestHeader = FileService.requestHeader();
    loader.load(urlFavorites, opObjects => {
      logger.log('VisualEvents::init loaded 2D Symbole');
      const symbols = opObjects.filter(op => op instanceof OpSymbol);
      symbols.forEach(s => {
          theApp.model.symbols.add(s);
      });

      const drawings = opObjects.filter(op => !(op instanceof OpSymbol));
      theApp.model.favorites2D = drawings; //replace!

      panel.setRoot(drawings[0]);

      panel.camera.zoom = 4;  // TODO: zoom ausrechnen, einpassen
      const cx = 150/2;
      const cy = -100/2;
      panel.center([this.name, cx, cy]); 
  
      theApp.model.changedFavorites = true;

      theApp.findDialogByName('Favorites')?.setCursor(undefined);
      panel.updateScene();
    });
  }
  
  saveFavorites(path) {
    logger.log(`saveFavorites(${path})`);

    const view = theApp.findViewByName('Favorites');
    const drawing = view.root;
    if (!drawing)
      return;

    const exporter = new CADdySVGExporter();
    const svg = exporter.write(theApp.model.symbols.getReferencedSymbols(drawing), drawing);

    const fileService = new FileService();
    
    fileService.requestUploadUrl(path, 'drawing.svg').then(url => fileService.upload(url, svg, 'image/svg+xml'));
  }

  doSaveFavorites (args) {
    logger.log('doSaveFavorites');

    let path = args[1];
    let pathFavorites = path;
    
    this.saveFavorites(pathFavorites);
  }

  doSave(args) {
    logger.log('doSave ' + args);

    let filename = args[1] + '/model.json';
    let buffer = Buffer.from(JSON.stringify(theApp.model.space));
    let content = buffer.toString('base64');

    let json = {
        filename: filename,
        content: content
    };

    let request = new XMLHttpRequest();

    request.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        logger.log(this.response);
      }
    }

    request.open('POST', 'scripts/upload.php');
    request.setRequestHeader("Content-Type", "application/json");
    request.send(json);
  }

  doMove3DObject (args) {
    logger.log('doMove3DObject');
    Action.start(new ActMove3DObject(args));
  }

  doPlaceBlock(args) {
    logger.log('doPlaceBlock');
    Action.start(new ActPlaceBlock(args));
  }

  doBooking(args) {
    logger.log('doBooking');
    Action.start(new ActBooking(args));
  }

  doReadSettings(args) {
    logger.log('doReadSettings');
    UtilSettings.read(Settings.get('.pathSettings'));
  }

  doWriteSettings(args) {
    logger.log('doWriteSettings');
    UtilSettings.write(Settings.get('.pathSettings'));
  }

  doLogDrawing(args){
    logger.log('doLogDrawing');
    UtilLogs.logDrawing(args);
  }

  doLogSymbols(args){
    logger.log('doLogSymbols');
    UtilLogs.logSymbols(args);
  }

  doLogSymbol(args){
    logger.log('doLogSymbol');
    UtilLogs.logSymbol(args);
  }

  doLogSpace(args){
    logger.log('doLogSpace');
    UtilLogs.logSpace(args);
  }
}
