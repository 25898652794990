<template>
    <div class="panel-circle">
        <div class="col-12 fw-bold">Tisch</div>
        <div class="ve-row mb-1">
            <label class="col-sm-12 ps-3 mb-0 mt-2 col-form-label">Größen</label>
            <div class="col-sm-6">
                <select class="form-select mb-2 col-6" v-model="optionSize" @change="onChangeOptionSize($event)">
                    <option key="0" value=0>Freie Eingabe</option>
                    <option key="1" value=1000>100 cm</option>
                    <option key="2" value=1200>120 cm</option>
                    <option key="3" value=1400>140 cm</option>
                    <option key="4" value=1600>160 cm</option>
                    <option key="5" value=1800>180 cm</option>
                    <option key="6" value=2000>200 cm</option>
                </select>
            </div>
        </div>
        <fieldset v-show="showInput" class="ve-row">
            <InputNumberWithUnit class="col-sm-6" unit="cm" label="Durchmesser" :scale="10" :min="1" :step="1" :digits="1"
                v-model="tableDiameter" @update:modelValue="onChangeTableDiameter" />
        </fieldset>
        <div class="mt-3 ms-1 mb-0">Farbe</div>
        <div class="mt-0 ms-1 mb-3">
            <InputButtonColor label="" v-model="tableColor" @update:modelValue="onChangeTableColor" />
            <hr class="hr" />
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue';
import Settings from '@/visual-events/data/Settings';

export default {
    name: 'PanelTableCircle',
    components: {
        InputNumberWithUnit,
        InputButtonColor,
    },
    data() {
        return {
            optionSize: this.findOptionSize(Settings.get('furniture.tableDiameter', 500)),
            tableDiameter: Settings.get('furniture.tableDiameter', 500),
            tableColor: Settings.get('furniture.tableColor', '#FFFFFF'),
            showInput: this.optionSize === 0
        }
    },
    mounted() {
        theApp.addDialog('PanelTableCircle', this)
    },
    unmounted() {
        theApp.removeDialog('PanelTableCircle')
    },
    methods: {
        update(action) {
            this.tableDiameter = action.tableDiameter;
            this.tableColor = action.tableColor;
            this.optionSize = this.findOptionSize(this.tableDiameter)
            this.showInput = this.optionSize === 0
        },
        onChangeOptionSize(event) {
            if (parseInt(event.target.value) !== 0) {
                this.tableDiameter = parseInt(event.target.value);
                this.onChangeTableDiameter()
                this.showInput = false;
            } else {
                this.showInput = true;
            }
        },
        onChangeTableDiameter() {
            theApp.executeValueEvent("tableDiameter", this.tableDiameter)
            Settings.set('furniture.tableDiameter', this.tableDiameter)
        },
        onChangeTableColor() {
            theApp.executeValueEvent("tableColor", this.tableColor)
            Settings.set('furniture.tableColor', this.tableColor)
        },
        findOptionSize(diameter) {
            switch (diameter) {
                case 1000:
                case 1200:
                case 1400:
                case 1600:
                case 1800:
                case 2000:
                    return diameter
                default:
                    return 0
            }
        }
    }
}
</script>

<style scoped></style>