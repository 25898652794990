<template>
  <div class="coupled-2d-3d">
    <WebGLRenderCanvas v-bind:viewName="'3D Ansicht'">3D-Ansicht</WebGLRenderCanvas>
    <WebGLRenderCanvas v-bind:viewName="'2D Ansicht'">Booking-Ansicht</WebGLRenderCanvas>
  </div>
</template>

<script>
// @ is an alias to /src
import theApp from '@/frame/Application';
import Logger from '@/frame/Logger';
import WebGLRenderCanvas from '@/vuejs/components/WebGLRenderCanvas.vue';
import Ticketing from '@/visual-events/ticketing/Ticketing';

const logger = new Logger('Booking.vue');

export default {
  name: 'Booking3D',
  props: {
    eventId: String,
    cartId: String,
    action: String,
    allStates: Boolean,
    apiKey: String,
    language: String,
    readOnly: Boolean,
    timerMax: Number,
    timerInterval: Number,
    zoomMin: Number,
    zoomMax: Number
  },
  components: {
    WebGLRenderCanvas
  },
  methods: {
    async load() {
      Ticketing.apiKey = this.apiKey;
      Ticketing.language = this.language;
      const pathModel = await Ticketing.getPlanForEvent(this.eventId)
      if (pathModel) 
          theApp.sendCommand(`.VisualEvents.load "${pathModel}" ${this.action} ${this.eventId} ${this.cartId} ${this.allStates} ${this.apiKey} ${this.language} ${this.readOnly} ${this.timerMax} ${this.timerInterval} ${this.zoomMin} ${this.zoomMax}`);
          //TODO: combine action args in options object in order not to rely on the ordering of the args, and missing opts
    }
  },

  mounted () {
    logger.log('mounted')
    this.load();
  },
  updated () {
    logger.log('updated')
    this.load();
  },
  unmounted () {
    logger.log('unmounted')
  }
}
</script>

<style>
.coupled-2d-3d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.ve-hover {
  display: flex;
  width: 200px;
  padding: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.3);
}
.ve-list {
  width: 100%;
}
.ve-attribute {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.ve-popup {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 250px;
    background-color: white;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.3);
}
.ve-popup-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
}
.ve-popup-header-title {
    font-size: 20px;
}
.ve-popup-header-close {
    font-size: 20px;
    cursor: pointer;
}
.ve-popup-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.ve-popup-body-value {
  font-size: 16px;
}
.ve-popup-body button {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    color: black;
    box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.3);
    border: none;
    cursor: pointer;
    font-size: 20px;
}
.ve-popup-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    font-size: 14px;
}
.ve-popup-footer button {
    padding: 10px;
    border-radius: 5px;
    background-color: blue;
    color: white;
    border: none;
    cursor: pointer;
}
</style>

<style>
.canvas-2d {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
</style>