import Command from '@/frame/Command';
import CommandPool from '@/frame/CommandPool';

import theApp from '@/frame/Application';

export default class CommandPoolView extends CommandPool {
  constructor () {
    super(CommandPool.root, 'view', [
      new Command('center', args => this.doCenter(args)),
      // new Command('up', args => doUp(args)),
      // new Command('down', args => doDown(args)),
      // new Command('left', args => doLeft(args)),
      // new Command('right', args => doRight(args)),
      // new Command('rotateLeft', args => doRotateLeft(args)),
      // new Command('rotateRight', args => doRotateRight(args)),
      new Command('fitToCanvas', args => this.doFitToCanvas(args)),
      new Command('zoom', args => this.doZoom(args)),
      new Command('printToPdf', args => this.doPrintToPdf(args))
    ]);
  }

  doCenter (args) { 
    if (args.length < 1)
      return;
    const view = theApp.findViewByName(args[0]);
    if (view)
      view.center(args); 
  }

  doZoom(args) {
    if (args.length < 2)
      return;
    const view = theApp.findViewByName(args[1]);
    if (view && view.zoom)
      view.zoom(parseFloat(args[2]));
  }
  
  doFitToCanvas(args) {
    if (args.length < 1)
      return;
    const view = theApp.findViewByName(args[1]);
    if (view && view.fitToCanvas) {
      view.fitToCanvas();

      //apply an initial zoom, usually zoomOut to give a margin
      if (args.length > 2) {
        const zoomToFit = Number.parseFloat(args[2]);
        if (!isNaN(zoomToFit))
          view.zoom(zoomToFit);
      }
    }
  }

  doPrintToPdf(args) {
    const view2D = theApp.findViewByName('2D Ansicht');
    view2D.toPdf();
  }
}
