import Variant from '@/visual-events/model/Variant';
import Inventory from '@/visual-events/inventory/Inventory';

export default class VariantInventory extends Variant {
    constructor (itemId, is2D, tags = []) {
        const opts = {
            itemId: itemId,
            is2D: is2D
        }
        super ('INVENTORY', 1, 'inventory', opts);
        this.json.tags = tags;
        this.json.allowTriangleMode = VariantInventory.isRoundTable(this.json.tags);
    }

    async createSymbolAsync () {
        const itemId = this.json.opts.itemId;
        const is2D = this.json.opts.is2D;

        if (is2D) 
            return await Inventory.createSymbol2D(itemId);
        else 
            return await Inventory.createSymbol3D(itemId);
    }

    static isRoundTable (tags) {
        const name = 'form'; //'furnitureType';
        const value = 'rund'; //'Rundtisch';
        const tag = tags[name];
        return tag?.includes(value);
    }
}