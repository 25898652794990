import FloorPlan from '@/visual-events/actions/FloorPlan';
import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import * as THREE from 'three';

export const DoorType = Object.freeze({ 
    ONE_WING : '4d89742c-5011-407f-bc94-cdf81f934033',
    TWO_WING : '4d89742c-5011-407f-bc94-cdf81f934034',
    SWING : '4d89742c-5011-407f-bc94-cdf81f934035',
});

export default class VariantDoor extends Variant {
    constructor (width, thickness, color, pivot, id, frame, sash, rail, strokeWidth) {
        const opts = {
            width: width,
            thickness: thickness,
            color: color,
            pivot: pivot,
            id: id,
            frame: frame,
            sash: sash,
            rail: rail,
            strokeWidth: strokeWidth,
        }
        super ('SHAPE_DOOR', 1, 'standard door', opts);
        this.t = new THREE.Matrix4();
        this.t1 = new THREE.Matrix4();
        this.r = new THREE.Matrix4();
    }

    createSymbol () {
        const width = this.json.opts.width;
        const thickness = this.json.opts.thickness;
        const color = this.json.opts.color;
        const pivot = this.json.opts.pivot;
        const id = this.json.opts.id;
        const frame = this.json.opts.frame;
        const sash = this.json.opts.sash;
        const rail = this.json.opts.rail;
        const strokeWidth = this.json.opts.strokeWidth;
        const sashYTop = -(thickness/2 - sash/2);
        const sashYBottom = -(thickness/2 + sash/2);
        const opSymbol = OpFactory.createSymbol(`${this.description}`);

        const pointsDoor =[
            [-0.5 * width, -thickness],
            [ 0.5 * width, -thickness],
            [ 0.5 * width, 0 ],
            [-0.5 * width, 0 ],
        ];

        const door = OpFactory.createPolygon(pointsDoor);
        door.style.fill = color;
        door.style.stroke = color;
        door.style.strokeWidth = strokeWidth;
        opSymbol.add(door);

        const pointsSash =[
            [-0.5 * width + strokeWidth, sashYTop],
            [ 0.5 * width - strokeWidth, sashYTop],
            [ 0.5 * width - strokeWidth, sashYBottom ],
            [-0.5 * width + strokeWidth, sashYBottom ],
        ];

        const drawSash = OpFactory.createPolygon(pointsSash);
        drawSash.style.fill = color;
        drawSash.style.stroke = '#000000';
        drawSash.style.strokeWidth = strokeWidth;
        opSymbol.add(drawSash);

        let radius = width - 2 * frame;
        if(id === DoorType.TWO_WING || id === DoorType.SWING)
            radius = radius / 2;

        const swingLeft = FloorPlan.createDoorSwing(-0.5 * width + frame, sashYTop, radius, pivot, sashYBottom);
        swingLeft.style.fill = color;
        swingLeft.style.stroke = '#000000';
        swingLeft.style.strokeWidth = strokeWidth;

        const swingRight = FloorPlan.createDoorSwing(0, sashYTop, radius, 'right', sashYBottom);
        swingRight.style.fill = color;
        swingRight.style.stroke = '#000000';
        swingRight.style.strokeWidth = strokeWidth;

        if(id === DoorType.ONE_WING || id === DoorType.TWO_WING){
            opSymbol.add(swingLeft);
        }
        if(id === DoorType.TWO_WING){
            opSymbol.add(swingRight);
        }

        if(id === DoorType.SWING){
            opSymbol.add(swingLeft);
            opSymbol.add(swingRight);

            const bottomLeft = FloorPlan.createDoorSwing(-0.5 * width + frame, sashYBottom, radius, 'bottomLeft', sashYTop);
            bottomLeft.style.fill = color;
            bottomLeft.style.stroke = '#000000';
            bottomLeft.style.strokeWidth = strokeWidth;
            opSymbol.add(bottomLeft);

            const bottomRight = FloorPlan.createDoorSwing(0, sashYBottom, radius, 'bottomRight', sashYTop);
            bottomRight.style.fill = color;
            bottomRight.style.stroke = '#000000';
            bottomRight.style.strokeWidth = strokeWidth;
            opSymbol.add(bottomRight);

        }

        const railTop = OpFactory.createLine(- 0.5 * width + frame, sashYTop - rail, 0.5 * width - frame, sashYTop - rail);
        railTop.style.fill = '#000000';
        railTop.style.stroke = '#000000';
        railTop.style.strokeWidth = strokeWidth;
        opSymbol.add(railTop);

        const railBottom = OpFactory.createLine(- 0.5 * width + frame, sashYBottom + rail, 0.5 * width - frame, sashYBottom + rail);
        railBottom.style.fill = '#000000';
        railBottom.style.stroke = '#000000';
        railBottom.style.strokeWidth = strokeWidth;
        opSymbol.add(railBottom);

        return opSymbol;
    }
}