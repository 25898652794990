import * as THREE from 'three';
import theApp from '@/frame/Application';
import FileService from '../RestAPI/FileService';
import Settings from '../data/Settings';
import { FileServiceTextureLoader } from '../loader/FileServiceTextureLoader';

export default class FloorPlan3D {

    static extrudeFloor(shapePath, depth, geometryOnly) {
        const geometry = new THREE.ExtrudeGeometry(shapePath.subPaths[1], { depth: depth, bevelEnabled: false/*, UVGenerator: new CustomUVGenerator()*/ });

        if (geometryOnly)
            return geometry;

        const material = new THREE.MeshStandardMaterial({
            color: 0xb37c56
        });
        const mesh = new THREE.Mesh(geometry, material);

        const textureLoader = new FileServiceTextureLoader();
        textureLoader.requestHeader = FileService.requestHeader();

        //const urlTexture = `${Settings.get('.urlFileService')}/files/$tenant/models/zeltplan/textures/9efd2d9e5c185d1634a3f69c54e6ec7a55239b0f.jpg`;
        const urlTexture = `${Settings.get('.urlFileService')}/files/$tenant/models/Festsaal1/textures/12744640615631874162.jpg`;
        textureLoader.load(urlTexture, (texture) => {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
			texture.repeat.set( 0.0008, 0.0008 );
            mesh.material.map = texture;
            theApp.model.changed3d = true;
        });

        return mesh;
    }

    static extrudeWalls(shapePath, depth, geometryOnly) {
        const geometry = new THREE.ExtrudeGeometry(shapePath.toShapes(), { depth: depth, bevelEnabled: false/*, UVGenerator: new CustomUVGenerator()*/ });

        if (geometryOnly)
            return geometry;
        
        const material = new THREE.MeshStandardMaterial({
            color: 0xffffff
        });
        const mesh = new THREE.Mesh(geometry, material);
        
        return mesh;
    }

}