import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Planer from '../views/Planer.vue'
import PlanerOld from '../views/PlanerOld.vue'
import PlanerTicketing from '../views/PlanerTicketing.vue';
import Planer2DOld from '../views/Planer2DOld.vue'
import Booking from '../views/Booking.vue'
import Booking3D from '../views/Booking3D.vue'
import Inventory from '../views/Inventory.vue'
import VisualEvents from '../../visual-events/VisualEvents';

/**
 * retrieve the configuration parameter 'option'
 * - url search parameter wins
 * - option handed over in startVisualEvents
 * - optional: default value
 * - undefined, else
 * @param {*} route 
 * @param {*} option 
 * @param {*} forUndefined 
 * @returns 
 */
const getOption = (route, option, forUndefined) => route.query[option] || VisualEvents.opts[option] || forUndefined
const getOptionBoolean = (route, option, forUndefined) => (route.query[option] ? route.query[option] === 'true' : undefined) || VisualEvents.opts[option] || forUndefined 
  
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/planerOld',
    name: 'PlanerOld',
    component: PlanerOld,
    props: route => ({ 
      model:   getOption(route, 'model'),
      symbols: getOption(route, 'symbols'),
      variant: getOption(route, 'variant'),
      action:  getOption(route, 'action'),
    })
  },
  {
    path: '/planer',
    name: 'Planer',
    component: Planer,
    props: route => ({ 
      eventId:    getOption(route, 'eventId'),
      model:      getOption(route, 'model'),
      action:     getOption(route, 'action'),
      apiKey:     getOption(route, 'apiKey', '0253bbae-1b78-45ca-94da-e7e2ccf740d3'),
      language:   getOption(route, 'language','de'),
      symbols:    getOption(route, 'symbols'),
      panels:     getOption(route, 'panels'),
      useUnreal:  getOption(route, 'useUnreal', false)
    })
  },
  {
    path: '/planerTicketing',
    name: 'PlanerTicketing',
    component: PlanerTicketing,
    props: route => ({ 
      eventId:    getOption(route, 'eventId'),
      model:      getOption(route, 'model'),
      action:     getOption(route, 'action'),
      apiKey:     getOption(route, 'apiKey', '0253bbae-1b78-45ca-94da-e7e2ccf740d3'),
      language:   getOption(route, 'language','de'),
      symbols:    getOption(route, 'symbols'),
      panels:     getOption(route, 'panels'),
    })
  },
  {
    path: '/planer2DOld',
    name: 'Planer2DOld',
    component: Planer2DOld,
    props: route => ({ 
      model:    getOption(route, 'model'),
      symbols:  getOption(route, 'symbols'),
      variant:  getOption(route, 'variant'),
      action:   getOption(route, 'action')
    })
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking,
    props: route => ({ 
      eventId:        getOption(route, 'eventId'),
      cartId:         getOption(route, 'cartId'),
      action:         getOption(route, 'action'),
      allStates:      getOptionBoolean(route, 'allStates', false),
      apiKey:         getOption(route, 'apiKey','0253bbae-1b78-45ca-94da-e7e2ccf740d3'),
      language:       getOption(route, 'language', 'de'),
      readOnly:       getOptionBoolean(route, 'readOnly'),
      timerMax:       Number(getOption(route, 'timerMax')),
      timerInterval:  Number(getOption(route, 'timerInterval')),
      zoomMin:        Number(getOption(route, 'zoomMin')),
      zoomMax:        Number(getOption(route, 'zoomMax')),
    })
  },
  {
    path: '/booking3d',
    name: 'Booking3D',
    component: Booking3D,
    props: route => ({ 
      eventId:        getOption(route, 'eventId'),
      cartId:         getOption(route, 'cartId'),
      action:         getOption(route, 'action'),
      allStates:      getOptionBoolean(route, 'allStates', false),
      apiKey:         getOption(route, 'apiKey','0253bbae-1b78-45ca-94da-e7e2ccf740d3'),
      language:       getOption(route, 'language', 'de'),
      readOnly:       getOptionBoolean(route, 'readOnly'),
      timerMax:       Number(getOption(route, 'timerMax')),
      timerInterval:  Number(getOption(route, 'timerInterval')),
      zoomMin:        Number(getOption(route, 'zoomMin')),
      zoomMax:        Number(getOption(route, 'zoomMax')),
    })
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
