import { Matrix4, Path, ShapePath } from 'three';
import { CADdySVGLoader } from '@/visual-events/loader/CADdySVGLoader.js';
import { nanoid } from 'nanoid'

import theApp from '@/frame/Application';

import OpObject from '@/visual-events/model/OpObject'
import { CADdyType } from '@/visual-events/loader/CADdyType'

import OpGroup from '@/visual-events/model/OpGroup';
import OpSymbol from '@/visual-events/model/OpSymbol';
import OpReference from '@/visual-events/model/OpReference';
import OpShapePath from '@/visual-events/model/OpShapePath';
import ShapeUtils from '@/visual-events/model/ShapeUtils';
import OpMesh from '@/visual-events/model/OpMesh';
import OpPoint from '@/visual-events/model/OpPoint';

import OpText from '@/visual-events/model/OpText'
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText'

export default class OpFactory {

    //-----------------------------------------------------------------------------------------------------------------
    // containers:
    //-----------------------------------------------------------------------------------------------------------------

    static createDrawing (name, width, height, viewbox) {
        const op = new OpGroup(CADdyType.DRAWING, name);
        op['width'] = width;
        op['height'] = height;
        op['viewBox'] = viewbox; 
        return op;
    }

    static createSpace (name) {
        return new OpGroup(CADdyType.SPACE, name);
    }

    static createSketchboard (name) {
        return new OpGroup(CADdyType.SKETCHBOARD, name);
    }

    static createGroup (name) {
        return new OpGroup(CADdyType.GROUP, name);
    }

   //-----------------------------------------------------------------------------------------------------------------
    // instancing
    //-----------------------------------------------------------------------------------------------------------------

    static findSymbol(symbolId) {
        return theApp.model.symbols.get(symbolId);
    }

    static createSymbol(name, symbolId, addToSymbols = true) {
        if (!symbolId)
            symbolId = OpFactory.createUUID();

        const symbol =  new OpSymbol(name, symbolId);
        if (addToSymbols)
            theApp.model.symbols.add(symbol);
        return symbol;
    }

    static createReference(name, symbolId, transform) {
        return new OpReference(name, symbolId, transform);
    }

    //-----------------------------------------------------------------------------------------------------------------
    // shape path objects incl. texts
    //-----------------------------------------------------------------------------------------------------------------

    static createLine ( x1, y1, x2, y2) {

        const path = new ShapePath();
        path.moveTo( x1, y1 );
        path.lineTo( x2, y2 );
        path.currentPath.autoClose = false;

        const style = { fill: 'none', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}

        return new OpShapePath(CADdyType.LINE, 'line', path, style);
    }

    static createPolygon ( points ) {

        if (points.length < 3)
            return null;

        const path = new ShapePath();

        path.moveTo( points[0][0], points[0][1] );
        for (let i = 1; i < points.length; i++) 
            path.lineTo( points[i][0], points[i][1] );
        path.currentPath.autoClose = true;

        const style = { fill: '#000000', fillOpacity: 1, stroke: 'none', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}
        
        return new OpShapePath(CADdyType.FACE, 'polygon', path, style);
    } 

    static createCircle (cx, cy, r) {
        const subpath = new Path();
        subpath.absarc( cx, cy, r, 0, Math.PI * 2 );

        const path = new ShapePath();
        path.subPaths.push( subpath );

        const style = { fill: '#000000', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}

        return new OpShapePath(CADdyType.CIRCLE, 'circle', path, style);
    }

    static createChair (x, y, width, height, r) {
        const shape = ShapeUtils.createChairPath(x, y, width, height, r);

        const style = { fill: '#000000', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}

        return new OpShapePath(CADdyType.CIRCLE, 'chair', shape, style);
    }

    static createFromSVG (svg) {
        const result = CADdySVGLoader.parse (svg);
        const path = result.paths[0];
        const style = { fill: '#000000', fillOpacity: 1, stroke: 'none', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}
        return new OpShapePath(CADdyType.FACE, '', path, style);
    }

    static createOpShapePath (type, name, path, style) {
        const op =  new OpShapePath(type, name, path, style);
        op.path = path;
        op.style = style;
        return op;
    }

    static createText (text, fontSize, fontFamily = undefined, textAnchor = TextAnchor.start, baseLine = BaseLine.baseline, style = undefined) {
        const op = new OpText(text, fontSize, fontFamily, textAnchor, baseLine, style);

        return op;
    }

   //-----------------------------------------------------------------------------------------------------------------
   // mesh objects
   //-----------------------------------------------------------------------------------------------------------------

    static createMesh(type, name, mesh, url, timestamp = url ? Date.now() : undefined) {
        const op =  new OpMesh(type, name, mesh, url, timestamp);

        return op;
    }

   //-----------------------------------------------------------------------------------------------------------------
   // special
   //-----------------------------------------------------------------------------------------------------------------

    /**
     * create a point of interest in the origin of the model
     * 
     * usually insufficent:
     * - set the transform to define a position and direction
     * - define the purpose and required options as attribute (see Example in CameraUtils)
     * 
     * TODO: possibly provide more useful create methods
     * @param {*} name 
     * @returns 
     */
    static createPoint(name) {
        return new OpPoint(name);
    }

    /**-----------------------------------------------------------------------------------------------------------------
     * create a universal unique identifier
     * 
     * Whenever a unique identifier is needed, which most probably never occur twice all over the world, 
     * use this method to generate it. Do not rely on anything other than === or !==. Do not assume, it is 
     * of a certain type such as string or number. 
     * 
     * Intended only for comparison, whether two items are the same, or to create a relationship such as the 2D-3D-coupling.
     *  
     * Currently it's nanoid, but may be replaced if needed.
     * 
     * @returns uuid as string
     */
    static createUUID () {
        return nanoid();
    }


}