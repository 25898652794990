import * as THREE from 'three';

import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import * as Event from '@/frame/Event.js';
import FltPointDef from '@/visual-events/actions/FltPointDef';

import Event3DPlacing from '@/visual-events/model/Event3DPlacing';
import Inventory from '@/visual-events/inventory/Inventory';
import VariantInventory from '@/visual-events/inventory/VariantInventory';
import Logger from '@/frame/Logger';

const State = Object.freeze({
    WAIT: 0,    // while asynchroniously loading
    PLACE: 1,   // click in position to place
  });

const logger = new Logger('ActPlaceInventory');
  
export default class ActPlaceInventory extends Action {

    constructor(args) {
      super();

      this.args = args;

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.view3D = theApp.findViewByName('3D Ansicht');
      this.root3D = this.view3D.getRoot();

      // working matrix
      this.t = new THREE.Matrix4();

      this.position = new THREE.Vector3(0, 0, 0);

      if (args.length < 2)
        return false;

      this.itemId = args[1];
      
      this.op2D = null; // current 2D symbol
      this.op3D = null; // current 3D symbol

      this.grafic2D = null; // temporary grafic
      this.grafic3D = null; // temporary grafic

      this.state = State.WAIT;

      // allways allow the user to explicitly place the inventory item
      // - either by drag&drop, i.e. keep the left mouse button pressed while moving
      // - or by click on the plan
      // The difficulty is, that createSymbols takes time to asynchroniuously load the symbols 
      // (in the meantime the user may have already released the mouse button)
      // or the symbol already exist and the next point up would finish the action without giving the 
      // opportunity to place the symbol.  Therefore, in the latter case besides the finishing mouse up 
      // there should at least happen one mouse move event after createSymbols was ready.
      this.minimumEventsToFinish;
    }

    actionStart () {
        logger.log(`actionStart`);

        this.state = State.WAIT;
        this.adaptCursor();

        this.hideSidePane();

        logger.log('starting createSymbols');
        this.minimumEventsToFinish = 1;
        this.createSymbols(this.itemId).then( immediate => {

            if (!this.op2D || !this.op3D) {
                theApp.sendCommand('.Ticketing.inventory');
                return;
            }

            logger.log(`in createSymbols callback ${immediate ? '(could use existing symbols)' : '(had to load symbol)'}`);
            if (immediate)
                this.minimumEventsToFinish += 1; // at least one mouse move expected

            this.state = State.PLACE;
            this.adaptCursor();
            this.showTemporaryGrafic();
            const filter = new FltPointDef();
            this.addFilter(filter);
        });

        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy ${this.state}`);
        this.resetCursor();
        this.clearTemporaryGrafic();
        this.disconnectFromGUI();
    }

    actionDynamic (event) {
        logger.log(`actionDynamic ${this.state}`);
        switch (this.state) {
            case State.PLACE: {
                this.minimumEventsToFinish--;

                this.position.x = event.p[0];
                this.position.y = event.p[1];

                this.adaptTemporaryGrafic();
                theApp.model.changed2d = true; //op;
            }
            break;
        }

        return null;
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.WAIT: {
                this.minimumEventsToFinish--;
                break;
            }
            case State.PLACE: {
                this.position.x = event.p[0];
                this.position.y = event.p[1];

                this.adaptTemporaryGrafic();

                this.minimumEventsToFinish--;
                if (this.minimumEventsToFinish < 1) {
                    const op = this.finish();
                
                    //stop the action, effectively go to ActWaitForUserInput
                    // return new Event.BreakEvent();
    
                    // return to the inventory dialog after placing 
                    //result = new Event.CommandEvent('.Ticketing.inventory');
    
                    return new Event.CommandEvent('.Ticketing.editBlock', op);
                }
            }
        }

        return null;
    }

    actionValue (event) {
        logger.log(`actionValue ${this.state}`);

        let done = false;

        // if (event.attribute === 'angle') {
        //     this.angle = event.value;
        //     this.adaptAngle();
        //     this.adaptTemporaryGrafic();
        //     done = true;
        // }

        return done ? null : event;
    }

    /**
     * transfer the symbol currently being placed from the temporary grafic into the model.
     * @returns 
     */
    finish () {
        const op2D = this.grafic2D;
        this.grafic2D = null; // clear temporary grafic
        this.root2D.add(op2D);

        const op3D = this.grafic3D;
        this.grafic3D = null;
        this.root3D.add(op3D);

        return [ op2D ];
    }

    showTemporaryGrafic () {
        switch (this.state) {
            case State.PLACE:
                this.grafic2D = this.op2D;
                this.root2D.add(this.grafic2D);
                theApp.model.changed2d = true; //op;
                this.grafic3D = this.op3D;
                this.root3D.add(this.grafic3D);
                theApp.model.changed3d = true; //op;
                break;
        }
    }

    clearTemporaryGrafic () {
        if (this.grafic2D) {
            this.root2D.remove(this.grafic2D);
            this.grafic2D = null;
            theApp.model.changed2d = true; //op;
        }
        if (this.grafic3D) {
            this.root3D.remove(this.grafic3D);
            this.grafic3D = null;
            theApp.model.changed3d = true; //op;
        }
    }

    adaptTemporaryGrafic () {
        switch (this.state) {
            case State.PLACE: {
                this.t.makeTranslation(this.position.x, this.position.y, 0.0);
                
                const op2D = this.grafic2D;
                op2D.setTransform(this.t)
                theApp.model.changed2d = true; 

                // const op3D = this.grafic3D;
                // op3D.setTransform(this.t)
                // theApp.model.changed3d = true;
                break;
            }
        }
    }

    /**
     * find in the existing symbols or create the 2D and 3D symbol
     * 
     * if the symbols already exist, the promise is resolved immediately,
     * i.e. no user events comes in between.
     * @param {*} itemId 
     * @returns true, if the symbols already exist
     */
    async createSymbols (itemId) {
        const item = await Inventory.getInventoryItem(itemId);
        const variant2D = new VariantInventory(itemId, true, item.tags);
        const alreadyExists2D = theApp.model.findVariant(variant2D);
        this.op2D = await variant2D.createAsync();
        const variant3D = new VariantInventory(itemId, false, item.tags);
        const alreadyExists3D = theApp.model.findVariant(variant3D);
        this.op3D = await variant3D.createAsync();
        if (this.op2D && this.op3D)
            Event3DPlacing.link2DTo3D(this.op2D, this.op3D);
        return alreadyExists2D && alreadyExists3D;
    }

    adaptCursor () {
        switch(this.state) {
            case State.WAIT: {
                theApp.findDialogByName('main')?.setCursor('wait');
                theApp.findDialogByName('2D Ansicht')?.setCursor('wait');
                theApp.findDialogByName('3D Ansicht')?.setCursor('wait');
            }
            break;
            case State.PLACE: {
                theApp.findDialogByName('main')?.setCursor('no-drop');
                theApp.findDialogByName('2D Ansicht')?.setCursor('grabbing');
                theApp.findDialogByName('3D Ansicht')?.setCursor('no-drop');
            }
       }
    }

    resetCursor () {
        theApp.findDialogByName('main')?.setCursor(undefined);
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
    }

    hideSidePane () {
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}    
