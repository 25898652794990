import RestAPI from '@/visual-events/RestAPI/RestAPI';
import Settings from '@/visual-events/data/Settings';

import Logger from '@/frame/Logger';

const logger = new Logger('FileService');

export default class FileService {

    // downloading resources from urls in the FileService require special headers
    // use constant requestHeader to set these headers for threejs Loader, e.g.
    //
    // let fileLoader = new THREE.FileLoader();
    // fileLoader.requestHeader = FileService.requestHeader()
    //  
    static requestHeader () {
      return {
        'VisualEvents-ApiKey': Settings.get('.userToken', Settings.get('.apiKey')),
        'VisualEvents-Language': Settings.get('.language')
      };
    }

    constructor () {
      this.urlFileService = Settings.get('.urlFileService');
      this.apiToken = Settings.get('.userToken', Settings.get('.apiKey'));
      this.language =  Settings.get('.language');
    }
    
    async subFolders(parentPath) {
      const search = {
        folder: 'folder',
        parentPath: parentPath
      }
      const query = `?${new URLSearchParams(search).toString()}`;
      const endpoint = `${this.urlFileService}/nodes${query}`;
      const method = 'GET';
      const header = new Headers({ Accept: 'application/json', 'VisualEvents-ApiKey': this.apiToken, 'VisualEvents-Language': this.language});

      try {
        const json = await RestAPI.getResponse(endpoint, method, header);
        logger.log(`${json}`)
        return json;
      } catch(e) {
        logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
        throw new Error(`fileService error! status: ${method} ${endpoint}:\n${e.message}`)
      }
    }

    //TODO: wünschenswert: ein Endpunkt um zu checken ob eine bestimmte Datei vorhanden ist

    getDownloadUrl (parentPath, filename) {
      return `${this.urlFileService}/files/${parentPath}/${filename}`;
    }

    async download(url) {
        const endpoint = url;
        const method = 'GET';
        const header = new Headers({'VisualEvents-ApiKey': this.apiToken, 'VisualEvents-Language': this.language});
  
        try {
          const response = await RestAPI.getResponse(endpoint, method, header);
        //TODO: download is not well defined with respect to possible Content-Types
        // in case of binary-octetstream (usual in S3) e.g. it returns the fetch Response object!?? s. RestAPI.js
          if (response instanceof Response) {
            logger.log(`download Response`)
            return await response.text();
          }
          return response;
        } catch(e) {
          logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
          throw new Error(`fileService error! ${method} ${endpoint}:\n${e.message}`)
        }
    }

    async requestUploadUrl(parentPath, filename) {
      const json = {
        type: 'file',
        name: filename,
        parentPath: parentPath
      }
      const endpoint = `${this.urlFileService}/nodes`;
      const method = 'POST';
      const header = new Headers({ Accept: 'application/json', 'VisualEvents-ApiKey': this.apiToken, 'VisualEvents-Language': this.language});

      try {
        const response = await RestAPI.postResponse(endpoint, method, header, json);
        return response;
      } catch(e) {
        logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
        throw new Error(`fileService error! ${method} ${endpoint}:\n${e.message}`)
      }
    }

    async upload(url, content, mime) {
        
      const endpoint = url;
      const method = 'PUT';
      const headers = { 'VisualEvents-ApiKey': this.apiToken, 'VisualEvents-Language': this.language };
      //TODO: worked as well with transferring the text directly, i.e. body: content; Understand, whether Blob or text body is preferable. Streaming, bigger content?
      const blob = new Blob([content], { type: `${mime}`});

      const response = await fetch(
        endpoint,
        {
            method: method,
            headers: headers,
            body: blob
        }
        );
      if (!response.ok) {
          const error = await response.json();
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}  ${error}`);
      }
      const type = response.headers.get('Content-Type');
      if (type && type.includes('application/json'))
          return await response.json();

      return null;
    }
  }

    // remarks:
    // 
    // the pattern 
    //  - requestUploadUrl
    //  - upload
    // is due to the implemention with aws S3 buckets.
    //
    // a  former FileService was written with nodes.js and based on FormData
    // We could handle FormData only correctly by using axios
    //
    //   import axios from 'axios';
    //   async upload(parentPath, filename, content, mime) {
    //
    //     const endpoint = `${this.urlFileService}/nodes`;
    //     const method = 'POST';
    //     const headers = { Accept: 'application/json', 'VisualEvents-ApiKey': this.apiToken, 'VisualEvents-Language': this.language };
    //
    //     const data = new FormData();
    //     data.append('type', 'file');
    //     data.append('name',`${filename}`);
    //     data.append('parentPath',`${parentPath}`);
    //     const blob = new Blob([content], { type: `${mime}`});
    //     data.append('file', blob);
    //
    //     try {
    //         axios.request({
    //                         url: endpoint, 
    //                         method: method,
    //                         headers: headers,
    //                         data:  data
    //                       })
    //           .then(function (response) {
    //             return response;
    //           })
    //           .catch(function (error) {
    //             logger.error(error);
    //           });    
    //     } catch(e) {
    //       logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
    //     }
    // }
    //
    // We did not succeed on handling the Content-Type multipart/form-data with fetch:
    //
    // The items are separated by an deliberately choosen boundary/delimiter, which must be stated in the Content-Type
    // and the correctly calculated length of the body
    //
    // Content-Type: multipart/form-data; boundary=---------------------------735323031399963166993862150
    // Content-Length: 834
    // -----------------------------735323031399963166993862150
    // Content-Disposition: form-data; name="text1"
    // text default
    // -----------------------------735323031399963166993862150
    // Content-Disposition: form-data; name="text2" 
    // ...
    //
    // FormData is available in browser to build up the payload.
    //
    // Obviously, axios under the hood creates the correct headers for us, but not so does the fetch api
    // We don't know how to correctly calcalute the Content-Length.
    //
    // Some forums say it's something like
    //
    // const getFormDataSize = formData => [...formData].reduce((size, [name, value]) => size + (typeof value === 'string' ? value.length : value.size), 0);
    //
    // But it is not clear whether the delimeter text and item headers should be considered, too.
    //
    // static async uploadFetch(parentPath, name, content, mime) {
    //
    //         const endpoint = `${Settings.get('.urlFileService')}/nodes`;
    //         const method = 'POST';
    //
    //         const data = new FormData();
    //         data.append('type', 'file');
    //         data.append('name',`${name}`);
    //         data.append('parentPath',`${parentPath}`);
    //         const blob = new Blob([content], { type: `${mime}`});
    //         data.append('file', blob);
    //         const length = getFormDataSize(data);
    //         // in headers omit 'Content-Type':  multipart/form-data; boundary=---------------------------735323031399963166993862150 ? Which delimiter?
    //         const headers = new Headers({ 'Content-Length': length, Accept: 'application/json', 'VisualEvents-ApiKey': Settings.get('.apiToken'), 'VisualEvents-Language': Settings.get('.language') });
    //         const length = getFormDataSize(data);
    //         const response = await fetch(
    //             endpoint,
    //             {
    //                 method: method,
    //                 headers: headers,
    //                 body: data,
    //             }
    //         );
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    //         }
    //         const type = response.headers.get('Content-Type');
    //         if (type && type.includes('application/json'))
    //             return await response.json();
    //         return null;
    //     }
  

    