import Action from '@/frame/Action';
import Geometry from '@/visual-events/model/Geometry';
import Logger from '@/frame/Logger';
import Settings from '@/visual-events/data/Settings';

const logger = new Logger('FltPointDef');
/**
 * use this filter in actions, where you need constructed 2D coordinates, i.e.
 * 
 * the PointEvent, DynamicEvent and PointUpEvent deliver points in the drawing scale
 * 
 * - apply the current drawing scale 
 *  attention: relies on the de facto convention, that the first child of the root OpObject is the main sketchboard, which
 *  carries a transformation from paper sheet coordinates to the world coordinates of the plan
 * TODO: find a better drawing scale concept than simple convention with first child of the root
 * - apply a raster, if enabled
 * - apply auto pick for other geometry, if enabled
 * - allow for explicit constructions like get endpoint of lines, center points of curves, intersections of lines etc. 
 */
export default class FltPointDef extends Action {

    constructor (args) {
        super();

        this.rasterEnabled = false;
        this.rasterSize = 10.0;
    }

    actionStart ()
    {
        logger.log(`actionStart`);
        this.rasterEnabled = Settings.get('raster.enabled');
        this.rasterSize = Settings.get('raster.size');
        return true;
    }

    actionDestroy ()
    {
        logger.log(`actionDestroy`);
    }

    actionPoint (event) {
        logger.log(`actionPoint ${this.state}`);
        FltPointDef.adaptToScale(event);
        return this.adaptToRaster(event);
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);
        FltPointDef.adaptToScale(event);
        return this.adaptToRaster(event);
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        FltPointDef.adaptToScale(event);
        return this.adaptToRaster(event);
    }

    actionValue (event) {

        if (event.attribute === 'rasterEnabled') {
            this.rasterEnabled = event.value;
        }
        if (event.attribute === 'rasterSize') {
            this.rasterSize = event.value;
        }
        return event;
    }

    /**
     * convenience function to adapt the scale of the current plan on a PointEvent
     * 
     * FltPointDef propagates PointEvents with coordinates, which are scaled.
     * 
     * adaptToScale is useful, where scales coordinates are needed, but FltPointDef is
     * not involved, see as example FltPick.
     * TODO: unsatisfying, that adaptToScale is to be called in virtually every filter, which might be used on top of the action stack
     * Better: In VisualEvents, which has a scaled model concept, it ought to be done in a default filter, but currently not possible (see Action.js)
     * @param {*} event 
     * @returns 
     */
    static adaptToScale (event) {
        event.p = FltPointDef.scalePoint(event.view, event.p);
        return event;
    }

    static scalePoint (view, p) {
        const drawing = view.getRoot();
        //TODO: avoid checking XOpSkB2Draft
        if (drawing?.children?.length > 0) {
            const root2D = drawing.children[0];
            if (root2D.type == 'XOpSkB2Draft') {
                //TODO: consider to apply the sketchboard transformation, not only the scale
                const scale = Geometry.getScaleX(root2D.transform);
                const x = p[0] / scale;
                const y = p[1] / scale;
                const z = p[2] / scale;
                return [x, y, z];
            }
        }
        return [...p]
    }

    /**
     * adapt the coordinates in PointEvent onto the raster point nearest by
     * @param {*} event 
     * @returns 
     */
    adaptToRaster (event) {
        if (this.rasterEnabled) {
            let x = Math.round(event.p[0] / this.rasterSize) * this.rasterSize;
            let y = Math.round(event.p[1] / this.rasterSize) * this.rasterSize;
            let z = Math.round(event.p[2] / this.rasterSize) * this.rasterSize;
            logger.log(`adaptToRaster ${event.p[0]},${event.p[0]}->${x},${y}`);
            event.p = [x, y, z];
        }

        return event;
    }
}