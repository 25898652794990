import Action from '@/frame/Action';
import CommandLineScanner from '@/frame/CommandLineScanner';
import CommandPool from '@/frame/CommandPool';
import Logger from '@/frame/Logger';

const logger = new Logger('ActDefaultTicketing');

export default class ActDefaultTicketing extends Action {
  constructor () {
    super();
    this.defaultCommand = '.Ticketing.waitForUserInput';
  }

  actionBreak (event) {
    Action.stopAll();
    this.executeDefaultCommand();
  }

  actionCommand (event) {
    const commandLine = event.commandLine; // Todo:commandline syntax auswerten, um Kommand und Argumente zu trennen
    const scanner = new CommandLineScanner(commandLine);
    const args = event.args;

    const cmd = scanner.getCommand();

    const command = CommandPool.root.findCommand(cmd);

    if (command != null) {
      command.execute(...scanner.getArgs(), ...args);
    } else {
      // TODO: Fehler/Warnungen Konzept incl. GUI
      // show a message
      logger.error(`Befehl ${cmd} nicht gefunden`);
    }

    if (Action.isDefaultStack())
        this.executeDefaultCommand();
    return null;
  }

  executeDefaultCommand () {
    const command = CommandPool.root.findCommand(this.defaultCommand);
    if (command != null) 
        command.execute();
    else
        logger.error(`Befehl ${this.defaultCommand} nicht gefunden`);
  }
}
