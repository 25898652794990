<template>
    <div class="col-sm-12">
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="table-number-enabled" v-model="displaySeatNoAtTable" @change="onChangeDisplaySeatNoAtTable">
            <label class="form-check-label" for="number-toggle">Sitzplatznummerierung</label>
        </div>
    </div>
    <div v-if="displaySeatNoAtTable">
        <div class="col-sm-12 mb-0 mt-2 ">
            <InputNumber label="Beginnen mit Nummer" :min="1" v-model="startNumber" @update:modelValue="onChangeStartNumber" />
        </div>   
        <div class="col-sm-12 mt-2">
            <fieldset>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-seats-at-table" id="numeration-clockwise" v-model="seatsAtTableMode" value="CLOCKWISE" @change="onChangeModeAtTable">
                    <label class="form-check-label" for="numeration-clockwise">
                        Uhrzeigersinn
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-seats-at-table" id="numeration-counter-clockwise" v-model="seatsAtTableMode" value="COUNTER_CLOCKWISE" @change="onChangeModeAtTable">
                    <label class="form-check-label" for="numeration-counter-clockwise">
                        Gegenuhrzeigersinn
                    </label>
                </div>
            </fieldset>
        </div> 
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputNumber from './InputNumber.vue';

export default {
  name: 'PanelRenumberSeatsForTable',
  components: { 
    InputNumber
  },
  data () {
    return {
        displaySeatNoAtTable : Settings.get('numeration.displaySeatNoAtTable'),
        seatsAtTableMode : Settings.get('numeration.seatsAtTableMode'),
        startNumber : Settings.get('numeration.startNumber'),
    }
  },
  mounted () {
    theApp.addDialog('PanelRenumberSeatsForTable', this)
  },
  unmounted () {
    theApp.removeDialog('PanelRenumberSeatsForTable')
  },
  methods: {
    update(action) {
        this.seatsAtTableMode = action.renumberTool.seatsAtTableMode
        this.startNumber = action.renumberTool.startNumber
        this.displaySeatNoAtTable = action.renumberTool.displaySeatNoAtTable
    },
    onChangeStartNumber() {
      theApp.executeValueEvent("startNumber", this.startNumber)
      Settings.set('numeration.startNumber', this.startNumber)
    },
    onChangeModeAtTable() {
      theApp.executeValueEvent("seatsAtTableMode", this.seatsAtTableMode)
      Settings.set('numeration.seatsAtTableMode', this.seatsAtTableMode)
    },
    onChangeDisplaySeatNoAtTable() {
      theApp.executeValueEvent("displaySeatNoAtTable", this.displaySeatNoAtTable)
      Settings.set('numeration.displaySeatNoAtTable', this.displaySeatNoAtTable)
    }
  }
}
</script>

<style scoped>
</style>