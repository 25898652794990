import { Matrix4 } from 'three';
import OpMesh from '@/visual-events/model/OpMesh'
import OpShapePath from '@/visual-events/model/OpShapePath'
import OpText from '@/visual-events/model/OpText'
import OpReference from '@/visual-events/model/OpReference'
import Settings from '@/visual-events/data/Settings'
import theApp from '@/frame/Application';

import Logger from '@/frame/Logger';
const logger = new Logger('GrfUnreal')

const toFixed2 = mat4 => mat4.elements.map(n => Number(n.toFixed(2)))

export default class GrfUnreal {
  constructor (pixelStreamingWrapper) {
    logger.log('constructor');

    // for debug logs indentation
    this.indent = '';
    this.indentStack = [];

    // attach OpObject ids to the Unreal items for picking
    // We need a stack for the OpReferences.
    this.idStack = [];

    // for calculation the recursive transform in the tree
    this.currentTransform = new Matrix4();
    this.transformStack = [];

    // reuse helper objects in order to avoid massive amounts of allocations
    this.t = new Matrix4();

    this.pixelStreamingWrapper = pixelStreamingWrapper;

    // HACK: decode the used JWT token to get $user and $tenant
    const token = Settings.get('.userToken').split('.');
    const decodedToken = JSON.parse(window.atob(token[1]));
    this.user = decodedToken.sub;
    this.tenant = decodedToken['custom:tenant'];

    this.meshesToLoad = new Map();
  }

  updateScene (view) {
    // logger.log('updateScene');
    const model = view.model;

    const opRoot = view.getRoot();
    if (!opRoot)
      return false;

    if (model.needsRebuild(view)) {

      this.emitUIInteractionClearScene();

      this.currentTransform = new Matrix4();  // identity
      this.transformStack = [];

      this.buildScene(opRoot.children);
      model.resetChanged(view);
  
      return true;
    } else {
        
      const render = model.getModifiedObjects(view).length > 0;
      model.getModifiedObjects(view).forEach(op => this.updateItem(op));
      model.resetChanged(view);
      
      return render;
    }
  }

  buildScene(children) {
    
    if (this.indent === '')
      logger.log(`buildScene`);
    
    children.forEach(op => {

      this.transformStack.push(this.currentTransform);
      const t = new Matrix4();
      t.copy(this.currentTransform);
      this.currentTransform = t;
      this.currentTransform.multiply(op.transform);
      
      this.pushIndentation();
      this.logOpObject(this.indent, op);
      this.logTransform(this.indent, this.currentTransform);

      let opTree = op;
      if (op instanceof OpReference) 
      {
        opTree = theApp.model.symbols.get(op.symbolId);
        if (!opTree)
          console.log(`missing symbol in OpReference ${op.id} ${op.name} ${op.symbolId}`)
        else {
            this.idStack.push(op.id);
            this.buildScene(opTree.children);
            this.idStack.pop();
        }
      } 
      else if (op instanceof OpMesh)
      {
        if (!op.url)
          console.log(`missing mesh in OpMesh ${op.id} ${op.name}`)
        else {
          const pickId = this.idStack.length > 0 ? this.idStack[0] : op.id;
          this.emitUIInteractionInsertMesh(op.id, pickId, this.currentTransform, op.url);
        }
      }
      else if (op instanceof OpShapePath)
      {
        //not implemented
      }
      else if (op instanceof OpText) 
      {
        //not implemented
      }
      else // OpGroup
      {
        this.buildScene(op.children);
      }

      this.currentTransform = this.transformStack.pop();
      this.popIndentation();
    });
      
  }
      
  /**
   * update one modified unreal item
   * 
   * Attention: Currently, this works only for OpReferences to symbols, which are made up of 
   * untransformed meshes. No recursive 3D symbols possible.
   * @param {*} op 
   */
  updateItem(op) {  

    logger.log(`updateItem(${op.id} ${op.type} ${op.symbolId})`);

    if (op instanceof OpReference) {
      
      const pickId = op.id;

      this.emitUIInteractionSetVisibility(op.id, op.visible);
      const opTree = theApp.model.symbols.get(op.symbolId);
      if (!opTree)
        console.log(`missing symbol in OpReference ${op.id} ${op.name} ${op.symbolId}`)
      else {

        this.calcTransform(op); // updates this.t
      
        for (const op of opTree.children)
          this.emitUIInteractionSetMeshTransform(op.id, pickId, this.t);

      }
    }
  }

  /**
   * calculate the recursive transform of op in the OpObject tree
   * @param {*} op 
   * @returns 
   */
  calcTransform(op) {
    
      let parent = op.parent;
      const stack = [ op ];
      while (parent && parent.parent) {
        stack.unshift(parent);
        parent = parent.parent;
      }

      this.t.identity();
      stack.forEach (op => {
        this.t.multiply(op.transform);
      })

      return this.t;
  }

//----------------------------------------------------------------------------
// UIInteraction commands
//

  /**
   * strip the file service url from the url in order to send relative pathes to Unreal
   * @param {*} url 
   * @returns 
   */
  reduceUrl(url) {
    const urlFileService = Settings.get('.urlFileService');
    return url.substring(urlFileService.length).replace('/files', '');
  }

  /**
   * Replaces $user and $tenant placeholders in the URL
   * @param {string} url 
   * @returns 
   */
  insertUrlPlaceholders(url) {
    return url.replace('$user', this.user).replace('$tenant', this.tenant);
  }

  emitUIInteractionInsertMesh (opId, pickId, transform, url) {
    url = this.reduceUrl(url);
    url = this.insertUrlPlaceholders(url);
    this.meshesToLoad.set(url, false);
    this.pixelStreamingWrapper.emitUIInteraction({
      command: 'insertMesh',
      version: 1,
      opts: {
        path: url,
        id: opId,
        pickId: pickId,
        transform: transform.elements
      }
    });
  }

  emitUIInteractionSetMeshTransform (opId, pickId, transform) {
    this.pixelStreamingWrapper.emitUIInteraction({
      command: 'setMeshTransform',
      version: 1,
      opts: {
        id: opId,
        pickId: pickId,
        transform: transform.elements
      }
    });
  } 

  emitUIInteractionClearScene () {
    this.pixelStreamingWrapper.emitUIInteraction({
      command: 'clearScene',
      version: 1,
      opts: {}
    });
  }

  emitUIInteractionSetAspectRatio (width, height) {
    this.pixelStreamingWrapper.emitUIInteraction({
      command: 'setAspectRatio',
      version: 1,
      opts: {
        width: width,
        height: height
      }
    });
  }

  emitUIInteractionSetSunSkyTime (time) {
    this.pixelStreamingWrapper.emitUIInteraction({
        command: 'setSunSkyTime',
        version: 1,
        opts: {
          time: time
        }
      });
  }

  emitUIInteractionSetSunSkyDay (day) {
    this.pixelStreamingWrapper.emitUIInteraction({
        command: 'setSunSkyDay',
        version: 1,
        opts: {
          day: day
        }
      });
  }

  emitUIInteractionSetSunSkyMonth (month) {
    this.pixelStreamingWrapper.emitUIInteraction({
        command: 'setSunSkyMonth',
        version: 1,
        opts: {
          month: month
        }
      });
  }

  emitUIInteractionSetCamera (transform) {
    this.pixelStreamingWrapper.emitUIInteraction({
        command: 'setCamera',
        version: 1,
        opts: {
          transform: transform.elements
        }
      });
  }

  emitUIInteractionAddLight (light) {
    this.pixelStreamingWrapper.emitUIInteraction({
        command: 'addLight',
        version: 1,
        opts: light
      });
  }

  emitUIInteractionClearLights () {
    this.pixelStreamingWrapper.emitUIInteraction({
        command: 'clearLights',
        version: 1,
        opts: {}
      });
  }

  emitUIInteractionSetVisibility (opId, visible) {
    this.pixelStreamingWrapper.emitUIInteraction({
        command: 'setVisible',
        version: 1,
        opts: {
            id: opId,
            visible: visible
        }
      });
  }

  hasFinishedLoading(name) {
    // if (this.meshesToLoad.has(name))
    //   this.meshesToLoad.set(name, true);

    // let allLoaded = true;

    // this.meshesToLoad.forEach((loaded) => { allLoaded = allLoaded && loaded })

    // return allLoaded;
    this.meshesToLoad.delete(name);
    return this.meshesToLoad.size == 0;
  }

//----------------------------------------------------------------------------
// helpers for logging
//

  /**
   * increase or decrease indentation
   */
  pushIndentation() {
    this.indentStack.push(this.indent);
    this.indent += '  ';
  }

  popIndentation() {
    this.indent = this.indentStack.pop();
  }

  /**
   * log op properties with indentation
   * TODO: indented output of OpObject tree maybe useful in several contexts, -> Utility or OpObject.log, configurable ?
   * @param {*} indent 
   * @param {*} op 
   */
  logOpObject (indent, op) {

    if (op instanceof OpReference) 
    {
      logger.log(`${indent}OpReference ${op.id}  ${op.symbolId ? op.symbolId : ''}`);
      this.logTransform(indent, op.transform);
    } 
    else if (op instanceof OpMesh)
    {
      logger.log(`${indent}OpMesh ${op.id}`);
      this.logTransform(indent, op.transform);
    }
    else if (op instanceof OpShapePath)
    {
      logger.log(`${indent}OpShapePath ${op.id}`);
      this.logTransform(indent, op.transform);
    }
    else if (op instanceof OpText) 
    {
      logger.log(`${indent}OpText ${op.id}  ${op.text}`);
      this.logTransform(indent, op.transform);
    }
    else // OpGroup
    {
      logger.log(`${indent}OpGroup ${op.id} ${op.name}`);
      this.logTransform(indent, op.transform);
    }
  } 

  logTransform(indent, transform) {
    logger.log(`${indent}${toFixed2(transform)}`);
  }

}