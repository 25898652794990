
import theApp from '@/frame/Application';
import Logger from '@/frame/Logger';

const logger = new Logger('UtilLogs');

const toFixed2 = mat4 => mat4.elements.map(n => Number(n.toFixed(2)))

export default class UtilLogs {

    static logDrawing(args) {
        const indent = 0;
        const op = theApp.model.drawing[0];

        console.log('**------------------------------ Drawing ------------------------------**');
        UtilLogs.nestedOpObject(op.children, indent, args);
        console.log('**------------------------------ Drawing ------------------------------**');
    }

    static logSymbols(args) {
        const indent = 0;

        let op = theApp.model.symbols.map;
        op = [...op.values()];

        console.log('**------------------------------ Symbols ------------------------------**');
        UtilLogs.nestedOpObject(op, indent, args);
        console.log('**------------------------------ Symbols ------------------------------**');
    }

    static logSymbol(args) {
        const indent = 0;

        let op = theApp.model.symbols.map;
        op = [...op.values()];

        op = op.filter(obj => obj.symbolId === args[1]);
        console.log(op);

        console.log('**------------------------------ Symbol ------------------------------**');
        UtilLogs.nestedOpObject(op, indent, args);
        console.log('**------------------------------ Symbol ------------------------------**');
    }

    static logSpace(args) {
        const indent = 0;

        let op = theApp.model.space;

        console.log('**------------------------------ Space ------------------------------**');
        UtilLogs.nestedOpObject(op.children, indent, args);
        console.log('**------------------------------ Space ------------------------------**');
    }

    static nestedOpObject (op, indent, args) {
        for (const value of op) {
            const indentation = ' '.repeat(indent);

            let outputString = `${indentation} ${value.constructor.name}`;
            args.forEach(option => {
                  switch (option) {
                    case 'id':
                      outputString += ` ${value.id}`;
                      break;
                    case 'name':
                      outputString += ` ${value.name}`;
                      break;
                    case 'symbolId':
                      outputString += ` ${value.symbolId}`;
                      break;
                    case 'pickable':
                      outputString += ` ${value.pickable}`;
                      break;
                    case 'timestamp':
                      outputString += ` ${value.timestamp}`;
                      break;
                    case 'url':
                      outputString += ` \n${value.url}`;
                      break;
                    case 'visible':
                      outputString += ` ${value.visible}`;
                      break;
                    case 'type':
                      outputString += ` ${value.type}`;
                      break;
                    case 'transform':
                      outputString += ` ${toFixed2(value.transform)}`;
                      break;
                    case 'attributes':
                      outputString += ` ${JSON.stringify(value.attributes)}`;
                      break;
                    case 'text':
                      outputString += ` ${value.text}`;
                      break;
                    case 'xref':
                      outputString += ` ${value.xref}`;
                      break;
                    case 'style':
                      outputString += ` ${JSON.stringify(value.style)}`;
                      break;
                  }
              });
            
            console.log(outputString);

            if(value.children.length > 0)
                UtilLogs.nestedOpObject(value.children, indent + 1, args);
        }
    }
}