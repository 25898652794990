import Action from '@/frame/Action';

import OpModel from '@/visual-events/model/OpModel'

import CommandPoolVisualEvents from './CommandPoolVisualEvents';
import CommandPoolView from './CommandPoolView';
import CommandPoolTicketing from './CommandPoolTicketing';
import CommandPoolPlaner from './CommandPoolPlaner';
import CommandPoolIO from './CommandPoolIO';
import CommandPoolFloorplan from './CommandPoolFloorplan';
import GrfUtils from '@/visual-events/view/GrfUtils';

import theApp from '@/frame/Application';
import Logger from '@/frame/Logger';

import config from '@/visual-events/ticketing/Config';
import Settings from '@/visual-events/data/Settings';

const logger = new Logger('VisualEvents');

class VisualEvents {

  constructor () {

    //TODO: integrate ticketing settings into regular settings
    //get rid of Options API: if required add concept of named settings to Settings API 
    this.opts = {
      ticketing: config
    };
  }

  // interface AddIn
  init (opts) {
    logger.log(`init(${JSON.stringify(opts)})`);

    logger.log(`effective options:`);
    for (const [key, value] of Object.entries(this.opts))
      logger.log(`  ${key}: ${value}`);

    Settings.merge('globals', opts);
    Settings.merge('ticketing', opts.ticketing);

    const userToken = Settings.get('.userToken');
    if (!userToken)
      Settings.set('.userToken', Settings.get('.apiKey'));

    //TODO: booking specific settings into settings
    //dto. readOnly, timerMax...
    //better remove onHover, onClickBookableId completely; it's bad!
    //what does connfair actually use, today?
    //TODO: viewport specific zoomMin, zoomMax (and iconbar) is not booking resp. ticketing special
    if (opts.onClickBookableId)
        this.onClickBookableId = opts.onClickBookableId;
    if (opts.onHover)
        this.onHover = opts.onHover;
    if (opts.fillColor)
        this.fillColor = opts.fillColor;
    if (opts.fillOpacity)
        this.fillOpacity = opts.fillOpacity;
    // end booking settings

    GrfUtils.loadFont('OpenSans_Regular.json').then ( () => {
        if (!theApp.model) {
            const model = new OpModel();
            theApp.setModel(model);
      
            this.commandPoolVisualEvents = new CommandPoolVisualEvents();
            this.commandPoolIO = new CommandPoolIO();
            this.commandPoolView = new CommandPoolView();
            this.CommandPoolTicketing = new CommandPoolTicketing();
            this.CommandPoolPlaner = new CommandPoolPlaner();
            this.CommandPoolFloorplan = new CommandPoolFloorplan();
      
            Action.stopAll();
      
            theApp.startAnimation();
        }
    });
  }

  // interface AddIn
  exit () {
    logger.log('exit()');

    //stopAnimation(); ?

    Action.stopAll();

    // CommandPool.root.remove(this.commandPoolVisualEvents);
    // CommandPool.root.remove(this.commandPoolView);

    // remove views ?

    // remove model ?
  }
}

export default new VisualEvents();
