import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText';

export default class VariantTableRectangle extends Variant {
    constructor (widthX, widthY, color) {
        const opts = {
            widthX: widthX,
            widthY: widthY,
            color: color
        }
        super ('TABLE_RECTANGLE', 1, 'standard table rectangle', opts);
    }

    createSymbol () {
        const widthX = this.json.opts.widthX;
        const widthY = this.json.opts.widthY;
        const color = this.json.opts.color;

        const points =[
            [-0.5 * widthX, - 0.5 * widthY],
            [ 0.5 * widthX, - 0.5 * widthY],
            [ 0.5 * widthX, 0.5 * widthY],
            [-0.5 * widthX, 0.5 * widthY],
        ];

        const rectangle = OpFactory.createPolygon(points);
        rectangle.style.fill = color;
        rectangle.style.stroke = '#000000';
        const opSymbol = OpFactory.createSymbol(`${this.description}`);
        opSymbol.add(rectangle);

        const opText = OpFactory.createText('?', 200, undefined, TextAnchor.middle, BaseLine.middle);
        opText.pickable = false;
        opText.setVisibility(false);
        opSymbol.add(opText);
        opSymbol.setAttribute('$tableNo', '?');
        opText.attachToAttribute(opSymbol, '$tableNo');

        return opSymbol;
    }
}