import theApp from '@/frame/Application';
import { nextTick } from 'vue'
import * as THREE from 'three';

import Action from '@/frame/Action';
import CommandLineScanner from '@/frame/CommandLineScanner';
import { BreakEvent } from '@/frame/Event.js';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Geometry from '@/visual-events/model/Geometry';
import OpFactory from '@/visual-events/model/OpFactory';
import OpText from '@/visual-events/model/OpText'
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText'
import OpUtils from '@/visual-events/model/OpUtils';
import Settings from '@/visual-events/data/Settings';
import ShapeUtils from '@/visual-events/model/ShapeUtils';

import Logger from '@/frame/Logger';

const logger = new Logger('ActText');

const State = Object.freeze({
    DEFINE: 0,
    EDIT: 1
});

export default class ActText extends Action {
    constructor(args) {
        super();

        this.args = args;

        this.view2D = theApp.findViewByName('2D Ansicht');
        this.root2D = this.view2D.getRoot().children[0];

        this.font = Settings.get('text.fontFamily');
        this.fontSize = Settings.get('text.fontSize');
        this.text = '';
        this.placeholder = 'Texteingabe...';
        this.angle =  0;
        this.useStroke = Settings.get('text.useStroke', true);
        this.stroke = Settings.get('text.stroke', '#000000');
        this.strokeWidth = Settings.get('text.strokeWidth');
        this.useFill = Settings.get('text.useFill', true);
        this.fill = Settings.get('text.fill', '#000000');
        this.placeholderFill = '#808080'

        // current cursor position resp. anchor of the text being defined
        this.position = new THREE.Vector3(0, 0, 0);

      // working matrix
      this.t = new THREE.Matrix4();
      this.tdiff = new THREE.Matrix4();
      this.c = new THREE.Vector3();

        // current selection
        this.objects = [];
        if (args.length > 1)
            this.objects = args[1];

        this.grafic = null; // temporary grafic

        this.state = this.objects.length > 0 ? State.EDIT : State.DEFINE;
    }

    actionStart() {
        logger.log(`actionStart`);

        if (this.state === State.EDIT)
            this.addFltSelectGrafic();
        else {

            this.showTemporaryGrafic(); // create initial OpText
            this.addFilter(new FltPointDef());
        }

        this.evaluateSelection();
        this.connectToGUI();
        return true;
    }

    actionDestroy() {
        logger.log(`actionDestroy`);
        switch (this.state) {
            case State.DEFINE: {
                this.clearTemporaryGrafic();
                break;
            }
            case State.EDIT: {
                if (this.text === '') {
                    this.objects.forEach(op => { op.removeFromParent() })
                    theApp.model.changed2d = true;
                }
                break;
            }
        }

        this.resetCursor();
        this.disconnectFromGUI();
    }

    actionDynamic(event) {
        logger.log(`actionDynamic`);
        switch (this.state) {
            case State.DEFINE: {
                this.position.x = event.p[0];
                this.position.y = event.p[1];
                this.adaptTemporaryGrafic();
                break;
            }
            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }
    }

    actionPointUp(event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.DEFINE: {
                this.position.x = event.p[0];
                this.position.y = event.p[1];
                this.adaptTemporaryGrafic();

                const op = this.finishText();

                // proceed with editing
                this.objects.push(op);
                this.disconnectFromGUI();
                this.state = State.EDIT;

                this.addFltSelectGrafic();
                this.connectToGUI();

                break;
            }
            case State.EDIT:
                break;
        }
    }

    actionCommand(event) {
        const scanner = new CommandLineScanner(event.commandLine);

        const cmd = scanner.getCommand();

        switch (cmd) {
            // from SelectGrafic
            case '.select.delete':
                logger.log('.select.delete')
                this.deleteSelection();
                return new BreakEvent();
            case '.select.copy': {
                logger.log('.select.copy')
                if(this.text !== ''){
                    this.copySelection();
                    this.connectToGUI();
                }
                return null;
            }
            case '.select.applyTransform': {
                const tdiff = event.args[0];
                for (const op of this.objects)
                    op.applyTransform(tdiff);
                this.angle = this.objects.length == 1 ? Geometry.getRotationAngle(this.objects[0].transform) : this.getFilter().getRotationAngle();
                theApp.findDialogByName('PanelText')?.update(this);
                return null;
            }
            case '.select.dragBoxPoint': {
                const box = event.args[1];

                this.fontSize = Math.abs(box.max.y - box.min.y);
                const op = this.objects[0];
                op.setFontSize(this.fontSize);

                const panel = theApp.findDialogByName('PanelDrawText');
                panel?.update(this);

                theApp.model.changed2d = true; //op;
                return null;
            }
        }

        return event;
    }

    /**
     * on delete command delete the selected objects 
     * and go into State.SELECT
     */
    deleteSelection() {
        OpUtils.deleteSelection(this.objects);
        theApp.model.changed2d = true;

        // State change to State.FIRST_POINT
        this.objects = [];
        this.clearTemporaryGrafic();
        this.disconnectFromGUI();
        this.state = State.DEFINE;
        this.addFilter(new FltPointDef());
        this.connectToGUI();
    }

    copySelection() {
        const shift = Settings.get('selection.grafic.iconDist', 300);
        this.objects = OpUtils.copySelection(this.objects, this.root2D, shift);

        this.clearTemporaryGrafic();
        this.disconnectFromGUI();
        this.state = State.EDIT;
        this.addFltSelectGrafic();
    }

    actionValue(event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'fontSize') {
            this.fontSize = event.value;
            this.applyFontSize();
            done = true;
        }

        if (event.attribute === 'font') {
            this.font = event.value;
            this.applyFont();
            done = true;
        }

        if (event.attribute === 'text') {
            this.text = event.value;
            if (this.text === '')
                this.applyStyle({ fill: this.placeholderFill });
            else
                this.applyStyle({ fill: this.fill });
            this.applyText();
            done = true;
        }

        if (event.attribute === 'useStroke') {
            this.useStroke = event.value;
            this.applyStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'stroke') {
            this.stroke = event.value;
            this.applyStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'strokeWidth') {
            this.strokeWidth = event.value;
            this.applyStyle({ strokeWidth: this.strokeWidth });
            done = true;
        }

        if (event.attribute === 'useFill') {
            this.useFill = event.value;
            this.applyStyle({ fill: (this.useFill ? this.fill : 'none') });
            done = true;
        }

        if (event.attribute === 'fill') {
            this.fill = event.value;
            if (this.text === '')
                this.applyStyle({ fill: this.placeholderFill });
            else
                this.applyStyle({ fill: this.fill });
            done = true;
        }

        if (event.attribute === 'angle') {
            this.angle = event.value;
            this.applyAngle();
            done = true;
        }

        return done ? null : event;
    }

    evaluateSelection() {

        //TODO: wenn unterschiedliche Styles, dann was???
        this.objects.forEach(op => {

            const text = ShapeUtils.getText(op);
            this.font = text.fontFamily;
            this.fontSize = text.fontSize;
            this.text = text.text;
            const style = op.style;
            this.useStroke = style.stroke !== 'none';
            this.stroke = style.stroke;
            this.strokeWidth = style.strokeWidth;
            this.useFill = style.fill !== 'none';
            this.fill = style.fill;
            this.angle = text.angle;
            this.position.x = text.x;
            this.position.y = text.y;
        });

        // the selection box starts axis parallel in case of multiselection
        if (this.objects.length > 1) {
            this.angle = 0; 
        }
    }

    applyText() {
        switch (this.state) {
            case State.DEFINE: {
                this.grafic.setText(this.text);
                theApp.model.changed2d = true;
                break;
            }
            case State.EDIT: {
                this.objects.forEach(op => {
                    if (op instanceof OpText)
                        op.setText(this.text === '' ? this.placeholder : this.text);
                    op.setStyle({  fill: this.text === '' ? this.placeholderFill : this.fill, stroke: 'none' })
                });
                this.adaptSelectGrafic();
                break;
            }
        }
    }

    applyFont() {
        switch (this.state) {
            case State.DEFINE: {
                this.grafic.setFont(this.font);
                theApp.model.changed2d = true;
                break;
            }
            case State.EDIT: {
                this.objects.forEach(op => {
                    if (op instanceof OpText)
                        op.setFont(this.font);
                });
                this.adaptSelectGrafic();
                break;
            }
        }
    }

    applyFontSize() {
        switch (this.state) {
            case State.DEFINE: {
                this.grafic.setFontSize(this.fontSize);
                theApp.model.changed2d = true;
                break;
            }
            case State.EDIT: {
                this.objects.forEach(op => {
                    if (op instanceof OpText)
                        op.setFontSize(this.fontSize);
                });
                this.adaptSelectGrafic();
            }
        }
    }

    applyStyle(optsStyle) {
        switch (this.state) {
            case State.EDIT: {
                if (optsStyle) {
                    this.objects.forEach(op => op.setStyle(optsStyle));
                    theApp.model.changed2d = true;
                }
                break;
            }
            default: {
                this.adaptTemporaryGrafic(optsStyle);
                break;
            }
        }
    }

    applyAngle() {
        switch (this.state) {
            case State.DEFINE: {
                const op = this.grafic;
                const [x, y, z] = Geometry.getTranslation(op.transform);
                Geometry.makePlacingTransform(this.t, x, y, 0, this.angle);
                op.setTransform(this.t);
                theApp.model.changed2d = true;
                break;
            }
            case State.EDIT: {
                this.getFilter().getCenter(this.c);
                const diff = this.angle - this.getFilter().getRotationAngle();
                Geometry.makeRotationZ(this.tdiff, this.c, diff);
                this.objects.forEach(op => { 
                    op.applyTransform(this.tdiff);
                });
                theApp.model.changed2d = true;
                
                if (this.objects.length === 1)
                    this.getFilter().update(this.objects[0].computeBox(), this.objects[0].transform);
                else
                    this.getFilter().applyTransform(this.tdiff);
                break;
            }
        }
    }

    adaptSelectedObjects(optsStyle) {
        if (optsStyle) {
            this.objects.forEach(op => op.setStyle(optsStyle));
            theApp.model.changed2d = true;
        }
    }

    /**
     * transfer the text currently beeing generated from the temporary grafic
     * into the model.
     * @returns 
     */
    finishText() {
        const op = this.grafic;
        this.grafic = null; // clear temporary grafic
        this.root2D.add(op);
        return op;
    }

    /**
     * while editing FltSelectGrafic does most of the job
     */
    addFltSelectGrafic() {
        if (this.objects.length === 1) {
            const box = Geometry.computeBox(this.objects[0]);
            const transform = this.objects[0].transform;
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon());
        } else if (this.objects.length > 1) {
            const box = Geometry.computeBoxUnion(this.objects);
            const transform = new THREE.Matrix4(); // identity -> axis parallel
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon());
        }
    }

    showTemporaryGrafic() {
        switch (this.state) {
            case State.DEFINE: {

                this.grafic = OpFactory.createText(this.placeholder, this.fontSize, undefined, TextAnchor.start, BaseLine.baseline);
                this.grafic.setStyle({  fill: this.text === '' ? this.placeholderFill : this.fill, stroke: 'none' });
                Geometry.makePlacingTransform(this.t, this.position.x, this.position.y, 0, this.angle);
                this.grafic.setTransform(this.t);
                this.root2D.add(this.grafic);
                theApp.model.changed2d = true;

                break;
            }
            case State.EDIT:
            // doew not happen
        }
    }

    /**
     * call this, if the size of the box or the angle of a single text might be effected 
     * in order to adapt the select grafic
     */
    adaptSelectGrafic() {
        if (this.objects.length === 1)
            this.getFilter().update(this.objects[0].computeBox(), this.objects[0].transform);
        else
            this.getFilter().update(Geometry.computeBoxUnion(this.objects));
        theApp.model.changed2d = true;
    }

    clearTemporaryGrafic() {
        if (this.grafic) {
            this.root2D.remove(this.grafic);
            this.grafic = null;
            theApp.model.changed2d = true; //op;
        }
    }

    adaptTemporaryGrafic() {
        switch (this.state) {
            case State.DEFINE: {

                this.grafic.setText(this.text === '' ? this.placeholder : this.text);
                this.grafic.setFontSize(this.fontSize);
                this.grafic.setFont(this.font);
                this.grafic.setStyle({ fill: this.text === '' ? this.placeholderFill : this.fill, stroke: 'none' });
                Geometry.makePlacingTransform(this.t, this.position.x, this.position.y, 0, this.angle);
                this.grafic.setTransform(this.t);
                theApp.model.changed2d = true;
                break;
            }
            case State.EDIT:
            // does not happen
        }
    }

    resetCursor() {
        theApp.findDialogByName('main')?.setCursor(undefined);
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
    }

    connectToGUI() {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(this.state === State.EDIT ? 'Select' : 'Draw');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PanelDraw');
        nextTick(() => {
            // access updated DOM
            const panelDraw = theApp.findDialogByName('PanelDraw');
            if (panelDraw) {
                panelDraw.header = this.state === State.EDIT ? "Text bearbeiten" : "Text erzeugen"
                panelDraw.showIcons = this.state !== State.EDIT;
                panelDraw.setTab('text');
                nextTick(() => {
                    const panelDrawText = theApp.findDialogByName('PanelDrawText');
                    panelDrawText.editMode = this.state === State.EDIT;
                    panelDrawText.update(this);
                    const panelText = theApp.findDialogByName('PanelText');
                    panelText.update(this);
                })
            }
        })
    }

    disconnectFromGUI() {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}    
