<template>
    <div class="settings-panel">
        <div class="d-flex flex-column" style="height: 100%; width: 100%; overflow: hidden;">
            <div class="h-100">
                <div class="ve-panel-header">
                    Einstellungen
                </div>
                <div class="p-3">
                    <legend>Raster</legend>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="raster-enabled" v-model="rasterEnabled" @change="onChangeRasterEnabled">
                        <label class="form-check-label" for="raster-enabled">Raster aus/ein</label>
                    </div>
                    <div class="col-sm-12">
                        <div class="input-group mt-3">
                            <label for="range-col-offset" class="form-label">Rastergröße {{rasterSize / 10}} cm</label>
                            <input type="range" v-model="rasterSize" min="10" max="1000" step="10" @input="onChangeRasterSize"  class="form-range" id="range-col-offset">
                        </div>
                    </div>
                </div>
                <div class="p-3">
                    <div>
                        <InputCheckBox label="2D und 3D Grafikfenster nebeneinander"  v-if="with2Dand3DView" v-model="split2D3Dvertically"  @update:modelValue="onChangeSplit2D3DVertically" />
                    </div>
                </div>
                <div hidden>
                  <label>Modell speichern und lesen</label>
                  <br>
                  <button class="col-3" @click="onSave()">Speichern</button>
                  <button class="col-3" @click="onLoad()">Laden</button>                
                </div>
                  <br>
                <div hidden>
                  <label>SVG nur für 2D</label>
                  <br>
                  <button class="col-3" @click="onSaveSVG()">Speichern</button>
                  <button class="col-3" @click="onLoadSVG()">Laden</button>
                </div>
                  <br>
                <div hidden>
                  <label>Webexport laden</label>
                  <br>
                  <button class="col-3" @click="onLoadWebExport()">Laden</button>
                </div>
                <br>
                <div hidden>
                  <label>Kameraposition für 3D</label>
                  <br>
                  <button class="col-3" @click="onStoreInitialCameraPosition()">Merken</button>
                  <button class="col-3" @click="onRestoreInitialCameraPosition()">Setzen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputCheckBox from '@/vuejs/components/InputCheckBox.vue';

export default {
  name: 'SettingsPanel',
  components: {
    InputCheckBox,
  },
  data () {
    return {
        rasterEnabled : Settings.get('raster.enabled'),
        rasterSize : Settings.get('raster.size'),
        split2D3Dvertically: Settings.get('layout.split2D3Dvertically'),
        withSunSky: Settings.get('sun')
    }
  },
  computed: {
        with2Dand3DView: {
            get() {
                const view2D = theApp.findMountedViewByName('2D Ansicht')
                const view3D = theApp.findMountedViewByName('3D Ansicht')
                return view2D && view3D
            }
        }
    },
  methods: {
    onChangeRasterEnabled () {
        Settings.set('raster.enabled', this.rasterEnabled)
    },
    onChangeRasterSize () {
        Settings.set('raster.size', this.rasterSize)
    },
    onChangeSplit2D3DVertically () {
        Settings.set('layout.split2D3Dvertically', this.split2D3Dvertically)
        theApp.executeCommand(this.split2D3Dvertically ? '.planer.splitVertically' : '.planer.splitHorizontally')
    },
    onSaveSVG () {
        theApp.executeCommand(`.VisualEvents.saveSVG`, this.getPathModel())
    },
    onLoadSVG () {
        theApp.executeCommand(`.VisualEvents.loadSVG`, this.getPathModel())
    },
    onSave () {
        theApp.executeCommand(`.io.save`, this.getPathModel())
    },
    onLoad () {
        theApp.executeCommand(`.io.load`, this.getPathModel())
    },
    onLoadWebExport () {
        theApp.executeCommand(`.VisualEvents.load`, this.getPathModel())
    },
    getPathModel() {
        return Settings.get('.model')
    },
    onStoreInitialCameraPosition () {
        theApp.executeCommand(`.planer.storeInitialCameraPosition3D`)
    },
    onRestoreInitialCameraPosition () {
        theApp.executeCommand(`.planer.restoreInitialCameraPosition3D`)
    },
  }
}
</script>

<style scoped>
.settings-panel {
    height: 100%;
    width: 100%;
}
</style>