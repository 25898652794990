import Command from '@/frame/Command';
import CommandPool from '@/frame/CommandPool';

import theApp from '@/frame/Application'
import Action from '@/frame/Action';

import ActWaitForUserInput from '@/visual-events/actions/ActWaitForUserInput';
import ActFavorites from '@/visual-events/actions/ActFavorites';
import FltPlaceBlock from '@/visual-events/actions/FltPlaceBlock';
import ActEditAttributes from '@/visual-events/actions/ActEditAttributes';
import ActEditSelection from '@/visual-events/actions/ActEditSelection';
import ActChairCircle from '@/visual-events/actions/ActChairCircle';
import ActChairRectangle from '@/visual-events/actions/ActChairRectangle';
import ActChairBanquet from '@/visual-events/actions/ActChairBanquet';
import ActTableCircle from '@/visual-events/actions/ActTableCircle';
import ActTableRectangle from '@/visual-events/actions/ActTableRectangle';
import ActRectangle from '@/visual-events/actions/ActRectangle';
import ActCircle from '@/visual-events/actions/ActCircle';
import ActPolygon from '@/visual-events/actions/ActPolygon';
import ActText from '@/visual-events/actions/ActText';
import ActEditStyle from '@/visual-events/actions/ActEditStyle';
import ActPlaceInventory from '@/visual-events/actions/ActPlaceInventory';
import BlockUtils from './actions/BlockUtils';
import OpFactory from '@/visual-events/model/OpFactory'
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';

import Logger from '@/frame/Logger';
const logger = new Logger('CommandPoolTicketing');

export default class CommandPoolTicketing extends CommandPool {
  constructor () {
    super(CommandPool.root, 'Ticketing');

    this.addCommands([
        new Command('XXX', args => this.doXXX(args)),

        new Command('waitForUserInput', args => this.doWaitForUserInput(args)),
        new Command('editSelection', args => this.doEditSelection(args)),
        new Command('completeGroup', args => this.doCompleteGroup(args)),
        new Command('favorites', args => this.doFavorites(args)),
        new Command('placeBlock', args => this.doPlaceBlock(args)),
        new Command('editBlock', args => this.doEditBlock(args)),
        new Command('editAttributes', args => this.doEditAttributes(args)),
        new Command('openDrawPanel', args => this.doOpenDrawPanel(args)),
        new Command('drawChairCircle', args => this.doDrawChairCircle(args)),
        new Command('editChairCircle', args => this.doEditChairCircle(args)),
        new Command('drawChairRectangle', args => this.doDrawChairRectangle(args)),
        new Command('editChairRectangle', args => this.doEditChairRectangle(args)),
        new Command('drawChairBanquet', args => this.doDrawChairBanquet(args)),
        new Command('editChairBanquet', args => this.doEditChairBanquet(args)),
        new Command('drawTableCircle', args => this.doDrawTableCircle(args)),
        new Command('editDrawTableCircle', args => this.doEditDrawTableCircle(args)),
        new Command('drawTableRectangle', args => this.doDrawTableRectangle(args)),
        new Command('editDrawTableRectangle', args => this.doEditDrawTableRectangle(args)),
        new Command('drawRectangle', args => this.doDrawRectangle(args)),
        new Command('editRectangle', args => this.doEditRectangle(args)),
        new Command('drawCircle', args => this.doDrawCircle(args)),
        new Command('editCircle', args => this.doEditCircle(args)),
        new Command('drawPolygon', args => this.doDrawPolygon(args)),
        new Command('editPolygon', args => this.doEditPolygon(args)),
        new Command('drawText', args => this.doDrawText(args)),
        new Command('editText', args => this.doEditText(args)),
        new Command('editStyle', args => this.doEditStyle(args)),
        new Command('settings', args => this.doSettings(args)),
        new Command('inventory', args => this.doInventory(args)),
        new Command('placeInventory', args => this.doPlaceInventory(args)),
        new Command('lights', args => this.doLights(args)),
        new Command('sunsky', args => this.doSunSky(args)),
      ]);
  }

  doXXX(args) {
    logger.log('doXXX');
  }

  doFavorites(args) {
    logger.log('doFavorites');
    if (!Action.findByClass(ActFavorites))
        Action.start(new ActFavorites());
  }

  doWaitForUserInput(args) {
    logger.log('doWaitForUserInput');
    if (!Action.findByClass(ActWaitForUserInput)) {
      theApp.model.selectionList.clear();
      Action.start(new ActWaitForUserInput());
    } else {
      const sideNav = theApp.findDialogByName('SideNav');
      sideNav.setActiveButton('Select');
      const sidePane = theApp.findDialogByName('SidePane');
      sidePane.setCurrentPanel(undefined);
    }
  }

  doEditSelection(args) {
    logger.log('doEditSelection');
    Action.start(new ActEditSelection(args));
  }

  doCompleteGroup(args) {
    logger.log('doCompleteGroup');

    const ops2D = args[1];
    this.view2D = theApp.findViewByName('2D Ansicht');
    this.root2D = this.view2D.getRoot().children[0];

    this.view3D = theApp.findViewByName('3D Ansicht');
    this.root3D = this.view3D?.getRoot(); 

    const opReference2D = BlockUtils.createGeometryGroup(ops2D);
    this.root2D.add(opReference2D);
    theApp.model.changed2d = true;

    const ops3D = Event3DPlacing.collectLinkedIn3D(ops2D);
    if(ops3D.length > 0){
        const opReference3D = BlockUtils.createGeometryGroup(ops3D);
        this.root3D.add(opReference3D);
        Event3DPlacing.link2DTo3D(opReference2D, opReference3D, '');
        theApp.model.changed3d = true;
  }

    Action.start(new FltPlaceBlock(['.Ticketing.editBlock', [opReference2D]]));
  }

  doPlaceBlock(args) {
    logger.log('doPlaceBlock');
    if (!Action.findByClass(FltPlaceBlock)) //?
        Action.start(new FltPlaceBlock(args));
  }

  doEditBlock(args) {
    logger.log('doEditBlock');
    Action.start(new FltPlaceBlock(args));
  }

  doEditAttributes(args) {
    logger.log('doEditAttributes');
    if (!Action.findByClass(ActEditAttributes)) //?
        Action.start(new ActEditAttributes());
  }

  doDrawRectangle(args) {
    logger.log('doDrawRectangle');
    Action.start(new ActRectangle(args));
  }

  doEditRectangle(args) {
    logger.log('doEditRectangle');
    Action.start(new ActRectangle(args));
  }

  doDrawCircle(args) {
    logger.log('doDrawCircle');
    Action.start(new ActCircle(args));
  }

  doEditCircle(args) {
    logger.log('doEditCircle');
    Action.start(new ActCircle(args));
  }

  doDrawPolygon(args) {
    logger.log('doDrawPolygon');
    Action.start(new ActPolygon(args));
  }

  doEditPolygon(args) {
    logger.log('doEditPolygon');
    Action.start(new ActPolygon(args));
  }

  doDrawText(args) {
    logger.log('doDrawText');
    Action.start(new ActText(args));
  }

  doEditText(args) {
    logger.log('doEditText');
    Action.start(new ActText(args));
  }

  doEditStyle(args) {
    logger.log('doEditStyle');
    Action.start(new ActEditStyle(args));
  }

  doOpenDrawPanel(args) {
    logger.log('doOpenDrawPanel');
    if (!Action.findByClass(ActWaitForUserInput))
        Action.start(new ActWaitForUserInput());
    const sideNav = theApp.findDialogByName('SideNav');
    sideNav.setActiveButton('Draw');
    const sidePane = theApp.findDialogByName('SidePane');
    sidePane.setCurrentPanel('PanelDraw');
  }

  doDrawChairCircle(args) {
    logger.log('doDrawChairCircle');
    Action.start(new ActChairCircle(args));
  }

  doEditChairCircle(args) {
    logger.log('doEditChairCircle');
    Action.start(new ActChairCircle(args));
  }

  doDrawChairRectangle(args) {
    logger.log('doDrawChairRectangle');
    Action.start(new ActChairRectangle(args));
  }

  doEditChairRectangle(args) {
    logger.log('doEditChairRectangle');
    Action.start(new ActChairRectangle(args));
  }

  doDrawChairBanquet(args) {
    logger.log('doDrawChairBanquet');
    Action.start(new ActChairBanquet(args));
  }

  doEditChairBanquet(args) {
    logger.log('doEditChairBanquet');
    Action.start(new ActChairBanquet(args));
  }

  doDrawTableCircle(args) {
    logger.log('doDrawTableCircle');
    Action.start(new ActTableCircle(args));
  }

  doEditDrawTableCircle(args) {
    logger.log('doEditDrawTableCircle');
    Action.start(new ActTableCircle(args));
  }

  doDrawTableRectangle(args) {
    logger.log('doDrawTableRectangle');
    Action.start(new ActTableRectangle(args));
  }

  doEditDrawTableRectangle(args) {
    logger.log('doEditDrawTableRectangle');
    Action.start(new ActTableRectangle(args));
  }
  
  doSettings(args) {
    logger.log('doOpenSettings');
    if (!Action.findByClass(ActWaitForUserInput))
        Action.start(new ActWaitForUserInput());
    const sideNav = theApp.findDialogByName('SideNav');
    sideNav.setActiveButton('Settings');
    const sidePane = theApp.findDialogByName('SidePane');
    sidePane.setCurrentPanel('SettingsPanel');
  }

  doInventory(args) {
    logger.log('doInventory');
    if (!Action.findByClass(ActWaitForUserInput))
        Action.start(new ActWaitForUserInput());
    const sideNav = theApp.findDialogByName('SideNav');
    sideNav.setActiveButton('Inventory');
    const sidePane = theApp.findDialogByName('SidePane');
    sidePane.setCurrentPanel('DlgInventory');
  }

  doPlaceInventory(args) {
    logger.log('doPlaceInventory');
    Action.start(new ActPlaceInventory(args));
  }

  doLights(args) {
    logger.log('doLights');
    if (!Action.findByClass(ActWaitForUserInput))
        Action.start(new ActWaitForUserInput());
    const sideNav = theApp.findDialogByName('SideNav');
    sideNav.setActiveButton('Lights');
    const sidePane = theApp.findDialogByName('SidePane');
    sidePane.setCurrentPanel('PanelLights');
  }

  doSunSky(args) {
    logger.log('doSunSky');
    if (!Action.findByClass(ActWaitForUserInput))
        Action.start(new ActWaitForUserInput());
    const sideNav = theApp.findDialogByName('SideNav');
    sideNav.setActiveButton('SunSky');
    const sidePane = theApp.findDialogByName('SidePane');
    sidePane.setCurrentPanel('SunSky');
  }
}
