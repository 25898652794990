import JsonPath from '@/frame/JsonPath';
import Geometry from '@/visual-events/model/Geometry'
import Subject from '@/frame/Subject';
import { Matrix4 } from 'three';
import Logger from '@/frame/Logger';

/**
 * Base class for all kinds of model items.
 * 
 * In the current session each item has an id by which it is uniquely identifiable in the model. 
 * It is not unique in the sense of a uuid.
 * 
 * Each OpObject has a name, but it has no specific meaning. It is only intended for either tagging objects internally
 * while debugging or displaying a name choosen by the user, e.g. a group name. Avoid implementations based on specific 
 * names.
 * 
 * Each OpObject has a 3D transform, i.e. a three.js Matrix4 in order to define its position and orientation
 * in the world. 
 * 
 * Give the model hierarchical structure by using OpGroup. Its transform applies on all children. But use this sparingly, 
 * because it is ambigeous, on which level of the tree hierarchy the transform should be applied. 
 * 
 * Currently the transform in a group is used to define the scale of a drawing layer. A 2D sheet's dimension is 
 * usually defined in paper size as needed for printing, e.g. DIN A4 210 mm x 297 mm. All decorating lines and texts 
 * on the paper are expressed in mm without further scaling. But the plan is sketched in a certain scale, e.g. 1:100. 
 * This is expressed by setting the root group's transform by scale 0.01 in x and y.
 * 
 * Visibility and pickability is working hierarchically, i.e. setting a subtree's root invisible will affect all
 * items in the subtree.
 */
export default class OpObject extends Subject {
    //TODO: Modify für Texte muss vielleicht anders gemacht sein: SVGTextInformation enthält x,y
    //TODO: possibly subclass OpDrawing for XOpDraft with width, height, viewbox

    static #logger = new Logger('OpObject');

    /**
     * 2D/3D object model.
     * @param {string} type 
     * @param {string} name 
     */
    constructor(type, name, url, timestamp) {

        super();

        this.id = ++OpObject.nextId;
        this.type = type;
        this.name = name;
        this.transform = new Matrix4();
        this.attributes = {};
        this.children = [];
        this.parent = null;

        this.visible = true;
        this.pickable = true;

        this.box = null;

        // most recent load or store url with timestamp
        this.url = url;
        this.timestamp = timestamp;
    }

    copy () {

        const copy = new OpObject(this.type, this.name);

        copy.visible = this.visible;
        copy.pickable = this.pickable;
        copy.setTransform(this.transform);

        return copy;
    }

//----------------------------------------------------------------------------
// transform
//
    setTransform (transform) {
        const previous = this.transform.clone();
        this.transform = transform.clone();
        this.invalidateBox();
        this.notify( { name: 'setTransform', data: { sender: this, previous: previous }});
    }

    applyTransform (transform) {
        const previous = this.transform.clone();
        this.transform.premultiply(transform);
        this.invalidateBox();
        this.notify( { name: 'setTransform', data: { sender: this, previous: previous }});
    }

//----------------------------------------------------------------------------
// op tree structure
//

    add (child) {
        child.removeFromParent();
        this.invalidateBox();
        child.parent = this;
        this.children.push(child);
        this.notify( { name: 'add', data:  { parent: this, child: child }});
        child.notify( { name: 'addTo', data:  { parent: this, child: child }});
    }

    remove (child) {
        if (child.parent === this) {
            child.parent.invalidateBox();
            child.parent = null;
            this.children = this.children.filter(op => op !== child);
            this.notify( { name: 'remove', data:  { parent: this, child: child }});
            child.notify( { name: 'removeFrom', data:  { parent: this, child: child }});
        }
    }

    removeFromParent () {
        if (this.parent)
            this.parent.remove(this);
    }

    /**
     * find the OpObject below this with 'id'
     * TODO:ScanTree artige Schnittstelle
     * @param {*} id 
     * @returns 
     */
    findOpObjectById(id) {
        let result = null;

        let iterateModel = (children, id) => {
            children.some((child) => {
                if (child.id === id) {
                    result = child;
                    return true;
                }
                iterateModel(child.children, id);
            });
        }

        const children = this.children;
        iterateModel(children, id);

        return result;
    }

    /**
     * Executes the callback on this object and all descendants.
     * @param {(object: OpObject) => any} callback 
     */
    traverse (callback) {
        callback(this);
        for (const child of this.children)
            child.traverse(callback);
    }

    /***
     * check, whether this is in the subtree below root including root
     */
    inSubtree (root) {
        let op = this;
        while (op && op !== root)
            op = op.parent;
        return op === root;
    }

    /**
     * calculate the box around the geometry resp. all geometries in an op tree
     * 
     * ATTENTION: The box is lazy evaluated and cached because it is an expensive calculation
     * It is important, that any modification of the OpObject, which might cause a change of the box
     * calls invalidateBox in this modification.
     * 
     * @returns THREE.Box3
     */
    computeBox() {
        if (!this.box)
            this.box = Geometry.computeBox(this);

        return this.box.clone();
    }

    invalidateBox() {
        if (this.parent)
            this.parent.invalidateBox();
        this.box = null;
    }

//----------------------------------------------------------------------------
// user attributes
//
    getAttribute(key) {
        
        try {
            return JsonPath.getValue(this.attributes, key);
        } catch(e) {
            return undefined;
        }   
    }

    setAttribute(key, object) {
        try {
            JsonPath.setValue(this.attributes, key, object);            
            this.notify( { name: 'setAttribute', data:  { sender: this, key: key }});
        } catch(e) { }
    }

    removeAttribute(key) {
        try {
            JsonPath.removeValue(this.attributes, key);            
            this.notify( { name: 'setAttribute', data:  { sender: this, key: key }});
        } catch(e) { }
    }

    logAttributes(root = '')
    {
        for (let key in this.attributes)
        {
            let value = this.attributes[key];
            OpObject.#logger.log(`OpObject[${this.id}].attributes[${key}] = ${value}`)
        }
    }

//----------------------------------------------------------------------------
//  visible, pickable, 

    /**
     * an op element is visible, if itself and all parents are visible
     */
    isVisible () {
        let op = this;
        do {
            if (!op.visible)
                return false;
            op = op.parent;
        } while (op);
        return true;
    }

    setVisibility(visible){
        this.visible = visible;
        this.notify( { name: 'setVisibility', data:  {sender: this, visibility: visible}});
    }

    /**
     * an op element can be picked, if itself and all parents are pickable
     */
    isPickable () {
        let op = this;
        do {
            if (!op.pickable)
                return false;
            op = op.parent;
        } while (op);
        return true;
    }

//----------------------------------------------------------------------------
// notifications
//

    onNotify(notification) {
        OpObject.#logger.log(`onNotify(${notification.name})`);
    }
}

// unique id generator
OpObject.nextId = 0;