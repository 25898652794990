<template>
    <div class="symbol-library-panel border">
        <div style="padding-left: 25px; padding-top: 10px; padding-bottom: 10px; font-size: 20px; background-color: lightblue;">
            Symbole
        </div>
        <div class="category-container">
            <div 
                v-for="(value, key) in tabs"
                :key="key"
                :class="['symbol-category', { active: currentTab === key }]"
                @click=onSelectTab(key)
                :name="value.caption"
                :data-library="value.library"
            >
                <div class="symbol-category-icon">
                </div>
                <span class="caption">{{value.caption}}</span>
            </div>
       </div>
        <WebGLRenderCanvas v-bind:viewName="'Favorites'">Symbole</WebGLRenderCanvas>
    </div>
</template>

<script>
import WebGLRenderCanvas from '@/vuejs/components/WebGLRenderCanvas.vue';
import theApp from '@/frame/Application';
import FileService from '@/visual-events/RestAPI/FileService';
import Logger from '@/frame/Logger';
import Settings from '@/visual-events/data/Settings';

const logger = new Logger('SymbolLibraryPanel');

export default {
    name: 'SymbolLibraryPanel',
    data () {
        return {
            parentPath: Settings.get('.pathFavorites'),
            library: Settings.get('.favorites'),
            currentTab: undefined,
            tabs: {}
        }
    },
    components: {
        WebGLRenderCanvas
    },
    methods: {
        async getTabs () {
            const fileService = new FileService();
            fileService.subFolders(this.parentPath).then(json => {
                logger.log(`${json}`)
                json.forEach(folder => {
                    const name = folder.name;
                    this.tabs[name] = { caption: name, library: `${name}`}
                })
            }
            ).catch(
                () => console.log(`FileService.subFolders(${this.parentPath}) failed`)
            )
        },
        load() {
            const urlFavorites = `${Settings.get('.urlFileService')}/files/${this.parentPath}/${this.library}`
            theApp.sendCommand(`.VisualEvents.loadFavorites "${urlFavorites}"`)
        },
        onSelectTab(key) {
            this.currentTab = key;
            this.library = this.tabs[key].library;
            this.load();            
        }
    },
    mounted () {
        this.getTabs();
    },
    updated () {
        this.load();
    }
}
</script>

<style scoped>
.category-container {
    display: flex;
    flex-direction: row;
    width: 100%; 
    border-bottom: 1px solid black;
}
.symbol-library-panel {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.symbol-category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 75px;
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
}
.symbol-category:hover {
    cursor: pointer;
    background-color: lightblue;
    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.1);
}
.symbol-category.active {
    background-color: lightblue;
    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.1);
}
.symbol-category-icon {
    width: 32px; 
    height: 32px; 
    border: 1px solid black; 
    border-radius: 5px;
}
.caption {
    display: block;
}
</style>