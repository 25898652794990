import theApp from '@/frame/Application';
import Action from '@/frame/Action';
import * as Event from '@/frame/Event';

import FltHover from '@/visual-events/actions/FltHover';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import Geometry from '@/visual-events/model/Geometry';

import Pick from '@/visual-events/view/Pick';
import SelectionFrame from '@/visual-events/view/SelectionFrame';
import { SelectionBox } from '@/visual-events/view/SelectionBox';
import Logger from '@/frame/Logger';
import { isEmpty } from '@/frame/Useful'

import VisualEvents2DView from '@/visual-events/view/VisualEvents2DView';

const State = Object.freeze({
    PICK:           1,  // wait for picking, no dragging
    DRAG_RECTANGLE: 2,  // wait for dragging
    DRAGGING:       3   // while dragging
});

const MouseButtonFlags = Object.freeze({
    LEFT_BUTTON:    0x0001,
    RIGHT_BUTTON:   0x0002,
    MIDDLE_BUTTON:  0x0004,
});

const logger = new Logger('FltPick');

//
// MSL stands for Model Selection List
// deprecate
//
export const PickFlags = Object.freeze({
    IGNORE_MSL:             0x0001, // Do not modify the model selection list; IMPLIES NO_HIGHLIGHT.
    NO_CLEAR_MSL:           0x0002, // The model selection list is not cleared at the beginning of the action.
    NO_HIGHLIGHT:           0x0004, // The model selection list does not highlight its content.
    NO_DRAG:                0x0008, // Do not allow dragging operation drag, that is only picking.
    NO_COLLECT:             0x0010, // No collection of objects, e.g. MSL is emptied on entering states DRAG_RECTANGLE or PICK.
    DYNAMIC_SELECT:         0x0020, // If set, the model selection list is updated dynamically.
    POINT_EVENTS:           0x0040, // propagate PointEvents to parent action
});

// formation types, i.e. information how it comes to the SelectionEvent
export class PickPointSelection {
    constructor(view, point) {
        logger.log(`PickPointSelection(${view.name}, ${point})`);
        this.view = view;
        this.point = point;
    }
} 

export class RectangleSelection {
    constructor(view, box) {
        logger.log(`RectangleSelection(${view.name}, ${box.min.x} ${box.max.x} ${box.min.y} ${box.max.y})`)
        this.view = view;
        this.box = box;
    }
}

export class GlobalSelection {
    constructor() {}
}

// TODO: needs heavy refactoring
// - responsibilty of private methods are unclear and is not obvious at all by the names
// - role of the global selection list dubious, see _updateSelectionList
// - possibly better: this.selected = new Set to keep objects unique, toArray only in EventSelection

// TODO: CADdyTypes should not be used, filter possibly against OpObject types

// TODO: Drag Rectangle implementation is not clean (s. HACK)
// currently only in 2D dragging a rectangle should be called, but sometimes in 3D select frames appear, not clear why it happens -> some state confusion?
// only view 2D can enable/disableCameraControls(), so if handleCameraControlsEnableState is called in 3D, something is wrong
// dto. in 3D should never appear a selectionFrame
// But in future selection will also be interesting in 3D. Then we need another concept. Selection by rectangle contradicts to camera
// left mouse in 3D as well as in 2D.
// Probably instead of general behavior flags we need a configuration interface
// - on which views the filter is to apply
// - the behaviour in each view 
// - which might defer in the different views: 2D, 3D, favorites
  
export default class FltPick extends Action {

    constructor(pick_flags = 0, pick_types = []) {
        super();

        this.view   = null;  // see function _initializeSelection. Only used for dragging by rectangle mode
        this.flags  = pick_flags;
        this.types  = new Set(pick_types);
        this.state  = State.PICK;
        this.selected = [];
        this.views = {};
        this.eventPoint = null;
        this.eventPointUp = null;
    }

    /**
     * allow picking for a specific view. Apply behavior options
     * TODO: Pickfilter options
     * - filter callback (predefined for types list)
     * - global selection
     * - single or multiple
     * - collecting 
     * - rectangle mode
     * @param {*} viewName 
     * @param {*} opts 
     */
    forView (viewName, opts = {}) {
        this.views[viewName] = opts;
        return this;
    }

    /**
     * check, whether the filter applies on the specific view
     * 
     * If the filter is not configured with forView, by default all views apply.
     * @param {*} name 
     * @returns options object, if the view is in the list
     */
    appliesOnView (viewName) {
        if (isEmpty(this.views))
            return {};

        return this.views[viewName];
    }

/**
 * deprecate: CADdy++ types shall not be used 
 * @param {*} types 
 */    
    setTypes (types) {
        this.types = new Set(types);
        this.types
    }

    addType (type) {
        this.types.add(type);
    }

    setFlags (flags) {
        this.flags = flags;
        this.adaptStateForFlags(); 
    }

    setFlag (flag) {
        this.flags = this.flags | flag;
        this.adaptStateForFlags(); 
    }

    unsetFlag (flag) {
        this.flags = this.flags & ~flag;
        this.adaptStateForFlags(); 
    }

    hasFlag(flag) {
        return ((this.flags & flag) === flag);
    }

    selection () {
        return this.selected;
    }

    adaptStateForFlags() {
        if (this.hasFlag(PickFlags.NO_DRAG))
            this.state = State.PICK;
        else
            this.state = State.DRAG_RECTANGLE;
    }

    actionStart ()
    {
        logger.log(`actionStart   ${this.state}`);

        this.point    = [0,0,0];
        this.selected = [];

        const hoverFilter = new FltHover();
        this.addFilter(hoverFilter);

        this.adaptStateForFlags();

        if (this.hasFlag(PickFlags.IGNORE_MSL) === false)
        {
            if (this.hasFlag(PickFlags.NO_HIGHLIGHT))
                theApp.model.selectionList.setHighlight(false); 
            else
                theApp.model.selectionList.setHighlight(true); 

            if (this.hasFlag(PickFlags.NO_CLEAR_MSL) === false) 
                theApp.model.selectionList.clear();
        }

        return true;
    }

    actionDestroy ()
    {
        logger.log(`actionDestroy ${this.state}`);
        this.selectionFrame?.reset();
        if (this.state === State.DRAGGING)
            this.state = State.PICK;
        this.handleCameraControlsEnableState();            
    }

    actionPoint (event)
    {
        logger.log(`actionPoint   ${event.raw.type} ${this.state}`);

        if (!this.appliesOnView(event.view.name))
            return null;

        let result = null;

        if (this.state==State.DRAG_RECTANGLE || this.State==State.DRAGGING)
        {
            // HACK: check if 3D => Dragging not allowed
            if (!(event.view instanceof VisualEvents2DView)) {
                this.setFlag(PickFlags.NO_DRAG);
                this.selectionFrame?.reset();
                this.state = State.PICK;
            }
        }

        switch (this.state) {
            case State.DRAG_RECTANGLE:
                this._initializeEntryStates(event);
                this._startSelection(event);
                this.state = State.DRAGGING;
                break;
            case State.DRAGGING:
                // If we get into this branch, a failure has happened. E.g. the mouse  up operation was
                // in another window context and the user reselected (i.e. pressed the left mouse button) in
                // the original view. To get back to correct operation we just restart the selection process.
                this._initializeEntryStates(event);
                this._startSelection(event);
                this.state = State.DRAGGING;
                break;
            case State.PICK:
            {
                this._initializeEntryStates(event);
                let result = this._startPick(event);
                this._evaluateResult(result);
                this.state = State.PICK;

                return new Event.SelectionEvent(this.selected, new PickPointSelection(event.view, FltPointDef.scalePoint(event.view, event.p)));
            }
        }

        this.handleCameraControlsEnableState();

        // propagate event, if the parent action wants so
        if (!result && this.hasFlag(PickFlags.POINT_EVENTS)) {
            FltPointDef.adaptToScale(event);
            return event;
        }

        return result;
    }

    actionPointUp (event)
    {
        logger.log(`actionPointUp ${event.raw.type} ${this.state}`);

        if (!this.appliesOnView(event.view.name))
            return null;

        let result = null;

        switch (this.state) {
            case State.DRAG_RECTANGLE:
                break;
            case State.DRAGGING:
            {
                let formation = null;

                //
                // Three.js does not support simultaneous selection over multiple views.
                // We therefore do not evaluate the event content but we return the
                // selection that has been (dynamically) found and return it to the
                // parent action. We also switch back to the entry DRAG_RECTANGLE state in order
                // to not break the Drag/Drop mouse handling.
                //
                if (this.view === event.view)
                {
                    if (this._comparePoint(event.p))
                    {
                        let result = this._startPick(event);
                        this._evaluateResult(result);
                        formation = new PickPointSelection(event.view, FltPointDef.scalePoint(event.view, event.p));
                    }
                    else
                    {
                        let result = this._collectObjects(event);
                        this._evaluateResult(result);
                        const box = Geometry.makeBox([FltPointDef.scalePoint(event.view, this.point), FltPointDef.scalePoint(event.view, event.p)]);
                        formation = new RectangleSelection(this.view, box);
                    }
                }

                this.selectionFrame.onMouseUp();
                this.adaptStateForFlags();
                
                result = new Event.SelectionEvent(this.selected, formation);
                break;
            }
                
            case State.PICK:
                break;
        }

        this.handleCameraControlsEnableState();

        // propagate event, if the parent action wants so
        if ((!result || result.objects.length === 0) && this.hasFlag(PickFlags.POINT_EVENTS)) {
            FltPointDef.adaptToScale(event);
            return event;
        }

        return result;
    }

    actionDynamic (event)
    {
        logger.log(`actionDynamic ${event.raw.type} ${this.state} ${event.raw.buttons}`);

        if (!this.appliesOnView(event.view.name))
            return null;

        let result = null;

        switch (this.state) {
            case State.DRAG_RECTANGLE:
                break;
            case State.DRAGGING:

                if (this.view === event.view)
                {
                    this.selectionFrame?.onMouseMove(event.raw);

                    if (event.raw.buttons & MouseButtonFlags.LEFT_BUTTON)
                    {
                        let result = this._collectObjects(event);

                        if (this.hasFlag(PickFlags.DYNAMIC_SELECT))
                            this._evaluateResult(result);
                    }
                    else
                    {
                        this.selectionFrame?.onMouseUp(event.raw);
                        this.state = State.DRAG_RECTANGLE;
                        this.adaptStateForFlags();
                    }
                }
                break;
            case State.PICK:
                result = event;
                break;
        }

        this.handleCameraControlsEnableState();

        return result;
    }

    /**
     * during dragging the selection frame disable the CameraControls
     */
    handleCameraControlsEnableState() {

        //HACK: avoid console error messages, currently only VisualEvents2DView have disableCameraControls
        if (!(this.view instanceof VisualEvents2DView))
            return;

        switch (this.state) {
            case State.DRAGGING: {
                this.view?.disableCameraControls();
                break;
            }
            default: {
                this.view?.enableCameraControls();
                break;
            }
        }
    }

    _comparePoint(p)
    {
        return (this.point[0] === p[0] 
             && this.point[1] === p[1] 
             && this.point[2] === p[2]);
    }

    _initializeEntryStates(event)
    {
        this.point = event.p;

        if (this.hasFlag(PickFlags.NO_COLLECT) || !event.raw.ctrlKey)
        {
            if (this.hasFlag(PickFlags.IGNORE_MSL) === false)
                theApp.model.selectionList.clear();

            this.selected = [];
        }
    }

    _initializeSelection(event)
    {
        this.view = event.view;

        let scene    = this.view.scene;
        let camera   = this.view.camera;
        let rootElement = this.view.canvas.parentElement;

        if (this.hasFlag(PickFlags.NO_DRAG) === false)
        {
            this.selectionFrame?.reset();

            this.selectionBox   = new SelectionBox   ( camera, scene );
            this.selectionFrame = new SelectionFrame ( rootElement, 've-selection-frame');
        }
    }

    _startPick(event)
    {
        return Pick.pick(event.view, event.raw, Array.from(this.types));
    }

    _startSelection(event)
    {
        this._initializeSelection(event);

        const raw = event.raw;

        const coord = this.view.getRelative(raw);

        //TODO: ? sollte eigentlich nicht nötig sein, gab aber Absturz -> durchdenken
        this.selectionBox?.startPoint.set(
            coord.x,
            coord.y,
            0.5 );
        
        //TODO: ? sollte eigentlich nicht nötig sein, gab aber Absturz -> durchdenken
        this.selectionFrame?.onMouseDown(raw);
    }

    _collectObjects(event)
    {
        const raw = event.raw;

        const coord = this.view.getRelative(raw);

        this.selectionBox.endPoint.set(
            coord.x,
            coord.y,
            0.5 );

        const meshes = this.selectionBox.select();

        const hits = [];

        const opRoot = this.view.getRoot();
        if (!opRoot)
            return hits;

        meshes.forEach (mesh => {
            const op = Pick.find(opRoot, mesh);

            if (op && 
                op.isPickable() && 
                (this.types.size === 0 || this.types.has(op.type)))
                    hits.push(op);
        })

        return hits;
    }

    _evaluateResult(result)
    {
        if (result.length === 0)
            return;

        let current  = new Set(this.selected);
        let selected = [];

        for ( let i = 0; i < result.length; i++ )
        {
            let op = result[i];

            if (!current.has(op))
            {
                this.selected.push(op);
                selected.push(op);
                current.add(op);
            }
         }

        // TODO: why not _updateSelectionList(this.selected)?
        this._updateSelectionList(selected);

        this._logSelection();
    }

    _updateSelectionList(selected)
    {
        if (this.hasFlag(PickFlags.IGNORE_MSL) === false)
        {
            selected.forEach(op => {
                theApp.model.selectionList.add(op);
            })
        }
    }

    _logSelection()
    {
        logger.log(`_logSelection: N_selected = ${this.selected.length}`);
        this.selected.forEach(op => {
            logger.log(`op = { ${op.id}, ${op.type}, ${op.name}, ${op.symbolId} }`);
        })

        theApp.model.selectionList.log();
    }
}    
