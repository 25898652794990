import JsonPath from '@/frame/JsonPath';
import OpObject from '@/visual-events/model/OpObject'
import OpSymbol from '@/visual-events/model/OpSymbol'
import Logger from '@/frame/Logger';

export const TextAnchor = Object.freeze({
    start: 'start',
    middle: 'middle',
    end: 'end'
});

export const BaseLine = Object.freeze({
    baseline: 'baseline',
    middle: 'middle',
    hanging: 'hanging'
});

export default class OpText extends OpObject {

    static #logger = new Logger('OpText');

    constructor (text, fontSize, fontFamily, textAnchor = TextAnchor.start, baseLine = BaseLine.baseline, style = undefined) {

        super ('XOpText2', 'text');

        this.text = text;
        this.fontSize = fontSize;
        this.fontFamily = fontFamily; 
        this.textAnchor = textAnchor;
        this.baseLine = baseLine; 
        this.style = style ? style: { fill: '#000000', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'none', strokeLineJoin: 'none', strokeMiterLimit: 0, strokeOpacity: 0, strokeWidth: 0}
        this.xref = undefined;
    }

    copy () {

        const copy = new OpText(this.text, this.fontSize, this.fontFamily, this.textAnchor, this.baseLine);

        copy.visible = this.visible;
        copy.pickable = this.pickable;
        copy.setTransform(this.transform.clone());

        copy.xref = this.xref;
        
        if (this['path'])
            copy['path'] = this['path'].clone();
        if (this['style'])
            copy['style'] = Object.assign( {}, this['style'] ); // clone style

        return copy;
    }

    setText(text) {
        this.text = text;
        this.invalidateBox();
    }
    setFontSize(size) {
        this.fontSize = size;
        this.invalidateBox();
    }
    setFont(font) {
        this.font = font;
        this.invalidateBox();
    }

    setStyle(style) {
        JsonPath.mergeValues(this.style, '', style);
        this.invalidateBox();
    }

    /**
     * An attribute text always displays the value of an attribute at another OpObject, e.g.
     * the seat number of a chair symbol, or the row number
     * 
     * Connect the text to the attribute at OpObject op.
     * @param {*} op 
     * @param {*} jsonPath 
     */
    attachToAttribute(op, jsonPath) {
        this.xref = `${op.id}/${jsonPath}`;
        op.subscribe(this);
        //initially, adapt the displayed text
        op.notify( { name: 'setAttribute', data:  { sender: op, key: jsonPath }});
    }

    /**
     * internal: check, whether op is an OpText, which describes an attribute text
     * at a symbol definition. This must not be displayed in the grafic, s. GrfOpSpace
     * @param {*} op 
     * @returns 
     */
    isAttributeTextAtSymbol() {
        const parent = this.parent;
        if (parent instanceof OpSymbol) {
            if (parent.isObservedBy(this))
                return true;
        }
        return false;
    }

    /**
     * Disconnect the text
     * @param {*} op 
     */
    detachFromAttribute(op) {
        this.xref = undefined;
        op.unsubscribe(op);
    }

    onNotify(notification) {
        OpText.#logger.log(`onNotify(${notification.name})`);
        switch(notification.name) {
            case  'setAttribute': {

                const sender = notification.data.sender;
                const key = notification.data.key;
                if (this.xref) {
                    const p= this.xref.indexOf('/');
                    if (p) {
                        const att = this.xref.substring(p+1);
                        const tokens = JsonPath.split(att);
                        if (tokens.length > 0 && tokens[0] === key)
                            this.text = sender.getAttribute(att);
                    }
                }
            }
            break;

            case 'setTransform': {
                // the relative position of this with respect to the owner is to be maintained
                const sender = notification.data.sender;

                // relative transform text to sender
                const m = notification.data.previous.clone();
                m.invert();
                m.multiply(this.transform);
                
                // calculate new transform
                m.premultiply(sender.transform);
                this.transform = m; //TODO: notification? vermeide zirkuläre Aufrufe
            }
            break;
        } 
    }    
}