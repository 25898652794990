import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText';

export default class VariantTableCircle extends Variant {
    constructor (diameter, color) {
        const opts = {
            diameter: diameter,
            color: color
        }
        super ('TABLE_CIRCLE', 1, 'standard table circle', opts);
    }

    createSymbol () {
        const diameter = this.json.opts.diameter;
        const color = this.json.opts.color;
        const circle = OpFactory.createCircle(0.0, 0.0, diameter / 2.0);
        circle.style.fill = color;
        const opSymbol = OpFactory.createSymbol(`${this.description}`);
        opSymbol.add(circle);

        const opText = OpFactory.createText('?', 200, undefined, TextAnchor.middle, BaseLine.middle);
        opText.pickable = false;
        opText.setVisibility(false);
        opSymbol.add(opText);
        opSymbol.setAttribute('$tableNo', '?');
        opText.attachToAttribute(opSymbol, '$tableNo');

        return opSymbol;
    }
}