<template>
    <div class="panel-saveGroup">
        <div class="d-flex flex-column h-100 w-100">
            <div class="mb-2">
                <div class="ve-panel-header">
                    Aufstellung speichern
                </div>
                <fieldset class="col-sm-12 ms-3 ps-1 mb-0 mt-2 col-form-label col-sm-6">
                    <InputText :value="groupName" label="Name" class="col-sm-6 ps-1" v-model="text" @update:modelValue="onChangeName"/>
                    <InputText :value="groupFurniture" label="Möbel" class="col-sm-6 ps-1" v-model="text" @update:modelValue="onChangeFurniture"/>
                    <!-- <InputText :value="groupComment" placeholder="Kommentar..." label="Kommentar" class="col-sm-6" v-model="text" @update:modelValue="onChangeComment"/> -->
                    <div class="mb-2 ps-1">
                        <label class="col-sm-12 ms-2 mb-0 mt-2 col-form-label">Form</label>
                        <div class="col-sm-6">
                            <select class="form-select col-sm-6" v-model="chairShape" @change="onChangeShape(value)">
                            <option key="1" value="Circle">Rund</option>
                            <option key="2" value="square">Eckig</option>
                            <option key="3" value="Banquet">Quadratisch</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
            <button class="btn btn-outline-blank text-white saveGroupButton" type="button" @onclick="saveContent">Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import InputText from '@/vuejs/components/InputText.vue';
import Settings from '@/visual-events/data/Settings'

export default {
    name: 'PanelSaveGroup',
    components: {
        InputText
    },

    data () {
      return {
        groupName: 'name',
        groupFurniture: 'furniture',
        groupComment: 'comment',
        groupShape: 'shape'
      }
    },

    mounted () {
      theApp.addDialog('PanelSaveGroup', this)
    },

    unmounted () {
        theApp.removeDialog('PanelSaveGroup')
    },

    methods: {

        saveContent(action){

            groupName = action.groupName
            groupFurniture = action.groupFurniture
            groupComment = action.groupComment
            groupShape = action.groupShape



            //speichern der Zusammenstellung
        },

        onChangeName(value){
            this.groupName = value
        },

        onChangeFurniture(){
            this.groupFurniture = value
        },

        onChangeComment(){
            this.groupComment = value
        },

        onChangeShape(value){
            this.groupShape = value
        }
    }
}
</script>

<style scoped>
.panel-saveGroup{
    height: 100%;
    width: 100%;
}

.saveGroupButton{
    background-color: #009aa3;
    margin-left: 60%;
    margin-top: 15%;
}

.form-label{
    margin-top: 5% !important;
    margin-left: 0% !important;
    padding-left: 0% !important;
}
</style>
