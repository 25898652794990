<template>
  <div class="home" v-bind:style="{cursor: cursor}">
    <SideNav/>
    <SidePane/>
    <div class="coupled-2d-3d">
      <div v-if="showOverlay" class="ve-overlay-absolute d-flex flex-column align-items-center justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="fs-5 fw-medium mt-2">Daten werden geladen...</span>
      </div>
      <div class="webgl-render-canvas">
        <WebGLRenderCanvas v-bind:viewName="'2D Ansicht'">2D-Ansicht</WebGLRenderCanvas>
        <div class="ticketing-icon-bar-view">
          <IconBarView :viewName="'2D Ansicht'" :zoomIn="2.0" :zoomOut="0.5" :zoomToFit="zoomToFit"/>
        </div>
      </div>
    </div>
    <!-- <DiagnosticActionStack/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import theApp from '@/frame/Application'
import Action from '@/frame/Action'
import Logger from '@/frame/Logger'
import WebGLRenderCanvas from '@/vuejs/components/WebGLRenderCanvas.vue'
import IconBarView from '@/vuejs/components/IconBarView.vue'
import SidePane from '@/vuejs/components/SidePane.vue'
import SideNav from '@/vuejs/components/SideNav.vue'
import Ticketing from '@/visual-events/ticketing/Ticketing'
import Inventory from '@/visual-events/inventory/Inventory'
import ModelIO from '@/visual-events/model/ModelIO'
import Settings from '@/visual-events/data/Settings'
import VisualEvents3DView from '@/visual-events/view/VisualEvents3DView'
import ActDefaultTicketing from '@/visual-events/actions/ActDefaultTicketing'
import configPlanerTicketing from '@/visual-events/data/ConfigPlanerTicketing'
import configTicketing from '@/visual-events/ticketing/Config'

// in order to see the current action stack insert in the template
//     <DiagnosticActionStack/>
//   </div>
// </template>
// and uncomment 
//import DiagnosticActionStack from '@/vuejs/components/DiagnosticActionStack.vue'

const logger = new Logger('PlanerTicketing.vue');

export default {
  name: 'Home',
  props: {
    eventId: String,
    model: String,
    action: String,
    apiKey: String,
    language: String,
    panels: String,
    symbols: String,
  },
  data () {
    return {
      show3D: true,
      cursor: 'default',
      showOverlay: false
    }
  },
  computed: {
    zoomToFit: {
      get () {
        return Settings.getOption('views', '2D Ansicht', 'zoomToFit')
      }
    }
  },
  components: {
    SideNav,
    WebGLRenderCanvas,
    IconBarView,
    SidePane,
    //DiagnosticActionStack
  },
  methods: {
    async load() {
      theApp.executeCommand('.VisualEvents.readSettings');
      //TODO: find a better way to provide the apiKey and language in Ticketing and Inventory
      Ticketing.apiKey = this.apiKey
      Ticketing.language = this.language
      Inventory.apiKey = this.apiKey
      Inventory.language = this.language
      if (this.panels)
        Settings.set('.pathFavorites', this.panels)
      if (this.symbols)
        Settings.set('.favorites', this.symbols)
      let pathModel = Settings.get('.model');
      if (this.eventId) {
        pathModel = await Ticketing.getPlanForEvent(this.eventId)
        Settings.set('.model', pathModel)
      }
      if (this.model) {
        pathModel = this.model;
        Settings.set('.model', pathModel)
      }

      this.setShowOverlay(true);

      //HACK: if threejs load GLTF files, if Unreal not; consider general concept of lazy evaluation of meshes only in the grafic
      const view3D = theApp.findViewByName('3D Ansicht');
      const withMeshes = view3D instanceof VisualEvents3DView;

      try {
        console.log('ModelIO.load')
        await ModelIO.load(pathModel, withMeshes);
      } catch (err) {
        console.log('ModelIO.loadWebExport')
        await ModelIO.loadWebExport(pathModel, withMeshes);
      }

      this.removeFloorplanIfNotEmpty();

      this.setShowOverlay(false);

      const zoomToFit = Settings.getOption('views', '2D Ansicht', 'zoomToFit')
      if (pathModel)
        theApp.executeCommand(`.view.fitToCanvas "2D Ansicht" ${zoomToFit}' `)

      theApp.sendCommand(`${this.action}`)
    },
    setCursor(cursor) {
      this.cursor = cursor
    },
    setShowOverlay (overlay) {
      this.showOverlay = overlay;
    },
    removeFloorplanIfNotEmpty () {
      const empty = theApp.model.emptyOnLoad();
      let containsFloorplan = false;
      if (!empty) {
        containsFloorplan = theApp.model.drawingContains('$wall');
        if (!containsFloorplan) {
          const all = Settings.get('nav.buttons')
          const filtered = Object.keys(all)
              .filter(key => key !== 'Floorplan')
              .reduce((obj, key) => {
                obj[key] = all[key];
                return obj;
              }, {});
          Settings.set('nav.buttons', filtered);
          theApp.findDialogByName('SideNav')?.update();
        }
      }
      Settings.set('floorplan.enabled', empty || containsFloorplan);
    }
  },
  setup () {
    Action.setDefaultAction(ActDefaultTicketing)
  },
  beforeMount () {
    // override the default settings by special configurations
    Settings.set('nav', configPlanerTicketing.nav)
    Settings.set('views', configPlanerTicketing.views)
    Settings.set('ticketing', configTicketing)
  },
  mounted () {
    logger.log('mounted');

    theApp.warnUserBeforeUnload(true);
    theApp.addDialog('main', this)

    const view2D = theApp.findViewByName('2D Ansicht')
    const zoomMin = Settings.getOption('views', '2D Ansicht', 'zoomMin');
    const zoomMax = Settings.getOption('views', '2D Ansicht', 'zoomMax');
    view2D?.addCameraControls(zoomMin, zoomMax);

    this.load()
  },
  update () {
    logger.log('updated')
    this.load()
  },
  beforeUnmount () {
    Action.stopAll();
    theApp.executeCommand('.VisualEvents.writeSettings');
    theApp.warnUserBeforeUnload(false);
  },
  unmounted() {
    logger.log('unmounted')
    theApp.removeDialog('main')

    //HACK: otherwise computed: isTickting does not work properly when changing between ticketing planer and planer
    Settings.set('ticketing', undefined)

    const view2D = theApp.findViewByName('2D Ansicht')
    view2D?.removeCameraControls()
  }
}
</script>

<style>
* {
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}
.home {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.home-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow-x: hidden;
}

.coupled-2d-3d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.webgl-render-canvas {
  width: 100%;
  height: 100%;
  position: relative;
}

.ticketing-icon-bar-view {
  position: absolute;
  top: 20px;
  right: 30px;
}

.ve-3d-iconbar {
  position: absolute;
  top: 20px;
  right: 30px;
}

.show-3d-button {
  position: absolute;
  top: 20px;
  right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.3);
  background-color: white;
  font-size: 16px;
}
.ve-3d-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.3);
  background-color: white;
  font-size: 16px;
}

.ve-3d-controls-button {
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

@media (hover: hover) {
  .ve-3d-controls-button:hover {
    cursor: pointer;
    background-color: lightblue;
  }
}
</style>
