<template>
  <div class="panel-chair p-3">
    <div class="col-12 fw-bold">Stuhl</div>
    <fieldset class="ve-row">
        <label class="col-sm-12 ps-3 mb-0 mt-2 col-form-label">Typ</label>
        <div class="col-sm-6">
          <select class="form-select col-sm-6" v-model="shape" @change="onChangeChairType($event)">
            <option key="0" value="">Stuhl Typ wählen </option>
            <option key="1" value="SHAPE_CIRCLE">Kreis</option>
            <option key="2" value="SHAPE_RECTANGLE">Rechteck</option>
            <option key="3" value="SHAPE_BANQUET">Bankett</option>
          </select>
      </div>
      <div v-if="shape === 'SHAPE_CIRCLE'">
        <div class="ve-row mt-1">
          <div class="prop-sub-heading"></div>
          <PanelChairCircle />
        </div>
      </div>
      <div v-if="shape === 'SHAPE_RECTANGLE'">
        <div class="ve-row mt-1">
          <div class="prop-sub-heading"></div>
          <PanelChairRectangle />
        </div>
      </div>
      <div v-if="shape === 'SHAPE_BANQUET'">
        <div class="ve-row mt-1">
          <div class="prop-sub-heading"></div>
          <PanelChairRectangle />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import theApp from '@/frame/Application';
import PanelChairRectangle from '@/vuejs/components/PanelChairRectangle.vue';
import PanelChairCircle from '@/vuejs/components/PanelChairCircle.vue';

export default {
  name: 'PanelChair',
  components: {
    PanelChairCircle,
    PanelChairRectangle,
  },
  data () {
    return {
      shape: '',
    }
  },
  mounted () {
    theApp.addDialog('PanelChair', this);
  },
  updated () {
    theApp.addDialog('PanelChair', this)
  },
  unmounted () {
    theApp.removeDialog('PanelChair')
  },
  methods: {
    setShape (shape) {
      this.shape = shape;
    },
    onChangeChairType(event) {
      if(event.target.value === 'SHAPE_CIRCLE'){
        theApp.executeCommand(`.Ticketing.drawChairCircle`)
      }
      if(event.target.value === 'SHAPE_RECTANGLE'){
        theApp.executeCommand(`.Ticketing.drawChairRectangle`)
      }
      if(event.target.value === 'SHAPE_BANQUET'){
        theApp.executeCommand(`.Ticketing.drawChairBanquet`)
      }
    },
  }
}
</script>

<style scoped>
</style>
