import * as THREE from 'three';
import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import VariantTableCircle from '@/visual-events/actions/VariantTableCircle';
import TableShapeUtils from '@/visual-events/model/TableShapeUtils';

import Logger from '@/frame/Logger';

const logger = new Logger('VariantTableCircleWithSeats');

export default class VariantTableCircleWithSeats extends Variant {
    constructor (tableDiameter, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, numberOfChair, freeSpaces) {
        const opts = {
            tableDiameter: tableDiameter,
            chairShape: chairShape,
            chairDiameter: chairDiameter,
            chairWidth: chairWidth,
            chairHeight: chairHeight,
            tableColor: tableColor,
            chairColor: chairColor,
            numberOfChair: numberOfChair,
            freeSpaces: freeSpaces
        }
        super ('TABLE_CIRCLE_WITH_SEATS', 1, 'standard table circle', opts);
        this.t = new THREE.Matrix4();
        this.r = new THREE.Matrix4();
    }

    createSymbol () {

        logger.log('createSymbol');

        // create table for rounded table
        const variant = new VariantTableCircle(this.json.opts.tableDiameter, this.json.opts.tableColor);
        const opReferenceTable = variant.create();

        const opSymbolGroup = OpFactory.createSymbol('');
        opSymbolGroup.add(opReferenceTable);

        const opText = opReferenceTable.findAttributeText('$tableNo');
        opSymbolGroup.setAttribute('$tableNo', '?');
        opText.attachToAttribute(opSymbolGroup, '$tableNo');
        opSymbolGroup.setAttribute('$seatAtTableNo', Array(this.json.opts.numberOfChair).fill('?'));

        // create chair around table 
        const chairVariant = TableShapeUtils.getChairVariant(this.json.opts.chairShape, this.json.opts.chairDiameter, this.json.opts.chairWidth, this.json.opts.chairHeight, this.json.opts.chairColor);
        for ( let i = 0; i < this.json.opts.numberOfChair; i ++ ) {
            const angle = i / (this.json.opts.freeSpaces + this.json.opts.numberOfChair) * Math.PI * 2;
            const x = (Math.cos(angle) * (this.json.opts.tableDiameter/2));
            const y = (Math.sin(angle) * (this.json.opts.tableDiameter/2));
            const a = angle - (Math.PI / 2);

            const opReferenceChair = chairVariant.create();
            this.r.makeRotationZ(a);
            this.t.makeTranslation(x, y, 0);
            this.t.multiply(this.r);
            opReferenceChair.setTransform(this.t);
            opSymbolGroup.add(opReferenceChair);

            const opTextChair = opReferenceChair.findAttributeText('$seatNo');
            opTextChair.attachToAttribute(opSymbolGroup, `$seatAtTableNo[${i}]`)
        }

        logger.log('createSymbol end');

        return opSymbolGroup;
    }
}