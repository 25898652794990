import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';
import { FileServiceTextureLoader } from './FileServiceTextureLoader';

import Settings from '@/visual-events/data/Settings';

import Logger from '@/frame/Logger';

import { THREE_TO_OP } from '@/visual-events/model/OpCoordinates.js';

const logger = new Logger('CADdyGLTFLoader');

export default class CADdyGLTFLoader {

    constructor() {
        
    }

    async loadAsync(url, filename, transform, onProgress) {
        logger.log(`CADdyGLTFLoader.loadAsync(${url}, ${filename})`);

        var requestHeaders = {
            'VisualEvents-ApiKey': Settings.get('.userToken'),
            'VisualEvents-Language': Settings.get('.language')
        };

        var fileServiceImageLoader = new FileServiceTextureLoader();
        fileServiceImageLoader.setRequestHeader(requestHeaders);

        var manager = new THREE.LoadingManager();
        manager.addHandler(/\.tga$/i, new TGALoader());
        // manager.addHandler(/\.jpg$/i, fileServiceImageLoader);
        // manager.addHandler(/\.png$/i, fileServiceImageLoader);

        var gltfLoader = new GLTFLoader(manager);
        gltfLoader.requestHeader = requestHeaders;

        const gltf = await gltfLoader.loadAsync(url + '/' + filename, onProgress);

        var castShadow = true;
        const finalObject = new THREE.Object3D();

        gltf.scene.traverse(child => {
            if (child.isMesh) {
                if (Array.isArray(child.material)) {
                    child.material.forEach(mat => {
                        if (mat.transparent)
                            castShadow = true;
                    });
                } else {
                    if (child.material.transparent)
                        castShadow = true;
                }
                var clonedMesh = child.clone();
                if (castShadow) {
                    clonedMesh.castShadow = true;
                    clonedMesh.receiveShadow = true;
                }
                finalObject.add(clonedMesh);
            }
        });

        finalObject.applyMatrix4(THREE_TO_OP);

        return finalObject;
    }

}
