import theApp from '@/frame/Application';
import visualEvents from '@/visual-events/VisualEvents';

import Action from '@/frame/Action';
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';
import FltPick from '@/visual-events/actions/FltPick';
import { PickFlags } from '@/visual-events/actions/FltPick';
import FltHover from '@/visual-events/actions/FltHover';
    
import GrfBookingStrategy from '@/visual-events/view/GrfBookingStrategy';

import Ticketing from '@/visual-events/ticketing/Ticketing';
import Popup from '@/frame/Popup';
import Logger from '../../frame/Logger';

const logger = new Logger('ActBooking');

const State = Object.freeze({
  SELECT: 0,
  REQUEST_COUNT: 1,
  POPUP_OPEN: 2
});
  
export default class ActBooking extends Action {
    constructor(args) {
      super();


      
      //TODO: combine action args in options object in order not to rely on the ordering of the args, and missing opts
      //TODO: no need to store args action members; all are in Ticketing
      this.eventId = args[1];
      this.cartId = args[2]; 
      this.allStates = args[3] === 'true';
      this.apiKey = args[4];
      this.language = args[5];
      this.readOnly = args[6] === 'true';

      logger.log('ActBooking-----------------------');
      logger.log(`readOnly=${this.readOnly}`);

      //default: 12 * 5 sec = 60 sec
      this.timerMax = parseInt(args[7]);
      if (isNaN(this.timerMax))
        this.timerMax = 60000;
      this.timerInterval = parseInt(args[8]);
      if (isNaN(this.timerInterval))
        this.timerInterval = 5000;

      this.zoomMin = parseFloat(args[9]);
      if (isNaN(this.zoomMin))
        this.zoomMin = undefined;
      this.zoomMax = parseFloat(args[10]);
      if (isNaN(this.zoomMax))
        this.zoomMax = undefined;

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.view3D = theApp.findViewByName('3D Ansicht');
      const drawing = this.view2D.getRoot();

      this.ticketing = new Ticketing(this.eventId, this.cartId, drawing, this.allStates, this.timerMax, this.timerInterval);

      this.selected = null;
      this.popup = null;
      this.hover = null;
      this.hoverTimer = null;

      // HACK: Remember last cursor position to set the popup correctly
      this.lastPosition = null;

      this.ctrlHeld = false;

      this.state = State.SELECT;
    }

    actionStart () {
        logger.log(`actionStart     ${this.state}`);
        
        this.state = State.SELECT;

        this.currGrfStrategy = this.view2D.getGrfStrategy();
        this.view2D.setGrfStrategy(new GrfBookingStrategy(visualEvents.fillColor, visualEvents.fillOpacity));

        let pick_flags = PickFlags.IGNORE_MSL | PickFlags.NO_COLLECT | PickFlags.NO_DRAG;
        let pick_types = ['XOpSymbol']

        const filter = new FltPick(pick_flags, pick_types);
        this.addFilter(filter);

        this.ticketing.startPolling();

        this.view2D.addCameraControls(this.zoomMin, this.zoomMax);
        this.view2D.fitToCanvas();

        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy   ${this.state}`);
        
        switch (this.state) {
            case State.SELECT:
                this.ticketing.stopPolling();
                break;
            case State.REQUEST_COUNT:
                this.ticketing.stopPolling();
                this.closePopup();
                break;
        }

        this.view2D.setGrfStrategy(this.currGrfStrategy);
        
        this.view2D.removeCameraControls();
    }

    actionBreak (event) {
        logger.log(`actionBreak     ${this.state}`);
        // switch (this.state) {
        //     case State.SELECT:
        //         this.ticketing.stopPolling();
        //         break;
        // }
        return null;
    }

    actionMouse (event) {
        logger.log(`actionMouse ${this.state}`);
        if (event.raw.type === 'click') {
            let test;
            switch (this.state) {
                case State.SELECT:
                    //never happens
                    break;
                case State.REQUEST_COUNT:
                    //left mouse click outside of the popup closes it
                    //remark: if this event occurs, the click happened outside of the popup
                    //clicks inside would have been masked by the popup 
                    if (this.popup) {
                        this.closePopup();
                        this.state = State.SELECT;
                    }
                    break;
                case State.POPUP_OPEN:
                    this.lastPosition = { x: event.raw.layerX, y: event.raw.layerY }
                    this.popup.setClientPosition(this.lastPosition);
                    this.popup.open();
                    this.popup.updatePosition();
                    this.state = State.REQUEST_COUNT;
                    break;
            }
        }
        
        return event; 
    }

    actionDynamic (event) {

        this.ctrlHeld = event.raw.ctrlKey;

        if (typeof(visualEvents.onHover) === 'function')
            this.onHoverCustom(event);
        else if (visualEvents.onHover === 'default')
            this.onHoverDefault(event);

        return event;
    }

    onHoverCustom (event) {
        if ('hovering' in event) {
            if (event.hovering) {
                let itemIds = event.objects.map(item => item.id);
                visualEvents.onHover(true, { x: event.raw.pageX, y: event.raw.pageY }, itemIds);
            } else {
                visualEvents.onHover(false, null, null);
            }
        }
    }

    onHoverDefault (event) {
        if ('hovering' in event) {
            if (this.hoverTimer) {
                clearTimeout(this.hoverTimer);
                this.hoverTimer = null;
            } 

            if (event.hovering) {
                this.hoverTimer = setTimeout(() => { this._hover(event) }, 700);
            } else {
                if (this.hover) {
                    this.hover.close();
                    this.hover = null;
                }
            }
        }
    }

    _hover (event) {
        let item = event.objects[0].getAttribute('"$ticketing.item"');
        
        if (!this.hover) {
            this.hover = new Popup('ve-hover',  this.view2D.canvas, this.view2D.scene, this.view2D.camera);
            
            let content = `
                <div class="ve-list">
                    <div class="ve-attribute">
                        <span class="ve-name">Block</span>
                        <span id="ve-att-block" class="ve-value">1</span>
                    </div>
                    <div class="ve-attribute">
                        <span class="ve-name">Reihe</span>
                        <span id="ve-att-row" class="ve-value">2</span>
                    </div>
                    <div class="ve-attribute">
                        <span class="ve-name">Sitz</span>
                        <span id="ve-att-seat" class="ve-value">3</span>
                    </div>
                    <div class="ve-attribute">
                        <span class="ve-name">Buchbar</span>
                        <span id="ve-att-bookable" class="ve-value">4</span>
                    </div>
                </div>
            `;
            this.hover.setContent(content);
            this.hover.setParent('app');
            this.hover.attach();
            this.hover.setClientPosition({ x: event.raw.pageX, y: event.raw.pageY });
            this.hover.open();
            this.hover.updatePosition();

            this.hover.setInnerHtml('ve-att-block', item.attributes['Block']);
            this.hover.setInnerHtml('ve-att-row', item.attributes['Reihe']);
            this.hover.setInnerHtml('ve-att-seat', item.attributes['number']);
            this.hover.setInnerHtml('ve-att-bookable', event.objects[0].getAttribute('bookableState') === 'bookable' ? 'Ja' : 'Nein');
        } else {
            this.hover.setClientPosition({ x: event.raw.pageX, y: event.raw.pageY });
            this.hover.updatePosition();
            this.hover.setInnerHtml('ve-att-block', item.attributes['Block']);
            this.hover.setInnerHtml('ve-att-row', item.attributes['Reihe']);
            this.hover.setInnerHtml('ve-att-seat', item.attributes['number']);
            this.hover.setInnerHtml('ve-att-bookable', event.objects[0].getAttribute('bookableState') === 'bookable' ? 'Ja' : 'Nein');
        }
    }

    actionSelection (event) {
        if (event.objects.length === 0) 
            return null;

        if (this.ctrlHeld) {
            const getFirstMesh = (item) => {
                let mesh = item.mesh;
                if (!mesh) {
                    item.children.some((child) => {
                        mesh = getFirstMesh(child);
                        return mesh !== undefined;
                    });
                }
                return mesh;
            }

            const op2D = event.objects[0];
            const op3D = Event3DPlacing.findLink2DTo3D(op2D);

            if (op3D) {
                const mesh = getFirstMesh(op3D).children[0];
                this.view3D.setFirstPersonControls(mesh)
            }
        } else {
            logger.log(`Selection ${this.state}`);
            switch (this.state) {
                case State.SELECT:
                    //in readonly mode do not accept any user selection
                    if (!this.readOnly)
                        this.handleSelection(this.eventId, this.cartId, event.objects);
                    break;
                case State.REQUEST_COUNT:
                    if (this.popup && !this.readOnly) {
                        this.closePopup();
                        this.handleSelection(this.eventId, this.cartId, event.objects);
                    }
                    break;
                }
        }
        return null;
    }

    actionValue (event) {
        logger.log(`actionValue ${this.state}`);
        switch (this.state) {
            case State.SELECT:
                //nothing to do
                break;
            case State.REQUEST_COUNT:
                if (event.attribute === 'quantity') {
                    this.ticketing.stopPolling();
                    const value = event.value;
                    logger.log(`actionValue itemId ${value.bookableId} quantity ${value.quantity}`);
                    this.addToOrRemoveFromCart(this.eventId, this.cartId, this.selected, value.quantity).then(result => { this.ticketing.startPolling(); });
                    this.state = State.SELECT;
                }
                break;
        }
        return event;
    }

    /**
     * react on the user selecting bookable items' geometry:
     * - for single seat items just toggle the 'bookableId' in the 'cartId'
     * - for en bloc booking show a popup dialog in order have the user select the requested number of tickets
     * @param {*} eventId 
     * @param {*} cartId 
     * @param {*} objects 
     */
    async handleSelection(eventId, cartId, objects) {

        this.ticketing.stopPolling();

        const cartActions = [];

        //relying in the stored states
        objects.forEach(op => {
            /**
             * HACK: Before, when single seats and blocks overlap, I only got the first symbol that was found.
             * But actually I would need all symbols that a click gets me and then decide which I want to use.
             */
            if (objects.length > 1 && Ticketing.getCountBookable(op))
                return;

            const bookableId = Ticketing.getBookableId(op);

            // optionally call the replacing callback
            if(visualEvents.onClickBookableId) {
                visualEvents.onClickBookableId(bookableId);
                return;
            }

            const countBookable = Ticketing.getCountBookable(op);

            if (countBookable === undefined) {

                //single seats: toogle in cart
                const state = op.getAttribute('bookableState');
                if (state === 'bookable') {
                    logger.log(`addingToCart ${bookableId}`);
                    op.setAttribute('bookableState', 'addingToCart');
                    cartActions.push(Ticketing.addToCart(eventId, cartId, [ bookableId ]));
                }
                else if (state === 'inMyCart') {
                    logger.log(`removingFromCart ${bookableId}`);
                    op.setAttribute('bookableState', 'removingFromCart');
                    cartActions.push(Ticketing.removeFromCart(eventId, cartId, [ bookableId ]));
                }

            } else {
                const bookableState = op.getAttribute('bookableState');
                if (bookableState === 'inMyCart' || bookableState === 'bookable') {
                    //en bloc tickets: show dialog in order to ask the user for the number of tickets to book
                    this.selected = op;
                    this.requestQuantity(eventId, cartId, op);
                    this.state = State.POPUP_OPEN;
                }
                return; // handle only one, if multi selection
            }

        });    

        await Promise.allSettled(cartActions);

        this.ticketing.startPolling();
        theApp.model.modifiedIn2D = objects;
    }

    /**
     * show a popup dialog at OpObject op in order to have the user input the
     * requested number of tickets for a collection item
     * @param {*} op 
     */
    requestQuantity(eventId, cartId, op) {
        if (!op)
            return;

        const blockName = Ticketing.getName(op) || '';
        const bookableId = Ticketing.getBookableId(op);
        const countBookable = parseInt(Ticketing.getCountBookable(op));

        let mesh = this.view2D.getMeshById(op.id);
        if (mesh) {
            this.popup = new Popup('ve-popup', this.view2D.canvas, this.view2D.scene, this.view2D.camera);
            const html = `
            <div class="ve-popup-header">
                <div id="ve-popup-header-title" class="ve-popup-header-title"></div>
                <span id="ve-popup-header-close" class="ve-popup-header-close">&times;</span>
            </div>
            <div class="ve-popup-body">
                <button id="ve-popup-body-sub" name="sub">-</button>
                <div id="ve-popup-body-value" class="ve-popup-body-value">0 / 0</div>
                <button id="ve-popup-body-add" name="add">+</button>
            </div>
            <div class="ve-popup-footer">
                <div id="ve-popup-footer-tickets"></div>
                <button name="reserve" id="ve-popup-footer-reserve">Reservieren</button>
            </div>`;

            const scope = this;
            let countMax = 0;
            const submit = () => {
                let quantity = parseInt(document.getElementById('ve-popup-body-value').innerHTML);
                let payload = {
                    bookableId: Ticketing.getBookableId(op),
                    quantity: quantity
                };
        
                theApp.executeValueEvent('quantity', payload);
                scope.closePopup();
                scope.state = State.SELECT;
            };
            const add = () => {
                let valueDiv = document.getElementById('ve-popup-body-value');
                let value = parseInt(valueDiv.innerHTML);
                if (value < countMax)
                    valueDiv.innerHTML = `${value + 1} / ${countMax}`;
            };
            const sub = () => {
                let valueDiv = document.getElementById('ve-popup-body-value');
                let value = parseInt(valueDiv.innerHTML);
                if (value > 0)
                    valueDiv.innerHTML = `${value - 1} / ${countMax}`;
            };
            const close = () => {
                this.closePopup();
                this.state = State.SELECT;
            }

            this.popup.setContent(html);
            this.popup.setParent('app');
            this.popup.attach();
            // this.popup.setClientPosition(this.lastPosition);
            // this.popup.open();
            // this.popup.updatePosition();
            this.popup.setInnerHtml('ve-popup-header-title', blockName);

            Ticketing.countInCart(eventId, cartId, bookableId)
            .then((countInCart) => {
                if (this.popup) {
                    countMax = parseInt(countBookable) + parseInt(countInCart);
                    this.popup.setInnerHtml('ve-popup-footer-tickets', `${countInCart} Ticket(s)`);
                    this.popup.setInnerHtml('ve-popup-body-value', `${countInCart} / ${countMax}`);
                }
            });

            this.popup.setClickEvent('ve-popup-header-close', close);
            this.popup.setClickEvent('ve-popup-body-add', add);
            this.popup.setClickEvent('ve-popup-body-sub', sub);
            this.popup.setSubmit('ve-popup-footer-reserve', submit);
        }
    }

    closePopup() {
        this.popup.close();
        this.popup = null;
    }

    /**
     * check how many tickets of 'eventId' and 'bookableId' are currently in the 'cartId'
     * and call addToCart resp. removeFromCart until the target 'quantity' is achieved
     * @param {*} eventId 
     * @param {*} cartId 
     * @param {*} bookableId 
     * @param {*} quantity 
     */
     async addToOrRemoveFromCart(eventId, cartId, op, quantity) {

        const bookableId = Ticketing.getBookableId(op);
        const countBookable = Ticketing.getCountBookable(op);
        // quantity = Math.min(quantity, countBookable);

        const countCart = await Ticketing.countInCart(eventId, cartId, bookableId);
        if (quantity > countCart) {
            op.setAttribute('bookableState', 'addingToCart');
            theApp.model.modifiedIn2D = [op];
            await Ticketing.addToCart(eventId, cartId, new Array(quantity - countCart).fill(bookableId));
        }
        else if (quantity < countCart) {
            op.setAttribute('bookableState', 'removingFromCart');
            theApp.model.modifiedIn2D = [op];
            await Ticketing.removeFromCart(eventId, cartId, new Array(countCart - quantity).fill(bookableId));
        }
    }
}    
