<template>
  <div class='threejs-viewport' v-bind:style="{cursor: cursor}" ref='viewport'/>
</template>

<script>
import theApp from '@/frame/Application'
import Logger from '@/frame/Logger';
import VisualEvents2DView from '@/visual-events/view/VisualEvents2DView'
import VisualEvents3DView from '@/visual-events/view/VisualEvents3DView'

const logger = new Logger('WebGLRenderCanvas');

export default {
  props: {
    viewName: String
  },
  data() {  	
    return {
      cursor: undefined
    }
  },
  beforeCreate () {
    logger.log(`beforeCreate ${this.viewName}`)
    if (this.viewName === '3D Ansicht')
      theApp.addView(new VisualEvents3DView(this.viewName, theApp.model));
    else
      theApp.addView(new VisualEvents2DView(this.viewName, theApp.model));
  },
  mounted () {
    logger.log(`mounted ${this.viewName}`)
    theApp.addDialog(this.viewName, this);
    const view = theApp.findViewByName(this.viewName)
    // get the div with ref='viewport' in the component
    view.mount(this.$refs.viewport)
  },
  unmounted () {
    logger.log(`unmounted ${this.viewName}`)
    theApp.removeDialog(this.viewName)
  },
  beforeUnmount () {
    logger.log(`beforeUnmount ${this.viewName}`)
    const view = theApp.findViewByName(this.viewName)
    view.unmount()
    view.dispose();
  },
  methods: {
    setCursor (cursor) {
      this.cursor = cursor;
    }
  }
}
</script>

<style>
.threejs-viewport {
  width: 100%;
  height: 100%;
}

.ve-selection-frame {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
}
</style>