import { Vector2 } from 'three';

/**
 * helper class for the visualisation of a mouse dragged rectangle
 * 
 * A rectangular div with css class name 'cssClassName' is inserted into the
 * 'rootElement' while the mouse is dragged.
 * 
 * usage:
 * 
 * create a SelectionFrame object in an action or filter and call
 * - in actionPoint                 selectionFrame.onMouseDown(event.raw)
 * - in actionDynamic               selectionFrame.onMouseMove(event.raw)
 * - in actionPointUp               selectionFrame.onMouseUp()
 * 
 * The css class should be provided somewhere in the css resource, e.g.
 * 
 * .ve-selection-frame {
 *    border: 1px solid #55aaff;
 *    background-color: rgba(75, 160, 255, 0.3);
 *    position: fixed;
 * }
 * 
 * defined in WebGLRenderCanvas.vue
 *  
 * (ideas are taken from the three.js example 'web-gl box selection, SelectionHelper.js)
 */
export default class SelectionFrame {

    constructor(rootElement, cssClassName) {

        this.element = document.createElement( 'div' );
        this.element.classList.add( cssClassName );
        this.element.style.pointerEvents = 'none';

        this.rootElement = rootElement;

        this.startPoint = new Vector2();
        this.pointTopLeft = new Vector2();
        this.pointBottomRight = new Vector2();

        this.isActive = false;
    }

    onMouseDown( event ) {
        this.rootElement.appendChild( this.element );

        this.element.style.left   = event.clientX + 'px';
        this.element.style.top    = event.clientY + 'px';
        this.element.style.width  = '0px';
        this.element.style.height = '0px';

        this.startPoint.x = event.clientX;
        this.startPoint.y = event.clientY;

        this.isActive = true;
    }

    onMouseMove( event ) {
        this.pointBottomRight.x = Math.max( this.startPoint.x, event.clientX );
        this.pointBottomRight.y = Math.max( this.startPoint.y, event.clientY );
        this.pointTopLeft.x     = Math.min( this.startPoint.x, event.clientX );
        this.pointTopLeft.y     = Math.min( this.startPoint.y, event.clientY );

        this.element.style.left   = this.pointTopLeft.x + 'px';
        this.element.style.top    = this.pointTopLeft.y + 'px';
        this.element.style.width  = ( this.pointBottomRight.x - this.pointTopLeft.x ) + 'px';
        this.element.style.height = ( this.pointBottomRight.y - this.pointTopLeft.y ) + 'px';
    }

    onMouseUp() {
        this.reset();
    }

    reset() {
        if (this.isActive) {
            this.element.parentElement.removeChild( this.element );
            this.isActive = false;
        }
    }
}

