import OpReference from '@/visual-events/model/OpReference';
import theApp from '@/frame/Application';
import VariantChairCircle from '@/visual-events/actions/VariantChairCircle';
import VariantChairRectangle from '@/visual-events/actions/VariantChairRectangle';
import VariantChairBanquet from '@/visual-events/actions/VariantChairBanquet';

// TODO: not worth a class, move methods somewhere else, e.h. VairaintChairXXX
export default class TableShapeUtils {

    static getChairVariant(chairShape, diameter, width, height, color) {

        if(chairShape == 'SHAPE_CIRCLE') 
            return new VariantChairCircle(diameter, color);
        
        if(chairShape == 'SHAPE_RECTANGLE')
            return new VariantChairRectangle(width, height, color);
        
        if(chairShape == 'SHAPE_BANQUET')
            return new VariantChairBanquet(width, height, color);
    }

    static isSymbolTableCircle (op) {
        if (!op instanceof OpReference)
            return false;

        const symbolId  = op.symbolId;
        const symbol = theApp.model.symbols.get(symbolId);
        if(!symbol)
            return false;
        
        const json = symbol.getAttribute('$variant');

        if(json?.type == 'TABLE_CIRCLE_WITH_SEATS')
            return true;
        else
            return false; 
    }

    static isSymbolTableRectangle (op) {
        if (!op instanceof OpReference)
            return false;

        const symbolId  = op.symbolId;
        const symbol = theApp.model.symbols.get(symbolId);
        if(!symbol)
            return false;

        const json = symbol.getAttribute('$variant');

        if(json?.type == 'TABLE_RECTANGLE_WITH_SEATS')
            return true;
        else
            return false; 
    }

}