/**
 * observer pattern: 
 * - Observers subscribe to Subjects
 * - Subjects send notifications to all subscribed Observers
 */
export default class Subject {
    constructor () {
        this.observers = [];
    }

    isObservedBy (op) {
        return this.observers.includes(op);
    }

    subscribe (observer) {
        this.observers.push(observer);
    }

    unsubscribe (observer) {
        const index = this.observers.indexOf(observer);
        if (index > -1)
            this.observers = this.observers.slice(index, 1);
    }

    notify (data) {
        this.observers.forEach(o => o.onNotify(data));
    }
}