<template>
    <div class="panel-polygon border-bottom p-3">
        <fieldset>
            <InputCheckBox label="Geschlossen" v-model="closed" @update:modelValue="onChangeClosed" /> 
            <!-- <div class="col-sm-12">
              <button @click="onClickBreak">Beenden</button>
            </div> -->
        </fieldset>
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import InputCheckBox from '@/vuejs/components/InputCheckBox.vue';
import Settings from '@/visual-events/data/Settings';

export default {
  name: 'PanelPolygon',
  components: {
    InputCheckBox,
  },
  data () {
    return {
        closed: true
    }
  },
  mounted () {
    theApp.addDialog('PanelPolygon', this)
  },
  unmounted () {
    theApp.removeDialog('PanelPolygon')
  },
  methods: {
    update(action) {
      this.closed = action.closed;
    },    
    onChangeClosed () {
        theApp.executeValueEvent("closed", this.closed);
        Settings.set('polygon.closed', this.closed);
    },
    onClickBreak() {
      theApp.sendCommand("val.break");
    }
  }
}
</script>

<style scoped>
</style>
