<script>
import theApp from '@/frame/Application'
import VisualEventsUnrealView from  '@/visual-events/view/VisualEventsUnrealView';
import Logger from '@/frame/Logger';

const logger = new Logger('UnrealCanvas');

export default {
    name: 'UnrealCanvas',
    props: {
        viewName: String
    },
    data() {
        return {
            clickToPlay: true,
            cursor: undefined
        }
    },
    setup() {
        logger.log('setup')
    },
    beforeCreate () {
        logger.log(`beforeCreate ${this.viewName}`)
        theApp.addView(new VisualEventsUnrealView(this.viewName, theApp.model));
    },
    mounted() {
        logger.log(`mounted ${this.viewName}`)

        theApp.addDialog(this.viewName, this);
        const view = theApp.findViewByName(this.viewName)
        // get the div with ref='viewport' in the component
        view.mount(this.$refs.viewport)
    },
    unmounted () {
        logger.log(`unmounted ${this.viewName}`)
        theApp.removeDialog(this.viewName)
    },
    beforeUnmount () {
        logger.log(`beforeUnmount ${this.viewName}`)
        const view = theApp.findViewByName(this.viewName)
        view.unmount()
        view.dispose()
    },
    methods: {
        isVideoReady () {
            const viewport = this.$refs.viewport
            const video = viewport.getElementsByTagName("video")[0]
            logger.log(`videoState = ${video?.readyState}`)
            return video !== undefined 
                && video.readyState !== undefined 
                && video.readyState > 0
        },
        setClickToPlay (clickToPlay) {
          this.clickToPlay = clickToPlay
        },
        onClickToPlay () {
            logger.log('play')
            const view = theApp.findViewByName(this.viewName)
            view.play();
            this.clickToPlay = false
        },
        setCursor (cursor) {
            this.cursor = cursor;
        }
    }
}
</script>

<template>
    <div id="videoParentElement"  v-bind:style="{cursor: cursor}" ref="viewport">
        <div v-if="clickToPlay" id="clickToPlayElement" @click="onClickToPlay">
            <div>Click to play</div>
        </div>
    </div>
</template>

<style>
    #videoParentElement {
        width: 100%; 
        height: 100%; 
        /* min-height: -webkit-fill-available; */
        font-family: 'Montserrat'; 
        margin: 0px;
        background-color: "#000000";
    }
    #videoParentElement > video {
        object-fit: fill;
        -o-object-fit: fill;
    }
    #clickToPlayElement {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>