import * as THREE from 'three';

/**
 * predefined matrices for converting a CAD like coordinate system into a grafic system like
 * and reverse.
 * 
 * In CAD systems like CADdy++ the coordinate system's conception usually differs form the
 * understanding in the WebGL world, and in three.js
 * 
 * In CADdy++ the floor is XY and Z the height
 * XY is also the plane with drawings
 *
*   z
*   ^
*   |  y
*   | /
*   |/
*   o---------> x
*
* in three.js the floor is XZ and Y the height
*
*       y
*       ^
*       |  
*       | /
*       |/
* ------o---------> x
*      /
*     /
*    z
*
* the y-axis in CADdy becomes the negative z-axis in three.js
*
* especially with shapes:
* 
* Shapes are two-dimensional pathes, i.e. expressed in x,y coordinates.
* Thus, the ShapeGeometry is located in the xy-plane of three.js in the first place.
* In order to get the shape geometry in the CADdy++ xy-plane the shapes must be flipped
* by -Math.PI /2 into the three.js XZ plane. 
* 
*/

const createOpMatrix = function() {
    const t = new THREE.Matrix4();

    // this.t.makeRotationX(-Math.PI / 2); is the same
    t.set(1,  0,  0,  0,
          0,  0,  1,  0,
          0, -1,  0,  0,
          0,  0,  0,  1);
    return t;
}
const createOpMatrixInverse = function() {
    const caddy = createOpMatrix();
    return caddy.invert();
}

export const OP_TO_THREE = createOpMatrix();
export const THREE_TO_OP = createOpMatrixInverse();
