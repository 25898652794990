import Action from './Action.js';
import theApp from '@/frame/Application';
import CommandLineScanner from './CommandLineScanner.js';
import * as Event from './Event.js';
import Logger from './Logger.js';

const logger = new Logger('FltDefault');

export default class FltDefault extends Action {
  constructor () {
    super();
    this.onGoingTouch = false; // TouchEvent interpretation for Point, PointUp
  }

  actionKey (event) {
    if (!(event instanceof Event.RawKeyEvent)) { return event; }

    const raw = event.raw;

    // If an input element is focused, don't do anything
    // TODO: Exclude more elements?
    const inputTypes = [ 'date', 'datetime-local', 'email', 'month', 'number', 'password', 'search', 'tel', 'text', 'url', 'week' ];
    if (
      (document.activeElement instanceof HTMLInputElement && inputTypes.includes(document.activeElement.type)) ||
      (document.activeElement instanceof HTMLTextAreaElement)
    ) { 
      return null; 
    }

    logger.log(`actionKey ${raw.type}`);

    // ESCAPE has fixed meaning 'break current actionstate'
    if (raw.type === 'keydown' && raw.key === 'Escape') { 
      logger.log(`-> BreakEvent`);
      return new Event.BreakEvent(); 
    }

    // ggfs. Hotkeys in Command-Events umwandeln
    return event;
  }

  //
  // convert RawMouseEvent to an CAD event
  actionMouse (event) {
    if (!(event instanceof Event.RawMouseEvent)) { return event; }

    const raw = event.raw;

    logger.log(`actionMouse ${raw.type}`);

    if (raw.type === 'mousemove') {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        const [x, y, z] = view.unproject(raw);
        logger.log(` -> DynamicEvent ${x},${-z},${y}`);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.DynamicEvent(raw, view, x, -z, y);
      }
    }

    // left button clicked
    if (raw.type === 'mousedown' && raw.button === 0) {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        const [x, y, z] = view.unproject(raw);
        logger.log(` -> PointEvent ${x},${-z},${y}`);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.PointEvent(raw, view, x, -z, y);
      }
    }

    // left button released 
    if (event.raw.type === 'mouseup' && raw.button === 0) {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        const [x, y, z] = view.unproject(raw);
        logger.log(` -> PointUpEvent ${x},${-z},${y}`);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.PointUpEvent(raw, view, x, -z, y);
      }
    }

    // Pressing the right mouse button has fixed meaning 'break current actionstate'
    if (raw.type === 'mousedown' && raw.button === 2) { 
      logger.log(`-> BreakEvent`);
      return new Event.BreakEvent(); 
    }

    return event;
  }

  //
  // convert RawTouchEvent to an CAD event
  actionTouch (event) {
    if (!(event instanceof Event.RawTouchEvent)) { return event; }

    const raw = event.raw;

    logger.log(`actionTouch ${raw.type} ${raw.touches.length}`);

    if (raw.type === 'touchmove' && this.onGoingTouch) {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        const [x, y, z] = view.unproject(raw);
        logger.log(`-> DynamicEvent ${x},${-z},${y}`);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.DynamicEvent(raw, view, x, -z, y);
      }
    }

    // first touch 
    if (raw.type === 'touchstart' 
     && raw.touches.length === 1
     && !raw.altKey
     && !raw.ctrlKey
     && !raw.metaKey) {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        this.onGoingTouch = true;
        const [x, y, z] = view.unproject(raw);
        logger.log(` -> PointEvent ${x},${-z},${y}`);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.PointEvent(raw, view, x, -z, y);
      }
    }

    // first touch released
    if (event.raw.type === 'touchend' && this.onGoingTouch) {
      const [view] = theApp.findView(raw.currentTarget);
      if (view) {
        this.onGoingTouch = false;
        const [x, y, z] = view.unproject(raw);
        logger.log(` -> PointUpEvent ${x},${-z},${y}`);
        // the CADdy++ coordinates are flipped into the XY plane
        return new Event.PointUpEvent(raw, view, x, -z, y);
      }
    }

  }

  actionCommand (event) {
    logger.log(`actionCommand ${event.commandLine} ${JSON.stringify(event.args)}`);

   // val-Events?
    const scanner = new CommandLineScanner(event.commandLine);

    const cmd = scanner.getCommand();

    if (cmd === 'val.val') { return new Event.ValueEvent(scanner.getArg(1), scanner.getArg(2)); }
    if (cmd === 'val.break') { return new Event.BreakEvent(); }
    if (cmd === 'val.point') {
      try {
        logger.log(`-> PointEvent`);
        return new Event.PointEvent(
          null,
          null, // TODO:find focussed view??
          Number(scanner.getArg(1)),
          Number(scanner.getArg(2)),
          Number(scanner.getArg(3))
        );
      } catch (ex) {
        logger.error(event.commandLine + '\n' + ex.getMessage());
      }
    }

    return event;
  }
}
