export default {
    // settings
    nav: {
        buttons: { 
          'Select':     { name: 'Bearbeiten',       icon: { type: 'fa', class: 'fa-solid fa-arrow-pointer'},  command: '.Ticketing.waitForUserInput'}, 
          'Favorites':  { name: 'Favoriten',        icon: { type: 'fa', class: 'fa-solid fa-star'},           command: '.Ticketing.favorites' }, 
          'Furniture':  { name: 'Mobiliar',         icon: { type: 'svg', name: 'icon-chair'},                 command: '.Ticketing.drawChairBanquet'}, 
          'Draw':       { name: 'Zeichnen',         icon: { type: 'fa', class: 'fa-solid fa-pen'},            command: '.Ticketing.drawRectangle'}, 
          'Floorplan':  { name: 'Grundrissplaner',  icon: { type: 'svg', name: 'icon-floor'},                 command: '.floorplan.openDialog'},
          'Attributes': { name: 'Eigenschaften',    icon: { type: 'fa', class: 'fa-solid fa-table-list'},     command: '.Ticketing.editAttributes'}, 
          'Settings':   { name: 'Einstellungen',    icon: { type: 'fa', class: 'fa-solid fa-gear'},           command: '.Ticketing.settings'}, 
          'Save':       { name: 'Speichern',        icon: { type: 'fa', class: 'fa-regular fa-floppy-disk'},  command: '.io.saveAll'},
          'Download':   { name: 'PDF Download',     icon: { type: 'fa', class: 'fa-solid fa-download'},       command: '.view.printToPdf'}
        },
        startButton: '',
    },
    layout: {
      split2D3Dvertically: false
    },
    views: [
      {
        id: '2D Ansicht',
        display: true,
        iconbar: {
          buttons: {
            'FitToCanvas':  { name: 'Ansicht zurücksetzen',   icon: 'fa-solid fa-arrows-up-down-left-right',  hidden: false, style: 'transform: rotate(45deg);', command: '.view.fitToCanvas "{{viewName}}" "{{zoomToFit}}"' },
            'ZoomOut':      { name: 'Zoom -',                 icon: 'fa-solid fa-minus',                      hidden: false, style: '',                          command: '.view.zoom "{{viewName}}" {{zoomOut}}' },
            'ZoomIn':       { name: 'Zoom +',                 icon: 'fa-solid fa-plus',                       hidden: false, style: '',                          command: '.view.zoom "{{viewName}}" {{zoomIn}}' },
          }
        },
        zoomMin: 0.2,
        zoomMax: 20,
        zoomToFit: 0.8
      }
    ],
}