import * as THREE from 'three';
import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import VariantTableRectangle from '@/visual-events/actions/VariantTableRectangle';
import TableShapeUtils from '@/visual-events/model/TableShapeUtils';

export default class VariantTableRectangleWithSeats extends Variant {
    constructor (
        width,
        height,
        chairShape, 
        chairDiameter, 
        chairWidth,
        chairHeight,
        tableColor,
        chairColor,
        chairTop,
        chairBottom, 
        chairLeft, 
        chairRight
        ) {
        const opts = {
            width: width,
            height: height,
            chairShape: chairShape,
            chairDiameter: chairDiameter,
            chairWidth: chairWidth,
            chairHeight: chairHeight,
            tableColor: tableColor,
            chairColor: chairColor,
            chairTop: chairTop,
            chairBottom: chairBottom,
            chairLeft: chairLeft,
            chairRight: chairRight
        }
        super ('TABLE_RECTANGLE_WITH_SEATS', 1, 'standard table rectangle', opts);
        this.t = new THREE.Matrix4();
        this.r = new THREE.Matrix4();
    }

    createSymbol () {
        const widthX = this.json.opts.width;
        const widthY = this.json.opts.height;
        const chairShape = this.json.opts.chairShape;
        const chairDiameter = this.json.opts.chairDiameter;
        const chairWidth = this.json.opts.chairWidth;
        const chairHeight = this.json.opts.chairHeight;
        const tableColor = this.json.opts.tableColor;
        const chairColor = this.json.opts.chairColor;
        const chairTop = this.json.opts.chairTop;
        const chairBottom = this.json.opts.chairBottom;
        const chairLeft = this.json.opts.chairLeft;
        const chairRight = this.json.opts.chairRight;
        const numberOfChair = chairTop + chairBottom + chairLeft + chairRight;

        const spaceTop = widthX / chairTop;
        const spaceBottom = widthX / chairBottom;
        const spaceLeft = widthY / chairLeft;
        const spaceRight = widthY / chairRight;

        // create table for rectangle table
        const variant = new VariantTableRectangle(widthX, widthY, tableColor);
        const opReferenceTable = variant.create();

        const opSymbolGroup = OpFactory.createSymbol('');
        opSymbolGroup.add(opReferenceTable);

        const opText = opReferenceTable.findAttributeText('$tableNo');
        opSymbolGroup.setAttribute('$tableNo', '?');
        opText.attachToAttribute(opSymbolGroup, '$tableNo');
        opSymbolGroup.setAttribute('$seatAtTableNo', Array(numberOfChair).fill('?'));
        
        // create chair around table 
        const chairVariant = TableShapeUtils.getChairVariant(chairShape, chairDiameter, chairWidth, chairHeight, chairColor);

        let idx = 0;
        for ( let i = 0; i < chairRight; i ++ ) {
            const x =  (widthX * 0.5);
            const y =  -(widthY * 0.5) + (spaceRight * i) + spaceRight / 2;
            const a = (3 * Math.PI) / 2;

            const opReferenceChair = chairVariant.create();
            this.r.makeRotationZ(a);
            this.t.makeTranslation(x, y, 0);
            this.t.multiply(this.r);
            opReferenceChair.setTransform(this.t);
            opSymbolGroup.add(opReferenceChair);

            const opTextChair = opReferenceChair.findAttributeText('$seatNo');
            opTextChair.attachToAttribute(opSymbolGroup, `$seatAtTableNo[${idx}]`)
            idx++;
        }

        for ( let i = chairTop -1; i >= 0; i -- ) {
            const x =  -(widthX * 0.5) + spaceTop *i + spaceTop/2;
            const y =  (widthY * 0.5);
            const a =  Math.PI / 2;

            const opReferenceChair = chairVariant.create();
            this.r.makeRotationZ(a);
            this.t.makeTranslation(x, y, 0);
            // this.t.multiply(this.r);
            opReferenceChair.setTransform(this.t);
            opSymbolGroup.add(opReferenceChair);

            const opTextChair = opReferenceChair.findAttributeText('$seatNo');
            opTextChair.attachToAttribute(opSymbolGroup, `$seatAtTableNo[${idx}]`)
            idx++;
        }

        for ( let i = chairLeft -1; i >= 0 ; i -- ) {
            const x =  -(widthX * 0.5);
            const y =  -(widthY * 0.5) + (spaceLeft * i) + spaceLeft / 2;
            const a = Math.PI / 2;

            const opReferenceChair = chairVariant.create();
            this.r.makeRotationZ(a);
            this.t.makeTranslation(x, y, 0);
            this.t.multiply(this.r);
            opReferenceChair.setTransform(this.t);
            opSymbolGroup.add(opReferenceChair);
            
            const opTextChair = opReferenceChair.findAttributeText('$seatNo');
            opTextChair.attachToAttribute(opSymbolGroup, `$seatAtTableNo[${idx}]`)
            idx++;
        }

        for ( let i = 0; i < chairBottom; i ++ ) {
            const x =  -(widthX * 0.5) + spaceBottom * i + spaceBottom / 2;
            const y =  -(widthY * 0.5);
            const a =  Math.PI;

            const opReferenceChair = chairVariant.create();
            this.r.makeRotationZ(a);
            this.t.makeTranslation(x, y, 0);
            this.t.multiply(this.r);
            opReferenceChair.setTransform(this.t);
            opSymbolGroup.add(opReferenceChair);

            const opTextChair = opReferenceChair.findAttributeText('$seatNo');
            opTextChair.attachToAttribute(opSymbolGroup, `$seatAtTableNo[${idx}]`)
            idx++;
        }

        return opSymbolGroup;
    }
}