import * as THREE from 'three';
import { nextTick } from 'vue'

import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Settings from '@/visual-events/data/Settings';
import CADdySVGExporter from '@/visual-events/loader/CADdySVGExporter'
import Logger from '@/frame/Logger';

const logger = new Logger('ActSaveGroup');

export default class ActSaveGroup extends Action {

    constructor() {
        super();

        this.groupName = String
        this.groupFurniture = String
        this.groupComment = String
        this.groupShape = String

        this.userId = String
        this.AwsConnect = String

    }

    actionStart(){

        this.connectToGUI()
        return true
    }

    connectToAws(){

        // const fileService = new FileService();
        // await fileService.requestUploadUrl(pathModel, 'drawing.svg').then(url => fileService.upload(url, svg, 'image/svg+xml'));
    }

    createSvg(){

        const exporter = new CADdySVGExporter();
        const svg = exporter.write(theApp.model.symbols.getReferencedSymbols(symbol), symbol);
    

    }

    getValues(groupName, groupFurniture, groupComment, groupShape) {
        this.groupName = groupName
        this.groupFurniture = groupFurniture
        this.groupComment = groupComment
        this.groupShape = groupShape
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Funiture');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PanelSaveGroup');
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}