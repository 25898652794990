import JsonPath from '@/frame/JsonPath';
import settings from '@/visual-events/data/DefaultSettings';

export default class Settings /*extends Subject*/ {
    static get (key, forUndefined) {
      if (key.startsWith('.')) key = `globals${key}`;
      const value = JsonPath.getValue(settings, key);
      return typeof value === 'undefined' ? forUndefined : value;
    }
  
    static set (key, value) {
      if (key.startsWith('.')) key = `globals${key}`;
      JsonPath.setValue(settings, key, value);
      //notify
    }

    static merge (path, opts) {
      //globals ggfs. behandeln
      JsonPath.mergeValues(settings, path, opts);
      //notify?
    }

/**
 * convenience methods to work with configuration objects, which are shaped according to
 * an options pool paradigma
 * 
 * settings (opts)
 *  |___ options pool
 *          |___  named options     (provided property 'id')
 *          |__  ...
 * 
 * remark: As enum like type, we prefer snake_case ids, e.g.
 *   grid_number_row, category_price
 * 
 *  Usually the option should also have a property name, which may serve as
 *  friendly name, potentially appearing in the GUI. 
 * 
 *  TODO: Lateron this should be handled as internationalized message. 
 *  TODO: remove Options.js in framework
 * @param {*} pool 
 * @param {*} id 
 * @param {*} key 
 * @param {*} forUndefined 
 * @returns 
 */
    static getOption (pool, id, key, forUndefined) {
      const options = JsonPath.getValue(settings, pool);
      const option = options.find(opt => opt.id === id);
      const value = JsonPath.getValue(option, key);
      return typeof value === 'undefined' ? forUndefined : value;
    }

    static setOption(pool, id, path, value) {
      const options = JsonPath.getValue(settings, pool);
      const option = options.find(opt => opt.id === id);
      JsonPath.setValue(option, path, value);
  }
}