import Logger from '@/frame/Logger';

const logger = new Logger('OpSymbols');

/**
 * the pool of existing OpSymbol objects in the model
 * and support of reusing and cleaning up symbols
 */
export default class OpSymbols {
   constructor () {
      this.map = new Map();
   }

   add (symbol) {
      logger.log(`add ${symbol.symbolId}`);
      this.map.set(symbol.symbolId, symbol);
   }

   remove (symbol) {
      logger.log(`remove ${symbol.symbolId}`);
      this.map.delete(symbol.symbolId);
   }

   get (symbolId) {
      return this.map.get(symbolId);
   }

    /**
     * find an OpSymbol in the pool with the same variant id and defining parameter
     * @param {*} variant 
     * @returns existing opSymbol or null
     */
   findVariant (variant) {
      for (const op of this.map.values()) {
         if (variant.variantOf(op))
            return op;
      }
      return null;
   }

   /**
   * mark all existing symbols
   * 
   * useful to mark all symbols, which exist at a certain point of time, e.g. when an
   * action starts, in order to ensure, that only symbols are removed, which are created
   * since
   */
   markExistingSymbols () {
      logger.log(`markExistingSymbols ${this.map.size}`);
      for (const op of this.map.values())
         op['marked'] = true;
   }

   /**
    * mark all symbols, which are referenced in root
    * @param {*} root 
    */
   markReferencedSymbols(root) {
      root.children.forEach(op => {
          if (op.symbolId) {
            const symbol = this.get(op.symbolId);
            symbol['marked'] = true;
            this.markReferencedSymbols(symbol);
          }
          else
              this.markReferencedSymbols(op);
      });
   }

   getMarkedSymbols () {
        const symbols = [];
    for (const op of this.map.values()) {
        if (op['marked']) 
            symbols.push(op);
    }
    return symbols;
 
   }


   /**
    * remove all symbols, which are not marked as referenced
    */
   removeUnmarkedSymbols () {
      logger.log(`removeUnmarkedSymbols ${this.map.size}`);
      for (const op of this.map.values()) {
         if (!op['marked']) 
            this.remove(op);
      }
      logger.log(`after removeUnmarkedSymbols ${this.map.size}`);
   }

   /**
   * unmark all symbols
   */
   unmarkAllSymbols () {
      logger.log(`unmarkAllSymbols ${this.map.size}`);
      for (const op of this.map.values())
         op['marked'] = false;
   }

   /**
    * build an array of all symbols, which are referenced in root
    * @param {*} root 
    * @returns 
    */
   getReferencedSymbols(root) {
      logger.log(`getReferencedSymbols ${this.map.size}`);
      this.unmarkAllSymbols();
      this.markReferencedSymbols(root);
      const symbols = [];
      for (const op of this.map.values()) {
         if (op['marked']) 
            symbols.push(op);
      }
      return symbols;
   }

}