export default class Model {

  /**
   * override this to perform a model animation step
   * time is the point of time
   * @param {} time in ms
   */
  move (time) {
    // Debug.log('Model::move');

    // if you need the elapsed time since last frame instead of the point of time
    // define this.last and calculate:
    // const elapsed = time - this.last;
    // this.last = time;

    // if you need the total time elapsed since starting
    // if (!this.start)
    //   this.start = time;
    // const totallyElapsed = time - this.start;

    // in order to calculate the frames per second
    // if (!this.lastFrame) {
    //   this.lastFrame = time;
    //   this.cnt = 0;
    // }

    // this.cnt++;
    // if (time - this.lastFrame > 1000) {
    //   const fps = this.cnt;
    //   this.cnt = 0;
    //   this.lastFrame = time;
    //   console.log(`fps ${fps}`);
    // }
    }
}
