import Geometry from '@/visual-events/model/Geometry';
import OpFactory from '@/visual-events/model/OpFactory';
import OpText from '@/visual-events/model/OpText';
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText';
import OpReference from '@/visual-events/model/OpReference';
import { Matrix4 } from 'three';
import { rad2Deg } from '@/frame/Useful'

import Logger from '@/frame/Logger';
import { AlternateMode } from './GridProvider';

const logger = new Logger("RenumberTool");

// TODO: Namen der enums überdenken: COLUMN, ROW... oder ROW, COL, RowMode...ColMode... , ModeRow..., ModeCol... ModeTable..., ebenso die Variablennamen

// column numbering (usually seats, but also block placed tables)
export const ModeAlternate = {
    NORMAL: 'NORMAL',
    OUTER: 'OUTER',
    INNER: 'INNER'
};

export const ModeInRow = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
};

export const ModeRows = {
    PER_ROW: 'PER_ROW',
    FORWARD: 'FORWARD',
    BACKWARD: 'BACKWARD'
};

// row numbering
export const RowModeDirection = {
    FORWARD: 'FORWARD',
    BACKWARD: 'BACKWARD'
}

export const RowModeText = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    BOTH: 'BOTH'
}

export const SeatsAtTableMode = {
    CLOCKWISE: 'CLOCKWISE',
    COUNTER_CLOCKWISE: 'COUNTER_CLOCKWISE'
}

const f = n => Number(n.toFixed(2))
const toFixed2 = mat4 => mat4.elements.map(n => Number(n.toFixed(2)))

/**
 * Tool for matrix number generation
 */
export class RenumberTool {

    constructor() {

        // numbers in rows, chairs only
        this.modeInRow = ModeInRow.LEFT;
        this.modeAlternate = ModeAlternate.NORMAL;
        this.modeRows = ModeRows.PER_ROW;
        this.startNumber = 1;
        this.displaySeatNo = false;

        // numbers in rows, tables only
        this.tableModeInRow = ModeInRow.LEFT;
        this.tableModeAlternate = ModeAlternate.NORMAL;
        this.tableModeRows = ModeRows.PER_ROW;
        this.tableStartNumber = 1;
        this.displayTableNo = false;

        // row numbers
        this.rowModeDirection = RowModeDirection.FORWARD;
        this.rowModeText = RowModeText.NO_LEFT;
        this.rowStartNumber = 1;
        this.rowTextSize = 0;
        this.rowTextDist = 0;
        this.displayRowNo = false;
        
        // seat numbers at tables
        this.seatsAtTableMode = SeatsAtTableMode.CLOCKWISE; 
        this.seatsAtTableStartNumber = 1;
        this.displaySeatNoAtTable = false;

        this.attributeSeatNo = '$seatNo';
        this.attributeTableNo = '$tableNo';
        this.attributeRowNo = '$rowNo';
        this.attributeSeatAtTableNo = '$seatAtTableNo';

        // working matrix
        this.t = new Matrix4();

        // box of the prototype symbol
        this.box = undefined;
    }

    /**
     * adapt the renumbering in the block according to the current settings
     * @param {*} grid 
     * @param {*} block 
     */
    applyOnBlock (grid, block) {
        const cntRows = block.children.length;

        let cntAll = 0;
        for (let row = 0; row < cntRows; row++) {
            const group = block.children[row];
            const symbols = group.children.filter(op => op instanceof OpReference);
            cntAll += symbols.length;
        }

        // determine and set row numbers
        for (let row = 0; row < cntRows; row++) {
            const rowNo = this.determineRowNumber(row, cntRows, this.rowModeDirection, this.rowStartNumber);
            const group = block.children[row];
            group.setAttribute(this.attributeRowNo, rowNo.toString());
   
            this.adaptRowNoTexts(row, group, grid);
        }

        // determine and set numbers in rows, seat resp. table number
        let cntBeforeRow = 0;
        for (let row = 0; row < cntRows; row++) {

            const group = block.children[row];
            const symbols = group.children.filter(op => op instanceof OpReference);
            const cntInRow = symbols.length;
            for (let col = 0; col < cntInRow; col++) {  
                const op = symbols[col];
                if (op.getAttribute(this.attributeSeatNo)) {
                    const itemNo = this.determineNumberInRow(col, cntAll, cntBeforeRow, cntInRow, this.modeAlternate, this.modeInRow, this.modeRows, this.startNumber);
                    op.setAttribute(this.attributeSeatNo, itemNo.toString());
                    op.setAttributeTextVisibility(this.attributeSeatNo, this.displaySeatNo)
                }
                else if (op.getAttribute(this.attributeTableNo)) {
                    const itemNo = this.determineNumberInRow(col, cntAll, cntBeforeRow, cntInRow, this.tableModeAlternate, this.tableModeInRow, this.tableModeRows, this.tableStartNumber);
                    op.setAttribute(this.attributeTableNo, itemNo.toString());
                    op.setAttributeTextVisibility(this.attributeTableNo, this.displayTableNo)
                }
            }
            cntBeforeRow += cntInRow;
        }

        //determine and set seat numbers of seats at tables
        for (let row = 0; row < cntRows; row++) {

            const group = block.children[row];
            const symbols = group.children.filter(op => op instanceof OpReference);
            const cntInRow = symbols.length;
            for (let col = 0; col < cntInRow; col++) {
                const op = symbols[col];
                const symbol = OpFactory.findSymbol(op.symbolId);
                const chairs = symbol.children.filter(child => child instanceof OpReference && child.getAttribute("$seatNo") != undefined );
                const cntSeatsAtTable = chairs.length;

                const attributeSeatAtTableNo = [];

                for (let idx = 0; idx <cntSeatsAtTable; idx++) {
                    const seatNo = this.determineNumberAtTable(idx, cntSeatsAtTable, this.seatsAtTableMode, this.startNumber);
                    attributeSeatAtTableNo.push(seatNo.toString());
                    op.setAttributeTextVisibility(`${this.attributeSeatAtTableNo}[${idx}]`, this.displaySeatNoAtTable)
                }

                op.setAttribute(this.attributeSeatAtTableNo, attributeSeatAtTableNo);
            }
        }
    }

    /**
     * adapt the OpText objects in rows and their placing transforms
     * @param {*} row 
     * @param {*} group 
     * @param {*} grid 
     */
    /*private*/ adaptRowNoTexts (row, group, grid) {

        const fontSize = grid.widthY;
        let textLeft;
        let textRight;

        if (this.displayRowNo) {
            switch (this.rowModeText) {
                case RowModeText.LEFT: {
                    const texts = this.adaptTexts(group, 1, fontSize);
                    textLeft = texts[0];
                    break;
                }
                case RowModeText.RIGHT: {
                    const texts = this.adaptTexts(group, 1, fontSize);
                    textRight = texts[0];
                    break;
                }
                case RowModeText.BOTH: {
                    const texts = this.adaptTexts(group, 2, fontSize);
                    textLeft = texts[0];
                    textRight = texts[1];
                    break;
                }
            }
        } else {
            this.adaptTexts(group, 0, fontSize);
        }

        if (textLeft) {
            this.prepareTransform(grid, row, true, this.rowTextDist, this.box);
            textLeft.textAnchor = TextAnchor.end;
            textLeft.baseLine = BaseLine.middle;
            textLeft.fontSize = this.rowTextSize;
            textLeft.setTransform(this.t);
        }
        
        if (textRight) {
            this.prepareTransform(grid, row, false, this.rowTextDist, this.box);
            textRight.textAnchor = TextAnchor.start;
            textRight.baseLine = BaseLine.middle;
            textRight.fontSize = this.rowTextSize;
            textRight.setTransform(this.t);
        }
    }

    /**
     * calculate the position and angle of the text.
     * 
     * It is to be vertically centered in the middle line through the symbol boxes.
     *                 
     * 2- |_| - ..... - |_| -2
     * 
     * The calculation takes into account the relative position of the symbol's reference point 
     * with respect to its box, e.g. the natural reference points of a circle symbol is the center
     * but in a rectangle it might be the left bottom corner.
     * 
     * @param {*} grid     the GridProvider
     * @param {*} row      
     * @param {*} left     (true) oder right (false)
     * @param {*} dist     distance of the text from the row's end 
     * @param {*} box      box of the prototype symbol
     */
    prepareTransform (grid, row, left, dist, box) {
        let [x, y] = left ? this.getRowFirstCoord(grid, row) : this.getRowLastCoord(grid, row); 
        const [u, v] = left ? this.getRowFirstVector(grid, row) : this.getRowLastVector(grid, row); 
        const a = Math.atan2(v,u);

        //logger.log(`i,j = ${i},${j} x=${f(x)} y=${f(y)} u=${f(u)} v=${f(v)} a=${rad2Deg(a)}`);

        // calculate the center of the box at i,j
        let cx = 0;
        let cy = 0;
        if (box) {
            cx = (box.max.x + box.min.x) / 2.0; 
            cy = (box.max.y + box.min.y) / 2.0; 
            x +=  u * cx - v * cy;
            y +=  v * cx + u * cy;
        }

        //logger.log(`cx=${cx} cy=${cy} x=${f(x)} y=${f(y)}`);

        // the distance from the left resp. right edge of the box
        if (left)
            dist = - (dist + (cx - box.min.x));
        else 
            dist = dist + (box.max.x - cx);
        
        x += u * dist;
        y += v * dist;

        //logger.log(`    dist=${dist} x=${f(x)} y=${f(y)} a=${rad2Deg(a)}`);

        Geometry.makePlacingTransform(this.t, x, y, 0, rad2Deg(a));
        logger.log(`    ${toFixed2(this.t)}`);
    }

    /**
     * position and direction of the left most and right most chair resp. table
     * in the block, i.e. especially under consideration of alternate mode
     * @param {*} grid 
     * @param {*} row 
     * @returns 
     */
    getRowFirstCoord(grid, row) {
        return grid.F(this.leftExcess(grid), row * grid.getOffsetY());
    }

    getRowLastCoord(grid, row) {
        return grid.F((grid.getCntX() - 1) * grid.getOffsetX() + this.rightExcess(grid), row * grid.getOffsetY());
    }

    getRowFirstVector(grid, row) {
        return grid.dFdu(this.leftExcess(grid), row * grid.getOffsetY());
    }

    getRowLastVector(grid, row) {
        return grid.dFdu((grid.getCntX() -1) * grid.getOffsetX() + this.rightExcess(grid), row * grid.getOffsetY());
    }

    /**
     * on alternate mode the chairs in every second row go beyond the normal boundaries of the block
     * either to the left or to the right. This must be considered for placing the row numbers.
     * @returns excess distance
     */
    leftExcess(grid) {
        return (grid.getCntX() > 1 && grid.alternateMode === AlternateMode.LEFT) 
                ? - grid.shift * grid.getOffsetX() 
                : 0;
    } 
    rightExcess(grid) {
        return (grid.getCntX() > 1 && (grid.alternateMode === AlternateMode.RIGHT || grid.alternateMode === AlternateMode.SHIFT_TRIANGLE)) 
                ? grid.shift * grid.getOffsetX() 
                : 0;
    } 

    /**
     *  
     * @param {*} grid 
     * @param {*} i 
     * @param {*} j 
     */

    adaptTexts(group, newCnt, fontSize) {

        const texts = group.children.filter(op => op instanceof OpText);
        const cnt = texts.length;
    
        if (newCnt > cnt) {
            for (let i = cnt; i < newCnt; i++) {
                const op = OpFactory.createText(`?`, fontSize);
                op.pickable = false;
                op.attachToAttribute(group, '$rowNo');
                texts.push(op);
                group.add(op);
            }
        } else if (newCnt < cnt) {
            for (let i = cnt; i > newCnt; i--) {
                const op = texts.pop();
                op.removeFromParent();
            }
        }

        return texts;
    }

    determineRowNumber(row, cntRows, modeDirection, startNumber) {
        switch (modeDirection)
        {
            case ModeRows.FORWARD:
            default:
                return startNumber + row;
            case ModeRows.BACKWARD:
                return startNumber + cntRows - row - 1;
        }
    }

    determineNumberInRow(col, cntAll, cntBeforeRow, cntColsInRow, modeAlternate, modeInRow, modeRows, startNumber) {
        let start = startNumber;

        switch (modeRows)
        {
            case ModeRows.PER_ROW:
                start = startNumber;
                break;
            case ModeRows.FORWARD:
                start = startNumber + cntBeforeRow;
                break;
            case ModeRows.BACKWARD:
                start = startNumber + cntAll - (cntBeforeRow + cntColsInRow);
                break;
        }

        let idx = 0;
        switch (modeInRow)
        {
            case ModeInRow.LEFT:
                idx = col;
                break;
            case ModeInRow.RIGHT:
                idx = cntColsInRow - 1 - col;
                break;
        }

        let noInRow = 0;

        switch (modeAlternate)
        {
            case ModeAlternate.NORMAL:
                noInRow = idx;
                break;
            case ModeAlternate.INNER:
                if (idx < parseInt((cntColsInRow + 1) / 2))
                    noInRow = 2 * ((cntColsInRow + 1) / 2 - idx - 1);
                else
                    noInRow = 2 * (idx - (cntColsInRow + 1) / 2) + 1;
                break;
            case ModeAlternate.OUTER:
                if (idx < parseInt((cntColsInRow + 1) / 2))
                    noInRow = 2 * idx;
                else
                    noInRow = 2 * (cntColsInRow - idx) - 1;
                break;
        }

        return noInRow + start;
    }

    determineNumberAtTable(idx, cntSeatsAtTable, seatsAtTableMode, startNumber) {

        let seatNo = 0;
        switch (seatsAtTableMode)
        {
            case SeatsAtTableMode.CLOCKWISE:
                seatNo = idx;
                break;
            case SeatsAtTableMode.COUNTER_CLOCKWISE:
                seatNo = cntSeatsAtTable - 1 - idx;
                break;
        }

        return seatNo + startNumber;
    }

}