import OpObject from '@/visual-events/model/OpObject'
import { CADdyType } from '@/visual-events/loader/CADdyType'
import OpText from '@/visual-events/model/OpText'

export default class OpReference extends OpObject {

    constructor (name, symbolId, transform) {
        super(CADdyType.SYMBOL_REFERENCE, name);
        this.symbolId = symbolId;
        if (transform)
            this.transform.copy(transform);
    }

    copy () {

        const copy = new OpReference(this.name, this.symbolId, this.transform.clone());

        copy.visible = this.visible;
        copy.pickable = this.pickable;

        copy.copyAttributeTexts(this, false);

        return copy;
    }

    copyAttributeTexts(from, doTransform) {
        for (const op of from.observers) {
            if (op instanceof OpText &&
                op.inSubtree(from) &&
                op.xref) {
                    const xref = op.xref;
                    const token = xref.split('/');
                    let jsonPath = token.length > 1 ? token[1] : token[0];
                    this.setAttribute(jsonPath, from.getAttribute(jsonPath));
                    const copyText = op.copy();
                    copyText.xref = `${this.id}/${jsonPath}`;
                    copyText.pickable = false;
                    if (doTransform)
                        copyText.applyTransform(this.transform);
                    this.add(copyText);
                    this.subscribe(copyText);
                }
        }
    }

    removeAttachedAttributes () {
        this.children.forEach(op => {
            this.unsubscribe(op);
            op.removeFromParent();
        });
    }

    /**
     * find the attribute text, which displays the content of attribute
     * @param {*} op 
     * @param {*} attribute 
     * @returns 
     */
    findAttributeText(attribute) {
        return this.children.find(child => child instanceof OpText && child.xref?.includes(attribute));
    } 

    /**
     * set the visibility of the attribute text
     * @param {*} op 
     * @param {*} attribute 
     * @param {*} visible 
     */
    setAttributeTextVisibility (attribute, visible) {
        const opText = this.findAttributeText(attribute);
        if (opText)
            opText.visible = visible;
    }
}
