<template>
    <div class="panel-furniture">
        <div class="d-flex flex-column h-100 w-100" style="overflow: auto;">
            <div class="h-100">
                <div class="sticky-top">

                    <div class="ve-panel-header">
                        Mobiliar
                    </div>
                    <div class="col-sm-12">
                        <ul class="nav nav-tabs nav-fill" id="panel-draw-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button :class="['nav-link', { active: tab === 'chairVariant' }]" id="draw-chairVariant" data-bs-toggle="tab" data-bs-target="#chairVariant" type="button" role="tab" @click="onExecute('.Ticketing.drawChairBanquet')"><!--<i class="fa-solid fa-chair"></i>--><SVGIcon :iconName="'icon-chair'" :size="16"/></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button :class="['nav-link', { active: tab === 'roundTable' }]" id="draw-roundTable" data-bs-toggle="tab" data-bs-target="#roundTable" type="button" role="tab" @click="onExecute('.Ticketing.drawTableCircle')"><SVGIcon :iconName="'icon-tisch-rund-6'" :size="16"/></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button :class="['nav-link', { active: tab === 'rectangleTable' }]" id="draw-rectangleTable" data-bs-toggle="tab" data-bs-target="#rectangleTable" type="button" role="tab" @click="onExecute('.Ticketing.drawTableRectangle')"><SVGIcon :iconName="'icon-tisch-eckig-6'" :size="16"/></button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="tab === 'chairVariant'">
                    <div class="ve-row px-2">
                        <PanelChair />
                    </div>
                </div>
                <div v-else-if="tab === 'roundTable'">
                    <div class="ve-row pt-3 px-2">
                        <div class="prop-sub-heading"></div>
                        <PanelTableCircle />
                        <PanelAllChair />
                        <PanelTableCircleSetting />
                    </div>
                </div>
                <div v-else-if="tab === 'rectangleTable'">
                    <div class="ve-row pt-3 px-2">
                        <div class="prop-sub-heading"></div>
                        <PanelTableRectangle />
                        <PanelAllChair />
                        <PanelTableRectangleSetting />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import SVGIcon from '@/vuejs/components/SVGIcon.vue';
import PanelChair from '@/vuejs/components/PanelChair.vue';
import PanelTableCircle from '@/vuejs/components/PanelTableCircle.vue';
import PanelTableCircleSetting from '@/vuejs/components/PanelTableCircleSetting.vue';
import PanelAllChair from '@/vuejs/components/PanelAllChair.vue';
import PanelTableRectangle from '@/vuejs/components/PanelTableRectangle.vue';
import PanelTableRectangleSetting from '@/vuejs/components/PanelTableRectangleSetting.vue';
import Settings from '@/visual-events/data/Settings';

export default {
    name: 'PanelFurniture',
    components: {
        SVGIcon,
        PanelChair,
        PanelTableCircle,
        PanelTableCircleSetting,
        PanelAllChair,
        PanelTableRectangle,
        PanelTableRectangleSetting,
    },
    data() {
        return {
            rasterEnabled: Settings.get('raster.enabled'),
            rasterSize: Settings.get('raster.size'),
            tab: '',
        }
    },
    mounted() {
        theApp.addDialog('PanelFurniture', this);
    },
    updated() {
        theApp.addDialog('PanelFurniture', this)
    },
    unmounted() {
        theApp.removeDialog('PanelFurniture')
    },
    methods: {
        setTab(tab) {
            this.tab = tab;
        },
        onExecute(command) {
            theApp.executeCommand(`${command}`)
        },
    }
}
</script>

<style scoped>.panel-furniture {
    height: 100%;
    width: 100%;
}</style>


