/**
 * settings is the global json-Object, which contains all configurations and settings
 * 
 * They are coming from different sources in the following preference order
 * - default settings are here
 * - read from the user's settings file 
 * - options handed over from an embedding system
 * - query parameters in the app url (only for a subset possible)
 */
import {DoorType} from '@/visual-events/actions/VariantDoor';
import {WindowType} from '@/visual-events/actions/VariantWindow';

export default {
    globals: {
      language: 'de',
      logging: false,

      //authentication
      apiKey: undefined,
      userPool: import.meta.env.VITE_USERPOOL,
      appClient: import.meta.env.VITE_APP_CLIENT,
      userToken: undefined,
      
      // urls for required services, REST APIs, authentication
      urlFileService: import.meta.env.VITE_URL_FILESERVICE,  
      urlInventory: import.meta.env.VITE_URL_INVENTORY,     
      urlTicketing: import.meta.env.VITE_URL_TICKETING,
      urlAssets: import.meta.env.VITE_URL_ASSETS,

      urlSignallingServer: import.meta.env.VITE_SIGNALLING_SERVER,

      // pathes in the FileService for resources
      pathUser: '$tenant/$user',
      pathSettings: '$tenant/$user', //settings.json
      pathFavorites: '$user/Favorites',
      favorites: 'MyFavorites',
      model: undefined
    },

    //evtl. nicht in default, sondern gezielt in AddIn hinzugefügt
//     booking: {
//       readOnly: false,
//       timerMax:
// ..
//     },
//     ticketing: {
//       bookingModes

//     }
//     planer: {

//     },

    // GUI configuration
    layout: {
      split2D3Dvertically: false
    },
    nav : {
      buttons: { 
        'Select':     { name: 'Warten',           icon: { type: 'fa', class: 'fa-solid fa-arrow-pointer'},  command: '.Ticketing.waitForUserInput'}, 
        'Favorites':  { name: 'Favoriten',        icon: { type: 'fa', class: 'fa-solid fa-star'},           command: '.Ticketing.favorites' }, 
        'Furniture':  { name: 'Mobiliar',         icon: { type: 'fa', class: 'fa-solid fa-chair'},          command: '.Ticketing.drawChairBanquet'}, 
        'Seating':    { name: 'Bestuhlung',       icon: { type: 'fa', class: 'fa fa-th'},                   command: '.Ticketing.placeBlock'}, 
        'Draw':       { name: 'Zeichnen',         icon: { type: 'fa', class: 'fa-solid fa-pen'},            command: '.Ticketing.openDrawPanel'}, 
        'Attributes': { name: 'Eigenschaften',    icon: { type: 'fa', class: 'fa-solid fa-table-list'},     command: '.Ticketing.editAttributes'}, 
        'Settings':   { name: 'Einstellungen',    icon: { type: 'fa', class: 'fa-solid fa-gear'},           command: '.Ticketing.settings'}, 
        'Inventory':  { name: 'Inventar',         icon: { type: 'svg', name: 'icon-chair'},                 command: '.Ticketing.inventory'},
        'Floorplan':  { name: 'Grundrissplaner',  icon: { type: 'svg', name: 'icon-floor'},                 command: '.floorplan.openDialog'},
        'Save':       { name: 'Speichern',        icon: { type: 'fa', class: 'fa-regular fa-floppy-disk'},  command: '.io.saveAll'}
    },
      startButton: '',
    },
    draw : {
      buttons: {
        'Rectangle':     { name: 'Rechteck',     icon: 'fa-sharp fa-regular fa-square',  command: '.Ticketing.drawRectangle'}, 
        'Circle':        { name: 'Kreis',        icon: 'fa-sharp fa-regular fa-circle',  command: '.Ticketing.drawCircle' }, 
        'Polygon':       { name: 'Polygon',      icon: 'fa-solid fa-draw-polygon',       command: '.Ticketing.drawPolygon' }, 
        'Text':          { name: 'Text',         icon: 'fa-sharp fa-solid fa-font',      command: '.Ticketing.drawText' }, 
      },
      startButton: ''
    },
    views: [
      {
        id: '2D Ansicht',
        display: true,
        iconbar: {
          buttons: {
            'FitToCanvas':  { name: 'Ansicht zurücksetzen',   icon: 'fa-solid fa-arrows-up-down-left-right',  hidden: false, style: 'transform: rotate(45deg);', command: '.view.fitToCanvas "{{viewName}}"' },
            'ZoomOut':      { name: 'Zoom -',                 icon: 'fa-solid fa-minus',                      hidden: false, style: '',                          command: '.view.zoom "{{viewName}}" {{zoomOut}}' },
            'ZoomIn':       { name: 'Zoom +',                 icon: 'fa-solid fa-plus',                       hidden: false, style: '',                          command: '.view.zoom "{{viewName}}" {{zoomIn}}' },
          }, 
          zoomMin: 1,
          zoomMax: 50,
          zoomToFit: 1
        }
      }
    ],
    // defaults or most recent settings used in actions
    inventory: {
      filterConditions: []
    },
    grid: {
      mode: 'SINGLE',
      cntX : 4,
      cntY : 3,
      distX : 0.0,
      triangleDistX : 500.0,
      distY : 500.0,
      angleX :  0.0,
      angleY : 90.0,
      alternate : false,
      keepRatioXY : false
    },
    numeration: {
      modeInRow: 'LEFT',
      modeAlternate: 'NORMAL',
      modeRows: 'PER_ROW',
      startNumber: 1,
      tableModeInRow: 'LEFT',
      tableModeAlternate: 'NORMAL',
      tableModeRows: 'PER_ROW',
      tableStartNumber: 1,
      displaySeatNo: false,
      displayTableNo: false,
      rowModeDirection: 'FORWARD',
      rowModeText: 'LEFT',
      rowStartNumber: 1,
      rowTextSize: 300.0,
      rowTextDist: 250.0,
      displayRowNo: false,
      seatsAtTableStartNumber: 1,
      seatsAtTableMode: 'CLOCKWISE',
      displaySeatNoAtTable: false
    },
    raster: {
      enabled: true,
      size: 100
    },
    selection: {
      grafic: {
        margin: 20, // TODO: preliminary margin for selection box, must be camera independent
        geoPointDiameter: 500, 
        fill: '#FFFFFE',    // not #FFFFFF and opacity 0.0
        fillOpacity: 0.01,  // with complete white and transparent picking fails!
        stroke: '#009aa3',
        iconDiameter: 400,
        iconDist: 300,
      }
    },
    style: {
      useStroke: false,
      stroke: '#000000',
      strokeWidth: 1,
      useFill: true,
      fill: '#BBBBBB'
    },
    circle: {
      radius: 500
    },
    rectangle: {
      angle: 0,
      width: 500,
      height: 500
    },
    polygon: {
      closed: true
    },
    text: {
      font: 'arial',
      fontSize: 300,
      text:  '',
      angle: 0,
      useStroke: false,
      stroke: '#000000',
      strokeWidth: 1,
      useFill: true,
      fill: '#000000'
    },
    furniture: {
      chairColor: '#FAC98B',
      chairShape: 'SHAPE_RECTANGLE',
      chairDiameter: 500,
      chairWidthX: 500,
      chairWidthY: 500,
      tableWidthX: 2000,
      tableWidthY: 1000,
      tableDiameter: 1800,
      tableColor: '#FAC98B',
      numberOfChair: 6,
      freeSpaces: 0,
      chairTop: 2,
      chairBottom: 2,
      chairLeft: 0,
      chairRight: 0
    },
    floorplan: {
        enabled: true,
        rectangularWalls: {
            widthX: 0,
            widthY: 0,
        },
        wall: {
            thickness: 240,
            useStroke: false,
            stroke: '#000000',
            strokeWidth: 0,
            useFill: true,
            fill: '#000000'
        },
        door: {
            color: '#FFFFFF',
            id: DoorType.ONE_WING,
            frame: 70,
            sash: 80,
            rails: 20,
            strokeWidth: 0.25,
            variants: [
              {
                id: DoorType.ONE_WING,
                description: 'one wing',
                width: 980,
                pivot: 'left'
              },
              {
                id: DoorType.TWO_WING,
                description: 'two wing',
                width: 1990
              },
              {
                id: DoorType.SWING,
                description: 'swing door',
                width: 1990
              }
            ]
        },
        window: {
            color: '#FFFFFF',
            id: WindowType.ONE_CASEMENT,
            jamb: 70,
            sash: 80,
            rails: 20,
            mullion: 50,
            strokeWidth: 0.25,
            variants: [
              {
                id: WindowType.ONE_CASEMENT,
                description: 'one casement window',
                width: 1180,
                casement: 1
              },
              {
                id: WindowType.TWO_CASEMENT,
                description: 'two casement window',
                width: 1380,
                casement: 2
              },
              {
                id: WindowType.THREE_CASEMENT,
                description: 'three casement window',
                width: 2080,
                casement: 3
              },
              {
                id: WindowType.FOUR_CASEMENT,
                description: 'four casement window',
                width: 2690,
                casement: 4
              }
            ]
      }
    }
};
  