<template>
    <div class="panel-rectangle border-bottom p-3">
        <div class="ve-row">
            <div Class="ve-FloorPlanSVG pe-auto p-3 mb-5 bg-body-tertiary" style="width: 64px; height: 64px;"
                @click=onRectangularWalls>
                <svg width="72" height="72" viewBox="0 0 72 72" :fill=bg1 xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_230_275)">
                        <rect x="4" width="64" height="64" rx="5" fill="white" />
                        <rect x="4.5" y="0.5" width="63" height="63" rx="4.5" stroke="#D9D9D9" />
                    </g>
                    <rect x="15.6665" y="16.238" width="40.6667"  :fill=bg1 height="30" :stroke=icon1 stroke-width="2" />
                    <circle cx="56" cy="17" r="3" fill="#D9D9D9" />
                    <circle cx="16" cy="46" r="3" fill="#D9D9D9" />
                    <defs>
                        <filter id="filter0_d_230_275" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_230_275" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_230_275" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div Class="ve-FloorPlanSVG pe-auto p-3 mb-5 bg-body-tertiary" style="width: 64px; height: 64px;"
                @click=onPolygonWalls>
                <svg width="72" height="72" viewBox="0 0 72 72" :fill=bg2 xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_230_275)">
                        <rect x="4" width="64" height="64" rx="5" fill="white" />
                        <rect x="4.5" y="0.5" width="63" height="63" rx="4.5" stroke="#D9D9D9" />
                    </g>
                    <rect x="15.6665" y="16.238" width="40.6667"  :fill=bg2 height="30" :stroke=icon2 stroke-width="2" />
                    <circle cx="56" cy="17" r="3" fill="#D9D9D9" />
                    <circle cx="16" cy="46" r="3" fill="#D9D9D9" />
                    <defs>
                        <filter id="filter0_d_230_275" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_230_275" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_230_275" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
        <div :hidden=hideSize id="wallParams">
            <fieldset class="ve-row">
                <InputNumberWithUnit title=" " class="col-6" label="Länge" unit="cm" :scale="10" :min=1 :digits="1"
                    v-model="widthX" @update:modelValue="onChangeWidthX" />
                <InputNumberWithUnit title=" " class="col-6" label="Breite" unit="cm" :scale="10" :min=1 :digits="1"
                    v-model="widthY" @update:modelValue="onChangeWidthY" />
            </fieldset>
        </div>
        <fieldset class="ve-row">
            <InputNumberWithUnit title=" " class="col-6" label="Wandstärke" unit="cm" :scale="10" :min=1 :digits="1"
                v-model="thickness" @update:modelValue="onChangeThickness" />
            <!-- <InputButtonColor class="col-4 mt-2" label="Farbe" v-model="fill" @update:modelValue="onChangeFill" /> -->
        </fieldset>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue'
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue'
import ActPolygonWall from '@/visual-events/actions/ActPolygonWall';

export default {
    name: 'PanelWall',
    components: {
        InputNumberWithUnit,
        InputButtonColor
    },
    data() {
        return {
            widthY: Settings.get('floorplan.rectangularWalls.widthX', 0),
            widthX: Settings.get('floorplan.rectangularWalls.widthY',0),
            thickness: Settings.get('floorplan.wall.thickness', 240),
            fill: Settings.get('floorplan.wall.fill', '#000000'),
            hideSize: true,
            icon1: "black",
            icon2: "black",
            bg1: "white",
            bg2: "white"
        }
    },
    mounted() {
        theApp.addDialog('PanelWall', this)
    },
    unmounted() {
        theApp.removeDialog('PanelWall')
    },
    methods: {
        update(action) {
            this.thickness = action.thickness
            this.widthY = action.widthY
            this.widthX = action.widthX
            this.fill = action.fill
            this.state = action.state

            // do not display size input field until user starts dragging
            this.hideSize = this.state === 0 || (action instanceof ActPolygonWall) // state 0 is 'FIRST_POINT', waiting that the user starts dragging a rectangle
        },
        onChangeWidthY() {
            theApp.executeValueEvent("widthY", this.widthY)
            //Settings.set('floorplan.rectangularWalls.widthY', this.widthY)
        },
        onChangeWidthX() {
            theApp.executeValueEvent("widthX", this.widthX)
            // Settings.set('floorplan.rectangularWalls.widthX', this.widthX)
        },
        onChangeThickness() {
            theApp.executeValueEvent("thickness", this.thickness)
            Settings.set('floorplan.wall.thickness', this.thickness)
        },
        onChangeFill() {
            theApp.executeValueEvent("fill", this.fill)
            Settings.set('floorplan.wall.fill', this.fill) 
        },
        onRectangularWalls() {
            theApp.executeCommand('.floorplan.rectangularWall')
        },
        onPolygonWalls() {
            theApp.executeCommand('.floorplan.polygonWall');
        },
        setActiveButton(casement) {
            switch (casement) {
                case 1: {
                    this.icon1 = '#009aa3';
                    this.icon2 = 'black';
                    this.bg1 = '#f5f5f5';
                    this.bg2 = 'white';
                    break;
                }
                case 2: {                    
                    this.icon1 = 'black';
                    this.icon2 = '#009aa3';
                    this.bg1 = 'white';
                    this.bg2 = '#f5f5f5';
                    break;
                }
            }

            
        },
        setDeactiveButton () {
            this.icon1 = 'black';
            this.icon2 = 'black';
            this.bg1 = 'white';
            this.bg2 = 'white';
        }

    },
    
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/ve-variables.scss';

.ve-iconbar-wall-icon {
    @if $iconbar-draw-button-show-text ==false {
        font-size: 16px;
    }
}

SVG {
    cursor: pointer;
}
</style>