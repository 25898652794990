<template>
    <div  v-show="!editMode" class="panel-style p-3">
        <fieldset class="ve-row">
          <div>Klicken Sie auf eine beliebige Stelle in der 2D-Planung, um mit der Eingabe zu beginnen</div>
        </fieldset>
    </div>
    <PanelText v-show="editMode" />
    <div class="panel-style p-3">
        <fieldset class="ve-row">
            <div>
              <InputNumberWithUnit  class="col-6" :min=1 :max=1000 label="Textgröße"  unit="cm" :scale="10" v-model="fontSize" @update:modelValue="onChangeFontSize" />
              <InputButtonColor class="col-6 mt-2" label="Textfarbe" id="text-color" v-model="fill"  @update:modelValue="onChangeFill" />
            </div>
        </fieldset>
    </div>
    <!-- <PanelStyle/> -->
</template>

<script>
import theApp from '@/frame/Application';
import PanelText from '@/vuejs/components/PanelText.vue';
//import PanelStyle from '@/vuejs/components/PanelStyle.vue';
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue';
import Settings from '@/visual-events/data/Settings';
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';

export default {
  name: 'PanelDrawText',
  components: {
    PanelText,
    InputButtonColor,
    InputNumberWithUnit,
    //PanelStyle,
  },
  data () {
    return {
        editMode: false,
        fill : '#000000',
        fontSize: 1000,
    }
  },
  mounted () {
    theApp.addDialog('PanelDrawText', this)
  },
  unmounted () {
    theApp.removeDialog('PanelDrawText')
  },
  methods: {
    update(action) {
      this.fontSize = action.fontSize
      this.fill = action.fill === 'none' ? this.fill : action.fill
    },
    onChangeFontSize () {
      theApp.executeValueEvent('fontSize',  this.fontSize)
      Settings.set('text.fontSize', this.fontSize)
    },
    onChangeFill () {
      theApp.executeValueEvent("fill", this.fill)
      Settings.set('text.fill', this.fill)
    }
  }
}
</script>

<style scoped>
</style>
