<template>
    <div class="input-group mt-2" style="width: auto;">
        <span class="input-group-text bg-white ve-search-icon" style="border-right: 0;"><i class="fa-solid fa-magnifying-glass"></i></span>
        <input type="text" :placeholder="this.placeholder" class="form-control ve-search-bar" :id="this.$.uid" :value="modelValue" @input="onInput" spellcheck="false">
        <button class="btn btn-outline-blank text-black" type="button" @click="clearModelValue()">X</button>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'InputText',
    props: {
      placeholder: String,
      modelValue: String
    },
    data () {
        return {
            typingTimer: undefined,
            interval: 1000,
            value: ''
        }
    },
    methods: {
        onInput (event) {
            event.preventDefault();
            event.stopPropagation();
            this.value = event.target.value;
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.doneTyping, this.interval);
        },
        doneTyping () {
            this.$emit('update:modelValue', this.value);
        },
        clearModelValue() {
            this.value = '';
            this.$emit('update:modelValue', this.value);
        }
    },
    emits: ['update:modelValue']
  }
  
  </script>
  
  <style>
  .ve-search-icon {
    border-right: 0 !important;
  }
  .ve-search-bar {
    border-left: 0 !important;
  }
  .ve-search-bar:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  </style>