import FileService from '@/visual-events/RestAPI/FileService';
import JsonPath from '@/frame/JsonPath';
import settings from '@/visual-events/data/DefaultSettings';
import Logger from '@/frame/Logger';

const logger = new Logger('UtilSettings');

export default class UtilSettings {

    static async read(pathSettings) {
        logger.log(`read(${pathSettings})`);

        const fileService = new FileService();
        const urlSettings = fileService.getDownloadUrl(pathSettings, `settings.json`);

        try {
            const json = await fileService.download(urlSettings);

            if (json) {
                //merge into settings
                JsonPath.mergeValues(settings, 'layout',  JsonPath.getValue(json, 'layout'));

                JsonPath.mergeValues(settings, 'grid',  JsonPath.getValue(json, 'grid'));
                JsonPath.mergeValues(settings, 'numeration',  JsonPath.getValue(json, 'numeration'));

                JsonPath.mergeValues(settings, 'raster',  JsonPath.getValue(json, 'raster'));

                JsonPath.mergeValues(settings, 'style',  JsonPath.getValue(json, 'style'));
                JsonPath.mergeValues(settings, 'circle',  JsonPath.getValue(json, 'circle'));
                JsonPath.mergeValues(settings, 'rectangle',  JsonPath.getValue(json, 'rectangle'));
                JsonPath.mergeValues(settings, 'polygon',  JsonPath.getValue(json, 'polygon'));
                JsonPath.mergeValues(settings, 'text',  JsonPath.getValue(json, 'text'));

                JsonPath.mergeValues(settings, 'inventory',  JsonPath.getValue(json, 'inventory'));
                JsonPath.mergeValues(settings, 'furniture',  JsonPath.getValue(json, 'furniture'));
                JsonPath.mergeValues(settings, 'floorplan',  JsonPath.getValue(json, 'floorplan'));
            }
        } catch (err) {
            console.log(`could not read ${pathSettings}/settings.json`);
        }
    }

    static async write(pathSettings) {
        logger.log(`write(${pathSettings}/settings.json)`);
        const content = JSON.stringify(settings);
        const fileService = new FileService();
        fileService.requestUploadUrl(pathSettings, 'settings.json').then(url => fileService.upload(url, content, 'application/json'));
    }
}