<template>
 <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g==" crossorigin="anonymous" referrerpolicy="no-referrer" />
 <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
    <div class="camera-controls">
      <span 
          v-for="(value, key) in buttons"
          :key="key"
          :class="['camera-controls-button']"
          @click="onClickButton(key)"
          :name="value.name"
          :hidden="value.hidden"
      >
        <i :class="value.icon" :style="value.style"></i>
      </span>
    </div>
</template>

<script>
//TODO: Abhängigkeit von fontawsome und bootstrap muss raus
import theApp from '@/frame/Application'
import JsonPath from '@/frame/JsonPath'
import Settings from '@/visual-events/data/Settings'

export default {
  props: {
    viewName: String,
    zoomIn: Number,
    zoomOut: Number,
    zoomToFit: Number
  },
  data () {
    return {
      buttons: Settings.getOption('views', this.viewName, 'iconbar.buttons')
    }
  },
  methods: {
    hideButton (id) {
      JsonPath.setValue(this.buttons, `${id}.hidden`, true)
    },
    unhideButton (id) {
      JsonPath.setValue(this.buttons, `${id}.hidden`, false)
    },
    replacePlaceholders(command) {
      Object.keys(this.$props).forEach((propKey) => {
        const propValue = this.$props[propKey];
        const replaceKey = `{{${propKey}}}`;
        command = command.replace(replaceKey, propValue);
      });
      Object.keys(this.$data).forEach((dataKey) => {
        const dataValue = this.$data[dataKey];
        if (typeof(dataValue) !== 'object') {
          const replaceKey = `{{${dataKey}}}`;
          command = command.replace(replaceKey, dataValue);
        }
      });
      return command;
    },
    onClickButton(key) {
      const button = this.buttons[key];
      if (button && button.command) {
        const command = this.replacePlaceholders(button.command);
        theApp.executeCommand(command);
      }
    }
  },
  mounted () {
    theApp.addDialog(`IconBarView(${this.viewName})`, this)
  },
  unmounted () {
    theApp.removeDialog(`IconBarView(${this.viewName})`)
  }
}
</script>

<style>
.camera-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.3);
  background-color: white;
  font-size: 16px;
}

.camera-controls-button {
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

@media (hover: hover) {
  .camera-controls-button:hover {
    cursor: pointer;
    background-color: lightblue;
  }
}
</style>