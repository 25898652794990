import * as THREE from 'three';
import { nextTick } from 'vue'

import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import { CADdyType } from '@/visual-events/loader/CADdyType'
import { BreakEvent } from '@/frame/Event.js';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import OpShapePath from '@/visual-events/model/OpShapePath'
import OpUtils from '@/visual-events/model/OpUtils';
import Settings from '@/visual-events/data/Settings';
import FloorPlan from '@/visual-events/actions/FloorPlan';

import Logger from '@/frame/Logger';
import FloorPlan3D from '../model/FloorPlan3D';
import OpFactory from '../model/OpFactory';
import Event3DPlacing from '../model/Event3DPlacing';
import { THREE_TO_OP } from '../model/OpCoordinates';

const logger = new Logger('ActRectangularWalls');

const State = Object.freeze({
    FIRST_POINT: 0,
    DRAG: 1,
    EDIT: 2
  });
  
export default class ActRectangularWalls extends Action {

    constructor (args) {
        super();

        this.args = args;

        this.view2D = theApp.findViewByName('2D Ansicht');
        this.root2D = this.view2D.getRoot().children[0];

        this.view3D = theApp.findViewByName('3D Ansicht');
        this.root3D = undefined;
        if (this.view3D)
            this.root3D = this.view3D.getRoot();

        this.x = 0;
        this.y = 0;
        this.widthX =  0;
        this.widthY =  0;
        this.thickness = Settings.get('floorplan.wall.thickness', 240);
        this.useFill = Settings.get('floorplan.wall.useFill');
        this.fill = 'black';

        // cursor positions while creating
        this.position1 = new THREE.Vector3(0, 0, 0);
        this.position2 = new THREE.Vector3(0, 0, 0);

        // working box to avoid frequent allocations
        this.box = new THREE.Box3();

        // the preliminary floor plan while creating
        this.floorplan = null;
        this.floorplan3d = null;

        this.objects = [];
        if (args.length > 1)
            this.objects = args[1];

        //TODO: now edit floor plan is called if the selection contains a floor plan
        // get rid of the filtering, here
        const objects = args[1];
        if (objects)
            this.objects = objects.filter (op => FloorPlan.getWall(op));
 
        this.state = this.objects.length > 0 ? State.EDIT : State.FIRST_POINT;
    }

    actionStart () {
        logger.log(`actionStart`);

        if (this.state === State.EDIT) {
            // only one floorplan at the same time
            this.floorplan = this.objects[0];
            this.floorplan3d = Event3DPlacing.findLink2DTo3D(this.floorplan)?.op3D;
            this.evaluateSelection();
            this.addFltSelectGrafic();
        }
        else 
        this.addFilter(new FltPointDef());
    
        // const panelWall = theApp.findDialogByName('PanelWall');
        // panelWall.setActiveButton()
        this.connectToGUI();
        this.togglePanalWall();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        this.clearPreliminaryFloorPlan();
        this.disconnectFromGUI();
    }

    actionPoint (event) {
         logger.log(`actionPoint (${event.p[0]}, ${event.p[1]})`);
        switch (this.state) {
            case State.FIRST_POINT: {
                this.position1.x = event.p[0];
                this.position1.y = event.p[1];
                
                this.x = this.position1.x;
                this.y = this.position1.y;
                    
                const panel = theApp.findDialogByName('PanelWall');
                panel?.update(this);

                this.addPreliminaryFloorPlan(this.x, this.y, this.widthX, this.widthY, this.thickness);
                this.state= State.DRAG;
                break;
            }
            case State.DRAG:
                // does not happen
                break;
            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }

        return null;
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);
        switch (this.state) {
            case State.FIRST_POINT:
                // ignored
                break;
            case State.DRAG: {
                this.position2.x = event.p[0];
                this.position2.y = event.p[1];

                const { minX, minY, maxX, maxY } =this.normalizeVertices(this.position1, this.position2);

                this.x = minX;
                this.y = minY;
                this.widthX = maxX - minX;
                this.widthY = maxY - minY;

                const panel = theApp.findDialogByName('PanelWall');
                panel?.update(this);

                this.adaptPreliminaryFloorPlan(this.x, this.y, this.widthX, this.widthY, this.thickness);
                theApp.model.changed2d = true; //op;

                this.state= State.DRAG;
                break;
            }
            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }
        return null;
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.FIRST_POINT:
                // does not happen
                break;
            case State.DRAG: {
                // attention: do not update the size here: The desired behavior is: 
                // if the user just clicks (i.e. no actionDynamic between actionPoint and action PointUp)
                // the plan is created with a default size.
                const op = this.finishFloorPlan();

                // proceed with editing
                this.objects.push(op);
                this.disconnectFromGUI();
                this.floorplan = op; // proceed with the floorplan
                this.evaluateSelection();
                this.state = State.EDIT;
                this.addFltSelectGrafic();
                this.connectToGUI();
                
                break;
            }
            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }

        return null;
    }

    actionCommand(event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.FIRST_POINT:
                // does not happen
                break;
            case State.DRAG:
                // does not happen
                break;
            case State.EDIT: {
                switch (cmd) {
                    case '.select.delete': {
                        this.deleteSelection();
                        return new BreakEvent();
                    }
                    case '.select.copy': {
                        logger.log('.select.copy')
                        this.copySelection();
                        this.connectToGUI();
                        return null;
                    }
                    case '.select.applyTransform': {
                        const tdiff = event.args[0];
                        for (const op of this.objects) {
                            op.applyTransform(tdiff);
                        }

                        return null;
                    }
                    case '.select.dragBoxPoint': {
                        const box = event.args[1];

                        this.x = box.min.x;
                        this.y = box.min.y;
                        this.widthX = Math.abs(box.max.x - box.min.x);
                        this.widthY = Math.abs(box.max.y - box.min.y);
        
                        const panel = theApp.findDialogByName('PanelWall');
                        panel?.update(this);
        
                        this.adaptPreliminaryFloorPlan(this.x, this.y, this.widthX, this.widthY, this.thickness);

                        theApp.model.changed2d = true; //op;
                        return null;
                    }
                }
            } // case EDIT
        } // switch state

        return event;
    }

    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'widthX') {
            this.widthX = event.value;
            this.adaptPreliminaryFloorPlan(this.x, this.y, this.widthX, this.widthY, this.thickness);
            this.adaptFltSelectGrafic();
            done = true;
        }

        if (event.attribute === 'widthY') {
            this.widthY = event.value;
            this.adaptPreliminaryFloorPlan(this.x, this.y, this.widthX, this.widthY, this.thickness);
            this.adaptFltSelectGrafic();
            done = true;
        }

        if (event.attribute === 'thickness') {
            this.thickness = event.value;
            this.adaptPreliminaryFloorPlan(this.x, this.y, this.widthX, this.widthY, this.thickness);
            this.adaptFltSelectGrafic();
            done = true;
        }

        if (event.attribute === 'fill') {
            this.fill = event.value;
            this.floorplan.setStyle({ fill: (this.useFill ? this.fill : 'none') });
            theApp.model.changed2d = true;
            done = true;
        }

        return done ? null : event;
    }

    /** 
     * while editing FltSelectGrafic does most of the job
     */
    addFltSelectGrafic () {
        if (this.objects.length === 1) {
            const box = new THREE.Box3();
            box.min.set(this.x, this.y, 0);
            box.max.set(this.x + this.widthX, this.y + this.widthY, 0);
            const transform = this.objects[0].transform;
            this.addFilter(new FltSelectGrafic(box, transform).useBoxPoints());
        } 
    }

    /**
     * adapt the selection box
     */
    adaptFltSelectGrafic () {
        switch (this.state) {
            case State.FIRST_POINT:
            case State.DRAG:
                // FltSelectGrafic not active
                break;
            case State.EDIT: {
                this.box.min.x = this.x;
                this.box.min.y = this.y;
                this.box.max.x = this.x + this.widthX;
                this.box.max.y = this.y + this.widthY;
                this.getFilter().update(this.box);
                break;
            }
        }
    }

    /**
     * retrieve the defining parameters of the current floor plan
     */
    evaluateSelection ()  {
        const { x, y, widthX, widthY, thickness} = FloorPlan.getSize(this.floorplan);
        this.x = x;
        this.y = y;
        this.widthX = widthX;
        this.widthY = widthY;
        this.thickness = thickness;
        this.fill = this.floorplan.style.fill
    }

    /**
     * normalize the cursor position in that way, that the rectangle's 
     * width and height have positive sign, i.e. in angle 0° it is always
     * left bottom to right top ordered.
     *  
     *  .________x
     *           |
     *  x________.
     * 
     * This corresponds with the result of ShapeUtils.getRectangle(op)
     * @param {*} pos1 
     * @param {*} pos2 
     * @returns  { minX, minY, maxX, maxY }
     */
    normalizeVertices (pos1, pos2) {
        const minX = Math.min(pos1.x,pos2.x);
        const maxX = Math.max(pos1.x,pos2.x);
        const minY = Math.min(pos1.y,pos2.y);
        const maxY = Math.max(pos1.y,pos2.y);
        return { minX, minY, maxX, maxY };
    }

    /**
     * create a floor plan consisting of four walls and add it to the drawing
     * @param {*} x 
     * @param {*} y 
     * @param {*} widthX 
     * @param {*} widthY 
     * @param {*} thickness 
     */
    addPreliminaryFloorPlan(x, y, widthX, widthY, thickness) {
        const style = { fill: this.useFill ? this.fill : 'none', fillOpacity: 1, stroke: 'none', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}
        const path = FloorPlan.createRectangularFloorPlanShapePath (x, y, widthX, widthY, thickness);
        this.floorplan = new OpShapePath(CADdyType.FACE, 'rectangular floor plan', path, style);
        FloorPlan.markAsWall(this.floorplan, FloorPlan.TYPES.RECTANGULAR, thickness);

        this.root2D.add(this.floorplan);
        theApp.model.changed2d = true; //op;

        if (this.root3D) {
            const group = OpFactory.createGroup('floorplan3d');
            const floor = FloorPlan3D.extrudeFloor(path, thickness, false);
            const opFloor = OpFactory.createMesh(CADdyType.SOLID, 'floor', floor, 'floor.gltf');
            opFloor.setAttribute('$isOp', true);
            group.add(opFloor);

            const walls = FloorPlan3D.extrudeWalls(path, 3000, false);
            const opWalls = OpFactory.createMesh(CADdyType.SOLID, 'walls', walls, 'walls.gltf');
            opWalls.setAttribute('$isOp', true);
            group.add(opWalls);
            
            this.floorplan3d = group;
            Event3DPlacing.link2DTo3D(this.floorplan, this.floorplan3d, '');
            this.root3D.add(this.floorplan3d);
            theApp.model.changed3d = true;
        }
    }

    /**
     * adapt the rectangular floor plan according to the dynamical situation
     */
    adaptPreliminaryFloorPlan (x, y, widthX, widthY, thickness) {
        const path = FloorPlan.createRectangularFloorPlanShapePath (x, y, widthX, widthY, thickness);
        this.floorplan.setShapePath(path);
        FloorPlan.markAsWall(this.floorplan, FloorPlan.TYPES.RECTANGULAR, thickness);

        if (this.root3D) {
            const gFloor = FloorPlan3D.extrudeFloor(path, thickness, true);
            const gWalls = FloorPlan3D.extrudeWalls(path, 3000, true);

            this.floorplan3d.children[0].setGeometry(gFloor);
            this.floorplan3d.children[1].setGeometry(gWalls);
        
            const tFloor = new THREE.Matrix4();
            tFloor.makeTranslation(0, 0, -thickness);
            this.floorplan3d.children[0].setTransform(tFloor);

            theApp.model.modifiedIn3D.push(this.floorplan3d.children[0]);
            theApp.model.modifiedIn3D.push(this.floorplan3d.children[1]);
        }
    }

    /**
     * transfer the rectangular floor plan currently beeing generated from the temporary grafic
     * into the model.
     */
    finishFloorPlan () {
        const op = this.floorplan;
        this.floorplan = null;
        this.root2D.add(op);
        return op;
    }

    /**
     * on delete command delete the selected objects 
     * and go into State.FIRST_POINT
     */
    deleteSelection () {
        OpUtils.deleteSelection(this.objects);
        theApp.model.changed2d = true;

        if (this.root3D) {
            OpUtils.deleteSelection([this.floorplan3d]);
            theApp.model.changed3d = true;
        }

        // State change to State.FIRST_POINT
        this.objects = [];
        this.clearPreliminaryFloorPlan();
        this.removeFilter();
        this.disconnectFromGUI();
        this.state = State.FIRST_POINT;
        this.addFilter(new FltPointDef());
        this.connectToGUI();
    }

    copySelection () {
        const dist = Settings.get('selection.grafic.iconDist', 300);
        this.objects = OpUtils.copySelection (this.objects, this.root2D, dist);

        this.disconnectFromGUI();
        this.floorplan = this.objects[0];
        this.state = State.EDIT;
        this.getFilter().shift(dist);

        theApp.model.changed2d = true;
    }

    /**
     * remove the preliminary floor plan from the drawing
     */
    clearPreliminaryFloorPlan () {
        switch (this.state) {            
            case State.FIRST_POINT: 
            case State.DRAG: {
                if (this.floorplan) {
                    this.root2D.remove(this.floorplan);
                    this.floorplan = null;
                    theApp.model.changed2d = true; //op;
                }
                break;
            }
        }
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Floorplan');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('DlgFloorplan');
        nextTick(() => {
            // access updated DOM
            const dlgFloorplan = theApp.findDialogByName('DlgFloorplan');
            dlgFloorplan?.open('Wall');
            nextTick(() => {
                const panelWall =  theApp.findDialogByName('PanelWall');
                panelWall?.setActiveButton(1);
                panelWall?.update(this);
            })
            
        })
    }

    togglePanalWall () {
        nextTick (() => {
            if (this.state === State.EDIT) {
                const dlgFloorplan = theApp.findDialogByName('DlgFloorplan')
                dlgFloorplan.close('Window')
                dlgFloorplan.close('Door')
                dlgFloorplan.open('Wall')
            }
        })
        
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
        const panelWall = theApp.findDialogByName('PanelWall');
        panelWall?.setDeactiveButton()
    }
}