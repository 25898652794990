<template>
    <InputNumberWithUnit v-show="editMode" class="col-6 ps-3" unit='°' label="Winkel" :periodic="true" v-model="angle" @update:modelValue="onChangeAngle" />
    <PanelPolygon v-show="nextMode || editMode" />
    <PanelStyle/>
</template>

<script>
import theApp from '@/frame/Application'
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue'
import PanelPolygon from '@/vuejs/components/PanelPolygon.vue'
import PanelStyle from '@/vuejs/components/PanelStyle.vue'

export default {
  name: 'PanelDrawPolygon',
  components: {
    PanelPolygon,
    PanelStyle,
    InputNumberWithUnit
  },
  data () {
    return {
        nextMode: false, 
        editMode: false,
        angle: 0
    }
  },
  mounted () {
    theApp.addDialog('PanelDrawPolygon', this)
  },
  unmounted () {
    theApp.removeDialog('PanelDrawPolygon')
  },
  methods: {
    update(action) {
      this.closed = action.closed;
      this.angle = action.angle;
    },    
    onChangeAngle () {
        theApp.executeValueEvent("angle", this.angle)
        //no setting, only temporary
    }
  }
}
</script>

<style scoped>
</style>
