import theApp from '@/frame/Application';

import { Matrix4} from 'three';
import { nextTick } from 'vue'

import Action from '@/frame/Action';
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import Geometry from '@/visual-events/model/Geometry';
import Pick from '@/visual-events/view/Pick';
import Logger from '@/frame/Logger';

const logger = new Logger('ActFavorites');

const State = Object.freeze({
    SELECT: 0,
    DRAG_IN_FAVORITES: 1,
    DRAG_IN_2D: 2,
    DRAG_ELSE: 3
  });
    
export default class ActFavorites extends Action {
    constructor(args) {
      super();

      this.args = args;

      // only available in nextTick
      this.viewFavorites = null;
      this.rootFavorites = null;

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.view3D = theApp.findViewByName('3D Ansicht');
      this.root3D = this.view3D?.getRoot();

      this.a = 0.0; 
      this.x = 0.0;
      this.y = 0.0;

      this.t0 = new Matrix4();

      // working matrix
      this.t = new Matrix4();
      this.grafic = null;

      // source view and OpObject
      this.source = null;
      this.original = null;

      this.isSymbol = false;
      this.state = State.SELECT;
    }

    actionStart () {
        logger.log(`actionStart`);
        this.connectToGUI();
        this.addFilter(new FltPointDef());
        this.state = State.SELECT;
        this.adaptCursor();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        this.resetCursor();
        this.disconnectFromGUI();
    }

    actionBreak () {
        logger.log('actionBreak');

        switch (this.state) {
            case State.SELECT: {
                break;
            }
            case State.DRAG_IN_2D: {
                this.state = State.SELECT;
                break;                
            }
            case State.DRAG_IN_FAVORITES: {
                this.state = State.SELECT;
                break;
            }
            case State.DRAG_ELSE: {
                this.state = State.SELECT;
                break;
            }
        }

        this.adaptCursor();

        return null;
    }

    actionPoint (event) {
        logger.log(`actionPoint    ${this.state}`);

        switch (this.state) {
            case State.SELECT: {
                const x0 = event.p[0];
                const y0 = event.p[1];
                this.t0.makeTranslation(-x0, -y0, 0);

                const hits = Pick.pick(event.view, event.raw);
                if (hits.length > 0) {
                    const op = hits[0];
                    if (op) {
                        const [view] = theApp.findView(event.raw.currentTarget);
                        logger.log(`in view ${view.name}`);
                        if (view === this.view2D) {
                            this.createTemporaryGrafic (view, op);
                            this.showTemporaryGrafic(view);
                            this.adaptTemporaryGrafic(view);
                            this.state = State.DRAG_IN_2D;
                            view.disableCameraControls();
                        } else if (view === this.viewFavorites) {
                            this.createTemporaryGrafic (view, op);
                            this.showTemporaryGrafic(view);
                            this.adaptTemporaryGrafic(view);
                            this.state = State.DRAG_IN_FAVORITES;
                            view.disableCameraControls();
                        }
                    }
                }
                break;
            }
            case State.DRAG_IN_2D:
            case State.DRAG_IN_FAVORITES:
            case State.DRAG_ELSE:
                // never happens
                break;
        }

        this.adaptCursor();
    }

    actionDynamic (event) {
        //logger.log('actionDynamic');

        switch (this.state) {
            case State.SELECT:
                break;
            case State.DRAG_IN_FAVORITES: {
                this.x = event.p[0];
                this.y = event.p[1];

                this.adaptTemporaryGrafic(this.viewFavorites);
                break;
            }
            case State.DRAG_ELSE:
                break;
            case State.DRAG_IN_2D: {
                this.x = event.p[0];
                this.y = event.p[1];

                this.adaptTemporaryGrafic(this.view2D);
                break;
            }
        }
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.SELECT: {
                break;
            }
            case State.DRAG_IN_FAVORITES: {
                this.x = event.p[0];
                this.y = event.p[1];

                // dropping a favorite in Favorites means moving
                if (this.source === this.viewFavorites) {
                    this.original.removeFromParent();
                    theApp.model.changedFavorites = true;
                } else {
                    // copy linked 3D object, if available and adapt grafic in oder to 
                    // update the transforms of 2D and 3D
                    this.drop3D(this.viewFavorites);
                    this.adaptTemporaryGrafic(this.viewFavorites);
                }

                this.clearTemporaryGrafic();
                this.state = State.SELECT;
                break;
            }
            case State.DRAG_IN_2D: {
                this.x = event.p[0];
                this.y = event.p[1];

                // dropping a 2D selection in 2D means cancel
                if (this.source === this.view2D) 
                    this.hideTemporaryGrafic(this.view2D);
                else {
                    // copy linked 3D object, if available and adapt grafic in oder to 
                    // update the transforms of 2D and 3D
                    this.drop3D(this.view2D);
                    this.adaptTemporaryGrafic(this.view2D);
                }
                this.view2D.enableCameraControls();
                this.state = State.SELECT;
                break;
            }
            case State.DRAG_ELSE: {
                // in Favorites dropping in Favorites means deleting
                if (this.source === this.viewFavorites) {
                    this.original.removeFromParent();
                    theApp.model.changedFavorites = true;
                }
                this.clearTemporaryGrafic();
                this.state = State.SELECT;
            break;
            }
        }
        
        this.adaptCursor();
    }

    actionMouse (event) {
        logger.log(`actionMouse ${event.raw.type} ${this.state}`);
        switch (this.state) {
            case State.SELECT: 
                break;
            case State.DRAG_IN_FAVORITES: {
                //if not mouse down, cancel
                if (event.raw.type === 'mouseenter') {
                    if (!(event.raw.buttons & 1)) {
                        //if not mouse down, cancel
                        this.clearTemporaryGrafic();
                        this.state = State.SELECT;
                    }
                }
                if (event.raw.type === 'mouseleave') {
                    this.hideTemporaryGrafic(this.viewFavorites);
                    this.viewFavorites.enableCameraControls();
                    this.state = State.DRAG_ELSE;
                }
                break;
            }
            case State.DRAG_IN_2D: {
                //if not mouse down, cancel
                if (event.raw.type === 'mouseenter') {
                    if (!(event.raw.buttons & 1)) {
                        //if not mouse down, cancel
                        this.clearTemporaryGrafic();
                        this.state = State.SELECT;
                    }
                }
                if (event.raw.type === 'mouseleave') {
                    this.hideTemporaryGrafic(this.view2D);
                    this.view2D.enableCameraControls();
                    this.state = State.DRAG_ELSE;
                }
                break;
            }
            case State.DRAG_ELSE: {
                if (event.raw.type === 'mouseenter') {
                    if (!(event.raw.buttons & 1)) {
                        //if not mouse down, cancel
                        this.clearTemporaryGrafic();
                        this.state = State.SELECT;
                    } else {
                        const [view] = theApp.findView(event.raw.currentTarget);
                        logger.log(`in view ${view.name}`);
                        if (view === this.view2D) {
                            this.showTemporaryGrafic(view);
                            this.adaptTemporaryGrafic(view);
                            view.disableCameraControls();
                            this.state = State.DRAG_IN_2D;
                        } else  if (view === this.viewFavorites) {
                            this.showTemporaryGrafic(view);
                            this.adaptTemporaryGrafic(view);
                            view.disableCameraControls();
                            this.state = State.DRAG_IN_FAVORITES;
                        } 
                    }
                }
                break;
            }
        }

        this.adaptCursor();
    }

    createTemporaryGrafic (view, op) {
        this.source = view;
        this.original = op;
        this.t0.premultiply(op.transform);
        this.grafic = op.copy();
    }

    showTemporaryGrafic (view) {
        logger.log(`showTemporaryGrafic(${view.name})`);
        const root = view.getRoot().children[0];
        root.add(this.grafic);
        this.notifyGraficChanged(view);
    }

    adaptTemporaryGrafic (view) {
        logger.log(`adaptTemporaryGrafic(${view.name})`);
        Geometry.makePlacingTransform(this.t, this.x, this.y, 0, this.a);
        this.t.premultiply(this.t0);
        this.grafic.setTransform(this.t);
        this.notifyGraficChanged(view);
    }

    hideTemporaryGrafic (view) {
        logger.log(`hideTemporaryGrafic(${view.name})`);
        this.grafic.removeFromParent();
        this.notifyGraficChanged(view);
    }

    clearTemporaryGrafic () {
        logger.log('clearTemporaryGrafic');
        this.grafic = null;
        this.source = null;
        this.original = null;
        this.op3D = null;
    }

    drop3D (view) {
        const link = Event3DPlacing.findLink2DTo3D(this.original);
        if (link) {
            const op3D = link.op3D.copy();
            Event3DPlacing.link2DTo3D(this.grafic, op3D, link.hint);
            if (view === this.view2D && this.root3D) {
                this.root3D.add(op3D);
                theApp.model.changed3d = true;
            } else  if (view === this.viewFavorites) {
                //TODO: implementation not completed??
            }
        }
    }

    adaptCursor () {
        switch(this.state) {
            case State.SELECT: {
                theApp.findDialogByName('main')?.setCursor(undefined);
                theApp.findDialogByName('2D Ansicht')?.setCursor('grab');
                theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
                theApp.findDialogByName('Favorites')?.setCursor('grab');
            }
            break;
            case State.DRAG_IN_FAVORITES: {
                theApp.findDialogByName('main')?.setCursor('no-drop');
                theApp.findDialogByName('Favorites')?.setCursor('grabbing');
                theApp.findDialogByName('3D Ansicht')?.setCursor('no-drop');
                theApp.findDialogByName('Favorites')?.setCursor('grabbing');
            }
            break;
            case State.DRAG_IN_2D: {
                theApp.findDialogByName('main')?.setCursor('no-drop');
                theApp.findDialogByName('2D Ansicht')?.setCursor('grabbing');
                theApp.findDialogByName('3D Ansicht')?.setCursor('no-drop');
                theApp.findDialogByName('Favorites')?.setCursor('grabbing');
            }
            break;
            case State.DRAG_ELSE: {
                theApp.findDialogByName('main')?.setCursor('no-drop');
                theApp.findDialogByName('2D Ansicht')?.setCursor('grabbing');
                theApp.findDialogByName('3D Ansicht')?.setCursor('no-drop');
                theApp.findDialogByName('Favorites')?.setCursor('grabbing');
            }
            break;
       }
    }

    resetCursor () {
        theApp.findDialogByName('main')?.setCursor(undefined);
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('Favorites')?.setCursor(undefined);
    }

    notifyGraficChanged (view) {
        if (view === this.view2D)
            theApp.model.changed2d = true;
        if (view === this.viewFavorites)
            theApp.model.changedFavorites = true;
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Favorites');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PaneSimpleFavorites');
        nextTick(() => {
            this.viewFavorites = theApp.findViewByName('Favorites');
            this.rootFavorites = this.viewFavorites.getRoot();
        });
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}    
