import * as THREE from 'three';

import OpText from '@/visual-events/model/OpText'
import OpShapePath from '@/visual-events/model/OpShapePath'
import OpReference from '@/visual-events/model/OpReference';
import theApp from '@/frame/Application';
import Geometry from '@/visual-events/model/Geometry'

export default class ShapeUtils {

    static isShapePath(op) {
        return op instanceof OpShapePath;
    }

    static isReference(op) {
        return op instanceof OpReference;
    }

    static isRectangle(op) {
        if (!ShapeUtils.isShapePath(op))
            return false;

        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return false;

        const curves = subPaths[0].curves;
        if (!(curves.length === 4 || curves.length === 3 && subPaths[0].autoClose))
            return false;

        if (!ShapeUtils.areLineCurves(curves))
            return false;

        //check if rectangular sides
        const dX0 = curves[0].v2.x - curves[0].v1.x;
        const dY0 = curves[0].v2.y - curves[0].v1.y;
        const dX1 = curves[1].v2.x - curves[1].v1.x;
        const dY1 = curves[1].v2.y - curves[1].v1.y;
        const dX2 = curves[2].v2.x - curves[2].v1.x;
        const dY2 = curves[2].v2.y - curves[2].v1.y;

        if (Math.abs(dX0 * dX1 + dY0 * dY1) > Number.EPSILON)
            return false;
            
        // ... and opposite sides are anti-parallel
        if (Math.abs(dX0 + dX2) > Number.EPSILON ||
            Math.abs(dY0 + dY2) > Number.EPSILON)
            return false;

        if (curves.length === 4) {
            const dX3 = curves[3].v2.x - curves[3].v1.x;
            const dY3 = curves[3].v2.y - curves[3].v1.y;
            if (Math.abs(dX0 + dX3) > Number.EPSILON ||
                Math.abs(dY0 + dY3) > Number.EPSILON)
                return false;
        }

        return true;
    }

    static getRectangle (op) {
        if (!ShapeUtils.isShapePath(op))
            return false;

        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return false;

        const curves = subPaths[0].curves;
        if (!(curves.length === 4 || curves.length === 3 && subPaths[0].autoClose))
            return false;

        if (!ShapeUtils.areLineCurves(curves))
            return false;

        //check if rectangular sides
        const dX0 = curves[0].v2.x - curves[0].v1.x;
        const dY0 = curves[0].v2.y - curves[0].v1.y;
        const dX1 = curves[1].v2.x - curves[1].v1.x;
        const dY1 = curves[1].v2.y - curves[1].v1.y;
        const dX2 = curves[2].v2.x - curves[2].v1.x;
        const dY2 = curves[2].v2.y - curves[2].v1.y;

        if (Math.abs(dX0 * dX1 + dY0 * dY1) > Number.EPSILON)
            return false;

        // ... and opposite sides are anti-parallel
        if (Math.abs(dX0 + dX2) > Number.EPSILON ||
            Math.abs(dY0 + dY2) > Number.EPSILON)
            return false;

        if (curves.length === 4) {
            const dX3 = curves[3].v2.x - curves[3].v1.x;
            const dY3 = curves[3].v2.y - curves[3].v1.y;
            if (Math.abs(dX0 + dX3) > Number.EPSILON ||
                Math.abs(dY0 + dY3) > Number.EPSILON)
                return false;
        }

        return {
            x: curves[0].v1.x,
            y: curves[0].v1.y,
            width: Math.sqrt(dX0 * dX0 + dY0 * dY0),
            height: Math.sqrt(dX1 * dX1 + dY1 * dY1),
            angle: Math.atan2(dY0, dX0)
        };
    }

    static createCirclePath (cx, cy, radius) {

        const path = new THREE.ShapePath();

        const subpath = new THREE.Path();
        subpath.absarc( cx, cy, radius, 0, Math.PI * 2 );

        path.subPaths.push( subpath );

        return path;
    }

    static isPolygon(op) {
        if (!ShapeUtils.isShapePath(op))
            return false;

        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return false;

        const curves = subPaths[0].curves;
        if (!ShapeUtils.areLineCurves(curves))
            return false;
        
        return true;
    }

    static createPolygonPath(points, autoClose) {

        const path = new THREE.ShapePath();

        path.moveTo( points[0][0], points[0][1] );
        for (let i = 1; i < points.length; i++) 
            path.lineTo( points[i][0], points[i][1] );
        path.currentPath.autoClose = autoClose;

        return path;
    } 

    static createRectanglePath (x, y, width, height) {
        const points = [
            [x, y],
            [x + width, y],
            [x+ width, y + height],
            [x, y + height]
        ];

        return ShapeUtils.createPolygonPath(points, true);
    }
    
    static areLineCurves(curves) {
        return !curves.some(curve => !(curve instanceof THREE.LineCurve));
    }

    static isText(op) {
        return op instanceof OpText;
    }

    static getText(op) {
        if (!ShapeUtils.isText(op))
            return false;

        const [x, y, z] = Geometry.getTranslation(op.transform);
        const angle = Geometry.getRotationAngle(op.transform);

        return {
            x: x,
            y: y,
            angle: angle,
            text: op.text,
            fontSize: op.fontSize,
            fontFamily: op.fontFamily,
            textAnchor: op.textAnchor,
            baseLine: op.baseLine,
            style: op.style,
            xref: op.xref
        };
    }

    static isCircle(op) {
        if (!ShapeUtils.isShapePath(op))
            return false;

        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return false;

        const curves = subPaths[0].curves;
        if (curves.length !== 1)
            return false;

        const curve = curves[0];
        if (!(curve instanceof THREE.EllipseCurve))
            return false;

        // ellipse
        if (curve.xRadius !== curve.yRadius)
            return false;

        if (curve.aEndAngle - curve.aStartAngle != 2 * Math.PI)
            return false;

        return true;
    }

    static getCircle (op) {
        if (!ShapeUtils.isShapePath(op))
            return null;
            
        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return null;

        const curves = subPaths[0].curves;
        if (curves.length !== 1)
            return null;

        const curve = curves[0];
        if (!(curve instanceof THREE.EllipseCurve))
            return null;

        // ellipse
        if (curve.xRadius !== curve.yRadius)
            return null;

        if (curve.aEndAngle - curve.aStartAngle != 2 * Math.PI)
            return null;

        return {
            cx: curve.aX,
            cy: curve.aY,
            radius: curve.xRadius
        }
    }

    /**
     * create the path for a standard chair, i.e.
     * a rectangle with a circular back
     * @param {*} x 
     * @param {*} y 
     * @param {*} width 
     * @param {*} height 
     * @param {*} cr 
     * @returns 
     */
    static createChairPath(x, y, width, height, cr){


        const path = new THREE.ShapePath();

        const subpath = new THREE.Path();

        const x1 = x + width;
        const cx = x + 0.5 * width;
        const cy = y + height - width;
        subpath.moveTo(x, y);
        subpath.lineTo(x1,  y);
        subpath.absarc (cx, cy, width, Math.PI/3, 2* Math.PI/3, false);
        subpath.lineTo(x, y);

        path.subPaths.push( subpath );

        return path;
    }

    static getChair(op){
        if (!ShapeUtils.isShapePath(op))
            return false;

        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return false;

        const curves = subPaths[0].curves;

        if (curves.length !== 4)
            return false;

        if (curves[0] instanceof THREE.LineCurve && 
            curves[1] instanceof THREE.LineCurve && 
            curves[2] instanceof THREE.EllipseCurve && 
            curves[3] instanceof THREE.LineCurve) {

                const width = curves[0].v2.x - curves[0].v1.x;
                const dy = (curves[0].v2.x - curves[0].v1.x) * (1 -Math.sin(Math.PI / 3));
                const height = curves[1].v2.y - curves[1].v1.y + dy;
                return {
                    x: curves[0].v1.x,
                    y: curves[0].v1.y,
                    width: width,
                    height: height
                };
        }

        return false;
    }

    static isChair(op){
        if (!ShapeUtils.isShapePath(op))
            return false;

        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return false;

        const curves = subPaths[0].curves;

        if (curves.length !== 4)
            return false;

        if (curves[0] instanceof THREE.LineCurve && 
            curves[1] instanceof THREE.LineCurve && 
            curves[2] instanceof THREE.EllipseCurve && 
            curves[3] instanceof THREE.LineCurve) 
                return true;
        
        return false;
    }

    /**
     * creates a path for a rectangle with rounded corners
     * @param {*} x 
     * @param {*} y 
     * @param {*} width 
     * @param {*} height 
     * @param {*} cr  corner radius
     * @returns 
     */
    static createChairPath1(x, y, width, height, cr){
        const shape = new THREE.ShapePath();
        const x1 = x + width;
        const y1 = y + height;
        shape.moveTo(x, y);
        shape.lineTo(x1,  y);
        shape.lineTo(x1,  y1- cr);
        shape.quadraticCurveTo(x1, y1, x1 -cr, y1);
        shape.lineTo(x+cr, y1);
        shape.quadraticCurveTo(x, y1, x, y1-cr);
        //shape.bezierCurveTo(x1, y1, x, y1, x, y1-cr);
        shape.lineTo(x, y);

        return shape;
    }

    static getChair1(op){
        if (!ShapeUtils.isShapePath(op))
            return false;

        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return false;

        const curves = subPaths[0].curves;

        if (curves.length !== 6)
            return false;

        if (curves[0] instanceof THREE.LineCurve && 
            curves[1] instanceof THREE.LineCurve && 
            curves[2] instanceof THREE.QuadraticBezierCurve && 
            curves[3] instanceof THREE.LineCurve && 
            curves[4] instanceof THREE.QuadraticBezierCurve && 
            curves[5] instanceof THREE.LineCurve) {

                const r = curves[2].v1.y - curves[2].v0.y;
                return {
                    x: curves[0].v1.x,
                    y: curves[0].v1.y,
                    width: curves[0].v2.x - curves[0].v1.x,
                    height: curves[1].v2.y - curves[1].v1.y + r,
                    r: r
                };
        }

        return false;
    }

    static isChair1(op){
        if (!ShapeUtils.isShapePath(op))
            return false;

        const path = op.path;
        const subPaths = path.subPaths;
        if (subPaths.length !== 1)
            return false;

        const curves = subPaths[0].curves;

        if (curves.length !== 6)
            return false;

        if (curves[0] instanceof THREE.LineCurve && 
            curves[1] instanceof THREE.LineCurve && 
            curves[2] instanceof THREE.QuadraticBezierCurve && 
            curves[3] instanceof THREE.LineCurve && 
            curves[4] instanceof THREE.QuadraticBezierCurve && 
            curves[5] instanceof THREE.LineCurve)
            return true;
        
        return false;
    }

    static areBezierCurves(curves) {
        return curves.some(curve => (curve instanceof THREE.CubicBezierCurve));
    }

    static isSymbolCircle (op) {
        if (!ShapeUtils.isReference(op))
            return false;

        const symbolId  = op.symbolId;
        const symbol = theApp.model.symbols.get(symbolId);
        const opShapePath = symbol.children[0];
        const circle = this.isCircle(opShapePath)
        return circle;
    }

    static isSymbolRectangle (op) {
        if (!ShapeUtils.isReference(op))
            return false;

        const symbolId  = op.symbolId;
        const symbol = theApp.model.symbols.get(symbolId);
        const opShapePath = symbol.children[0];
        const rectangle = this.isRectangle(opShapePath);
        return rectangle;
    }

    static isSymbolChair (op) {
        if (!ShapeUtils.isReference(op))
            return false;

        const symbolId  = op.symbolId;
        const symbol = theApp.model.symbols.get(symbolId);
        const opShapePath = symbol.children[0];
        return this.isChair(opShapePath);
    }
}