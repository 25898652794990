import OpFactory from '@/visual-events/model/OpFactory';
import { CommandEvent, BreakEvent } from '@/frame/Event';
import { CommandDispatcher } from '@/visual-events/actions/FltSelectAndDispatch';
import Geometry from '@/visual-events/model/Geometry';
import OpUtils from '@/visual-events/model/OpUtils';
import Pick from '@/visual-events/view/Pick';
import ShapeUtils from '@/visual-events/model/ShapeUtils';
import Settings from '@/visual-events/data/Settings';
import { Matrix4, Vector3 } from 'three';

import Logger from '@/frame/Logger';

const logger = new Logger('SelectGrafic');

/**
 * configurable selection grafic, i.e.
 * - a box
 *   sensitive for drag& drop
 * - geo points to drag the size
 * - icons for commands, such as
 *     delete
 *     copy
 *     rotate
 * 
 * usage:
 * 
 *  provided an initial box and a placing transform
 *  and the drawing 'root2D', in which to work 
 * 
 *      const selectGrafic = new SelectGrafic().useDeleteIcon().use...;
 *      root2D.add(selectGrafic.create(box, transform))
 *
 *  change box and transform, where needed
 *  
 *       selectGrafic.adapt(box, transform);
 * 
 *  finally remove from the plan
 *      selectGrafic.dispose();
 */

export default class SelectGrafic extends CommandDispatcher {

    constructor() {
        super();

        // root OpGroup
        this.grafic = null;
        
        this.area = null;
        this.box = null;
        this.boxPoints = [];
        this.bottomIcons = [];
        this.topIcons = [];
        this.iconDelete = null;
        this.iconCopy = null;
        this.iconRotate = null;
        this.iconGroup = null;
        this.iconReflectHorizontal = null;
        this.iconReflectVertical = null;
        this.iconSaveGroup = null;
        this.withBoxPoints = [];
        this.withCopyIcon = false;
        this.withDeleteIcon = false;
        this.withRotateIcon = false;
        this.withGroupIcon = false;
        this.withReflectHorizontalIcon = false;
        this.withReflectVerticalIcon = false;
        this.withSaveGroupIcon = false;
        this.sendBreakEventOnVoid = true;

        // working matrix to avoid frequent allocations
        this.t = new Matrix4();
        this.p = new Vector3();

        // HACK: skip PointUp without previous Point Event
        this.gotPointUp = false;
    }
 
    /**
     * configuration of the SelectGrafic 
     * 
     * By default all traits are switched off. Add traits by appending the respective method calls:
     * 
     * const selectGrafic = new SelectGrafic().useBoxPoints().useDeleteIcons()
     * 
     * useBoxPoints(true) as a default offers the eight box points on the selection rectangle, without center
     *
     * In order to configure a subset call with an array of requested positions, e.g. 
     * 
     *    useBoxPoints(['left', 'center', 'right'])
     * 
     * attention: you cannot add or remove the traits lateron
     * @param {*} use = true
     * @returns this
     */
    useBoxPoints (use) {
        if (Array.isArray(use)) 
            this.withBoxPoints = use; 
        else if (use)
            this.withBoxPoints = use ? ['leftBottom', 'bottom',  'rightBottom', 'right', 'rightTop', 'top', 'leftTop', 'left'] : [];
        return this; 
    }
    useDeleteIcon (use=true) { this.withDeleteIcon = use; return this; }
    useCopyIcon (use=true) { this.withCopyIcon = use; return this; }
    useGroupIcon (use=true) { this.withGroupIcon = use; return this; }
    useSaveGroupIcon (use=true) { this.withSaveGroupIcon = use; return this; }
    useRotateIcon (use=true) { this.withRotateIcon = use; return this; }
    useReflectHorizontalIcon (use=true) { this.withReflectHorizontalIcon = use; return this; }
    useReflectVerticalIcon (use=true) { this.withReflectVerticalIcon = use; return this; }
    sendBreakOnVoid (use=true) { this.sendBreakEventOnVoid = use; return this; }

    /**
     * create the selection grafic
     * 
     * this.grafic is an OpGroup, which carries the transform.
     * 
     * The selection area is an OpShapePath, rectangle with the dimension of the box + margins.
     * 
     * Other children are the geo points (box points) and the icon handles.
     * 
     * Usage:
     *  provided an initial box and placing transform
     *  and a drawing where to place the temporary grafic
     * 
     *  const selectGrafic = new SelectGrafic().use...
     *  drawing.add(selectGrafic.create(box, transform));
     * 
     *  use adapt or applyTransform, where the SelectGrafic must be changed or relocated
     * 
     *  finally in order to remove from the drawing
     * 
     *  selectGrafic.dispose();
     * 
     * @param {*} box 
     * @param {*} transform 
     * @returns the OpGroup
     */
    create (box, transform) {

        this.box = box;
        this.angle = transform ? Geometry.getRotationAngle(transform) : 0;
        const [x, y, z] = transform ? Geometry.getTranslation(transform) : [0, 0, 0];

        logger.log(`create( ${box.min.x} - ${box.max.x} x ${box.min.y} - ${box.max.y}, [${x}, ${y}, ${z}] ${this.angle} )`)

        this.grafic = OpFactory.createGroup('Selection');

        const margin = Settings.get('selection.grafic.margin');
        const radius = Settings.get('selection.grafic.iconDiameter') * 0.5;
        const dist = Settings.get('selection.grafic.iconDist');

        this.area = this.createSelectionBox(this.box, margin);
        this.grafic.add(this.area);

        if (this.withBoxPoints.length > 0) {
            const diameter = Settings.get('selection.grafic.geoPointDiameter');
            const points = this.calculateGeoPoints(this.box, margin);
                
            for (const point of points) {
                if(this.withBoxPoints.includes(point[2]))
                    this.addBoxPoint(point, diameter);
                else
                    this.boxPoints.push(null);
            } 
        }

        this.iconDelete = this.withDeleteIcon ? this.addIcon ('delete', radius) : null;
        this.iconCopy   = this.withCopyIcon   ? this.addIcon ('copy',  radius) : null;
        this.iconRotate = this.withRotateIcon ? this.addIcon ('rotate', radius) : null;
        this.iconGroup = this.withGroupIcon ? this.addIcon ('group', radius) : null;
        this.iconReflectHorizontal = this.withReflectHorizontalIcon ? this.addIcon ('reflectHorizontal', radius) : null;
        this.iconReflectVertical   = this.withReflectVerticalIcon   ? this.addIcon ('reflectVertical', radius) : null;
        this.iconSaveGroup = this.withSaveGroupIcon ? this.addIcon ('saveGroup', radius) : null;

        this.placeIcons();

        if (transform)
            this.grafic.setTransform(transform);

        return this.grafic;
    }

    addBoxPoint(point, diameter)
    {
        const geoPoint = this.createGeoPoint(diameter * 0.5);
        this.t.makeTranslation(point[0], point[1], 0);
        geoPoint.setTransform(this.t);
        this.grafic.add(geoPoint);
        this.boxPoints.push(geoPoint);
    }
    /**
     * finally dispose of the temporary grafic, i.e. remove it from the drawing
     */
    dispose () {
        this.grafic?.removeFromParent();
    }

    /**
     * adapt the box or the placing transform of the temporary grafic
     * @param {*} box 
     * @param {*} transform  (optional) 
     */
    adapt (box, transform) {

        this.box = box;
        this.angle = transform ? Geometry.getRotationAngle(transform) : 0;
        const [x, y, z] = transform ? Geometry.getTranslation(transform) : [0, 0, 0];

        logger.log(`adapt( ${box.min.x} - ${box.max.x} x ${box.min.y} - ${box.max.y}, [${x}, ${y}, ${z}] ${this.angle} )`)

        const margin = Settings.get('selection.grafic.margin');
        
        this.adaptSelectionBox(this.area, this.box, margin);

        if (this.withBoxPoints.length) {
            const points = this.calculateGeoPoints(this.box, margin);

            points.forEach( (point, index) => {
                const boxPoint = this.boxPoints[index];
                if (boxPoint) {
                    this.t.makeTranslation(point[0], point[1], 0);
                    boxPoint.setTransform(this.t);
                }
            })
        }

        this.placeIcons();

        if (transform)
            this.grafic.setTransform(transform);
    }

    /**
     * place the icons related to the selection box
     */
    placeIcons () {
        const diameter = Settings.get('selection.grafic.iconDiameter');
        const dist = Settings.get('selection.grafic.iconDist');
        const margin = Settings.get('selection.grafic.margin');

        // put icons into the groups on top and bottom of the selection grafic
        this.topIcons = [];
        this.bottomIcons = [];
        this.iconRotate && this.topIcons.push('useRotateIcon');
        this.iconDelete && this.bottomIcons.push('useDeleteIcon');
        this.iconCopy && this.bottomIcons.push('useCopyIcon');
        this.iconGroup && this.bottomIcons.push('useGroupIcon');
        this.iconSaveGroup && this.bottomIcons.push('useSaveGroupIcon');
        this.iconReflectHorizontal && this.bottomIcons.push('useReflectHorizontalIcon');
        this.iconReflectVertical && this.bottomIcons.push('useReflectVerticalIcon');

        const iconBottomPosition = this.calculateIconPositionsX(this.bottomIcons, diameter);
        const iconTopPosition = this.calculateIconPositionsX(this.topIcons, diameter);

        this.placeIcon(this.iconRotate,             this.box, margin, this.angle, 'top',    iconTopPosition['useRotateIcon'], dist);
        this.placeIcon(this.iconDelete,             this.box, margin, this.angle, 'bottom', iconBottomPosition['useDeleteIcon'], dist);
        this.placeIcon(this.iconCopy,               this.box, margin, this.angle, 'bottom', iconBottomPosition['useCopyIcon'], dist);
        this.placeIcon(this.iconGroup,              this.box, margin, this.angle, 'bottom', iconBottomPosition['useGroupIcon'], dist);
        this.placeIcon(this.iconReflectHorizontal,  this.box, margin, this.angle, 'bottom', iconBottomPosition['useReflectHorizontalIcon'], dist);
        this.placeIcon(this.iconReflectVertical,    this.box, margin, this.angle, 'bottom', iconBottomPosition['useReflectVerticalIcon'], dist);
        this.placeIcon(this.iconSaveGroup,          this.box, margin, this.angle, 'bottom', iconBottomPosition['useSaveGroupIcon'], dist);
    }

     /**
     * apply a differential transform on the selection grafic
     * 
     * TODO: transform the root grafic is not always sufficient: 
     * Better transform all children, but not all: Some icons are to be relocated below the rotated box.
     * @param {*} transform 
     */

    applyTransform (transform) {
        this.angle += Geometry.getRotationAngle(transform);
        const [x, y, z] = transform ? Geometry.getTranslation(transform) : [0, 0, 0];
        logger.log(`applyTransform( , [${x}, ${y}, ${z}] ${this.angle} )`)

        this.grafic.applyTransform(transform);

        const diameter = Settings.get('selection.grafic.iconDiameter');
        const dist = Settings.get('selection.grafic.iconDist');
        const margin = Settings.get('selection.grafic.margin');

        const iconBottomPosition = this.calculateIconPositionsX(this.bottomIcons, diameter);
        const iconTopPosition = this.calculateIconPositionsX(this.topIcons, diameter);

        this.placeIcon(this.iconRotate,             this.box, margin, this.angle, 'top',    iconTopPosition['useRotateIcon'], dist);
        this.placeIcon(this.iconDelete,             this.box, margin, this.angle, 'bottom', iconBottomPosition['useDeleteIcon'],  dist);
        this.placeIcon(this.iconCopy,               this.box, margin, this.angle, 'bottom', iconBottomPosition['useCopyIcon'], dist);
        this.placeIcon(this.iconGroup,              this.box, margin, this.angle, 'bottom', iconBottomPosition['useGroupIcon'], dist);
        this.placeIcon(this.iconReflectHorizontal,  this.box, margin, this.angle, 'bottom', iconBottomPosition['useReflectHorizontalIcon'],  dist);
        this.placeIcon(this.iconReflectVertical,    this.box, margin, this.angle, 'bottom', iconBottomPosition['useReflectVerticalIcon'],  dist);
        this.placeIcon(this.iconSaveGroup,          this.box, margin, this.angle, 'bottom', iconBottomPosition['useSaveGroupIcon'], dist);
    }

    /**
     * calculate the relative x position for the icons in one icon group
     * @param {*} names
     * @param {*} iconLength
     * @param {*} extraDistance
     * @returns
     */
    calculateIconPositionsX(names, iconLength, extraDistance = 75) {

        let positions = [];
        let numIcons = names.length;
        let totalLength = iconLength + extraDistance;
        let offset = numIcons / 2.0;

        for (let i = 0; i < numIcons; i++) {
            positions[names[i]] = (i - offset + 0.5) * totalLength;
        }
        return positions;
        }
    /**
     * if the caller needs information about the current transform or box
     */
    getTransform () {
        return this.grafic.transform;
    }

    getRotationAngle () {
        return Geometry.getRotationAngle(this.grafic.transform);
    }

    getCenter (center) {
        Geometry.makeBoxCenter(center, this.box, this.grafic.transform);
    }

    /**
     * check if a PointEvent hits the selection area
     * @param {*} event 
     * @returns true, if inside, false otherwise
     */
    hitsArea (event) {
        const command = this.findCommand(event);
        return command && command.commandLine === '.select.area';
    }

    /**
     * check, if the box intersects with the selection area
     * @param {*} box 
     * @returns true, if hit, false otherwise
     */
    boxHitsArea (box) {
        return Geometry.boxesIntersect(box, this.box, this.grafic.transform);
    }

    /**
     * special for copySelection in calling actions
     * @param {*} dist 
     */
    shift (dist) {
        OpUtils.shift([ this.grafic ], dist);
        // HACK: In the action calling FltSelectGrafic copySelection does not restart
        // the filter, but faces the same problem with PointUp coming late and causing BreakEvent()
        this.gotPointUp = false;
    }

    /**
     * special for FltSelectGrafic.update
     */
    setTransform(transform) {
        if (transform && this.grafic)
            this.grafic.setTransform(transform);
    }

//-----------------------------------------------------------------------------------------------------------------
// Dispatcher interface
//-----------------------------------------------------------------------------------------------------------------

    //HACK: gotPointUp resolves to following bug:
    // - select a rectangle ActRectangle
    // - pick copy icon, actionPoint sends .select.copy
    // - ActRectangle copies and proceeds with selected copy, i.e. restarts FltSelectGrafic
    // - FltSelectGrafic, receives the following PointUp Event, actionPointUp sends BreakEvent
    // -> ActRectangle stops prematurely
    actionPoint (event) {
        logger.log(`actionPoint`);
        this.gotPointUp = true;
        return this.findCommand(event);
    }

    actionPointUp (event) {
        logger.log(`actionPointUp`);
        const command = this.findCommand(event);
        if (this.sendBreakEventOnVoid && !command && this.gotPointUp)
            return new BreakEvent();
        return event;    
    }

    /**
     * handles must not be interpreted as commands unless they are explicitly picked
     * E.g. a delete icon selected by dragging a selection rectangle would delete the selection.
     * @param {*} event 
     * @returns 
     */
    actionSelection (event) {
        //HACK: ugly side effect to modify the event by filtering!! 
        // better rework the dispatcher concept, maybe like a local action stack, by which every dispatcher may
        // propagate the event or replace it by another event to hand over to the next in the sequence.
         
        // remove SelectGrafic objects: they are not pickable in the sense, that they must be invisible for any
        // action, which receives the SelectionEvent

        event.objects = event.objects.filter(op => 
               op !== this.area 
            && op !== this.iconDelete 
            && op !== this.iconDelete?.parent.children[1] 
            && op !== this.iconCopy 
            && op !== this.iconCopy?.parent.children[1] 
            && op !== this.iconGroup
            && op !== this.iconGroup?.parent.children[1]
            && op !== this.iconSaveGroup
            && op !== this.iconSaveGroup?.parent.children[1]
            && op !== this.iconRotate
            && op !== this.iconRotate?.parent.children[1] 
            && op !== this.iconReflectHorizontal
            && op !== this.iconReflectHorizontal?.parent.children[1] 
            && op !== this.iconReflectVertical
            && op !== this.iconReflectVertical?.parent.children[1] 
            && !this.boxPoints.find(bp => bp === op));
        return null;
    }

    actionKey (event) {
        const raw = event.raw;
        if (raw.type === 'keydown' && raw.key === 'Delete') { return new CommandEvent('.select.delete'); }
    }

//-----------------------------------------------------------------------------------------------------------------
// private
//-----------------------------------------------------------------------------------------------------------------

    findCommand(event) {
        // assume event instanceof PointDef
        const hits = Pick.pick(event.view, event.raw);
        const point = event.p;

        if (this.iconDelete && hits.find(op => op === this.iconDelete))
            return new CommandEvent('.select.delete');
        if (this.iconCopy && hits.find(op => op === this.iconCopy))
            return new CommandEvent('.select.copy');
        if (this.iconGroup && hits.find(op => op === this.iconGroup))
            return new CommandEvent('.select.group');
        if (this.iconSaveGroup && hits.find(op => op === this.iconSaveGroup))
            return new CommandEvent('.select.saveGroup');
        if (this.iconRotate && hits.find(op => op === this.iconRotate))
            return new CommandEvent('.select.rotate', point);
        if (this.iconReflectHorizontal && hits.find(op => op === this.iconReflectHorizontal))
            return new CommandEvent('.select.reflectHorizontal', point);
        if (this.iconReflectVertical && hits.find(op => op === this.iconReflectVertical))
            return new CommandEvent('.select.reflectVertical', point);
        if (this.boxPoints) {
            if (hits.find(op => op === this.boxPoints[0]))
                return new CommandEvent('.select.leftBottom');
            if (hits.find(op => op === this.boxPoints[1]))
                return new CommandEvent('.select.bottom');
            if (hits.find(op => op === this.boxPoints[2]))
                return new CommandEvent('.select.rightBottom');
            if (hits.find(op => op === this.boxPoints[3]))
                return new CommandEvent('.select.right');
            if (hits.find(op => op === this.boxPoints[4]))
                return new CommandEvent('.select.rightTop');
            if (hits.find(op => op === this.boxPoints[5]))
                return new CommandEvent( '.select.top');
            if (hits.find(op => op === this.boxPoints[6]))
                return new CommandEvent( '.select.leftTop');
            if (hits.find(op => op === this.boxPoints[7]))
                return new CommandEvent( '.select.left');
            if (hits.find(op => op === this.boxPoints[8]))
                return new CommandEvent( '.select.center');
        }
        if (this.area  && hits.find(op => op === this.area))
            return new CommandEvent('.select.area', point);

        return null;
    }

    createSelectionBox (box, margin) {
        const points = this.calculateSelectionBoxPoints(box, margin);
        const face = OpFactory.createPolygon(points);
        face.pickable = true; //TODO: if area is pickable might be configurable
        face.style.fill = Settings.get('selection.grafic.fill');
        face.style.fillOpacity = Settings.get('selection.grafic.fillOpacity');
        face.style.stroke = Settings.get('selection.grafic.stroke');
        return face;
    }

    adaptSelectionBox (face, box, margin) {
        const points = this.calculateSelectionBoxPoints(box, margin);
        const path = ShapeUtils.createPolygonPath(points, true);
        face.setShapePath(path);
    }

    calculateSelectionBoxPoints (box, margin) {
        const minX = box.min.x - margin;
        const maxX = box.max.x + margin;
        const minY = box.min.y - margin;
        const maxY = box.max.y + margin;
        
        const points = [ [minX, minY], [maxX, minY], [maxX, maxY], [minX, maxY] ];
        return points;
    }

    calculateGeoPoints (box, margin) {
        const minX = box.min.x - margin;
        const midX = 0.5 * (box.min.x + box.max.x);
        const maxX = box.max.x + margin;
        const minY = box.min.y - margin;
        const midY = 0.5 * (box.min.y + box.max.y);
        const maxY = box.max.y + margin;
        
        const points = [ 
            [minX, minY, 'leftBottom'], 
            [midX, minY, 'bottom'], 
            [maxX, minY, 'rightBottom'], 
            [maxX, midY, 'right'], 
            [maxX, maxY, 'rightTop'], 
            [midX, maxY, 'top'], 
            [minX, maxY, 'leftTop'], 
            [minX, midY, 'left'],
            [midX, midY, 'center']
        ];
        return points;
    }

    /**
     * create a geo point, a handle for manipulation 
     * @param {*} size 
     * @returns 
     */
    createGeoPoint (size) {
        const r = size*.5;
        const op = OpFactory.createPolygon([[-r, -r], [-r, r], [r, r], [r, -r]]);
        //const op = OpFactory.createCircle(0, 0, r);
        op.style.fill = '#ffffff' //Settings.get('selection.grafic.fill');
        op.style.stroke = Settings.get('selection.grafic.stroke');
        return op;
    }

    /**
     * calculate the position of the icon with respect to the local coordinate system
     * of the selection grafic
     * 
     *  _______. top_____
     * |                 |
     * |                 |
     * |_______. ________|
     *          bottom
     * 
     * @param {*} icon 
     * @param {*} box       current selection area box
     * @param {*} margin    margin from settings
     * @param {*} angle     current angle of the selection box
     * @param {*} where     'top' or 'bottom'
     * @param {*} distX     relative position
     * @param {*} distY 
     */
    placeIcon (icon, box, margin, angle, where, distX, distY) {
        if (icon) {
            let [x, y] = this.calculateIconPosition(box, margin, where, distX, distY);

            // rotate the group back so that the icon is upright always
            Geometry.makePlacingTransform(this.t, x, y, 0, -angle);

            const group = icon.parent;
            group.setTransform(this.t);
        }
    }

    calculateIconPosition (box, margin, where, distX, distY) {
        const midX = 0.5 * (box.min.x + box.max.x);
        const midY = 0.5 * (box.min.y + box.max.y);
        switch (where) {
            case 'top': 
                return [midX + distX, box.max.y + margin + distY];
            case 'bottom':
                return [midX + distX, box.min.y - margin - distY];
            case 'right':
                return [box.max.x + margin + distX, midY];
            default:
                // internal error:
                return [midX, 0.5 * (box.min.y + box.max.y)];
        }
    }

    /**
     * adds a handle to 'grafic'
     * 
     * the handle is itself an OpGroup containing the circular pick area
     * and the svg image
     * 
     *              OpGroup
     *            /        \
     *  OpShapePath         OpShapePath
     * 
     * circle as sensitive      the svg image
     *  area to pick the 
     *   command
     *  
     * @param {*} name 
     * @param {*} radius 
     * @returns the circle OpShapePath
     */
    addIcon (name, radius) {
        const group = OpFactory.createGroup(name);
        this.grafic.add(group);

        const circle = this.createIconCircle(0, 0, radius);
        group.add(circle);
    
        // center align the icon's geometry in an square inscribed to the
        // icon circle i.e. of size 1/sqrt(2)*diameter
        const icon = this.createIcon(name, 0.59 * radius);
        group.add(icon);

        return circle;
    }

    /**
     * create a circular face for picking icons
     */
    createIconCircle (x, y, size) {
        const face = OpFactory.createCircle(0, 0, size);
        face.style.fill = Settings.get('selection.grafic.fill');
        face.style.stroke = Settings.get('selection.grafic.stroke');
        this.t.makeTranslation(x, y, 0);
        face.setTransform(this.t);
        return face;
    }

    /**
     * create an OpShapePath with the icon svg centered in (0,0)
     * @param {*} name 
     * @param {*} size 
     * @returns 
     */
    createIcon (name, size) {

        if (!svg[name])
            return;

        const op = OpFactory.createFromSVG(svg[name]);
        // The SVG pathes are taken from a bootstrap svg icon of size 16x16
        // attention: the y-direction in SVG is down, in our coordinates it is up
        // this explains the minus sign
        this.t.set( 2*size/16,  0,          0,  - size, 
                    0,          -2*size/16, 0,  + size, 
                    0,          0,          1,  0,
                    0,          0,          0,  1);
        op.setTransform(this.t);
        return op;
    }
}

const svg = Object.freeze({
    delete: '<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>',
    copy:   '<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>',
    group:   '<path d="M1.128 17L1.10604 1H1.49004C1.64 1 1.87404 1 1.87404 1L1.896 1.37647V5.89412V8.34118V10.0353H6.248C6.54628 10.076 6.63939 10.2299 6.632 10.7882V16.8118H5.992V11.1647L5.736 10.9765H1.896V16.8118H1M16.872 17L16.4883 16.8118M16.4883 16.8118L16.51 1H16.126H15.742L15.72 1.37647V5.89412V8.34118V10.0353H11.368C11.0697 10.076 10.9766 10.2299 10.984 10.7882V16.8118H11.624V11.1647L11.88 10.9765H15.72V16.8118H16.4883ZM16.4883 16.8118H17M8.296 16.8118H9.064V7.65183M9.064 7.65183H8.296M9.064 7.65183H12.776V7.02353H4.84V7.65183H8.296M8.296 7.65183V17"/>',
    ungroup:   '<path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5z"/>',
    rotate: /* temp Icon for saveGroup */ '<path d="M3.254,6.572c0.008,0.072,0.048,0.123,0.082,0.187c0.036,0.07,0.06,0.137,0.12,0.187C3.47,6.957,3.47,6.978,3.484,6.988c0.048,0.034,0.108,0.018,0.162,0.035c0.057,0.019,0.1,0.066,0.164,0.066c0.004,0,0.01,0,0.015,0l2.934-0.074c0.317-0.007,0.568-0.271,0.56-0.589C7.311,6.113,7.055,5.865,6.744,5.865c-0.005,0-0.01,0-0.015,0L5.074,5.907c2.146-2.118,5.604-2.634,7.971-1.007c2.775,1.912,3.48,5.726,1.57,8.501c-1.912,2.781-5.729,3.486-8.507,1.572c-0.259-0.18-0.618-0.119-0.799,0.146c-0.18,0.262-0.114,0.621,0.148,0.801c1.254,0.863,2.687,1.279,4.106,1.279c2.313,0,4.591-1.1,6.001-3.146c2.268-3.297,1.432-7.829-1.867-10.101c-2.781-1.913-6.816-1.36-9.351,1.058L4.309,3.567C4.303,3.252,4.036,3.069,3.72,3.007C3.402,3.015,3.151,3.279,3.16,3.597l0.075,2.932C3.234,6.547,3.251,6.556,3.254,6.572z" />',
    saveGroup: '<path d="M1.128 17L1.10604 1H1.49004C1.64 1 1.87404 1 1.87404 1L1.896 1.37647V5.89412V8.34118V10.0353H6.248C6.54628 10.076 6.63939 10.2299 6.632 10.7882V16.8118H5.992V11.1647L5.736 10.9765H1.896V16.8118H1M16.872 17L16.4883 16.8118M16.4883 16.8118L16.51 1H16.126H15.742L15.72 1.37647V5.89412V8.34118V10.0353H11.368C11.0697 10.076 10.9766 10.2299 10.984 10.7882V16.8118H11.624V11.1647L11.88 10.9765H15.72V16.8118H16.4883ZM16.4883 16.8118H17M8.296 16.8118H9.064V7.65183M9.064 7.65183H8.296M9.064 7.65183H12.776V7.02353H4.84V7.65183H8.296M8.296 7.65183V17"/>',
    reflectVertical: '<path d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5"/>',
    reflectHorizontal: '<path d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"/>'
});

