import * as THREE from 'three';
import { nextTick } from 'vue'

import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import { BreakEvent } from '@/frame/Event.js';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FloorPlan from '@/visual-events/actions/FloorPlan';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Geometry from '@/visual-events/model/Geometry';
import OpUtils from '@/visual-events/model/OpUtils';
import Pick from '@/visual-events/view/Pick';
import VariantDoor from '@/visual-events/actions/VariantDoor';
import Settings from '@/visual-events/data/Settings';
import { deg2Rad } from '@/frame/Useful';
import {DoorType} from '@/visual-events/actions/VariantDoor';

import Logger from '@/frame/Logger';

const logger = new Logger('ActDoor');

const State = Object.freeze({
    CREATE: 0,
    EDIT: 2
  });

  export default class ActDoor extends Action {
    constructor(args) {
      super();

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];
      this.color = Settings.get('floorplan.door.color', '#FFFFFF');
      this.thickness = Settings.get('floorplan.wall.thickness', 240);
      this.id  = Settings.get('floorplan.door.id', DoorType.ONE_WING);
      this.width = Settings.getOption('floorplan.door.variants', this.id, 'width');
      this.pivot = 'left';
      this.frame  = Settings.get('floorplan.door.frame', 70);
      this.sash  = Settings.get('floorplan.door.sash', 80);
      this.rail  = Settings.get('floorplan.door.rails', 20);
      this.strokeWidth  = Settings.get('floorplan.door.strokeWidth', 0.25);

      this.position = new THREE.Vector3(0, 0, 0);
      this.box = new THREE.Box2();
      this.wall = null;
      
      this.objects = [];
      if (args.length > 1)
        this.objects = args[1].filter(op => FloorPlan.getDoor(op));

      this.t = new THREE.Matrix4(); // for reuse inoder to avoid frequent allocation

      this.state = this.objects.length > 0 ? State.EDIT : State.CREATE;
    }

    actionStart () {
        logger.log(`actionStart`);
        if (this.state === State.EDIT) {
            this.evaluateSelection();
            this.addFltSelectGrafic();
        }
        else  {
            this.addFilter(new FltPointDef());
            this.op2D = this.createDoor();
            this.objects.push(this.op2D);
        }

        this.connectToGUI();
        this.togglePanelDoor();

        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        switch(this.state){
            case State.CREATE: {
                const opReference = this.objects[0];
                opReference.removeFromParent();
                theApp.model.changed2d = true;
                break;
            }
        }
        this.disconnectFromGUI();
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);

        this.position.x = event.p[0];
        this.position.y = event.p[1];

        switch(this.state){
            case State.CREATE: {
                if (this.wall) {
                    const result = FloorPlan.closestToWall(this.wall, this.position);
                    Geometry.makePlacingTransform(this.t, result.x, result.y, 0, result.angle);
                } else
                    return new BreakEvent(); // leave if not attached to wall

                const opReference = this.objects[0];
                opReference.setTransform(this.t);
                theApp.model.changed2d = true;

                // proceed with editing
                this.state = State.EDIT;
                this.addFltSelectGrafic();
                this.connectToGUI();

                break;
            }
        }   
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);

        this.position.x = event.p[0];
        this.position.y = event.p[1];
        switch(this.state){
            case State.CREATE: {
                let hits = Pick.pick(event.view, event.raw);
                const wall = this.findWall(hits);
                if (wall)
                    this.wall = wall;
                
                const op = this.objects[0];
                const angle = Geometry.getRotationAngle(op.transform);

                if (this.wall) {
                    const result = FloorPlan.closestToWall(this.wall, this.position);
                    Geometry.makePlacingTransform(this.t, result.x, result.y, 0, result.angle);
                } else
                    Geometry.makePlacingTransform(this.t, this.position.x, this.position.y, 0, angle);
 
                op.setTransform(this.t);
                theApp.model.changed2d = true;

                break;
            }
        }
    }

    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'color') {
            this.color = event.value;
            this.editDoor();
            done = true;
        }

        if (event.attribute === 'door.width') {
            this.width = event.value;
            this.editDoor();

            this.adaptBox();
            this.getFilter().update(this.box, this.objects[0].transform);
            done = true;
        }

        if (event.attribute === 'thickness') {
            this.thickness = event.value;
            this.editDoor()
            done = true;
        }

        if (event.attribute === 'door.frame') {
            this.frame = event.value;
            this.editDoor()
            done = true;
        }

        if (event.attribute === 'pivot') {
            this.pivot = event.value;
            this.editDoor()
            done = true;
        }

        if (event.attribute === 'id') {
            this.id = event.value;
            this.editDoor()
            done = true;
        }

        return done ? null : event;
    }

    actionCommand(event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.CREATE:
                // does not happen
                break;
            case State.EDIT: {
                switch (cmd) {
                    case '.select.delete': {
                        OpUtils.deleteSelection(this.objects);
                        theApp.model.changed2d = true;
                        return new BreakEvent();
                    }
                    case '.select.reflectHorizontal': {
                        const op = this.objects[0];
                        let [x, y, z] = Geometry.getTranslation(op.transform);
                        
                        const angle = Geometry.getRotationAngle(op.transform) + 180;

                        if(angle === 270 || (angle >= 89 && angle <= 90))
                            x += angle < 270 ? - this.thickness : this.thickness;

                        if(angle === 180 || angle === 360)
                            y += angle === 180 ? - this.thickness : this.thickness;
                        
                        Geometry.makePlacingTransform(this.t, x, y, z, angle);
                        op.setTransform(this.t);
                        this.adaptBox();
                        this.getFilter().update(this.box, op.transform);
                        this.addFltSelectGrafic();
                        break;
                    }
                    case '.select.reflectVertical': {
                        this.pivot = this.pivot === 'left' ? 'right' : 'left';
                        this.editDoor();
                        break;                        
                    }
                    case '.select.dragBoxPoint': {
                        const dragMode = event.args[0];
                        const box = event.args[1];

                        switch (dragMode)
                        {
                            case 'left':
                            case 'right': {
                                const op = this.objects[0];
                                const width = this.width;
                                this.width = Math.abs(box.max.x - box.min.x);
                                
                                this.editDoor();
                                
                                // move along the wall by half of the width
                                const dist = (dragMode === 'left' ? -1 : 1 ) * 0.5 * (this.width - width);
                                let [x, y, z] = Geometry.getTranslation(op.transform);
                                const angle = Geometry.getRotationAngle(op.transform);
                                x += Math.cos(deg2Rad(angle)) * dist;
                                y += Math.sin(deg2Rad(angle)) * dist;
                                Geometry.makePlacingTransform(this.t, x, y, z, angle);
                                op.setTransform(this.t);
                                
                                const panel = theApp.findDialogByName('PanelDoor');
                                panel?.update(this);
        
                                this.adaptBox();
                                this.getFilter().update(this.box, op.transform);
                                break;
                            }
                            case 'center': {
                                // start drag&drop
                                break;
                            }
                            default:
                                // should not happen
                                break;
                        }
                       
                        return null;
                    }
                }
            } // case EDIT
        } // switch state

        return event;
    }

    createDoor()
    {
        const variant = new VariantDoor(this.width, this.thickness, this.color, this.pivot, this.id, this.frame, this.sash, this.rail, this.strokeWidth);
        const opReference = variant.create();

        const translation = this.t;
        translation.makeTranslation(this.position.x, this.position.y, 0);
        //transform the axis point
        opReference.setTransform(translation);

        this.root2D.add(opReference);

        return opReference;
    }

    editDoor () {
        const variant = new VariantDoor(this.width, this.thickness, this.color, this.pivot, this.id, this.frame, this.sash, this.rail, this.strokeWidth);
        for (const opReference of this.objects) {
            variant.edit(opReference);
            theApp.model.changed2d = true;
        }
    }

    findWall (objects) {
        const walls = objects.filter(op => FloorPlan.getWall(op));
        return walls.length > 0 ? walls[0] : null;
    } 

    /** 
     * while editing FltSelectGrafic displays the box and offers handles
     */
    addFltSelectGrafic () {
        this.adaptBox();
        const transform = this.objects[0].transform;
        const filter = new FltSelectGrafic(this.box, transform).useDeleteIcon().useBoxPoints(['left', 'right']).useDragAndDrop(false);

        if (this.id === DoorType.ONE_WING) {
            filter.useReflectHorizontalIcon().useReflectVerticalIcon();
        }

        if (this.id === DoorType.TWO_WING) {
            filter.useReflectHorizontalIcon();
        }

        this.addFilter(filter);
    }

    /**
     * display as box the area of the opening which covers the wall
     */
    adaptBox () {
        this.box.min.x = - 0.5 * this.width; 
        this.box.max.x =   0.5 * this.width; 
        this.box.min.y = - this.thickness;
        this.box.max.y = 0;
    }

    evaluateSelection () {
        //TODO: wenn unterschiedliche Styles, dann was???
        for (const opReference of this.objects) {

            const symbolId  = opReference.symbolId;
            const symbol = theApp.model.symbols.get(symbolId);
            const json = symbol.getAttribute('$variant');
            
            this.color = json.opts.color;
            this.width = json.opts.width;
            this.thickness = json.opts.thickness;
            this.pivot = json.opts.pivot;
            this.id = json.opts.id;
            this.frame = json.opts.frame;
            this.sash = json.opts.sash;
            this.rail = json.opts.rail;
            this.strokeWidth = json.opts.strokeWidth;
        }
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Floorplan');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('DlgFloorplan');
        nextTick(() => {
            const panelDoor = theApp.findDialogByName('PanelDoor');
            panelDoor?.update(this);
        })
    }
    
    togglePanelDoor (){
        nextTick(() => {
            if (this.state === State.EDIT) {
                const dlgFloorplan = theApp.findDialogByName('DlgFloorplan')
                dlgFloorplan.open('Door')
                dlgFloorplan.close('Wall')
                dlgFloorplan.close('Window')
            }
            nextTick (()=>{
            const panelDoor = theApp.findDialogByName('PanelDoor');
            panelDoor.setActiveButton(this.id)
            })
        })
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
        const panelDoor = theApp.findDialogByName('PanelDoor');
        panelDoor?.setDeactiveButton()
    }
}