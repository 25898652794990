<template>
        <div class="d-flex flex-wrap gap-1 m-3 align-content-start">
            <button
                v-for="(value, key) in buttons"
                :key="key"
                :class="['btn ve-btn-outline', { active: activeButton === key }]"
                @click=onClickButton(key)
                :name="value.name"
                :title="value.name"
            >
                <i :class="['ve-iconbar-draw-icon', value.icon]"></i>
                <p class="ve-iconbar-draw-icon-text">{{ value.name }}</p>
            </button>
        </div>
</template>

<script>
import theApp from '@/frame/Application';
import Action from '@/frame/Action';
import Settings from '@/visual-events/data/Settings';

export default {
    name: 'IconBarDraw',
    data () {
        return {
            activeButton: Settings.get('nav.startButton'),
            buttons: Settings.get('draw.buttons')
        }
    },
    mounted () {
        theApp.addDialog('IconBarDraw', this);
    },
    unmounted () {
        theApp.removeDialog('IconBarDraw');
    },
    methods: {
        setActiveButton(key) {
            this.activeButton = key
        },
        onClickButton(key) {
            this.activeButton = key

            const button = this.buttons[key]
            if (button && button.command)
                theApp.executeCommand(button.command)
            else
                Action.stopAll()
        }   
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/ve-variables.scss';
.ve-iconbar-draw-icon-text {
    font-size: 12px; 
    margin-top: 5px; 
    overflow-x: hidden;
    @if $iconbar-draw-button-show-text {
        display: block;
    } @else {
        display: none;
    }
}
.ve-iconbar-draw-icon {
    @if $iconbar-draw-button-show-text == false {
        font-size: 16px;
    }
}
</style>