<template>
    <div class="symbol-library-panel">
        <div class="ve-panel-header">
            Favoriten
        </div>
        <WebGLRenderCanvas v-bind:viewName="'Favorites'">Symbole</WebGLRenderCanvas>
    </div>
</template>

<script>
import WebGLRenderCanvas from '@/vuejs/components/WebGLRenderCanvas.vue';
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import Logger from '@/frame/Logger';

export default {
    name: 'PaneSimpleFavorites',
    components: {
        WebGLRenderCanvas
    },
    methods: {
        load() {
            const notebook = `${Settings.get('.pathFavorites')}/${Settings.get('.favorites')}`
            theApp.sendCommand(`.VisualEvents.loadFavorites "${notebook}"`)
        },
        save() {
            const notebook = `${Settings.get('.pathFavorites')}/${Settings.get('.favorites')}`
            theApp.sendCommand(`.VisualEvents.saveFavorites "${notebook}"`)
        }
    },
    mounted () {
        this.load();
    },
    updated () {
        this.load();
    },
    beforeUnmount () {
        this.save();
    }
}
</script>

<style scoped>.symbol-library-panel {
    height: 100%;
    width: 500px;
    overflow: hidden;
}
</style>