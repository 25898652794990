<template>
  <div class="form-group mt-2">
      <label :for="this.$.uid" class="form-label">{{label}}</label>
      <div class="input-group">
        <!-- title="" to remove the tooltip from HTML -->
        <input type="number" title="" :min="min" :max="max" :step="step" class="form-control" :id="this.$.uid" :value="displayedValue" @blur="focusOut" @focus="focusIn" @input="onInput" >
        <span class="input-group-text">{{unit}}</span>
      </div>
  </div>
</template>

<script>
import { clamp, periodic, equals } from '@/frame/Useful'

export default {
  name: 'InputNumberWithUnit',
  props: {
    label: String,
    modelValue: Number,
    min: Number,
    max: Number,
    step: Number,
    periodic: {
      type: Boolean,
      default: false
    },
    unit: {
      type: String,
      default: 'mm'
    },
    scale: {
        type: Number,
        default: 1.0
    },
    digits: {
        type: Number,
        default: 0
    }
  },
  data () {
    return {
        editing: false, //TODO: not yet exploited, could distinguish between having focus or not
        typingTimer: undefined,
        interval: 1000,
        value: 0
    }
  },
  computed: {
    displayedValue: {
        get() {
            const value = Number.parseFloat(this.modelValue)
            if (!Number.isNaN(value))
                return this.clamp(Number.parseFloat((this.modelValue / this.scale).toFixed(this.digits)))
        }
    },
  },
  emits: ['update:modelValue'],
  methods: {
    focusIn () {
        this.editing = true
    },
    focusOut () {
        this.$forceUpdate(); 
        this.editing = false
    },
    scaleValue (value) {
        return Number(value) * this.scale;
    },
    clamp (value) {
      if (this.periodic) {
        //in case of undefined limits use defaults [0, 360] (most use case for angles)
        const min = typeof this.min === 'undefined' ? 0.0 : this.min
        const max = typeof this.max === 'undefined' ? 360.0 : this.max
        return this.guiPeriodic(Number(value), min, max)
      } else {
        // avoid crash in clamp if min or max is not defined
        const min = typeof this.min === 'undefined' ? Number.NEGATIVE_INFINITY : this.min
        const max = typeof this.max === 'undefined' ? Number.POSITIVE_INFINITY : this.max
        return clamp(Number(value), min, max)
      }
    },
    // when clamping to (-180°, 180°), in the GUI we expect to see 180°, the right border of the interval, instead of -180°
    // when clamping to (0°, 360°), we expect 0°, i.e. the left border of the interval
    guiPeriodic (value, min, max) {
      const a = periodic(value, min, max);
      if (equals(a, min, 1) && min < 0)
        return max;
      return a;
    },
    onInput (event) {

        if (this.periodic) {
            clearTimeout(this.typingTimer);
            // accept the value only if the input is valid
            if (event.target.value !== '') {
                this.value = this.scaleValue(this.clamp(Number.parseFloat(Number(event.target.value).toFixed(this.digits))))
                clearTimeout(this.typingTimer)
                this.typingTimer = setTimeout(this.doneTyping, this.interval)
            }
        } else {
            clearTimeout(this.typingTimer);
            // accept the value only if the input is valid
            const min = typeof this.min === 'undefined' ? Number.NEGATIVE_INFINITY : this.min
            const max = typeof this.max === 'undefined' ? Number.POSITIVE_INFINITY : this.max
            if (event.target.value !== '' && event.target.value >= min && event.target.value <= max)
            {
                this.value = this.scaleValue(this.clamp(Number.parseFloat(Number(event.target.value).toFixed(this.digits))))
                this.typingTimer = setTimeout(this.doneTyping, this.interval)
            }
        }
    },
    doneTyping () {
        this.$forceUpdate(); 
        this.$emit('update:modelValue', this.value)
    }
  }
}

</script>

<style>
</style>