import * as THREE from 'three';

import theApp from "@/frame/Application";
import Action from "@/frame/Action";
import Settings from "../data/Settings";
import Logger from "@/frame/Logger";
import FltPointDef from "./FltPointDef";
import FloorPlan from "./FloorPlan";
import { CADdyType } from "../loader/CADdyType";
import FloorPlan3D from '../model/FloorPlan3D';
import OpFactory from '../model/OpFactory';
import Event3DPlacing from '../model/Event3DPlacing';
import FltSelectGraficPolygon from './FltSelectGraficPolygon';
import CommandLineScanner from '@/frame/CommandLineScanner';
import { nextTick } from 'vue';
import { BreakEvent } from '@/frame/Event';
import OpUtils from '../model/OpUtils';

const logger = new Logger('ActPolygonWall');

const State = Object.freeze({
    FIRST_POINT: 0,
    NEXT_POINT: 1,
    EDIT: 2
});

export default class ActPolygonWall extends Action {

    constructor (args) {
        super();

        this.args = args;

        this.view2D = theApp.findViewByName('2D Ansicht');
        this.root2D = this.view2D.getRoot().children[0];

        this.view3D = theApp.findViewByName('3D Ansicht');
        this.root3D = undefined;
        if (this.view3D)
            this.root3D = this.view3D.getRoot();

        this.points = [];
        this.thickness = Settings.get('floorplan.wall.thickness', 240);
        this.useStroke = Settings.get('style.useStroke', true);
        this.stroke = Settings.get('style.stroke', '#000000');
        this.strokeWidth = Settings.get('style.strokeWidth');
        this.useFill = Settings.get('floorplan.wall.useFill');
        this.fill = 'black';

        // cursor positions while creating
        this.position1 = new THREE.Vector3(0, 0, 0);
        this.position2 = new THREE.Vector3(0, 0, 0);

        // working box to avoid frequent allocations
        this.box = new THREE.Box3();

        // the preliminary floor plan while creating
        this.floorplan = null;
        this.floorplan3d = null;

        this.objects = [];
        if (args.length > 1)
            this.objects = args[1];

        //TODO: now edit floor plan is called if the selection contains a floor plan
        // get rid of the filtering, here
        const objects = args[1];
        if (objects)
            this.objects = objects.filter (op => FloorPlan.getWall(op));

        this.state = this.objects.length > 0 ? State.EDIT : State.FIRST_POINT;
    }

    actionStart () {
        logger.log('actionStart');
        
        if (this.state === State.EDIT) {
            this.floorplan = this.objects[0];
            this.floorplan3d = Event3DPlacing.findLink2DTo3D(this.floorplan)?.op3D;
            this.evaluateSelection();
            this.addFltSelectGrafic();
        } else {
            this.addFilter(new FltPointDef());
        }

        this.connectToGUI();
        return true;
    }

    actionDestroy () {
        logger.log('actionDestroy');
        this.clearPreliminaryFloorPlan();
        this.disconnectFromGUI();
    }

    actionBreak (event) {
        logger.log('actionBreak');
        switch (this.state) {
            case State.FIRST_POINT:
            case State.EDIT:
                return event;

            case State.NEXT_POINT:
                const points = this.points.slice(0, this.points.length - 1);
                if (points.length >= 3) {
                    this.adaptPreliminaryFloorPlan(points, this.thickness);

                    const [op2d, op3d] = [...this.finishFloorPlan()];

                    this.objects.push(op2d);
                    this.disconnectFromGUI();
                    this.floorplan = op2d;
                    this.floorplan3d = op3d;
                    this.evaluateSelection();
                    this.state = State.EDIT;
                    this.addFltSelectGrafic();
                    this.connectToGUI();
                } else {
                    return event;
                }
        }

        return null;
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.FIRST_POINT: {
                this.points[0] = [ event.p[0], event.p[1] ];
                this.points[1] = [ event.p[0] + 1, event.p[1] + 1 ];
        
                this.addPreliminaryFloorPlan(this.points, this.thickness)

                this.state = State.NEXT_POINT;
                break;
            }

            case State.NEXT_POINT: {
                const nextindex = this.points.length;

                this.points[nextindex - 1] = [ event.p[0], event.p[1] ];
                this.points[nextindex] = [ event.p[0] + 1, event.p[1] + 1 ];

                this.adaptPreliminaryFloorPlan(this.points.slice(0, this.points.length - 1), this.thickness);
                break;
            }

            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }
    }

    actionDynamic (event) {
        logger.log('actionDynamic');
        switch (this.state) {
            case State.FIRST_POINT:
                break;

            case State.NEXT_POINT: {
                const nextindex = this.points.length;

                this.points[nextindex - 1] = [ event.p[0] + 1, event.p[1] + 1 ];

                this.adaptPreliminaryFloorPlan(this.points, this.thickness);
                break;
            }

            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }
    }

    actionCommand (event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.FIRST_POINT:
                // does not happen
                break;
            case State.NEXT_POINT:
                // does not happen
                break;
            case State.EDIT: {
                switch (cmd) {
                    case '.select.delete': {
                        this.deleteSelection();
                        return new BreakEvent();
                    }
                    case '.select.applyTransform': {
                        const tdiff = event.args[0];
                        for (const op of this.objects) {
                            op.applyTransform(tdiff);
                        }

                        return null;
                    }
                    case '.select.dragBoxPoint': {
                        const selectedPoint = event.args[0];
                        const newPos = event.args[1];

                        this.points[selectedPoint] = [ newPos.x, newPos.y ];
                        this.points[this.points.length - 1] = this.points[0];

                        this.adaptPreliminaryFloorPlan(this.points, this.thickness);

                        theApp.model.changed2d = true;
                        return null;
                    }
                }
            }
        }

        return event;
    }

    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'thickness') {
            this.thickness = event.value;
            this.adaptPreliminaryFloorPlan(this.points, this.thickness);
            this.adaptFltSelectGrafic();
            done = true;
        }

        if (event.attribute === 'fill') {
            this.fill = event.value;
            this.floorplan.setStyle({ fill: (this.useFill ? this.fill : 'none') });
            theApp.model.changed2d = true;
            done = true;
        }

        return done ? null : event;
    }

    addPreliminaryFloorPlan (points, thickness) {
        const style = { fill: 'none', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: thickness / 10 }
        this.floorplan = OpFactory.createLine(points[0][0], points[0][1], points[1][0], points[1][1]);
        this.floorplan.setStyle(style);
        FloorPlan.markAsWall(this.floorplan, FloorPlan.TYPES.POLYGON, thickness);

        this.root2D.add(this.floorplan);
        theApp.model.changed2d = true;

        if (this.root3D) {
            const group = OpFactory.createGroup('floorplan3d');
            const opFloor = OpFactory.createMesh(CADdyType.SOLID, 'floor', undefined, 'floor.gltf');
            opFloor.setAttribute('$isOp', true);
            group.add(opFloor);

            const opWalls = OpFactory.createMesh(CADdyType.SOLID, 'walls', undefined, 'walls.gltf');
            opWalls.setAttribute('$isOp', true);
            group.add(opWalls);
            
            this.floorplan3d = group;
            Event3DPlacing.link2DTo3D(this.floorplan, this.floorplan3d, '');
            this.root3D.add(this.floorplan3d);
            theApp.model.changed3d = true;
        }
    }

    adaptPreliminaryFloorPlan (points, thickness) {
        let path = undefined;
        let style = {};
        if (points.length === 2) {
            const line = OpFactory.createLine(points[0][0], points[0][1], points[1][0], points[1][1]);
            this.floorplan.setShapePath(line.path);
        } else {
            style = { fill: this.useFill ? this.fill : 'none', fillOpacity: 1, stroke: 'none', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}
            path = FloorPlan.createPolygonFloorPlanShapePath(points, thickness);
            this.floorplan.setShapePath(path);
            this.floorplan.setStyle(style);
        }

        FloorPlan.markAsWall(this.floorplan, FloorPlan.TYPES.POLYGON, thickness);
        theApp.model.changed2d = true;

        if (this.root3D && path) {

            if (this.floorplan3d.children[0].mesh && this.floorplan3d.children[1].mesh) {
                const gFloor = FloorPlan3D.extrudeFloor(path, thickness, true);
                const gWalls = FloorPlan3D.extrudeWalls(path, 3000, true);    
                this.floorplan3d.children[0].setGeometry(gFloor);
                this.floorplan3d.children[1].setGeometry(gWalls);
            } else {
                const gFloor = FloorPlan3D.extrudeFloor(path, thickness, false);
                const gWalls = FloorPlan3D.extrudeWalls(path, 3000, false);
                this.floorplan3d.children[0].mesh = gFloor;
                this.floorplan3d.children[1].mesh = gWalls;
                theApp.model.changed3d = true;
            }

            const tFloor = new THREE.Matrix4();
            tFloor.makeTranslation(0, 0, -thickness);
            this.floorplan3d.children[0].setTransform(tFloor);

            theApp.model.modifiedIn3D.push(this.floorplan3d.children[0]);
            theApp.model.modifiedIn3D.push(this.floorplan3d.children[1]);
        }
    }

    finishFloorPlan () {
        const op2d = this.floorplan;
        this.floorplan = null;
        this.root2D.add(op2d);

        const op3d = this.floorplan3d;
        this.floorplan3d = null;
        this.root3D.add(op3d);

        return [op2d, op3d];
    }

    evaluateSelection () {
        this.points = [];
        const points = this.floorplan.path.currentPath.getPoints();
        for (const point of points)
            this.points.push([ point.x, point.y ]);
        this.thickness = FloorPlan.getWallThickness(this.floorplan);
        this.fill = this.floorplan.style.fill;
    }

    deleteSelection () {
        OpUtils.deleteSelection(this.objects);
        theApp.model.changed2d = true;

        if (this.root3D) {
            OpUtils.deleteSelection([this.floorplan3d]);
            theApp.model.changed3d = true;
        }

        this.objects = [];
        this.clearPreliminaryFloorPlan();
        this.removeFilter();
        this.disconnectFromGUI();
        this.state = State.FIRST_POINT;
        this.addFilter(new FltPointDef());
        this.connectToGUI();
    }

    addFltSelectGrafic () {
        if (this.objects.length === 1) {
            const transform = this.objects[0].transform;
            this.addFilter(new FltSelectGraficPolygon(this.points, transform).useGeoPoints())
        }
    }

    adaptFltSelectGrafic () {
        switch (this.state) {
            case State.FIRST_POINT:
            case State.NEXT_POINT:
                // FltSelectGrafic not active
                break;
            case State.EDIT: {
                this.getFilter().update(this.points, this.transform);
                break;
            }
        }
    }

    clearPreliminaryFloorPlan () {
        switch (this.state) {
            case State.FIRST_POINT:
            case State.NEXT_POINT : {
                if (this.floorplan) {
                    this.root2D.remove(this.floorplan);
                    this.floorplan = null;
                    theApp.model.changed2d = true;
                }
                if (this.floorplan3d) {
                    this.root3D.remove(this.floorplan3d);
                    this.floorplan3d = null;
                    theApp.model.changed3d = true;
                }
                break;
            }
        }
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Floorplan');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('DlgFloorplan');
        nextTick(() => {
            const dlgFloorplan = theApp.findDialogByName('DlgFloorplan');
            dlgFloorplan?.open('Wall');
            nextTick(() => {
                const panelWall = theApp.findDialogByName('PanelWall');
                panelWall?.setActiveButton(2);
                panelWall?.update(this);
            })
        })
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
        const panelWall = theApp.findDialogByName('PanelWall');
        panelWall?.setDeactiveButton();
    }

}