<template>
    <div class="col-12 mb-2">
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="number-enabled" v-model="displaySeatNo" @change="onChangeDisplaySeatNo">
            <label class="form-check-label mb-2" for="number-toggle">Sitzplatznummerierung</label>
        </div>
    </div>
    <div v-if="displaySeatNo">
        <div class="col-12 mb-2">
            <InputNumber label="Beginnen mit Nummer" :min="1" v-model="startNumber" @update:modelValue="onChangeStartNumber" />
        </div>   
        <div class="col-12">
            <fieldset Class="mt-2">
                <p class="fw-bold mb-0">In der Reihe</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-row" id="numeration-row-left" v-model="modeInRow" value="LEFT" @change="onChangeModeInRow">
                    <label class="form-check-label" for="numeration-row-left">
                        von links
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-row" id="numeration-row-right" v-model="modeInRow" value="RIGHT" @change="onChangeModeInRow">
                    <label class="form-check-label" for="numeration-row-right">
                        von rechts
                    </label>
                </div>
            </fieldset>
        </div> 
        <!-- <div class="col-12">
            <fieldset>
                <p class="fw-bold">Alternierend</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-none" v-model="modeAlternate" value="NORMAL" @change="onChangeModeAlternate">
                    <label class="form-check-label" for="numeration-alternating-none">
                        Nicht (fortlaufend)
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-outside" v-model="modeAlternate" value="OUTER" @change="onChangeModeAlternate">
                    <label class="form-check-label" for="numeration-alternating-outside">
                        Von außen
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-inside" v-model="modeAlternate" value="INNER" @change="onChangeModeAlternate">
                    <label class="form-check-label" for="numeration-alternating-inside">
                        Von innen
                    </label>
                </div>
            </fieldset>
        </div>  -->
        <div class="col-12">
            <fieldset>
                <p class="fw-bold mb-0">Reihen</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-front" v-model="modeRows" value="FORWARD" @change="onChangeModeRows">
                    <label class="form-check-label" for="numeration-rows-front">
                        von vorne
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-back" v-model="modeRows" value="BACKWARD" @change="onChangeModeRows">
                    <label class="form-check-label" for="numeration-rows-back">
                        von hinten
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-rows" id="numeration-rows-per-row" v-model="modeRows" value="PER_ROW" @change="onChangeModeRows">
                    <label class="form-check-label" for="numeration-rows-per-row">
                        pro Reihe
                    </label>
                </div>
            </fieldset>
        </div>
    </div>
     
   
</template>

<script>
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputNumber from './InputNumber.vue';

export default {
  name: 'PanelRenumberSeats',
  components: {
    InputNumber
  },
  data () {
    return {
        startNumber : Settings.get('numeration.startNumber'),
        modeInRow : Settings.get('numeration.modeInRow'),
        modeAlternate : Settings.get('numeration.modeAlternate'),
        modeRows : Settings.get('numeration.modeRows'),
        displaySeatNo : Settings.get('numeration.displaySeatNo'),
    }
  },
  mounted () {
    theApp.addDialog('PanelRenumberSeats', this)
  },
  unmounted () {
    theApp.removeDialog('PanelRenumberSeats')
  },
  methods: {
    update(action) {
        this.startNumber = action.renumberTool.startNumber
        this.modeInRow = action.renumberTool.modeInRow
        this.modeAlternate = action.renumberTool.modeAlternate
        this.modeRows = action.renumberTool.modeRows
        this.displaySeatNo = action.renumberTool.displaySeatNo
    },
    onChangeStartNumber() {
      theApp.executeValueEvent("startNumber", this.startNumber)
      Settings.set('numeration.startNumber', this.startNumber)
    },
    onChangeModeInRow () {
      theApp.executeValueEvent("modeInRow", this.modeInRow)
      Settings.set('numeration.modeInRow', this.modeInRow)
    },
    onChangeModeAlternate () {
      theApp.executeValueEvent("modeAlternate", this.modeAlternate)
      Settings.set('numeration.modeAlternate', this.modeAlternate)
    },
    onChangeModeRows () {
      theApp.executeValueEvent("modeRows", this.modeRows)
      Settings.set('numeration.modeRows', this.modeRows)
    },
    onChangeDisplaySeatNo () {
      theApp.executeValueEvent("displaySeatNo", this.displaySeatNo)
      Settings.set('numeration.displaySeatNo', this.displaySeatNo)
    },
  }
}
</script>

<style scoped>
</style>