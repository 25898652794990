import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import * as THREE from 'three';

export const WindowType = Object.freeze({
    ONE_CASEMENT : 'b9d5b489-a217-481e-9457-ed5b211b8fd5',
    TWO_CASEMENT : 'b9d5b489-a217-481e-9457-ed5b211b8fd6',
    THREE_CASEMENT : 'b9d5b489-a217-481e-9457-ed5b211b8fd7',
    FOUR_CASEMENT : 'b9d5b489-a217-481e-9457-ed5b211b8fd8',
});

export default class VariantWindow extends Variant {
    constructor (width, thickness, color, jamb, casement, mullion, sash, rail, strokeWidth) {
        const opts = {
            width: width,
            thickness: thickness,
            color: color,
            jamb: jamb,
            casement: casement,
            mullion: mullion,
            sash: sash,
            strokeWidth: strokeWidth,
            rail: rail
        }
        super ('SHAPE_WINDOW', 1, 'standard window', opts);
        this.t = new THREE.Matrix4();
    }

    createSymbol () {
        const width = this.json.opts.width;
        const thickness = this.json.opts.thickness;
        const color = this.json.opts.color;
        const jamb = this.json.opts.jamb;
        const casement = this.json.opts.casement;
        const mullion = this.json.opts.mullion;
        const sash = this.json.opts.sash;
        const rail = this.json.opts.rail;
        const strokeWidth = this.json.opts.strokeWidth;
        const sashYTop = -(thickness/2 - sash/2);
        const sashYBottom = -(thickness/2 + sash/2);

        const opSymbol = OpFactory.createSymbol(`${this.description}`);

        const pointsWindow =[
            [-0.5 * width, -thickness],
            [ 0.5 * width, -thickness],
            [ 0.5 * width, 0],
            [-0.5 * width, 0],
        ];
        const window = OpFactory.createPolygon(pointsWindow);
        window.style.fill = color;
        window.style.stroke = color;
        window.style.strokeWidth = strokeWidth;
        opSymbol.add(window);

        const pointsSash =[
            [-0.5 * width + strokeWidth/2, sashYTop],
            [ 0.5 * width - strokeWidth/2, sashYTop],
            [ 0.5 * width - strokeWidth/2, sashYBottom ],
            [-0.5 * width + strokeWidth/2, sashYBottom ],
        ];
        const drawSash = OpFactory.createPolygon(pointsSash);
        drawSash.style.fill = color;
        drawSash.style.stroke = '#000000';
        drawSash.style.strokeWidth = strokeWidth;
        opSymbol.add(drawSash);

        const railTop = OpFactory.createLine(- 0.5 * width + jamb, sashYTop - rail, 0.5 * width - jamb, sashYTop - rail);
        railTop.style.fill = '#000000';
        railTop.style.stroke = '#000000';
        railTop.style.strokeWidth = strokeWidth;
        opSymbol.add(railTop);

        const railBottom = OpFactory.createLine(- 0.5 * width + jamb, sashYBottom + rail, 0.5 * width - jamb, sashYBottom + rail);
        railBottom.style.fill = '#000000';
        railBottom.style.stroke = '#000000';
        railBottom.style.strokeWidth = strokeWidth;
        opSymbol.add(railBottom);

        let mullionWidth = -0.5 * width;
        const lineLeft = OpFactory.createLine(mullionWidth + jamb, sashYTop, mullionWidth + jamb, sashYBottom);
        lineLeft.style.fill = '#000000';
        lineLeft.style.stroke = '#000000';
        lineLeft.style.strokeWidth = strokeWidth;
        opSymbol.add(lineLeft);

        mullionWidth = mullionWidth + jamb;
        const paneWidth = (width - 2 * jamb - (casement -1) * mullion) / casement;
        for (let i = 0; i <casement - 1; i++) {
            mullionWidth = mullionWidth + paneWidth;

            const pointsMullion =[
                [mullionWidth - mullion, sashYTop],
                [mullionWidth + mullion, sashYTop],
                [mullionWidth + mullion, sashYBottom],
                [mullionWidth - mullion, sashYBottom],
            ];
            const drawMullion = OpFactory.createPolygon(pointsMullion);
            drawMullion.style.fill = color;
            drawMullion.style.stroke = '#000000';
            drawMullion.style.strokeWidth = strokeWidth;
            opSymbol.add(drawMullion);

            mullionWidth = mullionWidth + mullion;
        }

        mullionWidth = mullionWidth + paneWidth;
        const lineRight = OpFactory.createLine(mullionWidth, sashYTop, mullionWidth, sashYBottom);
        lineRight.style.fill = '#000000';
        lineRight.style.stroke = '#000000';
        lineRight.style.strokeWidth = strokeWidth;
        opSymbol.add(lineRight);

        return opSymbol;
    }
}