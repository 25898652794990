import GrfStrategy from './GrfStrategy';

export default class GrfBookingStrategy extends GrfStrategy {

    constructor(merge_fill_colors, merge_fill_opacity) {
        super();

        this.state_fill_colors = {
            //bookable states in the API
            'bookable':         'none',
            //'bookable':             '#4488aa',
            'booked':           '#eeeeee',
            'nonbookable':      '#dddddd',
            'reserved':         '#996666',
            //in cart
            'inMyCart':         '#0044aa',
            // diagnostic: intermediate between selection and cart operation
            'addingToCart':     '#0044aa', // or more eye-catching green ='#00FF00',
            'removingFromCart': 'none', // // or more eye-catching red = '#FF0000',
        }

        this.state_fill_opacity = {
            //bookable states in the API
            'bookable':         'none',
            //'bookable':            1,
            'booked':           'none',
            'nonbookable':      'none',
            'reserved':         'none',
            //in cart
            'inMyCart':         'none',
            // diagnostic: intermediate between selection and cart operation
            'addingToCart':     0.9,
            'removingFromCart': 0.9,
        }

        this.state_stroke_colors = {
            //bookable states in the API
            'bookable':         'none',
            //'bookable':         '#008800',
            'booked':           '#880000',
            'nonbookable':      '#880088',
            'reserved':         '#000088',
            //in cart
            'inMyCart':         '#0044aa',
            // diagnostic: intermediate between selection and cart operation
            'addingToCart':     '#00FF00',
            'removingFromCart': '#FF0000',
        }

        this.state_fill_colors = { ...this.state_fill_colors, ...merge_fill_colors };
        this.state_fill_opacity = { ...this.state_fill_opacity, ...merge_fill_opacity };
    }

    fillColor(op) {
        const symbol = this.bottom();

        if (symbol) {
            const state = symbol.getAttribute('bookableState')

            if (state in this.state_fill_colors) {
                let fillColor = this.state_fill_colors[state];
                if (fillColor && typeof(fillColor) === 'object') {
                    const countBookable = parseInt(symbol.getAttribute('"$ticketing.item".attributes.$countBookable'));
                    const countMax = parseInt(symbol.getAttribute('"$ticketing.item".attributes.$countMax'));
                    if (countBookable !== undefined && countMax !== undefined && countMax > 0) {
                        const percentBooked = 100 - (countBookable / countMax * 100);
                        let i = 0;
                        Object.keys(fillColor).forEach((percent) => {
                            if (percentBooked >= percent) i = percent;
                        });
                        fillColor = fillColor[i];
                    } else {
                        fillColor = 'none';
                    }
                }
                
                if (fillColor && fillColor !== 'none')
                    return fillColor;
            }
        }

        return super.fillColor(op);
    }

    fillOpacity(op) {
        const symbol = this.bottom();

        if (symbol) {
            const state = symbol.getAttribute('bookableState')

            if (state in this.state_fill_opacity) {
                let fillOpacity = this.state_fill_opacity[state];
                if (fillOpacity && typeof(fillOpacity) === 'object') {
                    const countBookable = parseInt(symbol.getAttribute('"$ticketing.item".attributes.$countBookable'));
                    const countMax = parseInt(symbol.getAttribute('"$ticketing.item".attributes.$countMax'));
                    if (countBookable && countMax) {
                        const percentBooked = 100 - (countBookable / countMax * 100);
                        let i = 0;
                        Object.keys(fillOpacity).forEach((percent) => {
                            if (percentBooked >= percent) i = percent;
                        });
                        fillOpacity = fillOpacity[i];
                    } else {
                        fillOpacity = 'none';
                    }
                }

                if (fillOpacity && fillOpacity !== 'none')
                    return fillOpacity;
            }
        }

        return super.fillOpacity(op);
    }

    strokeColor(op) {
        const symbol = this.bottom();

        if (symbol) {
            const state = symbol.getAttribute('bookableState')

            if (state in this.state_stroke_colors) {
                const strokeColor = this.state_stroke_colors[state];
                if (strokeColor && strokeColor !== 'none')
                    return strokeColor;
            }
        }
        
        return super.strokeColor(op);
    }
}
