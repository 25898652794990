import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import BlockUtils from '@/visual-events/actions/BlockUtils';
import FltPick from '@/visual-events/actions/FltPick';
import { PickFlags } from '@/visual-events/actions/FltPick';
import * as Event from '@/frame/Event';
import OpReference from '@/visual-events/model/OpReference';
import OpShapePath from '@/visual-events/model/OpShapePath';
import ShapeUtils from '@/visual-events/model/ShapeUtils';
import TableShapeUtils from '@/visual-events/model/TableShapeUtils';
import OpText from '@/visual-events/model/OpText';
import FloorPlan from '@/visual-events/actions/FloorPlan';
import Logger from '@/frame/Logger';

const logger = new Logger('FltSelectAndDispatch');

/**
 * let the user select op objects and decide, which command is appropriate to handle the selection
 * 
 * The filter sequentially evaluates a list of dispatcher objects, i.e. objects with the CommandDispatcher interface.
 * On actionPoint it picks an object and returns a CommandEvent for editing that object.
 * On actionSelection it returns a command feasable to edit the selected objects, e.g.
 * - only circles -> editCircles with the possibility to edit the radius of all, and the color of all
 * - only rectangles -> editRectangles with the possibility to edit width, height and angle, color etc. of all 
 * - mixed circles and rectangles -> editStyle to edit only style properties
 * 
 * Attention: The PointEvent comes before the SelectionEvent
 * All dispatchers first evaluate actionPoint, and if one returns a command, it wins.
 * Only then all dispatchers check the selection.
 */
export default class FltSelectAndDispatch extends Action {
    constructor() {
      super();

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.view3D = theApp.findViewByName('3D Ansicht');

      this.dispatchers = [];

      this.first(new DefaultDispatcher());
    }

    /**
     * add a dispatcher in front of the sequence, i.e. will check before all others
     * @param {*} dispatcher 
     * @param {*} index 
     * @returns 
     */
    first (dispatcher) {
      this.remove(dispatcher);
      this.dispatchers.splice(0, 0, dispatcher);
      return this;
    }

    /**
     * append a dispatcher to sequence, i.e. will check after all others
     * @param {*} dispatcher 
     * @returns 
     */
    last (dispatcher) {
      this.remove(dispatcher);
      this.dispatchers.push(dispatcher);
      return this;
    }

    /**
     * remove dispatcher from the sequence
     * @param {*} dispatcher 
     * @returns 
     */
    remove (dispatcher) {
      const index = this.dispatchers.indexOf(dispatcher);
      if (index > -1)
        this.dispatchers.splice(index, 1);
      return this;
    }

    actionStart () {
        logger.log(`actionStart`);

        // PickFlags.POINT_EVENTS is important in order to let dispatchers decide based on whether the Selection comes from a mouse click or from a selecting by rectangle drag
        // e.g. SelectGrafic must not accidentally interprete selected handles unless they are explicitly picked.
        const pickFlags = PickFlags.NO_CLEAR_MSL | PickFlags.IGNORE_MSL  | PickFlags.POINT_EVENTS;
        this.filter = new FltPick(pickFlags).forView('2D Ansicht');
        this.addFilter(this.filter);

        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
    }

    actionPoint (event) {
      logger.log(`actionPoint ${this.state}`);

      for (const dispatcher of this.dispatchers) {
        const commandEvent = dispatcher.actionPoint(event);
        if (commandEvent) 
          return commandEvent;
      }

      return null;
    }

    actionPointUp (event) {
      logger.log(`actionPointUp ${this.state}`);

      for (const dispatcher of this.dispatchers) {
        const commandEvent = dispatcher.actionPointUp(event);
        if (commandEvent) 
          return commandEvent;
      }

      return null;
    }

    actionSelection (event) {
        logger.log(`actionSelection`);

        for (const dispatcher of this.dispatchers) {
          const commandEvent = dispatcher.actionSelection(event);
          if (commandEvent)
            return commandEvent;
        }
  
        return null;
    }

    actionMouse (event) {
      logger.log(`actionMouse ${event.raw.type} ${this.state}`);

      if (event.raw.type === 'mouseenter') {
        //disable rectangle selection in 3D
        const [view] = theApp.findView(event.raw.currentTarget);
        if (view === this.view3D)
          this.filter.setFlag(PickFlags.NO_DRAG);
        else if (view === this.view2D)
            this.filter.unsetFlag(PickFlags.NO_DRAG);
        return null;
      }

      return event;
    }

    actionKey (event) {
      logger.log(`actionKey`);

      for (const dispatcher of this.dispatchers) {
        const commandEvent = dispatcher.actionKey(event);
        if (commandEvent)
          return commandEvent;
      }

      return null;
  }
}    

//-----------------------------------------------------------------------------------------------------------------
// CommandDispatcher interface
//-----------------------------------------------------------------------------------------------------------------

export class CommandDispatcher {
  constructor () {}

  actionPoint (event) {
    return null;
  }

  actionPointUp (event) {
    return null;
  }

  actionSelection (event) {
    return null;
  }

  actionKey (event) {
    return null;
  }
}

//-----------------------------------------------------------------------------------------------------------------
// standard Dispatcher for VisualEvents
//-----------------------------------------------------------------------------------------------------------------

class DefaultDispatcher extends CommandDispatcher {

  constructor () {
    super();
  }

  actionSelection (event) {
    const commandLine = this.findAppropriateCommand(event.objects);
    if (!commandLine)
      return null;

    return new Event.CommandEvent(commandLine, event.objects);
  }

  /**
   * evaluate the objects in order to determine an appropriate command for editing
   * @param {*} objects 
   * @returns 
   */
  findAppropriateCommand (objects) {

      if (objects.length < 1)
        return null;

      if (this.areBlock(objects))
        return '.Ticketing.editBlock';
      else if (this.containsDoor(objects))
        return '.floorplan.editDoor';
      else if (this.containsWindow(objects))
        return '.floorplan.editWindow';
      else if (this.containsWalls(objects))
        return '.floorplan.editFloorPlan';
      else if (this.isSymbolCircle(objects)) //return '.Ticketing.editChairCircle';
        return '.Ticketing.editBlock';
      else if (this.isSymbolRectangle(objects)) //return '.Ticketing.editChairRectangle';
        return '.Ticketing.editBlock';
      else if (this.isSymbolChair(objects)) //return '.Ticketing.editChairBanquet';
        return '.Ticketing.editBlock';
      else if (this.isSymbolTableCircle(objects)) //return '.Ticketing.editDrawTableCircle';
        return '.Ticketing.editBlock';
      else if (this.isSymbolTableRectangle(objects)) //return '.Ticketing.editDrawTableRectangle';
        return '.Ticketing.editBlock';
      else if (this.areRectangles(objects)) 
        return '.Ticketing.editRectangle';
      else if (this.areCircles(objects)) 
        return '.Ticketing.editCircle';
      else if (this.arePolygon(objects)) 
        return '.Ticketing.editPolygon';
      else if (this.areTexts(objects)) 
        return '.Ticketing.editText';
      else if (this.areShapePath(objects))
        return '.Ticketing.completeGroup';    // previous Ticketing.editStyle
      else if (this.canEditStyle(objects))
        return '.Ticketing.completeGroup';   // previous Ticketing.editStyle
      else if (this.areGroups(objects) && this.areBlockItems(objects))
        return '.Ticketing.completeGroup';
      else
        return '.Ticketing.editSelection'; // previous Ticketing.editSelection
    }

    areBlock (objects) {
      let block = null;
      for (const op of objects) {
        const group = BlockUtils.findBlockGroup(op);
        if (group && !block)
          block = group;
        else if (!group || group !== block)
          return false;
        else 
          block = group;
      }
      return block;
    }

    containsDoor (objects){
      return objects.filter(op => FloorPlan.getDoor(op)).length > 0;
    }

    containsWindow (objects) {
        return objects.filter(op => FloorPlan.getWindow(op)).length > 0;
    }

    containsWalls (objects) {
      return objects.some(op => FloorPlan.getWall(op));
    }

    areRectangles (objects) {
      return !objects.some(op => !ShapeUtils.isRectangle(op));
    }

    areCircles (objects) {
      return !objects.some(op => !ShapeUtils.isCircle(op));
    }

    isSymbolCircle (objects) {
      return objects.length === 1 && ShapeUtils.isSymbolCircle(objects[0]);
    }

    areSymbolCircles (objects) {
      return !objects.some(op => !ShapeUtils.isSymbolCircle(op));
    }

    isSymbolRectangle (objects) {
      return objects.length === 1 && ShapeUtils.isSymbolRectangle(objects[0]);
    }

    areSymbolRectangle (objects) {
      return !objects.some(op => !ShapeUtils.isSymbolRectangle(op));
    }

    isSymbolChair (objects) {
      return objects.length === 1 && ShapeUtils.isSymbolChair(objects[0]);
    }

    areSymbolChair (objects) {
      return !objects.some(op => !ShapeUtils.isSymbolChair(op));
    }

    areShapePath (objects) {
      return !objects.some(op => !(op instanceof OpShapePath));
    }

    isSymbolTableCircle (objects) {
      return objects.length === 1 && TableShapeUtils.isSymbolTableCircle(objects[0]);
    }

    areSymbolTableCircle (objects) {
      return !objects.some(op => !TableShapeUtils.isSymbolTableCircle(op));
    }

    isSymbolTableRectangle (objects) {
      return objects.length === 1 && TableShapeUtils.isSymbolTableRectangle(objects[0]);
    }

    areSymbolTableRectangle (objects) {
      return !objects.some(op => !TableShapeUtils.isSymbolTableRectangle(op));
    }

    areReferences (objects) {
      return !objects.some(op => !(op instanceof OpReference));
    }

    isPolygon (objects) {
      return objects.length === 1 && ShapeUtils.isPolygon(objects[0]);
    }
    
    arePolygon (objects) {
      // do not offer polygon edit functionality for rectangles
      // polygon + rectangle is considered to be a heterogenous selection
      return !objects.some(op => !ShapeUtils.isPolygon(op) || ShapeUtils.isRectangle(op));
    }
    
    areTexts (objects) {
      return !objects.some(op => !(op instanceof OpText));
    }

    canEditStyle (objects) {
        return !objects.some(op => !(op instanceof OpText) && !(op instanceof OpShapePath));
    }

    areGroups (objects) {
      return !objects.some(op => (op.name === 'GROUPED_GEOMETRY'));
    }

    areBlockItems (objects) {
      const idCounts = {};

      for (const op of objects) {
          const block = BlockUtils.findBlockGroup(op);
          if (!block) continue;
          if(block.children.length > 1 || block.children[0]?.children.length > 1) return false;

          idCounts[block.id] = (idCounts[block.id] || 0) + 1;
          if (idCounts[block.id] > 1) return false;
      }

      return true;
    }
}
