<template>
  <div class="home" v-bind:style="{cursor: cursor}">
    <SideNav v-if="display2D" />
    <SidePane v-if="display2D" />
    <div :class="coupled2D3DClass">
      <div v-if="showOverlay" class="ve-overlay-absolute d-flex flex-column align-items-center justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="fs-5 fw-medium mt-2">Daten werden geladen...</span>
      </div>
      <div v-if="display2D" class="webgl-render-canvas">
        <WebGLRenderCanvas v-bind:viewName="'2D Ansicht'">2D-Ansicht</WebGLRenderCanvas>
        <div class="ticketing-icon-bar-view">
          <IconBarView :viewName="'2D Ansicht'" :zoomIn="2.0" :zoomOut="0.5" :zoomToFit="zoomToFit"/>
        </div>
      </div>
      <div v-if="display3D && useUnreal" class="webgl-render-canvas">
        <UnrealCanvas :viewName="'3D Ansicht'">3D-Ansicht</UnrealCanvas>
        <div class="ticketing-icon-bar-view">
          <IconBarView :viewName="'3D Ansicht'"/>
        </div>
        <div v-if="displayControlBar" class="ticketing-icon-bar-controls">
          <IconBarControls :viewName="'3D Ansicht'"/>
        </div>
      </div>
      <div v-if="display3D && !useUnreal" class="webgl-render-canvas">
        <WebGLRenderCanvas :viewName="'3D Ansicht'">3D-Ansicht</WebGLRenderCanvas>
        <div class="ticketing-icon-bar-view">
          <IconBarView :viewName="'3D Ansicht'"/>
        </div>
        <div v-if="displayControlBar" class="ticketing-icon-bar-controls">
          <IconBarControls :viewName="'3D Ansicht'"/>
        </div>
      </div>
    </div>
    <!-- <DiagnosticActionStack/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import theApp from '@/frame/Application'
import Action from '@/frame/Action'
import Logger from '@/frame/Logger'
import WebGLRenderCanvas from '@/vuejs/components/WebGLRenderCanvas.vue'
import IconBarView from '@/vuejs/components/IconBarView.vue'
import IconBarControls from '@/vuejs/components/IconBarControls.vue'
import ModelIO from '@/visual-events/model/ModelIO'
import SidePane from '@/vuejs/components/SidePane.vue'
import SideNav from '@/vuejs/components/SideNav.vue'
import Ticketing from '@/visual-events/ticketing/Ticketing'
import Inventory from '@/visual-events/inventory/Inventory'
import Settings from '@/visual-events/data/Settings'
import UtilSettings from '@/visual-events/data/UtilSettings'
import VisualEvents3DView from '@/visual-events/view/VisualEvents3DView'
import ActDefaultTicketing from '@/visual-events/actions/ActDefaultTicketing'
import config from '@/visual-events/data/ConfigPlaner'
import { nextTick } from 'vue'

import UnrealCanvas from '@/vuejs/components/UnrealCanvas.vue'

// in order to see the current action stack insert in the template
//     <DiagnosticActionStack/>
//   </div>
// </template>
// and uncomment 
//import DiagnosticActionStack from '@/vuejs/components/DiagnosticActionStack'

const logger = new Logger('Planer.vue');

export default {
  name: 'Planer',
  props: {
    eventId: String,
    model: String,
    action: String,
    apiKey: String,
    language: String,
    panels: String,
    symbols: String,
    useUnreal: Boolean
  },
  data () {
    return {
      cursor: 'default',
      showOverlay: false,
      display2D: true,
      display3D: true,
      displayControlBar: true,
      coupled2D3DClass: Settings.get('layout.split2D3Dvertically') ? 'coupled-2d-3d-row' : 'coupled-2d-3d-col'
    }
  },
  computed: {
    zoomToFit: {
      get () {
        return Settings.getOption('views', '2D Ansicht', 'zoomToFit')
      }
    },
  },
  components: {
    SideNav,
    WebGLRenderCanvas,
    IconBarView,
    IconBarControls,
    SidePane,
    //DiagnosticActionStack
    UnrealCanvas
  },
  methods: {
    setCursor(cursor) {
      this.cursor = cursor
    },
    setShowOverlay (overlay) {
      this.showOverlay = overlay;
    },
    async load() {

      //theApp.executeCommand('.VisualEvents.readSettings');
      await UtilSettings.read(Settings.get('.pathSettings'));
      
      this.setSplit2D3Dvertically(Settings.get('layout.split2D3Dvertically'));

      //TODO: find a better way to provide the apiKey and language in Ticketing and Inventory
      Ticketing.apiKey = this.apiKey
      Ticketing.language = this.language
      Inventory.apiKey = this.apiKey
      Inventory.language = this.language
        Settings.set('.pathFavorites', this.panels)
      if (this.symbols)
        Settings.set('.favorites', this.symbols)
      let pathModel = Settings.get('.model');
      if (this.eventId) {
        pathModel = await Ticketing.getPlanForEvent(this.eventId)
        Settings.set('.model', pathModel)
      }
      if (this.model) {
        pathModel = this.model;
        Settings.set('.model', pathModel)
      }

      this.setShowOverlay(true);

      //HACK: if threejs load GLTF files, if Unreal not; consider general concept of lazy evaluation of meshes only in the grafic
      const view3D = theApp.findViewByName('3D Ansicht');
      const withMeshes = view3D instanceof VisualEvents3DView;

      try {
        await ModelIO.load(pathModel, withMeshes);
      } catch (err) {
        await ModelIO.loadWebExport(pathModel, withMeshes);
      }

      this.removeFloorplanIfNotEmpty();

      if (withMeshes)
        this.setShowOverlay(false);

      const zoomToFit = Settings.getOption('views', '2D Ansicht', 'zoomToFit')
      if (pathModel)
        theApp.executeCommand(`.view.fitToCanvas "2D Ansicht" ${zoomToFit}' `)

      theApp.sendCommand(`${this.action}`)
    },
    setSplit2D3Dvertically (split2D3Dvertically) {
      this.coupled2D3DClass = split2D3Dvertically ? 'coupled-2d-3d-row' : 'coupled-2d-3d-col'
    },
    setViewLayout (display2D, display3D) {
      const display2DBefore = this.display2D
      const display3DBefore = this.display3D
      this.display2D = display2D
      this.display3D = display3D

      // threejs must be informed about the changed canvas size
      //HACK: first take only half of the space to give the other view
      // enough remaining space. Another call onWindowResize() fixes it up.
      nextTick(() => {
        const view2D = theApp.findViewByName("2D Ansicht")
        const view3D = theApp.findViewByName("3D Ansicht")

        if (this.display2D) {
          if (!display2DBefore) {
            this.addCameraControls();
            theApp.model.changed2d = true
            view2D.updateScene()
          }
          view2D.onWindowResize(.5)
        }
        if (this.display3D) {
          if (!display3DBefore) {
            theApp.model.changed3d = true
            theApp.model.changedLight = true;
            view3D.updateScene()
            view3D.initCameraPosition()
          }
          view3D.onWindowResize(.5)
        }
        if (this.display2D)
          view2D.onWindowResize()
        if (this.display3D)
          view3D.onWindowResize()

        //TODO: the concept of adding and removing camera controls is unclear
        // and different in 2D and 3D
        if (display2DBefore && !this.display2D)
          this.removeCameraControls()

        const iconBarView2D = theApp.findDialogByName('IconBarView(2D Ansicht)');

        if (this.display2D) {
          if (this.display3D) {
            iconBarView2D.unhideButton('Maximize');
            iconBarView2D.hideButton('Minimize');
          } else {
            iconBarView2D.hideButton('Maximize');
            iconBarView2D.unhideButton('Minimize');
          }
        }

        const iconBarView3D = theApp.findDialogByName('IconBarView(3D Ansicht)');

        if (this.display3D) {
          if (this.display2D) {
            iconBarView3D.unhideButton('Maximize');
            iconBarView3D.hideButton('Minimize');
          } else {
            iconBarView3D.hideButton('Maximize');
            iconBarView3D.unhideButton('Minimize');
          }
        }
      })
    },
    addCameraControls () {
      const view2D = theApp.findViewByName('2D Ansicht')
      const zoomMin = Settings.getOption('views', '2D Ansicht', 'zoomMin')
      const zoomMax = Settings.getOption('views', '2D Ansicht', 'zoomMax')
      view2D?.addCameraControls(zoomMin, zoomMax)
    },
    removeCameraControls () {
      const view2D = theApp.findViewByName('2D Ansicht')
      view2D?.removeCameraControls()
    },
    removeSunSkyIfNotUnreal () {
        logger.log(`removeSunSkyIfNotUnreal useUreal=${this.useUnreal}`)
        // remove SunSky Icon, if not Unreal
        // HACK: find better solution to modify the default SideNav according to
        // special use case, such as
        // - functionality generally not available due to application modes
        // - functionality temporarily not available
        // - funtionality not available due to role access rules etc,
        if (!this.useUnreal) {
            const all = Settings.get('nav.buttons')
            const filtered = Object.keys(all)
                .filter(key => key !== 'SunSky')
                .reduce((obj, key) => {
                    obj[key] = all[key];
                    return obj;
                }, {});
            Settings.set('nav.buttons', filtered)
        }
    },
    removeFloorplanIfNotEmpty () {
      const empty = theApp.model.emptyOnLoad();
      let containsFloorplan = false;
      if (!empty) {
        containsFloorplan = theApp.model.drawingContains('$wall');
        if (!containsFloorplan) {
          const all = Settings.get('nav.buttons')
          const filtered = Object.keys(all)
              .filter(key => key !== 'Floorplan')
              .reduce((obj, key) => {
                obj[key] = all[key];
                return obj;
              }, {});
          Settings.set('nav.buttons', filtered);
          theApp.findDialogByName('SideNav')?.update();
        }
      }
      Settings.set('floorplan.enabled', empty || containsFloorplan);
    }
  },
  setup () {
    Action.setDefaultAction(ActDefaultTicketing)
  },
  beforeMount () {
    logger.log('beforeMount')
    Settings.set('nav', config.nav)
    this.removeSunSkyIfNotUnreal();

    Settings.set('views', config.views)
    this.display2D = Settings.getOption('views', '2D Ansicht', 'display')
    this.display3D = Settings.getOption('views', '3D Ansicht', 'display')
  },
  mounted () {
    logger.log('mounted');

    theApp.warnUserBeforeUnload(true);
    theApp.addDialog('main', this)

    this.addCameraControls()

    this.load()
  },
  update () {
    logger.log('updated')
    this.load()
  },
  beforeUnmount () {
    logger.log('beforeUnmount')
    Action.stopAll();
    theApp.executeCommand('.VisualEvents.writeSettings');
    theApp.warnUserBeforeUnload(false);
  },
  unmounted() {
    logger.log('unmounted')
    theApp.removeDialog('main')

    this.removeCameraControls()
  }
}
</script>

<style>
* {
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}
.home {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.home-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow-x: hidden;
}

.coupled-2d-3d-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.coupled-2d-3d-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.webgl-render-canvas {
  width: 100%;
  height: 100%;
  position: relative;
}

.ticketing-icon-bar-view {
  position: absolute;
  top: 20px;
  right: 30px;
}

.ticketing-icon-bar-controls {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 900px) {
  .ticketing-icon-bar-controls {
    width: 60%;
  }
}

@media screen and (min-width: 1400px) {
  .ticketing-icon-bar-controls {
    width: 40%;
  }
}
</style>
