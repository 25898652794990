<template>
    <div class="dlg-place-block">
        <div class="d-flex flex-column" style="height: 100%; width: 100%; overflow: auto;">
            <div class="h-100">
                <div class="sticky-top">
                    <div v-if="forTicketing" class="ve-panel-header">
                        Sitzplätze
                    </div>
                    <div v-if="!forTicketing" class="ve-panel-header">
                        Aufstellung
                    </div>
                    <div class="col-sm-12">
                        <ul v-if="forTicketing" class="nav nav-tabs nav-fill" id="sidepanel-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button :class="['nav-link', { active: tab === 'placement' }]" id="placement-tab" data-bs-toggle="tab" data-bs-target="#placement" type="button" role="tab" aria-controls="placement" aria-selected="true">Allgemein</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button :class="['nav-link', { active: tab === 'numeration' }]" id="numeration-tab" data-bs-toggle="tab" data-bs-target="#numeration" type="button" role="tab" aria-controls="numeration" aria-selected="false">Nummerierung</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button :class="['nav-link', { active: tab === 'furniture' }]" id="furniture-tab" data-bs-toggle="tab" data-bs-target="#furniture" type="button" role="tab" aria-controls="furniture" aria-selected="false">Mobiliar</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="tab-content p-3" id="sidepanel-tab-content">

                    <div class="tab-pane fade show active" id="placement" role="tabpanel" aria-labelledby="placement-tab">
                        <PanelPlaceBlock />
                    </div>

                    <div class="tab-pane fade" id="numeration" role="tabpanel" aria-labelledby="numeration-tab">
                        <div v-show="isWithChairs()">
                            <PanelRenumberSeats />
                            <hr />
                        </div>
                        <div v-show="isWithTableCircle() || isWithTableRectangle()">
                            <PanelRenumberTables />
                            <hr />
                        </div>
                        <div>
                            <PanelRenumberRows />
                            <hr />
                        </div>
                        <div v-show="isWithTableCircle() || isWithTableRectangle()">
                            <PanelRenumberSeatsForTable />
                        </div>
                    </div>

                    <div class="tab-pane fade" id="furniture" role="tabpanel" aria-labelledby="furniture-tab">
                        <div v-show="isWithTableCircle()">
                            <PanelTableCircle />
                        </div>
                        <div v-show="isWithTableRectangle()">
                            <PanelTableRectangle />
                        </div>
                        <div>
                            <PanelAllChair />
                        </div>
                        <div v-show="isWithTableCircle()">
                            <PanelTableCircleSetting />
                        </div>
                        <div v-show="isWithTableRectangle()">
                            <PanelTableRectangleSetting />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import PanelPlaceBlock from '@/vuejs/components/PanelPlaceBlock.vue'
import PanelRenumberSeats from '@/vuejs/components/PanelRenumberSeats.vue'
import PanelRenumberTables from '@/vuejs/components/PanelRenumberTables.vue'
import PanelRenumberRows from '@/vuejs/components/PanelRenumberRows.vue'
import PanelRenumberSeatsForTable from '@/vuejs/components/PanelRenumberSeatsForTable.vue'
import Settings from '@/visual-events/data/Settings'
import PanelAllChair from '@/vuejs/components/PanelAllChair.vue'
import PanelTableCircle from '@/vuejs/components/PanelTableCircle.vue'
import PanelTableRectangle from '@/vuejs/components/PanelTableRectangle.vue'
import PanelTableCircleSetting from '@/vuejs/components/PanelTableCircleSetting.vue'
import PanelTableRectangleSetting from '@/vuejs/components/PanelTableRectangleSetting.vue'

import Logger from '@/frame/Logger';

const logger = new Logger('DlgPlaceBlock');

export default {
    name: 'DlgPlaceBlock',
    components: {
        PanelPlaceBlock,
        PanelRenumberSeats,
        PanelRenumberTables,
        PanelRenumberRows,
        PanelRenumberSeatsForTable,
        PanelAllChair,
        PanelTableCircle,
        PanelTableRectangle,
        PanelTableCircleSetting,
        PanelTableRectangleSetting
    },
    data() {
        return {
            tab: 'placement',
            variantType: undefined
        }
    },
    computed: {
        forTicketing: {
            get() {
                return Settings.get('ticketing') !== undefined;
            }
        }
    },
    mounted() {
        theApp.addDialog('DlgPlaceBlock', this)
    },
    unmounted() {
        theApp.removeDialog('DlgPlaceBlock')
    },
    methods: {
        setTab(tab) {
            this.tab = tab;
        },
        setVariantType(variantType) {
            this.variantType = variantType
        },
        isWithChairs() {
            return this.variantType === 'CHAIR_CIRCLE' || this.variantType === 'CHAIR_RECTANGLE' || this.variantType === 'CHAIR_BANQUET'
        },
        isWithTableCircle() {
            return this.variantType === 'TABLE_CIRCLE_WITH_SEATS'
        },
        isWithTableRectangle() {
            return this.variantType === 'TABLE_RECTANGLE_WITH_SEATS'
        }
    }
}
</script>

<style scoped>
.dlg-place-block {
    background: white;
    height: 100%;
    width: 100%;
}
</style>