import * as Event from './Event';
import ActDefault from './ActDefault';
import FltDefault from './FltDefault';
import Logger from './Logger';
import events from 'events';

/**
 * do not call removeFilter in destroyAction
 * 
 * Before destroyAction is called, all filters in front of the action or filter are already
 * removed. Trying to change the action stack during destroyAction by yourself would
 * disrupt the stack and lead to failure lateron.
 */
export class InvalidRemoveFilterCall extends Error {
  constructor(caller) {
    super(`do not call removeFilter in ${caller}.actionDestroy`);
    this.name = 'InvalidRemoveFilterCall';
  }
}

const logger = new Logger('ActionStack');

export class ActionStack {
  constructor () {
    this.stack = [];
    this.DefaultActionClass = ActDefault;
    this.DefaultFilterClass = FltDefault;
    this.addDefaultAction();
    this.addDefaultFilter();

    // Create an eventEmitter object
    this.eventEmitter = new events.EventEmitter();
    this.eventEmitter.on('command', (commandLine, ...args) => {
      // process.nextTick(() => {
      //   this.emit("event");
      // });
      const command = new Event.CommandEvent(commandLine, ...args);
      this.processEvent(command);
    });

    this.actionDestroyCaller = null;
  }

  /**
   * check, if there is any non trivial action working
   * @returns 
   */
  isDefaultStack () {
    //ActDefault | FltDefault or less
    return this.stack.length <= 2;
  }

  /**
   * find the action of class 'clazz', if it is running, otherwise null
   * @param {*} clazz 
   * @returns the action resp. filter
   */
  findByClass (clazz) {
    return this.stack.find(action => action instanceof clazz ? action : null);
  }

  /**
   * @deprecated
   * find the action 'name', if it is running, otherwise null
   * 
   * attention: The class check based on constructor.name did not work in webpack deployments unless build with debug symbols 
   * (seems to be caused by webpack renaming classes.Observed in FltEditAttributes with AttributesPanel; 
   * with other action classes it worked, unknown why)
   * @param {*} name
   * @returns the action resp. filter
   */
  findByClassName (name) {
    return this.stack.find(action => action.constructor.name === name ? action : null);
  }

  addUIListeners (component) {
    component.addEventListener('click', this.mouseClick);
    component.addEventListener('mousemove', this.mouseMove);
    component.addEventListener('mousedown', this.mouseDown);
    component.addEventListener('mouseup', this.mouseUp);
    component.addEventListener('mouseenter', this.mouseEnter);
    component.addEventListener('mouseleave', this.mouseLeave);
    component.addEventListener('wheel', this.mouseWheel);
    component.addEventListener('touchstart', this.touchStart);
    component.addEventListener('touchend', this.touchEnd);
    component.addEventListener('touchcancel', this.toucCancel);
    component.addEventListener('touchmove', this.touchMove);
  }

  removeUIListeners (component) {
    component.removeEventListener('click', this.mouseClick);
    component.removeEventListener('mousemove', this.mouseMove);
    component.removeEventListener('mousedown', this.mouseDown);
    component.removeEventListener('mouseup', this.mouseUp);
    component.removeEventListener('mouseenter',this.mouseEnter);
    component.removeEventListener('mouseleave', this.mouseLeave);
    component.removeEventListener('wheel', this.mouseWheel);
    component.removeEventListener('touchstart', this.touchStart);
    component.removeEventListener('touchend', this.touchEnd);
    component.removeEventListener('touchcancel', this.touchCancel);
    component.removeEventListener('touchmove', this.touchMove);
  }

  /**
   * keyboard events are only generated by <input>, <textarea>, <summary> and anything with the contentEditable or tabindex attribute,
   * especially not in a canvas 
   */
  addUIListenersForKeyboardEvents () {
    //'keypress' is obsolete
    window.addEventListener('keydown', this.keyDown);
    window.addEventListener('keyup', this.keyUp);
  }

  removeUIListenersForKeyboardEvents () {
    window.removeEventListener('keydown', this.keyDown);
    window.removeEventListener('keyup',  this.keyUp);
  }

  throwExceptionOnInvalidCall() {
    if (this.actionDestroyCaller)
      throw new InvalidRemoveFilterCall(this.actionDestroyCaller?.constructor?.name);
  }

  replaceAction (action) {
    this.throwExceptionOnInvalidCall();

    this.stopAll();
    this.addFilter(action);
  }

  addFilter (action) {
    this.throwExceptionOnInvalidCall();

    this.removeDefaultFilter();
    this.stack.push(action);
    if (!action.actionStart()) {
      this.stack.pop();
      if (!this.stack.length) { this.addDefaultAction(); }
    }
    if (this.getFilter(action) == null) { this.addDefaultFilter(); }
  }

  removeFilter (action) {
    this.throwExceptionOnInvalidCall();
    
    const filter = this.getFilter(action);
    if (filter != null) { this.removeAction(filter); }

    if (!this.stack.length) { this.addDefaultAction(); }
    this.addDefaultFilter();
  }

  removeAction (action) {
    const filter = this.getFilter(action);
    if (filter != null) { this.removeAction(filter); }

    //callback actionDestroy, no removeFilter during that call!
    this.actionDestroyCaller = action;
    action.actionDestroy();
    this.actionDestroyCaller = null;
    
    this.stack.pop();
    if (this.stack.length<2)
      this.activeAction = null;
  }

  stopAll () {
    if (this.stack.length) {
      this.removeAction(this.stack[0]);
      // should lead to an empty stack, since each action and filter
      // is responsible to remove all filters in front of it
    }

    if (!this.stack.length) { this.addDefaultAction(); }
    this.addDefaultFilter();
  }

  /**
   * find the filter action in front of 'action', if there is one
   * @param action
   * @return filter or null
   */
  getFilter (action) {
    for (let idx = 0; idx < this.stack.length - 1; idx++) {
      if (action === this.stack[idx]) { return this.stack[idx + 1]; }
    }
    return null;
  }

  // MouseWheelEvent
  mouseWheel = (event) => {
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseDragged = (event) => {
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseMove = (event) => {
    logger.log("mouseMove");
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseClick = (event) => {
    logger.log("Click");
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseEnter = (event) => {
    logger.log("mouseEnter");
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseLeave = (event) => {
    logger.log("mouseLeave");
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseDown = (event) => {
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // MouseEvent
  mouseUp = (event) => {
    event.preventDefault();
    this.processEvent(new Event.RawMouseEvent(event));
  }

  // KeyEvent
  keyDown = (event) => {
    this.processEvent(new Event.RawKeyEvent(event));
  }

  // KeyEvent
  keyUp = (event) => {
    this.processEvent(new Event.RawKeyEvent(event));
  }

  // TouchEvent
  touchStart = (event) => {
    this.processEvent(new Event.RawTouchEvent(event));
  }

  // TouchEvent
  touchEnd = (event) => {
    this.processEvent(new Event.RawTouchEvent(event));
  }

  // TouchEvent
  touchCancel = (event) => {
    this.processEvent(new Event.RawTouchEvent(event));
  }

  // TouchEvent
  touchMove = (event) => {
    this.processEvent(new Event.RawTouchEvent(event));
  }

  // ActionEvent
  actionPerformed = (event) => {
    // this.processEvent(new Event.CommandEvent(event.getActionCommand()));
  }

  /**
   * replace the standard default filter class
   *
   * This filter should usually be derived from FltDefault
   * or rebuild its behavior for some general concepts, such as
   * - commands, which immediately translate to events, such as val.point
   * - catch hotkeys and convert these to commands
   * - translate raw mouse move and clicks to dynamic and point events
   * - interprete the ESC key as standard break event
   * @param clazz
   */
  setDefaultFilterClass (clazz) {
    this.DefaultFilterClass = clazz;
  }

  /**
   * replace the standard default action
   *
   * This action usually should be derived from ActDefault
   * in order to handle all commands, which have not yet been
   * catched by filters and actions.
   * @param clazz
   */
  setDefaultActionClass (clazz) {
    this.DefaultActionClass = clazz;
  }

  addDefaultAction () {
    try {
      const action = new this.DefaultActionClass();
      this.stack.push(action);
      action.actionStart();
    } catch (ex) {
      // TODO: allgemeines Fehlerhandling, Traces/Logs?
      console.log(ex); // eslint-disable-line no-console
    }
  }

  addDefaultFilter () {
    try {
      this.addUIListenersForKeyboardEvents();
      const action = new this.DefaultFilterClass();
      this.stack.push(action);
      action.actionStart();
    } catch (ex) {
      console.log(ex); // eslint-disable-line no-console
    }
  }

  removeDefaultFilter () {
    this.removeAction(this.stack[this.stack.length - 1]);
    this.removeUIListenersForKeyboardEvents();
  }

  /**
 * propagate the event through the stack of filters and actions
 *
 * Filters and actions, which are interested in the event, can perform
 * their work
 * - do some state change
 * - decide to stop propagation of the event or
 * - possibly replace the event by a modified or new event, which is handed over to the next
 *
 * Usually the first filter to receive the event is the default filter.
 * The last action in the stack is the default filter.
 *
 * @param {*} event
 */
  processEvent (event) {
    for (let idx = this.stack.length - 1; idx > -1; idx--) {
      const action = this.stack[idx];

      if (event instanceof Event.RawMouseEvent) {
        event = action.actionMouse(event);
      } else if (event instanceof Event.RawTouchEvent) {
        event = action.actionTouch(event);
      } else if (event instanceof Event.RawKeyEvent) {
        event = action.actionKey(event);
      } else if (event instanceof Event.BreakEvent) {
        event = action.actionBreak(event);
      } else if (event instanceof Event.CommandEvent) {
        event = action.actionCommand(event);
      } else if (event instanceof Event.ValueEvent) {
        event = action.actionValue(event);
      } else if (event instanceof Event.DynamicEvent) {
        event = action.actionDynamic(event);
      } else if (event instanceof Event.PointEvent) {
        event = action.actionPoint(event);
      } else if (event instanceof Event.PointUpEvent) {
        event = action.actionPointUp(event);
      } else if (event instanceof Event.SelectionEvent) {
        event = action.actionSelection(event);
      }
      if (event == null) { return; }
    }
  }

  toHtml () {
    let out = '';
    for (let idx = 0; idx < this.stack.length; idx++) {
      const action = this.stack[idx];
      out += action.constructor.name;
      out += '<br>';
    }
    return out;
  }
}

export default new ActionStack();
