<template>
    <div class="panel-text border-bottom p-3">
        <!-- <InputCombobox class="col-12" label="Font" :options="options" :value=font @update:modelValue="onChangeFont" /> -->
        <InputText placeholder="Texteingabe..." label="Text" class="col-12" v-model="text" @update:modelValue="onChangeText" />
        <InputNumberWithUnit class="col-6" unit='°' label="Winkel" :periodic="true" v-model="angle" @update:modelValue="onChangeAngle" />
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import InputText from '@/vuejs/components/InputText.vue';
//import InputCombobox from '@/vuejs/components/InputCombobox.vue';
import Settings from '@/visual-events/data/Settings';
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';

export default {
  name: 'PanelText',
  components: {
    InputText,
//    InputCombobox,
    InputNumberWithUnit
  },
  data () {
    return {
        options: [
            {id: 'arial', label: 'Arial', value: 'Arial' },
            {id: 'helvetica', label: 'Helvetica', value: 'Helvetica' }
        ],
        font: 'arial',
        text: '',
        angle: 0
    }
  },
  mounted () {
    theApp.addDialog('PanelText', this)
  },
  unmounted () {
    theApp.removeDialog('PanelText')
  },
  methods: {
    update(action) {
      this.font = action.font
      this.text = action.text
      this.angle = action.angle
    },    

    onChangeFont () {
        theApp.executeValueEvent('font',  this.font)
        Settings.set('text.font', this.font)
    },
    onChangeText () {
        theApp.executeValueEvent('text',  this.text)
        Settings.set('text.text', this.text)
        
    },
    onChangeAngle () {
      theApp.executeValueEvent('angle', this.angle)
      Settings.set('text.angle', this.angle)
    },
  }
}
</script>

<style scoped>
</style>
