import * as THREE from 'three';
import { nextTick } from 'vue'

import theApp from '@/frame/Application';

import Settings from '@/visual-events/data/Settings';
import Action from '@/frame/Action';
import * as Event from '@/frame/Event.js';
import FltSelectAndDispatch from '@/visual-events/actions/FltSelectAndDispatch';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import Logger from '@/frame/Logger';
import VariantTableRectangleWithSeats from '@/visual-events/actions/VariantTableRectangleWithSeats';

const logger = new Logger('ActTableRectangle');

const State = Object.freeze({
    CREATE: 0,
    EDIT: 2
  });

  export default class ActTableRectangle extends Action {
    constructor(args) {
        super();
  
        this.view2D = theApp.findViewByName('2D Ansicht');
        const drawing = this.view2D.getRoot();
        this.root2D = drawing.children[0];
  
        this.tableColor = Settings.get('furniture.tableColor', '#BBBBFF');
        this.chairColor = Settings.get('furniture.chairColor', '#BBBBFF');
        this.chairShape = Settings.get('furniture.chairShape', 'SHAPE_CIRCLE');
        this.chairDiameter = Settings.get('furniture.chairDiameter', 500);
        this.height = Settings.get('furniture.tableWidthY', 1000);
        this.width = Settings.get('furniture.tableWidthX', 500);
        this.chairHeight = Settings.get('furniture.chairWidthY', 500);
        this.chairWidth = Settings.get('furniture.chairWidthX', 500);
        this.chairTop = Settings.get('furniture.chairTop', 1);
        this.chairBottom = Settings.get('furniture.chairBottom', 1);
        this.chairLeft = Settings.get('furniture.chairLeft', 1);
        this.chairRight = Settings.get('furniture.chairRight', 1);
        
        this.position = new THREE.Vector3(0, 0, 0);
        
        this.objects = [];
        if (args.length > 1)
          this.objects = args[1];
  
        this.t = new THREE.Matrix4(); // for reuse inoder to avoid frequent allocation

        this.state = this.objects.length > 0 ? State.EDIT : State.CREATE;
    }

    actionStart () {
        logger.log(`actionStart`);
        if (this.state === State.EDIT)
        this.addFilter(new FltSelectAndDispatch());
        else
            this.addFilter(new FltPointDef());

        this.evaluateSelection();
        this.connectToGUI();
        this.op2D = this.createTableWithChairs();
        this.objects.push(this.op2D);

        theApp.model.markExistingSymbols();

        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        switch(this.state){
            case State.CREATE: {
                if (this.op2D)
                    this.op2D.removeFromParent();
                if (this.op3D)
                    this.op3D.removeFormParent();

                theApp.model.changed2d = true;
            }
        }

        theApp.model.removeUnusedSymbols();

        this.disconnectFromGUI();
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);

        this.position.x = event.p[0];
        this.position.y = event.p[1];

        switch(this.state){
            case State.CREATE: {
                const opReference = this.objects[0];
                this.t.makeTranslation(this.position.x, this.position.y, 0);
                opReference.setTransform(this.t);
    
                // the created object is permanently in the drawing, reset op2D and op3D in order
                // to avoid, that actionDestroy removes ist
                this.op2D = undefined;
                this.op3D = undefined;
                theApp.model.changed2d = true;

                // immediately go to the block dialog
                // remark: send .Ticketing.editBlock in actionPointUp and not in actionPoint, 
                // otherwise the PointUp event will be sent to FltPlaceBlock and start drag&drop
                return new Event.CommandEvent('.Ticketing.editBlock', [opReference]);

                // other behavior: proceed with editing
                // this.disconnectFromGUI();
                // this.state = State.EDIT;
                // this.connectToGUI();
                
                // // allow for selecting things in order to start actions
                // this.addFilter(new FltSelectAndDispatch());

                // theApp.model.changed2d = true;

                // break;
            }
        }
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);

        this.position.x = event.p[0];
        this.position.y = event.p[1];
        switch(this.state){
            case State.CREATE: {
                const opReference = this.objects[0];
                this.t.makeTranslation(this.position.x, this.position.y,0);
                opReference.setTransform(this.t);
                theApp.model.changed2d = true;
                break;
            }
        }
    }

    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'chairShape') {
            this.chairShape = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'tableColor') {
            this.tableColor = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'chairColor') {
            this.chairColor = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'chairDiameter') {
            this.chairDiameter = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'tableWidth') {
            this.width = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'tableHeight') {
            this.height = event.value;
            this.editTableProperty();
            done = true;
        }
        

        if (event.attribute === 'chairHeight') {
            this.chairHeight = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'chairWidth') {
            this.chairWidth = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'chairTop') {
            this.chairTop = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'chairBottom') {
            this.chairBottom = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'chairLeft') {
            this.chairLeft = event.value;
            this.editTableProperty();
            done = true;
        }

        if (event.attribute === 'chairRight') {
            this.chairRight = event.value;
            this.editTableProperty();
            done = true;
        }

        return done ? null : event;
    }

    createTableWithChairs() {
        const variant = new VariantTableRectangleWithSeats(
            this.width, 
            this.height,
            this.chairShape, 
            this.chairDiameter, 
            this.chairWidth, 
            this.chairHeight,
            this.tableColor,
            this.chairColor,
            this.chairTop,
            this.chairBottom, 
            this.chairLeft, 
            this.chairRight
            );
        
            const opReference = variant.create();
            this.t.makeTranslation(this.position.x, this.position.y, 0);
            opReference.setTransform(this.t);

            this.root2D.add(opReference);
            return opReference;
    }

    editTableProperty()
    {
        const variant = new VariantTableRectangleWithSeats(
            this.width, 
            this.height,
            this.chairShape, 
            this.chairDiameter, 
            this.chairWidth, 
            this.chairHeight,
            this.tableColor,
            this.chairColor,
            this.chairTop,
            this.chairBottom, 
            this.chairLeft, 
            this.chairRight
            );
        for (const opReference of this.objects) {
            variant.edit(opReference);
            theApp.model.changed2d = true;
        }
    }

    evaluateSelection () {
        for (const opReference of this.objects) {

            const symbolId  = opReference.symbolId;
            const symbol = theApp.model.symbols.get(symbolId);
            const json = symbol.getAttribute('$variant');

            this.width = json.opts.width;
            this.height = json.opts.height;
            this.chairShape = json.opts.chairShape;
            this.chairDiameter = json.opts.chairDiameter;
            this.chairWidth = json.opts.chairWidth;
            this.chairHeight = json.opts.chairHeight;
            this.tableColor = json.opts.tableColor;
            this.chairColor = json.opts.chairColor;
            this.chairTop = json.opts.chairTop;
            this.chairBottom = json.opts.chairBottom;
            this.chairLeft = json.opts.chairLeft;
            this.chairRight = json.opts.chairRight;
            
        }
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(this.state === State.EDIT ? 'Select' : 'Furniture');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PanelFurniture');
        
            nextTick(() => {
                const panelFurniture = theApp.findDialogByName('PanelFurniture');
                panelFurniture.setTab('rectangleTable');
                nextTick(() => {
                    const panelTableRectangle = theApp.findDialogByName('PanelTableRectangle');
                    panelTableRectangle?.update(this);
                    const panelAllChair = theApp.findDialogByName('PanelAllChair');
                    panelAllChair.setChairShape(this.chairShape);
                    panelAllChair?.update(this);
                    const panelTableRectangleSetting = theApp.findDialogByName('PanelTableRectangleSetting');
                    panelTableRectangleSetting?.update(this);
                })
            })
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
  }