import * as THREE from 'three';

class Popup 
{
    constructor(name, canvas, scene, camera) {
        this.name = name;
        this.canvas = canvas;
        this.scene = scene;
        this.camera = camera;

        this.element = document.createElement('div');
        this.element.id = name;
        this.element.className = name;
        this.element.style.position = 'absolute';
        this.element.style.zIndex = '1';
        this.element.hidden = true;

        this.parent = '';
        this.mesh = false;
        this.position = new THREE.Vector3(0, 0, 0);
        this.clientPosition = false;
    }

    setParent(id) {
        this.parent = id;
    }

    setContent(html) {
        this.element.innerHTML = html;
    }

    setMesh(mesh) {
        this.mesh = mesh;
    }

    setPosition(position) {
        this.position = position;
    }

    setPositionFromArray(posArr) {
        this.position = new THREE.Vector3(posArr[0], posArr[1], posArr[2]);
    }

    setClientPosition(pos) {
        this.clientPosition = pos;
    }

    setValue(id, value) {
        document.getElementById(id).value = value;
    }

    setInnerHtml(id, value) {
        document.getElementById(id).innerHTML = value;
    }

    setClickEvent(id, callback) {
        document.getElementById(id).addEventListener('click', callback);
    }

    updatePosition() {
        if (this.mesh) {
          let box = new THREE.Box3().setFromObject(this.mesh);
          box.getCenter(this.position);
        }

        var coords2d = this.clientPosition ? this.clientPosition : this.get2DCoords();
        
        let app = document.getElementById(this.parent);
        let boundingClientRect = app.getBoundingClientRect();

        if (coords2d.x + this.element.clientWidth > boundingClientRect.width)
            coords2d.x = coords2d.x - this.element.clientWidth;

        if (coords2d.y + this.element.clientHeight > boundingClientRect.height) 
            coords2d.y = coords2d.y - this.element.clientHeight;
        
        this.element.style.left = coords2d.x + 'px';
        this.element.style.top = coords2d.y + 'px';
    }

    get2DCoords() {
        let vector = new THREE.Vector3().copy(this.position);

        var halfWidth = this.canvas.offsetWidth / 2;
        var halfHeight = this.canvas.offsetHeight / 2;

        if (this.scene.autoUpdate) this.scene.updateMatrixWorld();
        if (this.camera.parent === null) this.camera.updateMatrixWorld();

        var viewMatrix = new THREE.Matrix4();
        viewMatrix.copy(this.camera.matrixWorldInverse);

        var viewProjectionMatrix = new THREE.Matrix4();
        viewProjectionMatrix.multiplyMatrices(this.camera.projectionMatrix, viewMatrix);

        vector.applyMatrix4(viewProjectionMatrix);

        vector.x = (vector.x * halfWidth) + halfWidth;
        vector.y = -(vector.y * halfHeight) + halfHeight;

        return vector;
    }

    setSubmit(id, callback) {
        let btn = document.getElementById(id);
        btn.onclick = callback;
    }

    attach() {
        document.getElementById(this.parent).appendChild(this.element);
    }

    open() {
        document.getElementById(this.name).toggleAttribute('hidden');
    }

    close() {
        let popup = document.getElementById(this.name);
        popup.remove();
    }

    static closeAll(name) {
        let popups = document.getElementsByClassName(name);
        for (let i = 0; i < popups.length; i++) {
            const popup = popups.item(i);
            popup.remove();
        }
    }

    static isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);  
    }
}

export default Popup;