import BlockUtils from '@/visual-events/actions/BlockUtils'
import OpReference from '@/visual-events/model/OpReference';

import {Matrix4} from 'three';

const _t = new Matrix4();

export default class OpUtils {

    /**
     * deep copy an OpObject or all in an array
     * 
     * remark: consider using getRoots to prepare the objects in order 
     * to achieve disjunct subtrees.
     * 
     * @param {array of OpObject or OpObject} what 
     * @returns copied array of OpObject resp. OpObject
     */
    static deepCopy(what) {

        if (Array.isArray(what)) {

            const roots = what;
            const copies = [];
            roots.forEach(root => {
                const copy = OpUtils.deepCopy(root);
                copies.push(copy);
            });
            return copies;
    
        } else {

            const root = what;
            const copy = root.copy();
            copy.attributes = {...root.attributes};

            //TODO: avoid Hack: OpReference's children are only attribute texts have already been
            // copied in copy();
            if (copy instanceof OpReference)
                return copy;

            for (const child of root.children) {
                const subtree = OpUtils.deepCopy(child);
                copy.add(subtree);
            }   
            return copy;
        }
    }
    
    /**
     * collect roots of objects which are to be copied
     * 
     * TODO: not yet well defined
     * - minimum requirement: the result shall fulfill the condition
     *    no object is in the subtree below another object
     * - needs semantic in order to decide, which objects are taken as given 
     *   and where to look for parents
     * 
     * copySelection currently only gets the blocks with attribute $PlacingBlock
     * by calling BlcokUtils.getBlocks. This is no good dependancy. 
     * @param {*} objects 
     * @returns 
     */
    static getRoots (objects) {

        // not implemented
        return [];
    }

    /**
     * build the collection of leaf nodes in the subtrees
     * 
     * remark: leads to duplicates, if the objects are not
     * disjunct roots of subtrees
     * @param {*} objects 
     * @returns 
     */
    static getLeafs (objects) {

        const leafs = [];
        objects.forEach(op => {
            if (op instanceof OpReference || op.children.length == 0)
                leafs.push(op);
            else
                leafs.push(...this.getLeafs(op.children));
        });

        return leafs;
    }

    /**
     * shift all objects by dist
     * @param {*} objects 
     * @param {*} dist 
     */
    static shift (objects, dist) {
        _t.identity();
        _t.setPosition(dist, dist, 0);
        objects.forEach(op => op.applyTransform(_t));
    }

    /**
     * convenience function to support the typical copy behavior in actions:
     * - prepare the set of roots to copy, especially determine the root of
     *   the $PlacingBlocks, which are always to handle as a unit
     * - deep copy all objects
     * - determine the leafs, because only these are to be transformed later on, not the
     *   groups
     * - shift the copy a bit for better user experience
     * - returns the leafs as the selection to proceed with in the action
     */

    static copySelection (selection, root, dist) {

        //TODO: copySelection based on well defined getRoots 
        //      getLeafs: OpReferences and attribute texts??
        //const roots = OpUtils.getRoots (selection);
        const roots = BlockUtils.getBlocks (selection);

        const copies = OpUtils.deepCopy (roots);
        
        copies.forEach(copy => root.add(copy));

        //const leafs = OpUtils.getLeafs(copies);
        const leafs = [];
        for (const copy of copies) {
            if (BlockUtils.isBlockGroup(copy))
                leafs.push(...BlockUtils.getReferences(copy));
            else
                leafs.push(...this.getLeafs([copy]));
        }

        OpUtils.shift (leafs, dist);

        return leafs;
    }

    /**
     * convenience function to support the typical delete behavior in actions:
     * - prepare the set of roots to delete, especially determine the root of
     *   the $PlacingBlocks, which are always to handle as a unit
     * - delete all objects recursively
     * 
     * TODO: Resources in OpMesh and Grafic cleanly disposed??
     * @param {*} selection 
     */
    static deleteSelection (selection) {
        const roots = BlockUtils.getBlocks (selection);

        roots.forEach(op => {
            OpUtils.clear(op);
            op.removeFromParent();
        });
    }

    /**
     * clear root, i.e. delete all children recursively
     * 
     * TODO: Resources in OpMesh and Grafic cleanly disposed??
     * @param {*} objects 
     */
    static clear (root) {
        root.children.forEach(op => { 
            OpUtils.clear(op);
            op.removeFromParent();
        });
    }
}