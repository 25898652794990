<template>
    <PanelCircle v-show="editMode" />
    <PanelStyle/>
</template>

<script>
import theApp from '@/frame/Application';
import PanelCircle from '@/vuejs/components/PanelCircle.vue';
import PanelStyle from '@/vuejs/components/PanelStyle.vue';

export default {
  name: 'PanelDrawCircle',
  components: {
    PanelCircle,
    PanelStyle,
  },
  data () {
    return {
        editMode: false
    }
  },
  mounted () {
    theApp.addDialog('PanelDrawCircle', this)
  },
  unmounted () {
    theApp.removeDialog('PanelDrawCircle')
  }
}
</script>

<style scoped>
</style>
