import OpFactory from '@/visual-events/model/OpFactory';
import Variant from '@/visual-events/model/Variant';
import { TextAnchor, BaseLine } from '@/visual-events/model/OpText';
import * as THREE from 'three';

export default class VariantChairRectangle extends Variant {
    constructor (width, height, color) {
        const opts = {
            width: width,
            height: height,
            color: color
        }
        super ('CHAIR_RECTANGLE', 1, 'standard chair rectangle', opts);
        this.t = new THREE.Matrix4();
    }

    createSymbol () {
        const width = this.json.opts.width;
        const height = this.json.opts.height;
        const color = this.json.opts.color;

        const points =[
            [-0.5 * width, 0],
            [ 0.5 * width, 0],
            [ 0.5 * width, height],
            [-0.5 * width, height],
        ];
        const rectangle = OpFactory.createPolygon(points);
        rectangle.style.fill = color;
        rectangle.style.stroke = '#000000';
        const opSymbol = OpFactory.createSymbol(`${this.description}`);
        opSymbol.add(rectangle);

        const opText = OpFactory.createText('?', 100, undefined, TextAnchor.middle, BaseLine.middle);
        opText.pickable = false;
        opText.setVisibility(false);
        opSymbol.add(opText);
        opSymbol.setAttribute('$seatNo', '?');
        opText.attachToAttribute(opSymbol, '$seatNo');
        this.t.makeTranslation(0.0, height / 2.0, 0);
        opText.setTransform(this.t);

        return opSymbol;
    }
}