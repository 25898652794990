import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import Logger from '@/frame/Logger';
import FltSelectAndDispatch from '@/visual-events/actions/FltSelectAndDispatch';
import Settings from '@/visual-events/data/Settings';

const logger = new Logger('ActWaitForUserInput');

const State = Object.freeze({
    SELECT: 0
  });
  
export default class ActWaitForUserInput extends Action {
    constructor(args) {
      super();

      this.args = args;
    }

    actionStart () {
        logger.log(`actionStart`);

        this.addFilter(new FltSelectAndDispatch());

        this.connectToGUI();

        this.state = State.SELECT;

        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);

        this.disconnectFromGUI();
    }

    actionBreak () {
      logger.log(`actionBreak`);

      // switch off the select by rectangle mode
      if (!Settings.get('selection.noDrag'))
        theApp.executeCommand('.Ticketing.toggleSelection')
        Settings.set('selection.noDrag', true);
      this.disconnectFromGUI();
    }

    actionValue (event) {
        logger.log(`actionValue`);
        //TODO: Hier, oder lieber in DefaultAction; allgemeines Prinzip für Parametersätze?

        /**
         * if PanelDraw is active
         */

        if (event.attribute === 'useStroke')
          Settings.set('style.useStroke', event.value);

        if (event.attribute === 'stroke')
          Settings.set('style.stroke', event.value);

        if (event.attribute === 'strokeWidth')
          Settings.set('style.strokeWidth', event.value);

        if (event.attribute === 'useFill')
        Settings.set('style.useFill', event.value);
        
        if (event.attribute === 'fill')
          Settings.set('style.fill', event.value);
          
        return event;
    }

    connectToGUI () {
      const sideNav = theApp.findDialogByName('SideNav');
      sideNav.setActiveButton(Settings.get('selection.noDrag') ? undefined : 'Select');
      const sidePane = theApp.findDialogByName('SidePane');
      sidePane.setCurrentPanel(undefined);
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav?.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane?.setCurrentPanel(undefined);
    }
}    
