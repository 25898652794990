<template>
  <div class="form-group mt-2">
      <label :for="this.$.uid" class="form-label">{{label}}</label>
      <input :placeholder="placeholder" type="text" class="form-control" :id="this.$.uid" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" >
  </div>
</template>

<script>

export default {
  name: 'InputText',
  props: {
    label: String,
    modelValue: String,
    placeholder: String
  },
  emits: ['update:modelValue']
}

</script>

<style>
</style>