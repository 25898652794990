// Unreal imports only here!
import { Config, PixelStreaming, Logger as UnrealLogger } from '@epicgames-ps/lib-pixelstreamingfrontend-ue5.3';

import Logger from '@/frame/Logger';
import Settings from '../data/Settings';
const logger = new Logger('PixelStreamingWrapper');

/**
 * wrapper class for the pixel streaming with the Unreal Engine 
 */
export default class PixelStreamingWrapper {

    constructor () {

      this.videoElementParent = document.getElementById("videoParentElement");
      this.autoPlayVideo = true;
      this.ss = Settings.get('.urlSignallingServer');
      this.loggerVerbosity = 0; //10
      this.pixelStreaming = undefined;
    }

    init() {
      // Example of how to set the logger level
      UnrealLogger.SetLoggerVerbosity(this.loggerVerbosity);

      // Create a config object
      const config = new Config({
          initialSettings: {
              AutoPlayVideo: this.autoPlayVideo,
              AutoConnect: true,
              ss: this.ss,
              StartVideoMuted: true,
              WaitForStreamer: true,
              SuppressBrowserKeys: false,
              HoveringMouse: true,
              ControlsQuality: false
          }
      })

      // Create a PixelStreaming instance and attach the video element to an existing parent div
      this.pixelStreaming = new PixelStreaming(config, { videoElementParent: this.videoElementParent})
    }

    /**
     * If AutoPlayVideo is true the pixel streaming tries to start the video as soon as possible.
     * 
     * Otherwise it waits for an explicit call of the method play.
     * 
     * For the latter case the GUI usually displays an icon or a text 'Click to play', by which
     * the user can start the video.
     */
    play () {
      this.pixelStreaming.connect()
      this.pixelStreaming.play()
    }

    /**
     * Even with autoplay, expect the browser to deny.
     * Thus, in any case show "Click to play" in the GUI and register a click-to-play handler, which
     * activate this button.
     * @param {*} fn 
     */
    addPlayStreamRejectedHandler(fn) {
      this.pixelStreaming.addEventListener("playStreamRejected", fn)
    }

    addResponseHandler(fn) {
      this.pixelStreaming.addResponseEventListener("receivedResponse", fn)
    }

    /**
     * send commands to the Unreal project in order to add, remove or modify actors, 
     * change settings etc.
     * 
     * These commands must be implemented in the Unreal project. Hand over the required
     * parameters in a JSON object. For examples see VisualEventsUnrealView.
     * @param {*} description 
     */

    emitUIInteraction (description) {
        logger.log(`emitUIInteraction(${JSON.stringify(description)})`);
        const sent = this.pixelStreaming?.emitUIInteraction(description);
        logger.log(`   sent=${sent}`);
    }

    emitUICommand (description) {
      logger.log(`emitUIInteraction(${JSON.stringify(description)})`);
      const sent = this.pixelStreaming?.emitCommand(description);
      logger.log(`   sent=${sent}`);
    }
}