<template>
    <div class="panel-all-chair">
      <div class="col-12 fw-bold">Stuhl</div>
        <div class="mb-2">
            <label class="col-sm-12 ps-1 mb-0 mt-2 col-form-label">Typ</label>
            <div class="col-sm-6">
                <select class="form-select col-sm-6" v-model="chairShape" @change="onChangeChairShape($event)">
                  <option key="1" value="SHAPE_CIRCLE">Kreis</option>
                  <option key="2" value="SHAPE_RECTANGLE">Rechteck</option>
                  <option key="3" value="SHAPE_BANQUET">Bankett</option>
                </select>
            </div>
        </div>
        <div v-if="chairShape === 'SHAPE_CIRCLE'">
            <div class="mb-1">
              <label class="col-sm-12 ps-1 mb-0 mt-2 col-form-label">Größen</label>
              <div class="col-sm-6">
                  <select class="form-select mt-0 col-6" v-model="optionSizeCircle" @change="onChangeOptionSizeCircle($event)">
                      <option key="0" value=0>Freie Eingabe</option>
                      <option key="1" value=500>50 cm</option>
                      <option key="2" value=600>60 cm</option>
                      <option key="3" value=700>70 cm</option>
                  </select>
              </div>
            </div>
            <div v-show="showInputCircle" class="ve-row">
              <InputNumberWithUnit class="col-sm-6" unit="cm" label="Durchmesser" :scale="10" :min="1" :max="200" :step="1" :digits="1" v-model="chairDiameter" @update:modelValue="onChangeDiameter" />
            </div>
        </div>
        <div v-if="chairShape === 'SHAPE_RECTANGLE' || chairShape === 'SHAPE_BANQUET'">
          <div class="mb-1">
            <label class="col-sm-12 ps-1 mb-0 mt-2  col-form-label">Größen</label>
            <div class="col-sm-6">
              <select class="form-select col-sm-6" v-model="optionSizeRectangle" @change="onChangeOptionSizeRectangle($event)">
                <option key="0" value=0>Freie Eingabe</option>
                <option key="1" value=500>50 x 50 cm</option>
                <option key="2" value=600>60 x 60 cm</option>
                <option key="3" value=700>70 x 70 cm</option>
              </select>
            </div>
          </div>
          <fieldset v-show="showInputRectangle" class="ve-row">
            <InputNumberWithUnit label="Breite" class="col-sm-6" unit="cm" :scale="10" :min="1" :max="200" :step="1" :digits="1" v-model="chairHeight" @update:modelValue="onChangeHeight" />
            <InputNumberWithUnit label="Tiefe" class="col-sm-6" unit="cm" :scale="10" :min="1" :max="200" :step="1" :digits="1" v-model="chairWidth" @update:modelValue="onChangeWidth" />
          </fieldset>
        </div> 
        <div class="mt-3 ms-2 mb-0">Farbe</div>
        <div class="mt-0 ms-2 mb-3">
          <InputButtonColor label="" v-model="chairColor"  @update:modelValue="onChangeChairColor" />
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue';
import Settings from '@/visual-events/data/Settings';

import Logger from '@/frame/Logger';

const logger = new Logger('PanelAllChair');

export default {
  name: 'PanelAllChair',
  components: {
    InputNumberWithUnit,
    InputButtonColor,
  },
  data () {
    return {
      chairShape: Settings.get('furniture.chairShape', 'SHAPE_CIRCLE'),
      optionSizeCircle: this.findOptionSizeCircle(Settings.get('furniture.chairDiameter', 500)),
      chairDiameter: Settings.get('furniture.chairDiameter', 500),
      optionSizeRectangle: this.findOptionSizeRectangle(Settings.get('furniture.chairWidthX', 500), Settings.get('furniture.chairWidthY', 500)),
      chairHeight: Settings.get('furniture.chairWidthY', 500),
      chairWidth: Settings.get('furniture.chairWidthX', 500),
      chairColor: Settings.get('furniture.chairColor', '#FFFFFF'),
      showInputCircle: this.optionSizeCircle === 0,
      showInputRectangle: this.optionSizeRectangle === 0
    }
  },
  mounted () {
    theApp.addDialog('PanelAllChair', this)
  },
  unmounted () {
    theApp.removeDialog('PanelAllChair')
  },
  methods: {
    setChairShape (chairShape) {
      this.chairShape = chairShape;
      this.optionSizeCircle = this.findOptionSizeCircle(this.chairDiameter)
      this.optionSizeRectangle = this.findOptionSizeRectangle(this.chairWidth, this.chairHeight)
      this.showInputCircle = this.optionSizeCircle === 0
      this.showInputRectangle = this.optionSizeRectangle === 0
    },
    update(action) {
      this.chairShape = action.chairShape;
      this.chairDiameter = action.chairDiameter;
      this.chairHeight = action.chairHeight;
      this.chairWidth = action.chairWidth;
      this.chairColor = action.chairColor;
      this.optionSizeCircle = this.findOptionSizeCircle(this.chairDiameter)
      this.optionSizeRectangle = this.findOptionSizeRectangle(this.chairWidth, this.chairHeight)
      this.showInputCircle = this.optionSizeCircle === 0
      this.showInputRectangle = this.optionSizeRectangle === 0
    },
    onChangeChairShape(event) {
        theApp.executeValueEvent("chairShape", event.target.value)
        Settings.set('furniture.chairShape', this.chairShape)
    },
    // circle
    onChangeOptionSizeCircle(event) {
        if (parseInt(event.target.value) !== 0) {
            this.chairDiameter = parseInt(event.target.value);
            this.onChangeDiameter()
            this.showInputCircle = false;
        } else {
            this.showInputCircle = true;
        }
    },
    onChangeDiameter () {
        theApp.executeValueEvent("chairDiameter", this.chairDiameter)
        Settings.set('furniture.chairDiameter', this.chairDiameter)
    },
    findOptionSizeCircle (diameter) {
        switch (diameter) {
            case 500:
            case 600:
            case 700:
                return diameter
            default: 
                return 0
        }
    },
    // rectangle
    onChangeOptionSizeRectangle(event) {
        if (parseInt(event.target.value) !== 0) {
            this.chairHeight = parseInt(event.target.value);
            this.chairWidth = parseInt(event.target.value);

            this.onChangeHeight();
            this.onChangeWidth();
            this.showInputRectangle = false;
        } else {
            this.showInputRectangle = true;
        }
    },
    onChangeHeight() {
        theApp.executeValueEvent("chairHeight", this.chairHeight)
        Settings.set('furniture.chairWidthY', this.chairHeight)
    },
    onChangeWidth() {
        theApp.executeValueEvent("chairWidth", this.chairWidth)
        Settings.set('furniture.chairWidthX', this.chairWidth)
    },
    onChangeChairColor () {
      theApp.executeValueEvent("chairColor", this.chairColor)
      Settings.set('furniture.chairColor', this.chairColor)
    },
    findOptionSizeRectangle (widthX, widthY) {
        if (widthX !== widthY)
            return 0

        switch (widthX) {
            case 500:
            case 600:
            case 700:
                return widthX
            default: 
                return 0
        }
    }
  }
}
</script>

<style scoped>
</style>
