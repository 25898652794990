import * as AST from 'mnemonist';

import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';

import RestAPI from '@/visual-events/RestAPI/RestAPI';
import Logger from '@/frame/Logger';

const logger = new Logger('Ticketing');

export default class Ticketing {

  static apiKey = null;
  static language = null;

  constructor (eventId, cartId, root, allStates, timerMax, timerInterval) {

    this.eventId = eventId;
    this.cartId = cartId;

    this.bimap = new AST.BiMap();
    this._update(root.children);

    this.allStates = allStates;

    // timerMaxCalls = 0: do not stop polling
    this.timerInterval = timerInterval;  // msec
    this.timerMaxCalls = Math.floor(timerMax / timerInterval);    
    this.timer = null;
    this.timerCalls = 0;

    logger.log(`timerMax: ${timerMax}   (${this.timerInterval * this.timerMaxCalls})`);
    logger.log(`timerInterval: ${this.timerInterval}`);
    logger.log(`timerMaxCalls: ${this.timerMaxCalls}`);
  }

  /**
   * if the user requests or removes a ticket the request is sent
   * to the booking server. Only the booking server decides on the state
   * of the bookable items:
   * - a ticket could not be accepted, because another user has booked it
   *   in the meantime
   * - the cart might have expired, since the user did not go further for a
   *   longer time, could not pay it etc.
   * 
   * Thus, the bookable items'states are periodically polled by this app.
   * 
   * In order to limit the API requests, the polling calls are limited.
   * Its starts again, as soon as the user selects something.
   */
  startPolling() {
    logger.log(`Ticketing.startPolling timer=${this.timer}`);

    this.updateBookableStates().then(() => 
      {
        //do not start multiple timers
        //attention: stopPolling immediately before setInterval; otherwise in the meantime a second or third times might start
        this.stopPolling();
        this.timer = setInterval(() => {
          this.timerCalls++; 
          this.updateBookableStates().then(() => {
            logger.log(`timer ${this.timer}: ${this.timerCalls} timerMaxCalls of ${this.timerMaxCalls}`);
            if (this.timerMaxCalls > 0 && this.timerCalls >= this.timerMaxCalls - 1)
                this.stopPolling();
            }
          )
        }, this.timerInterval);
      }
    )
  }

  stopPolling() {
    logger.log(`Ticketing.stopPolling timer=${this.timer}`);
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
      this.timerCalls = 0;
    }
  }

  async updateBookableStates() {

    let modified = [];

    logger.log(`polling bookable states --------------------------------`);
    if (this.allStates) 
      modified = await this.updateBookableStatesAllStates();
    else  
      modified = await this.updateBookableStatesMandatoryStates();
    logger.log(`-------------------------------- polling bookable states`);

    theApp.model.modifiedIn2D = modified;
  }

  /**
   * first algorithm: use the mandatory states only
   * 
   * - bookable: available for purchase
   * - inMyCart: selected in the user's cart 
   * 
   * all others cannot be booked, use bookableState 'booked'
   */
  async updateBookableStatesMandatoryStates() {

    const bookable = await Ticketing.getBookableItems(this.eventId, { status: ['bookable'] });
    const reserved = await Ticketing.getBookableItems(this.eventId, { status: ['reserved'] });
    const inMyCartAll = await Ticketing.getBookableItemsInCart(this.cartId);

    const inMyCart = inMyCartAll.filter(item => item.eventId === this.eventId);

    const bookableIds = new Set(bookable.map(item => item.itemId));
    const inMyCartIds = new Set(inMyCart.map(item => item.itemId));

    // logger.log(`bookable: ${bookable.length} inMyCart: ${inMyCart.length}`);

    let updated = [];

    // determine the bookableState of all items
    this.bimap.forEach((op, id, map) => {

      const newState = inMyCartIds.has(id) ? 'inMyCart' : (bookableIds.has(id) ? 'bookable' : 'booked');

      if (op.getAttribute('bookableState') !== newState) {
        op.setAttribute('bookableState', newState);
        updated.push(op);
      }

    });

    // determine countBookable (consider only bookable items!)
    bookable.forEach(item => {
      const bookableId = `${item.itemId}`;
      const op = this.bimap.get(bookableId);
      if (!op) {
        console.warn(`bookableId ${bookableId} unknown in the plan`);
        return;
      }
      const countFree = Ticketing.getAttributeFromItem(item, '$countBookable');
      const countMax = Ticketing.getAttributeFromItem(item, '$countMax');
      if (countMax)
        Ticketing.setCountMax(op, countMax);
      if (Ticketing.getCountBookable(op) !== countFree) {
        Ticketing.setCountBookable(op, countFree);
        updated.push(op);
      }
    });

    reserved.forEach(item => {
      const bookableId = `${item.itemId}`;
      const op = this.bimap.get(bookableId);
      if (!op) {
        console.warn(`reserved bookableId ${bookableId} unknown in the plan`);
        return;
      }
      const countFree = Ticketing.getAttributeFromItem(item, '$countBookable');
      const countMax = Ticketing.getAttributeFromItem(item, '$countMax');
      if (countFree !== undefined)
        op.setAttribute('"$ticketing.item".attributes.$countBookable', countFree);
      if (countMax !== undefined)
        op.setAttribute('"$ticketing.item".attributes.$countMax', countMax);
    });

    return updated;
  }

  /**
   * second algorithm: use all states provided
   * 
   * all states but bookable and inMyCart have no semantic, it's just
   * for visualisation 
   */
  async updateBookableStatesAllStates() {

    const bookable = await Ticketing.getBookableItems(this.eventId, { status: ['bookable'] });
    const booked = await Ticketing.getBookableItems(this.eventId, { status: ['booked']});
    const nonbookable = await Ticketing.getBookableItems(this.eventId, { status: ['nonbookable']});
    const reserved = await Ticketing.getBookableItems(this.eventId, { status: ['reserved']});
    const inMyCart = await Ticketing.getBookableItemsInCart(this.cartId);

    logger.log(`bookable: ${bookable.length} inMyCart: ${inMyCart.length}`);

    const scope = this;

    let updated = [];
 
    bookable.forEach(item => {
      const bookableId = `${item.itemId}`;
      const op = scope.bimap.get(bookableId);
      if (op && op.getAttribute('bookableState') !== 'bookable') {
        op.setAttribute('bookableState', 'bookable');
        updated.push(op);
      }
    })

    booked.forEach(item => {
      const bookableId = `${item.itemId}`;
      const op = scope.bimap.get(bookableId);
      if (op && op.getAttribute('bookableState') !== 'booked') {
        op.setAttribute('bookableState', 'booked');
        updated.push(op);
      }
    })
    
    nonbookable.forEach(item => {
      const bookableId = `${item.itemId}`;
      const op = scope.bimap.get(bookableId);
      if (op && op.getAttribute('bookableState') !== 'nonbookable') {
        op.setAttribute('bookableState', 'nonbookable');
        updated.push(op);
      }
    })

    reserved.forEach(item => {
      const bookableId = `${item.itemId}`;
      const op = scope.bimap.get(bookableId);
      if (op && op.getAttribute('bookableState') !== 'reserved') {
        op.setAttribute('bookableState', 'reserved');
        updated.push(op);
      }
    })

    inMyCart.forEach(item => {
      if (item.eventId != scope.eventId)
        return;
      const bookableId = `${item.itemId}`;
      const op = scope.bimap.get(bookableId);
      if (op && op.getAttribute('bookableState') !== 'inMyCart') {
        op.setAttribute('bookableState', 'inMyCart');
        updated.push(op);
      }
    })

    return updated;

  }

  static getBookableId(op) {
    return op.getAttribute('"$ticketing.item".itemId');
  }

  static getAttributeFromItem(item, attribute)  {
    if (item && item.attributes)
      return item.attributes[attribute];
    return undefined;
  }

  static setCountBookable(op, countFree) {
    op.setAttribute('"$ticketing.item".attributes.$countBookable', countFree);
  }

  static setCountMax(op, countMax) {
    op.setAttribute('"$ticketing.item".attributes.$countMax', countMax);
  }

  static getCountBookable(op) {
    return op.getAttribute('"$ticketing.item".attributes.$countBookable');
  }

  static getName(op) {
    return op.getAttribute('"$ticketing.item".attributes.name');
  }

  static async countInCart(eventId, cartId, bookableId) {
    const inMyCartAll = await Ticketing.getBookableItemsInCart(cartId);

    const inMyCart = inMyCartAll.filter(item => item.eventId === eventId && item.itemId === bookableId);
    logger.log(`countInCart returns ${inMyCart.length}`);
    return inMyCart.length;
  }

  _update(children)
  {
    children.forEach(op => {
        const id = Ticketing.getBookableId(op)
        if (id)
          this._insert(id, op);

        this._update(op.children);
      }, this);
  }

  _insert(id, op)
  {
      if (!id)
          throw Error("BiMap: insertion failure: invalid id");

      const has_id = this.bimap.has(id);

      if (has_id && this.bimap.get(id) !== op)
          throw Error(`BiMap: insertion failure: integrity violation: false object found for id = ${id}`);

      if (!has_id)
      {
          console.assert(!this.bimap.inverse.has(op));

          this.bimap.set(id, op);

          console.assert(this.bimap.get(id) === op);
          console.assert(this.bimap.inverse.get(op) === id);
      }        
  }

  //----------------------------------------------------------------------------------------------------
  // REST Api wrappers
  //----------------------------------------------------------------------------------------------------

  /**
   * TODO: Currently the eventId contains the path of the plan model relative to "$tenant/models"; this should be
   * decided somewhere else (url or path include $/tenant/models?)
   * @param {*} eventId 
   * @returns 
   */
  static async getPlanForEvent(eventId) {
      const endpoint = `${Settings.get('.urlTicketing')}/events/${eventId}`;
      const method = 'GET';
      const header = new Headers({Accept: 'application/json', 'VisualEvents-ApiKey': Ticketing.apiKey, 'VisualEvents-Language': Ticketing.language});

      try {
        const data = await RestAPI.getResponse(endpoint, method, header);

        const planId = data.planId; 
        const pathModel = "$tenant/models/" + planId;
          
        return pathModel
      } catch(e) {}

      return null;
    }

    /**
     * alternative call: query over all events
     * @param {*} eventId 
     * {
     *   state: [bookable, booked, nonbookable, reserved]
     * }
     * @param {*} opts 
     * @returns 
     */
    static async getBookableItems(eventId, opts) {

      const query = `?${new URLSearchParams(opts).toString()}`;

      const endpoint = `${Settings.get('.urlTicketing')}/events/${eventId}/items${query}`;
      const method = 'GET';
      const header = new Headers({Accept: 'application/json', 'VisualEvents-ApiKey': Ticketing.apiKey, 'VisualEvents-Language': Ticketing.language});

      try {
        const data = await RestAPI.getResponse(endpoint, method, header);
        return data;
      } catch(e) {
        logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
      }

      return null;
    }

    /**
     * alternative call: query over all events
     * {
     *   eventId: <eventId>
     *   state: [bookable, booked, nonbookable, reserved]
     * }
     * @param {*} opts 
     * @returns 
     */

    static async getOverallBookableItems(opts) {

      const query = `?${new URLSearchParams(opts).toString()}`;

      const endpoint = `${Settings.get('.urlTicketing')}/items${query}`;
      const method = 'GET';
      const header = new Headers({Accept: 'application/json', 'VisualEvents-ApiKey': Ticketing.apiKey, 'VisualEvents-Language': Ticketing.language});

      try {
        const data = await RestAPI.getResponse(endpoint, method, header);
        return data;
      } catch(e) {
        logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
      }

      return null;
    }

    static async getBookableItemsInCart(cartId, opts = {}) {

      const query = `?${new URLSearchParams(opts).toString()}`;
      
      const endpoint = `${Settings.get('.urlTicketing')}/carts/${cartId}/tickets${query}`;
      const method = 'GET';
      const header = new Headers({Accept: 'application/json', 'VisualEvents-ApiKey': Ticketing.apiKey, 'VisualEvents-Language': Ticketing.language});

      try {
        const data = await RestAPI.getResponse(endpoint, method, header);
        return data;
      } catch(e) {
        logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
      }

      return null;
    }

    static async addToCart(eventId, cartId, itemIds) {
      
      const data = itemIds.map(itemId => { return {'eventId': eventId, 'itemId': itemId} });

      const response = await fetch(
        `${Settings.get('.urlTicketing')}/carts/${cartId}/tickets`,
        {
          method: 'POST',
          headers: new Headers({'Content-Type': 'application/json', Accept: 'application/json', 'VisualEvents-ApiKey': Ticketing.apiKey, 'VisualEvents-Language': Ticketing.language}),
          body: JSON.stringify(data),
        }
      )
      
      if (!response.ok) 
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);

      return response.json();
    }

    static async removeFromCart(eventId, cartId, itemIds) {
      const data = itemIds.map(itemId => { return {'eventId': eventId, 'itemId': itemId} });

      const response = await fetch(
        `${Settings.get('.urlTicketing')}/carts/${cartId}/tickets`,
        {
          method: 'DELETE',
          headers: new Headers({'Content-Type': 'application/json', Accept: 'application/json', 'VisualEvents-ApiKey': Ticketing.apiKey, 'VisualEvents-Language': Ticketing.language}),
          body: JSON.stringify(data),
        }
      )
      
      if (!response.ok) 
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);

      return Promise.resolve();
    }

    static async createBookableItems(eventId) {
      // get bookable items from drawing
      const drawings = theApp.model.drawing;

      const getBookableItemsFromDrawing = (children) => {
        let items = [];
        for (let i = 0; i < children.length; i++) {
          let child = children[i];
          if ('$ticketing.item' in child.attributes) {
            let payload = {
              eventId: eventId,
              itemId: child.attributes['$ticketing.item'].itemId,
              status: 'bookable',
              attributes: child.attributes['$ticketing.item'].attributes
            };
            items.push(payload);
          }
          items = items.concat(getBookableItemsFromDrawing(child.children));
        }
        return items;
      }

      const items = getBookableItemsFromDrawing(drawings);

      // delete all items for the event first
      fetch(`${Settings.get('.urlTicketing')}/events/${eventId}/items`, {
        method: 'DELETE',
        headers: { 'VisualEvents-ApiKey': Ticketing.apiKey, 'VisualEvents-Language': Ticketing.language }
      })
      .then(response => {
        if (response.ok) {
          console.log(`Deleted items for event ${eventId}`);
          // create new items only when old items could be deleted
          fetch(`${Settings.get('.urlTicketing')}/events/${eventId}/items`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'VisualEvents-ApiKey': Ticketing.apiKey, 'VisualEvents-Language': Ticketing.language },
            body: JSON.stringify(items)
          })
          .then(response => response.json())
          .then(data => console.log(data));
        }
      })
    }
}