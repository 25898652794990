<template>
    <div class="col-12 mb-2">
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="row-display-number" v-model="displayRowNo" @change="onChangeDisplayRowNo">
            <label class="form-check-label mb-2" for="number-toggle">Reihennummerierung</label>
        </div>
    </div>
    <div v-if="displayRowNo">
        <div class="col-12 mb-2">
            <InputNumber label="Reihe beginnen mit" :min="1" v-model="rowStartNumber" @update:modelValue="onChangeRowStartNumber" />
        </div>
        <div class="col-12 mb-2">
            <fieldset mb-2>
                <p class="fw-bold mb-0 mt-1">Richtung</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="row-numeration-direction" id="row-numeration-direction-front" v-model="rowModeDirection" value="FORWARD" @change="onChangeRowModeDirection">
                    <label class="form-check-label" for="row-numeration-direction-front">
                        von vorne
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="row-numeration-" id="row-numeration-direction-back" v-model="rowModeDirection" value="BACKWARD" @change="onChangeRowModeDirection">
                    <label class="form-check-label" for="row-numeration-direction-back">
                        von hinten
                    </label>
                </div>
            </fieldset>
        </div> 
        <div class="col-12">
            <fieldset class=" col-12 mb-2">
                <p class="fw-bold mb-0 mt-1">Anzeige</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-row-mode-text" id="numeration-rows-left" v-model="rowModeText" value="LEFT" @change="onChangeRowModeText">
                    <label class="form-check-label" for="numeration-row-mode-text">
                        links
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-row-mode-text" id="numeration-rows-right" v-model="rowModeText" value="RIGHT" @change="onChangeRowModeText">
                    <label class="form-check-label" for="numeration-row-mode-text">
                        rechts
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-row-mode-text" id="numeration-rows-both" v-model="rowModeText" value="BOTH" @change="onChangeRowModeText">
                    <label class="form-check-label" for="numeration-row-mode-text">
                        beide
                    </label>
                </div>
            </fieldset>
        </div> 

        <div class="ve-row">
            <InputNumberWithUnit unit="cm" :scale="10" label="Textgröße" :min="1" :max="200" :step="10" v-model="rowTextSize" @update:modelValue="onChangeRowTextSize" />
            <InputNumberWithUnit unit="cm" :scale="10" label="Abstand von der Reihe" :min="0" :max="200" :step="10" v-model="rowTextDist" @update:modelValue="onChangeRowTextDist" />
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputNumberWithUnit from './InputNumberWithUnit.vue';
import InputNumber from './InputNumber.vue';

export default {
  components: { 
    InputNumberWithUnit, 
    InputNumber 
  },
  name: 'PanelRenumberRows',
  data () {
    return {
        rowStartNumber : Settings.get('numeration.rowStartNumber'),
        rowModeDirection : Settings.get('numeration.rowModeDirection'),
        rowModeText : Settings.get('numeration.rowModeText'),
        rowTextSize : Settings.get('numeration.rowTextSize'),
        rowTextDist : Settings.get('numeration.rowTextDist'),
        displayRowNo : Settings.get('numeration.displayRowNo'),
    }
  },
  mounted () {
    theApp.addDialog('PanelRenumberRows', this)
  },
  unmounted () {
    theApp.removeDialog('PanelRenumberRows')
  },
  methods: {
    update(action) {
        this.rowStartNumber = action.renumberTool.rowStartNumber
        this.rowModeDirection = action.renumberTool.rowModeDirection
        this.rowModeText = action.renumberTool.rowModeText
        this.rowTextSize = action.renumberTool.rowTextSize
        this.rowTextDist = action.renumberTool.rowTextDist
        this.displayRowNo = action.renumberTool.displayRowNo
    },
    onChangeRowStartNumber() {
      theApp.executeValueEvent("rowStartNumber", this.rowStartNumber)
      Settings.set('numeration.rowStartNumber', this.rowStartNumber)
    },
    onChangeRowModeDirection () {
      theApp.executeValueEvent("rowModeDirection", this.rowModeDirection)
      Settings.set('numeration.rowModeDirection', this.rowModeDirection)
    },
    onChangeRowModeText() {
      theApp.executeValueEvent("rowModeText", this.rowModeText)
      Settings.set('numeration.rowModeText', this.rowModeText)
    },
    onChangeRowTextSize () {
      theApp.executeValueEvent("rowTextSize", this.rowTextSize)
      Settings.set('numeration.rowTextSize', this.rowTextSize)
    },
    onChangeRowTextDist() {
      theApp.executeValueEvent("rowTextDist", this.rowTextDist)
      Settings.set('numeration.rowTextDist', this.rowTextDist)
    },
    onChangeDisplayRowNo () {
      theApp.executeValueEvent("displayRowNo", this.displayRowNo)
      Settings.set('numeration.displayRowNo', this.displayRowNo)
    },
  }
}
</script>

<style scoped>
</style>
