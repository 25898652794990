import * as THREE from 'three';

import Geometry from '@/visual-events/model/Geometry'
import { rad2Deg, isEmpty } from '@/frame/Useful'
import OpShapePath from '@/visual-events/model/OpShapePath';
import ShapeUtils from '@/visual-events/model/ShapeUtils';

/**
 * build SVG text for a drawing and the referenced symbols
 * 
 * TODO: get rid of CADdyTypes
 * TODO: possibly use ShapeUtils.isXXX to find lines, etc. (for circles already done, because the SVGLoader cannot handle pathes with a full circle)
 * principles:
 * - evaluate the path to find the 'direct' SVG properties, such as x1,y1.. for lines, cx, cy, r for circles etc.
 * - addionally the OpObject may have a transform,  use  const transform = this.buildTransform(op); to provide transform="${transform}" 
 */

export default class CADdySVGExporter {

    constructor () {
        this.buffer = '';
        this.indent = '';
        this.indentStack = [];

        //for matrix decomposition
        this.p = new THREE.Vector3();
        this.q = new THREE.Quaternion();
        this.s = new THREE.Vector3();

        this.inDefs = false; //in recursive call in <defs> texts are hidden
    }

    /**
     * build a svg text containing the symbols in <defs> and a single drawing
     * 
     * All symbols referred to in the drawing should be contained in symbols, recursively.
     * @param {*} symbols 
     * @param {*} drawing 
     * @returns svg text ready to store into a file or to embed in html
     */
    write (symbols, drawing) {

        this.writeln('<?xml version="1.0" encoding="UTF-8"?>');
        this.writeln('<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">');

        //the drawing is supposed to be XOpDraft with attached width, height
        const width = drawing.width;
        const height = drawing.height;

        this.writeln(`<svg width="${width}mm" height="${height}mm" viewBox="0.000000 0.000000 ${width} ${height}" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">`);
        this.pushIndentation();

        //
        // export all symbols in <defs>
        //
        this.writeln(`<defs>`);
        this.inDefs = true;
        this.pushIndentation();
        symbols.forEach (symbol => this.writeXOpSym(symbol));
        this.popIndentation();
        this.inDefs = false;
        this.writeln(`</defs>`);

        // background
        this.writeln(`<rect x="0" y="0" width="${width}" height="${height}" fill="#ffffff"/>`);

        // marker for XOpPoint2
        this.writeln('<defs>');
        this.writeln('  <marker id="markerCircle" markerWidth="9" markerHeight="9" refX="5" refY="5" fill="none" markerUnits="userSpaceOnUse">');
        this.writeln('     <circle cx="5" cy="5" r="3" style="stroke:black; stroke-width:1px"/>');
        this.writeln('  </marker>');
        this.writeln('</defs>');

        //
        // export the drawing
        //
        this.writeXOpDraft(drawing)

        this.popIndentation();
        this.writeln('</svg>');
        return this.buffer;
    }

    writeXOpSym (op) {
        const xtype = op.type;
        const xid = op.id;
        const id = op.symbolId;
        const attributes = this.writeAttributes(op);
        this.writeln(`<g data-xid="${xid}" data-xtype="${xtype}" id="${id}" ${attributes}>`);
        this.writeChildren (op.children);
        this.writeln('</g>');
    }

    writeXOpDraft (op) {
        const xtype = op.type;
        const attributes = this.writeAttributes(op);
        this.writeln(`<g data-xtype="${xtype}"${attributes} transform="translate(0 ${op.height}) matrix(1 0 0 -1 0 0)" style="stroke:black; stroke-width:1px;" vector-effect="non-scaling-stroke">`);
        this.writeln(`  <title>${op.name}</title>`);
        this.writeChildren (op.children);
        this.writeln('</g>');
    }

    writeXOpSkB2Draft(op) {
        const xtype = op.type;
        const xid = op.id;
        const name = op.name;
        const transform = op.transform;
        transform.decompose(this.p, this.q, this.s);
        const scale = this.s.x; //should be uniform scale
        const attributes = this.writeAttributes(op);
        this.writeln(`<g data-xid="${xid}" data-xtype="${xtype}" data-name="${name}"${attributes} transform="scale(${scale})">`);
        this.writeln(`  <title>${op.name}</title>`);
        this.writeChildren (op.children);
        this.writeln('</g>');
    }

    writeXOpGroup (op) {
        const xtype = op.type;
        const xid = op.id;
        const name = op.name;
        const attributes = this.writeAttributes(op);
        this.writeln(`<g data-xid="${xid}" data-xtype="${xtype}" data-name="${name}"${attributes}>`);
        this.writeln(`  <title>${op.name}</title>`);
        this.writeChildren (op.children);
        this.writeln('</g>');
    }

    writeXOpFace (op) {
        const xtype = op.type;
        const xid = op.id;
        const path = op['path'];
        const style = op['style'];
        const fill = style.fill;
        const d = this.buildPath(path);
        const attributes = this.writeAttributes(op);
        this.writeln(`<path data-xid="${xid}" data-xtype="${xtype}"${attributes} d="${d}" fill="${fill}" style="position:absolute; z-index:0.000000" stroke="none"/>`);
    }

    buildPath(path) {
        let d = '';
        const v = new THREE.Vector2();

        const subPaths = path.subPaths;

        for ( let i = 0, n = subPaths.length; i < n; i ++ ) {

            const subPath = subPaths[ i ];
            const curves = subPath.curves;
            
            for ( let j = 0; j < curves.length; j ++ ) {

                const curve = curves[ j ];

                if (j == 0) {
                    curve.getPoint(0, v);
                    d += ` M ${v.x} ${v.y}`;
                }

                if ( curve.isLineCurve ) {
                    d += ` L ${curve.v2.x} ${curve.v2.y}`;

                } else if ( curve.isCubicBezierCurve ) {
                    d += ` C ${curve.v0.x}, ${curve.v0.y} ${curve.v1.x}, ${curve.v1.y} ${curve.v3.x}, ${curve.v3.y}`; // ${curve.v2.x} ${curve.v2.y}?

                } else if ( curve.isQuadraticBezierCurve ) {
                    d += ` Q ${curve.v0.x}, ${curve.v0.y} ${curve.v2.x}, ${curve.v2.y}`; // ${curve.v1.x}, ${curve.v1.y} ?

                } else if ( curve.isEllipseCurve ) {

                    const rx = curve.xRadius;
                    const ry = curve.yRadius;
                    const angle = curve.aRotation;
                    curve.getPoint(1, v);
                    const largeArc = rad2Deg(Math.abs(curve.aEndAngle - curve.aStartAngle)) > Math.PI ? 0 : 1;
                    const sweep = curve.aClockwise ? 0 : 1;

                    d += ` A ${rx} ${ry} ${angle} ${largeArc} ${sweep} ${v.x} ${v.y}`;
                }

                if (j== curves.length-1 && subPath.autoClose)
                    d += ` Z`;
            }

        }

        return d;
    }

    writeAttributes (op) {
        if (isEmpty(op.attributes))
            return '';

        const attributes = this.escape(JSON.stringify(op.attributes));
        return ` data-userattributes="${attributes}"`;
    }

    escape (str) {
        const escaped =
                str.replace(/&/g, '&amp;')
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;')
               .replace(/"/g, '&quot;')
               .replace(/'/g, '&apos;');
        return escaped;
    }

    buildTransform (op) {
        const m = op.transform; //Matrix4, but for 2D geometry assumed to be in the x,y-plane
        m.decompose(this.p, this.q, this.s);
        //hoose the sign appropriately: we are interested in the angle looking from z-direction
        let angle = (this.q.z < 0 ? -2 : 2) * rad2Deg(Math.acos(this.q.w));
        return `translate(${this.p.x} ${this.p.y}) rotate(${angle}) scale(${this.s.x})`;
    }

    //just to explain the mathematics of interpreting quaternions
    //https://stackoverflow.com/questions/62457529/how-do-you-get-the-axis-and-angle-representation-of-a-quaternion-in-three-js
    quaternionToAxisAngle(q) {
        const angle = 2 * Math.acos(q.w);
        var s;
        if (1 - q.w * q.w < 0.000001) {
          // test to avoid divide by zero, s is always positive due to sqrt
          // if s close to zero then direction of axis not important
          // http://www.euclideanspace.com/maths/geometry/rotations/conversions/quaternionToAngle/
          s = 1;
        } else { 
          s = Math.sqrt(1 - q.w * q.w);
        }
        return { axis: new THREE.Vector3(q.x/s, q.y/s, q.z/s), angle };
    }

    writeXOpSymbol (op) {
        const xtype = op.type;
        const xid = op.id;
        const symbolId = op.symbolId;
        const attributes = this.writeAttributes(op);
        const transform = this.buildTransform(op);
        this.writeln(`<g data-xid="${xid}" data-xtype="${xtype}"${attributes}>`);
        this.writeln(`  <use xlink:href="#${symbolId}" transform="${transform}"/>`);
        this.writeChildren(op.children);
        this.writeln('</g>');
    }

    writeXOpText2 (op) {
        const xtype = op.type;
        const xid = op.id;
        const xref = op.xref ? `data-xref="${op.xref}"` : '' ;
        const attributes = this.writeAttributes(op);
        const transform = this.buildTextTransform(op);
        const text = op['text'];
        const [x, y, z] = Geometry.getTranslation(op.transform);
        const hidden = (this.inDefs && op.xref) ? `visibility="hidden"` : '';
        this.writeln(`<text data-xid="${xid}" data-xtype="${xtype}"${attributes} ${xref} transform="${transform}" ${hidden} x="${x}" y="${y}" font-size="${op.fontSize}" font-family="${op.fontFamily}" text-anchor="${op.textAnchor}" dominant-baseline="${op.baseLine}" fill="${op.style.fill}" stroke="none" vector-effect="non-scaling-stroke" style="position:absolute; z-index:0.000000">${text}</text>`);
    } 

    /**
     * for texts the position is stored in text.x and text.y and must be reverted in advance
     * and they are mirrored at the x-axis because of the y-axis being considered to be upside down
     * @param {*} op 
     * @returns 
     */
    buildTextTransform (op) {
        const m = op.transform; //Matrix4, but for 2D geometry assumed to be in the x,y-plane
        m.decompose(this.p, this.q, this.s);
        //hoose the sign appropriately: we are interested in the angle looking from z-direction
        let angle = (this.q.z < 0 ? -2 : 2) * rad2Deg(Math.acos(this.q.w));
        return `translate(${this.p.x} ${this.p.y}) rotate(${angle}) scale(${this.s.x} ${-this.s.x}) translate(${-this.p.x} ${-this.p.y})`;
    }

    writeXOpStraSeg2 (op) {
        const path = op['path'];
        const style = op['style'];

        if (path.subPaths.length !== 1 || path.subPaths[0].curves.length !==  1)
            return;

        const line = path.subPaths[0].curves[0];

        if (line.type !== 'LineCurve')
            return;

        const xtype = op.type;
        const xid = op.id;
        const x1 = line.v1.x;
        const y1 = line.v1.y;
        const x2 = line.v2.x;
        const y2 = line.v2.y;
        const stroke = style.stroke;
        const strokeWidth = style.strokeWidth;
        const attributes = this.writeAttributes(op);

        this.writeln(`<line data-xid="${xid}" data-xtype="${xtype}"${attributes}  x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke="${stroke}" stroke-width="${strokeWidth}" vector-effect="non-scaling-stroke" style="position:absolute; z-index:0.000000"/>`);
    }

    writeXOpCirSeg2 (op) {
        const path = op['path'];
        const style = op['style'];
        if (path.subPaths.length !== 1 || path.subPaths[0].curves.length !==  1)
            return;
        
        const circle = path.subPaths[0].curves[0];

        if (circle.type !== 'EllipseCurve') //same as ArcCurve
            return;

        const xtype = op.type;
        const xid = op.id;
        const cx = circle.aX;
        const cy = circle.aY;
        const r = circle.xRadius;
        const start = circle.aStartAngle;
        const end = circle.aEndAngle;
        const fill = style.fill;
        const stroke = style.stroke;
        const strokeWidth = style.strokeWidth;
        const attributes = this.writeAttributes(op);
        const transform = this.buildTransform(op);

        if (this.isFullArc(start, end)) {
            this.writeln(`<circle data-xid="${xid}" data-xtype="${xtype}"${attributes}  cx="${cx}" cy="${cy}" r="${r}" transform="${transform}" fill="${fill}" stroke="${stroke}" stroke-width="${strokeWidth}" vector-effect="non-scaling-stroke" style="position:absolute; z-index:0.000000"/>`);
        } else {
            const d = this.buildPath(path);
            this.writeln(`<path data-xid="${xid}" data-xtype="${xtype}"${attributes}  d="${d}" fill="${fill}" stroke="${stroke}" stroke-width="${strokeWidth}" vector-effect="non-scaling-stroke" style="position:absolute; z-index:0.000000"/>`);
        } 
    }

    isFullArc(a0, a1) {
        const aDiff = a1 - a0;
        return Math.abs(aDiff) === 2 * Math.PI;
    }
    
    writeXOpEllSeg2 (op) {
        const path = op['path'];
        const style = op['style'];
        if (path.subPaths.length !== 1 || path.subPaths[0].curves.length !==  1)
            return;
        
        const ellipse = path.subPaths[0].curves[0];

        if (ellipse.type !== 'EllipseCurve')
            return;

        const xtype = op.type;
        const xid = op.id;
        const cx = ellipse.aX;
        const cy = ellipse.aY;
        const rx = ellipse.xRadius;
        const ry = ellipse.yRadius;
        const start = ellipse.aStartAngle;
        const end = ellipse.aEndAngle;
        const angle = rad2Deg(ellipse.aRotation);
        const transform = `translate(${cx} ${cy}) rotate(${angle}) scale(1) translate(${-cx} ${-cy})`;
        const stroke = style.stroke;
        const strokeWidth = style.strokeWidth;
        const attributes = this.writeAttributes(op);

        if (this.isFullArc(start, end)) {
            this.writeln(`<ellipse data-xid="${xid}" data-xtype="${xtype}"${attributes}  cx="${cx}" cy="${cy}" rx="${rx} ry="${ry}" transform=${transform} fill="none" stroke="${stroke}" stroke-width="${strokeWidth}" vector-effect="non-scaling-stroke" style="position:absolute; z-index:0.000000"/>`);
        } else {
            const d = this.buildPath(path);
            this.writeln(`<path data-xid="${xid}" data-xtype="${xtype}"${attributes}  d="${d}" fill="none" stroke="${stroke}" stroke-width="${strokeWidth}" vector-effect="non-scaling-stroke" style="position:absolute; z-index:0.000000"/>`);
        } 
    }

    writeOpShapePath (op) {

        if (ShapeUtils.isCircle(op)) {
            // SVGLoader cannot handle full circles correctly, so treat them here separately as circle
            this.writeXOpCirSeg2(op);
            return;
        }

        const path = op['path'];
        const style = op['style'];
        
        const xtype = 'OpShapePath';
        const xid = op.id;
        const fill = style.fill;
        const stroke = style.stroke;
        const strokeWidth = style.strokeWidth;

        const attributes = this.writeAttributes(op);
        const transform = this.buildTransform(op);

        const d = this.buildPath(path);
        this.writeln(`<path data-xid="${xid}" data-xtype="${xtype}"${attributes} d="${d}" transform="${transform}" fill="${fill}" stroke="${stroke}" stroke-width="${strokeWidth}" vector-effect="non-scaling-stroke" style="position:absolute; z-index:0.000000"/>`);
    }

    writeChildren(children) {
        this.pushIndentation();
        children.forEach(op => {
            if (op instanceof OpShapePath) {
                this.writeOpShapePath(op);
            } else 
            switch(op.type) {
                case 'XOpDraft':        this.writeXOpDraft(op);     break;
                case 'XOpSkB2Draft':    this.writeXOpSkB2Draft(op); break;
                case 'XOpGroup':        this.writeXOpGroup(op);     break;
                case 'XOpSymbol':       this.writeXOpSymbol(op);    break;
                case 'XOpFace':         this.writeXOpFace(op);      break;
                case 'XOpText2':        this.writeXOpText2(op);     break;
                case 'XOpStraSeg2':     this.writeXOpStraSeg2(op);  break;
                case 'XOpCirSeg2':      this.writeXOpCirSeg2(op);   break;
                case 'XOpEllSeg2':      this.writeXOpEllSeg2(op);   break; //TODO: test
                case 'XOpSplSeg2':      break;   //TODO: not implemented
                case 'XOpImage2':       break;   //TODO: not implemented
                case 'XOpHatch2':       break;   //TODO: not implemented
                case 'XOpPoint2':       break;   //TODO: not implemented
                //TODO: exotic XOpObects, test or deprecate
                case 'XUdoOpComp':      this.writeXOpGroup(op);     break;
                case 'XOpGrf2Ann':      this.writeXOpGroup(op);     break;
                case 'XOpModelView':    this.writeXOpGroup(op);     break;
                case 'XOpAxis2':        this.writeXOpStraSeg2(op);  break;
                case 'XOpAxis2Cir':     this.writeXOpCirSeg2(op);   break;
            }
        });
        this.popIndentation();
    }

    /**
     * build up the svg in this.buffer
     */
    clear () { this.buffer = ''; }

    writeln (text) { this.buffer += `${this.indent}${text}\n`; }

    /**
     * increase or decrease indentation
     */
    pushIndentation() {
        this.indentStack.push(this.indent);
        this.indent += '  ';
    }

    popIndentation() {
        this.indent = this.indentStack.pop();
    }
}