<template>
    <div class="panel-place-block ve-row">
        <div class="col-sm-12 mb-3">
            <div class="d-flex justify-content-between">
                <div class="d-flex gap-1">
                    <input type="radio" class="btn-check" name="options-placement" id="placement-single" autocomplete="off" v-model="mode" value="SINGLE" @change="onChangePlacingMode">
                    <label class="btn ve-btn-outline" for="placement-single" title="Einzelplatzierung"><i class="fa-solid fa-square"></i></label>

                    <input type="radio" class="btn-check" name="options-placement" id="placement-rect" autocomplete="off" v-model="mode" value="RECT" @change="onChangePlacingMode">
                    <label class="btn ve-btn-outline" for="placement-rect" title="rechteckige Aufstellung"><i class="fa-solid fa-bars"></i></label>
                </div>
                <div v-if="mode === 'RECT'" class="d-flex gap-1">
                    <input type="radio" class="btn-check" name="options-placement" id="hide-furniture" autocomplete="off" v-model="visibilityOption" value="INVISIBLE" @change="onDefineGaps">
                    <label class="btn ve-btn-outline" for="hide-furniture" title="Mobiliar ausblenden"><i class="fa fa-eye-slash"></i></label>

                    <input type="radio" class="btn-check" name="options-placement" id="show-furniture" autocomplete="off" v-model="visibilityOption" value="VISIBLE" @change="onFillGaps">
                    <label class="btn ve-btn-outline" for="show-furniture" title="Alles Mobiliar einblenden"><i class="fa fa-eye"></i></label>
                </div>
            </div>
        </div>

        <div v-if="displayGridParameters" class="col-sm-12 mb-3">
            <div class="col-sm-6 mb-1" >
                <div class="form-label">Ausrichtung</div>
            </div>
            <div class="col-sm-6 mb-3">
                <fieldset>
                    <div class="d-flex gap-1 justify-content-start">
                        <input type="radio" class="btn-check" name="options-offset" id="offset-none" autocomplete="off" value = "NONE" v-model = "shiftMode" @click="onClickShiftMode('NONE')">
                        <label class="btn ve-btn-outline" for="offset-none" title="Kein Versatz"><SVGIcon class="fs-4" :iconName="'icon-row'" :size="16" /></label>

                        <input type="radio" class="btn-check" name="options-offset" id="offset-half-right" autocomplete="off"  value = "RIGHT" v-model = "shiftMode"  @click="onClickShiftMode('RIGHT')">
                        <label class="btn ve-btn-outline" for="offset-half-right" title="Halb Versetz nach rechts"><i class="fa-solid fa-bars-staggered"></i></label>

                        <input type="radio" class="btn-check" name="options-offset" id="offset-half-left" autocomplete="off"  value = "LEFT" v-model = "shiftMode"  @click="onClickShiftMode('LEFT')">
                        <label class="btn ve-btn-outline" for="offset-half-left" title="Halb Versetzt nach links"><i class="fa-solid left fa-bars-staggered"></i></label>

                        <input v-if="allowTriangleMode" type="radio" class="btn-check" name="options-offset" id="offset-triangle" autocomplete="off"  value = "SHIFT_TRIANGLE" v-model = "shiftMode"  @click="onClickShiftMode('SHIFT_TRIANGLE')">
                        <label v-if="allowTriangleMode" class="btn ve-btn-outline" for="offset-triangle" title="Versetzte Anordnung für Rundtische"><SVGIcon class="fs-4" :iconName="'icon-gala'" :size="16" /></label>
                    </div>
                </fieldset>
            </div>
        </div>

        <div v-if="forTicketing" class="col-sm-12">
            <div class="form-group mt-2">
                <label for="object-title" class="form-label">Block</label>
                <input type="text" class="form-control" id="object-title"  v-model="block" @input="onChangeBlock">
            </div>
        </div>
        <div  v-if="forTicketing" class="col-sm-12">
            <div class="form-group mt-2">
                <label for="object-category" class="form-label">Kategorie</label>
                <select class="form-control" id="object-category"  v-model="category" @input="onChangeCategory">
                    <option value="" selected></option>
                    <option value="A">Block A</option>
                    <option value="B">Block B</option>
                    <option value="C">Block C</option>
                    <option value="D">Block D</option>
                </select>
            </div>
        </div>

        <InputNumber v-if="displayGridParameters" class="col-sm-6" label="Anzahl pro Reihe" :min=1 :max=1000 v-model="cntX" @update:modelValue="onChangeCntX" />

        <InputNumber v-if="displayGridParameters" class="col-sm-6" label="Reihen" :min="1" :max=100 v-model="cntY" @update:modelValue="onChangeCntY" />

        <InputNumberWithUnit v-if="displayGridParameters" class="col-sm-6" unit="cm" label="Abstand Objekte" :scale="10" :digits="1" :min="0" :step="10" v-model="distX" @update:modelValue="onChangeDistX" />

        <InputNumberWithUnit v-if="displayGridParameters && !isTriangleMode" class="col-sm-6" unit="cm" label="Abstand Reihen" :scale="10" :min="0" :digits="1" :step="10" v-model="distY" @update:modelValue="onChangeDistY" />
         <!-- placeholder: --><div class="col-sm-6"  v-if="displayGridParameters && (!displayGridParameters || isTriangleMode)"/>

        <InputNumberWithUnit class="col-sm-6" unit="°" label="Winkel" :periodic="true" v-model="angleX" @update:modelValue="onChangeAngleX" />

        <!-- <InputNumberWithUnit v-if="displayGridParameters" class="col-sm-6" unit="°" label="Winkel Schräge" :min="-90" :max="90"  :periodic="true" v-model="shear" @update:modelValue="onChangeAngleShear" /> -->

        <!-- <div v-if="displayGridParameters" class="col-sm-12">
            <div class="form-check form-check-inline mt-2">
                <input class="form-check-input" type="checkbox" id="object-count-per-row" value="true">
                <label class="form-check-label" for="object-count-per-row">Anzahl pro Reihe anpassen</label>
            </div>
        </div> -->
        </div>
</template>

<script>
import theApp from '@/frame/Application'
import Settings from '@/visual-events/data/Settings'
import SVGIcon from '@/vuejs/components/SVGIcon.vue'
import InputNumber from '@/vuejs/components/InputNumber.vue'
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue'
import {AlternateMode} from '@/visual-events/actions/GridProvider'

export default {
  name: 'PanelPlaceBlock',
  components: {
    InputNumber,
    InputNumberWithUnit,
    SVGIcon
  },
  data () {
    return {
        block: '',
        category: '',
        mode : Settings.get('grid.mode'),
        cntX : Settings.get('grid.cntX'),
        cntY : Settings.get('grid.cntY'),
        distX : Settings.get('grid.distX'),
        distY : Settings.get('grid.distY'),
        angleX : Settings.get('grid.angleX'),
        angleY : Settings.get('grid.angleY'),
        shear : 90.0 - (Settings.get('grid.angleY')*1 - Settings.get('grid.angleX')*1) % 180.0,

        alternateMode: AlternateMode.NONE,
        // alternate: Settings.get('grid.alternate'),
        keepRatioXY: Settings.get('grid.keepRatioXY'),
        allowTriangleMode: false,
        isRound: false,
        visibilityOption: "UNDEFINED",
    }
  },
  computed: {
    displayGridParameters: {
        get() {
            return this.mode !== 'SINGLE'
        }
    },
    isTriangleMode: {
        get() {
            return this.alternateMode === AlternateMode.SHIFT_TRIANGLE && this.keepRatioXY
        }
    },
    shiftMode: {
        get() {
            switch(this.alternateMode){
                case AlternateMode.NONE:
                    return 'NONE';
                case AlternateMode.RIGHT:
                    return 'RIGHT';
                case AlternateMode.LEFT:
                    return 'LEFT';
                case AlternateMode.SHIFT_TRIANGLE:
                    return 'SHIFT_TRIANGLE';
                default:
                    return 'NONE';
            }        
        },
        set(newValue) {
            switch (newValue) {
                case 'NONE':  this.keepRatioXY = false; break;
                case 'RIGHT': this.keepRatioXY = false; break;
                case 'LEFT':  this.keepRatioXY = false; break;
                case 'SHIFT_TRIANGLE': this.keepRatioXY = true; break;
            }
        }
    },
    forTicketing: {
        get() {
            return Settings.get('ticketing') !== undefined;
        }
    }
  },
  mounted () {
    theApp.addDialog('PanelPlaceBlock', this)
  },
  unmounted () {
    theApp.removeDialog('PanelPlaceBlock')
  },
  methods: {
    update(action) {
        this.mode = action.grid.mode
        this.cntX = action.grid.cntX
        this.cntY = action.grid.cntY
        this.distX = action.grid.distX
        this.distY = action.grid.distY
        this.angleX = action.grid.angleX
        this.angleY = action.grid.angleY
        this.shear = 90.0 - (action.grid.angleY*1 - action.grid.angleX*1) % 180.0

        this.alternateMode =  action.grid.alternateMode
        this.keepRatioXY = action.grid.keepRatioXY
        this.allowTriangleMode = action.allowTriangleMode
        this.visibilityOption = 'UNDEFINED'

        this.isRound = action.isRound();
    },
    setMode(mode) {
        this.visibilityOption = 'UNDEFINED'
        this.mode = mode
    },
    onChangePlacingMode() {
        this.visibilityOption = 'UNDEFINED'
        theApp.executeValueEvent("mode", this.mode)
    },
    onDefineGaps () {
        theApp.executeCommand('.FltPlaceBlock.defineGaps');
    },
    onFillGaps () {
        this.mode = 'UNDEFINED'
        theApp.executeCommand('.FltPlaceBlock.fillGaps');
    },
    onChangeBlock () {
      theApp.executeValueEvent("block", this.block)
    },
    onChangeCategory () {
      theApp.executeValueEvent("category", this.category)
    },
    onChangeCntX () {
      theApp.executeValueEvent("cntX", this.cntX)
      Settings.set('grid.cntX', this.cntX)
    },
    onChangeCntY () {
      theApp.executeValueEvent("cntY", this.cntY)
      Settings.set('grid.cntY', this.cntY)
    },
    onChangeDistX () {
      theApp.executeValueEvent("distX", this.distX)
      if (this.isTriangleMode || this.isRound)
        Settings.set('grid.triangleDistX', this.distX)
      else 
        Settings.set('grid.distX', this.distX)
    },
    onChangeDistY () {
      theApp.executeValueEvent("distY", this.distY)
      Settings.set('grid.distY', this.distY)
    },
    onChangeAngleX () {
      this.angleY = (this.angleX*1 + 90.0 - this.shear*1) % 360.0
      theApp.executeValueEvent("angleX", this.angleX)
      theApp.executeValueEvent("angleY", this.angleY)
      Settings.set('grid.angleY', this.angleX)
      Settings.set('grid.angleY', this.angleY)
    },
    onChangeAngleY () {
      this.shear = 90.0 - (Settings.get('grid.angleY')*1 - Settings.get('grid.angleX')*1) % 180.0
      theApp.executeValueEvent("angleY", this.angleY)
    },
    onChangeAngleShear () {
      this.angleY = (this.angleX*1 + 90.0 - this.shear*1) % 360.0
      theApp.executeValueEvent("angleY", this.angleY)
    }, 
    onClickShiftMode (mode) {
        let keepRatioXY = false;
        switch (mode) {
            case 'NONE':
                this.alternateMode = AlternateMode.NONE;
                break;
            case 'RIGHT': 
                this.alternateMode = AlternateMode.RIGHT;
                break;
            case 'LEFT': 
                this.alternateMode = AlternateMode.LEFT;
                break;
            case 'SHIFT_TRIANGLE': 
                this.alternateMode = AlternateMode.SHIFT_TRIANGLE;
                keepRatioXY = true;
                break;
        }
        theApp.executeValueEvent("alternateMode", this.alternateMode)
        theApp.executeValueEvent("keepRatioXY", keepRatioXY)
    }
  }
}
</script>

<style scoped>
.panel-place-block {
    background: white;
    height: 100%;
    width: 100%;

}
.left {
    rotate: 200grad;
}
</style>