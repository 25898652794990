import * as THREE from 'three';
import { nextTick } from 'vue';

import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';

import Action from '@/frame/Action';
import { BreakEvent } from '@/frame/Event.js';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Geometry from '@/visual-events/model/Geometry';
import OpFactory from '@/visual-events/model/OpFactory';
import OpUtils from '@/visual-events/model/OpUtils';
import ShapeUtils from '@/visual-events/model/ShapeUtils';
import Logger from '@/frame/Logger';

const logger = new Logger('ActCircle');

const State = Object.freeze({
    CENTER: 0,
    DRAG: 1,
    EDIT: 2
  });
  
export default class ActCircle extends Action {
    constructor(args) {
      super();

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.useStroke = Settings.get('style.useStroke', true);
      this.stroke = Settings.get('style.stroke', '#000000');
      this.strokeWidth = Settings.get('style.strokeWidth');
      this.useFill = Settings.get('style.useFill', true);
      this.fill = Settings.get('style.fill', '#222222');

      this.radius = Settings.get('circle.radius');

      // center and border and temporary grafic while creating a circle
      this.position1 = new THREE.Vector3(0, 0, 0);
      this.position2 = new THREE.Vector3(0, 0, 0);
      this.grafic = null; // temporary grafic

      this.objects = [];
      if (args.length > 1)
          this.objects = args[1];

      this.state = this.objects.length > 0 ? State.EDIT : State.CENTER;
   }

    actionStart () {
        logger.log(`actionStart`);
        if (this.state === State.EDIT)
            this.addFltSelectGrafic();
        else 
            this.addFilter(new FltPointDef());

        this.evaluateSelection();
        this.connectToGUI();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        this.resetCursor();
        this.clearTemporaryGrafic();
        this.disconnectFromGUI();
    }

    actionPoint (event) {
        logger.log(`actionPoint ${this.state}`);
        switch (this.state) {
            case State.CENTER: {
                    this.position1.x = event.p[0];
                    this.position1.y = event.p[1];

                    this.changePanel();

                    this.position2.x = this.position1.x;
                    this.position2.y = this.position1.y;

                    this.radius = Settings.get('circle.radius');

                    this.showTemporaryGrafic();

                    this.state= State.DRAG;
                }
                break;
            case State.DRAG:
                // does not happen
                break;
            case State.EDIT:
                //// handled by FltSelectGrafic
                break;
            }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);
        switch (this.state) {
            case State.CENTER:
                // ignored
                break;
            case State.DRAG: {
                this.position2.x = event.p[0];
                this.position2.y = event.p[1];
                const dx = this.position2.x - this.position1.x;
                const dy = this.position2.y - this.position1.y;

                this.radius = Math.sqrt(dx * dx + dy *dy);

                const panel = theApp.findDialogByName('PanelCircle');
                panel?.update(this);

                this.adaptTemporaryGrafic();

                this.state= State.DRAG;
                break;
            }
            case State.EDIT:
                // ignore
                break;
            }
        return null;
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.CENTER:
                // does not happen
                break;
            case State.DRAG: {

                    // do not update the radius on PointUp:
                    // If the PointUp follows on PointDown without intermediate Dynamic events, 
                    // the desired behavior is, that the initial radius is kept.
                    // In effect, the circle is just placed into the clicked position.

                    // this.position2.x = event.p[0];
                    // this.position2.y = event.p[1];

                    // const dx = this.position2.x - this.position1.x;
                    // const dy = this.position2.y - this.position1.y;

                    // this.radius = Math.sqrt(dx * dx + dy * dy);

                    // this.adaptTemporaryGrafic();
                    
                    // const panel = theApp.findDialogByName('PanelCircle');
                    // panel?.update(this);

                    const op = this.finishCircle();

                    // proceed with editing
                    this.objects.push(op);
                    this.disconnectFromGUI();
                    this.state = State.EDIT;

                    this.addFltSelectGrafic();
                    this.connectToGUI();
                    
                    break;
                }
            case State.EDIT:
                // ignore
                break;
        }
        this.handleCameraControlsEnableState();
        return null;
    }

    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'useStroke') {
            this.useStroke = event.value;
            this.applyStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'stroke') {
            this.stroke = event.value;
            this.applyStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'strokeWidth') {
            this.strokeWidth = event.value;
            this.applyStyle({ strokeWidth: this.strokeWidth });
            done = true;
        }

        if (event.attribute === 'useFill') {
            this.useFill = event.value;
            this.applyStyle({ useFill: this.useFill })
            this.applyStyle({ fillOpacity: (this.useFill ? 1 : 0.01) });
            done = true;
        }
        
        if (event.attribute === 'fill') {
            this.fill = event.value;
            this.applyStyle({ fill: (this.useFill ? this.fill : '#0000000') });
            done = true;
        }

        if (event.attribute === 'radius') {
            this.radius = event.value;
            this.adaptSize();
            this.adaptTemporaryGrafic();
            done = true;
        }

        return done ? null : event;
    }

    actionCommand(event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.CENTER:
                // does not happen
                break;
            case State.DRAG:
                // does not happen
                break;
            case State.EDIT: {
                switch (cmd) {
                    case '.select.delete': {
                        this.deleteSelection();
                        return new BreakEvent();
                    }
                    case '.select.copy': {
                        logger.log('.select.copy')
                        this.copySelection();
                        this.connectToGUI();
                        return null;
                    }
                    case '.select.applyTransform': {
                        const tdiff = event.args[0];
                        for (const op of this.objects)
                            op.applyTransform(tdiff);
                        return null;
                    }
                    case '.select.dragBoxPoint': {
                        const dragMode = event.args[0];
                        const box = event.args[1];

                        switch (dragMode)
                        {
                            case 'left':
                            case 'right':
                                this.radius = Math.abs(box.max.x - box.min.x) / 2.0;
                                break;
                            case 'bottom':
                            case 'top':
                                this.radius = Math.abs(box.max.y - box.min.y) / 2.0;
                                break;
                            default:
                                this.radius = Math.max(Math.abs(box.max.x - box.min.x) / 2.0, Math.abs(box.max.y - box.min.y) / 2.0);
                        }

                        logger.log(`${this.width} ${this.height}`)

                        const panel = theApp.findDialogByName('PanelCircle');
                        panel?.update(this);
                        this.adaptSize();

                        theApp.model.changed2d = true; //op;
                        return null;
                    }
                }
            } // case EDIT
        } // switch state

        return event;
    }

    /**
     * on delete command delete the selected objects 
     * and go into State.FIRST_POINT
     */
    deleteSelection () {
        OpUtils.deleteSelection(this.objects);
        theApp.model.changed2d = true;

        // State change to State.FIRST_POINT
        this.objects = [];
        this.clearTemporaryGrafic();
        this.removeFilter();
        this.disconnectFromGUI();
        this.state = State.FIRST_POINT;
        this.addFilter(new FltPointDef());
        this.connectToGUI();
        this.handleCameraControlsEnableState();
    }

    copySelection () {
        const dist = Settings.get('selection.grafic.iconDist', 300);
        this.objects = OpUtils.copySelection (this.objects, this.root2D, dist);

        this.disconnectFromGUI();
        this.state = State.EDIT;
        this.getFilter().shift(dist);

        theApp.model.changed2d = true;
    }


    finishCircle () {
        const op = this.grafic;
        this.grafic = null; // clear temporary grafic
        this.root2D.add(op);
        return op;
    }

    /** 
     * while editing FltSelectGrafic does most of the job
     */
    addFltSelectGrafic () {
        if (this.objects.length === 1) {
            const box = Geometry.computeBox(this.objects[0]);
            const transform = this.objects[0].transform;
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon(false).useBoxPoints());
        } else if (this.objects.length > 1) {
            const box = Geometry.computeBoxUnion(this.objects);
            const transform = new THREE.Matrix4(); // identity -> axis parallel
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon(false));
        }
    }

    showTemporaryGrafic () {
        switch (this.state) {
            case State.CENTER: {
                this.grafic = OpFactory.createCircle(this.position1.x, this.position1.y, this.radius);
                const style = { 
                    fill: this.useFill ? this.fill : 'none', 
                    fillOpacity: 1, 
                    stroke: this.useStroke ? this.stroke : 'none', 
                    strokeLineCap: 'but', 
                    strokeLineJoin: 'miter', 
                    strokeMiterLimit: 4, 
                    strokeOpacity: 0, 
                    strokeWidth: this.strokeWidth
                };
                this.grafic.setStyle(style);
                this.root2D.add(this.grafic);
                theApp.model.changed2d = true; //op;
                break;
            }
            case State.DRAG:
            case State.EDIT:
                    break; //does not happen
        }
    }

    clearTemporaryGrafic () {
        if (this.grafic) {
            this.root2D.remove(this.grafic);
            this.grafic = null;
            theApp.model.changed2d = true; //op;
        }
    }

    adaptTemporaryGrafic (optsStyle) {
        switch (this.state) {
            case State.CENTER:
                break; // does not happen
            case State.DRAG: {
                const op = this.grafic;
                const path = ShapeUtils.createCirclePath(this.position1.x, this.position1.y, this.radius);
                op.setShapePath(path);
        
                if (optsStyle)
                    op.setStyle(optsStyle);
        
                theApp.model.changed2d = true; //op;
                break;
            }
        }
    }

    evaluateSelection () {

        //TODO: wenn unterschiedliche Styles, dann was???
        this.objects.forEach(op => {
            const style = op.style;
            this.useStroke = style.stroke !== 'none';
            this.stroke = style.stroke;
            this.strokeWidth = style.strokeWidth;
            this.useFill =  style.fill !== 'none';
            this.fill = style.fill;

            const circle  = ShapeUtils.getCircle(op);
            this.radius = circle.radius;
        });

    }

    applyStyle (optsStyle) {
        switch (this.state) {
            case State.EDIT: 
                this.adaptSelectedObjects(optsStyle);
                break;
            default:
                this.adaptTemporaryGrafic(optsStyle);
                break;
        }
    }

    adaptSelectedObjects (optsStyle) {
        if (optsStyle) {
            this.objects.forEach(op => op.setStyle(optsStyle));
            theApp.model.changed2d = true;
        }
    }

    adaptSize () {
        this.objects.forEach(op => { 
            const circle = ShapeUtils.getCircle(op);
            const path = ShapeUtils.createCirclePath(circle.cx, circle.cy, this.radius);
            op.setShapePath(path);
        });
        theApp.model.changed2d = true;

        if (this.objects.length === 1)
            this.getFilter().update(this.objects[0].computeBox(), this.objects[0].transform);
        else
            this.getFilter().update(Geometry.computeBoxUnion(this.objects));
    }

    /**
     * while dragging disable the CameraControls
     */
    handleCameraControlsEnableState() {
        switch (this.state) {
            case State.DRAG:
            {
                this.view2D.disableCameraControls();
                break;
            }
            default: 
            {
                this.view2D.enableCameraControls();
                break;
            }
        }
    }

    resetCursor () {
        theApp.findDialogByName('main')?.setCursor(undefined);
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(this.state === State.EDIT ? 'Select' : 'Draw');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PanelDraw');
        nextTick(() => {
            // access updated DOM
            const panelDraw = theApp.findDialogByName('PanelDraw');
            if (panelDraw) {
                panelDraw.header = this.state === State.EDIT  ? "Kreis bearbeiten" : "Kreis erzeugen"
                panelDraw.showIcons = this.state !== State.EDIT;
                panelDraw.setTab('circle');
                nextTick(() => {
                    const panelDrawCircle =  theApp.findDialogByName('PanelDrawCircle');
                    panelDrawCircle.editMode = this.state === State.EDIT;
                    const panelStyle = theApp.findDialogByName('PanelStyle');
                    panelStyle.update(this);
                    const panelCircle = theApp.findDialogByName('PanelCircle');
                    panelCircle.update(this);
                })
            }
        })
    }

    
    changePanel() {
        const panelDraw = theApp.findDialogByName('PanelDraw');
        panelDraw.header = this.state === State.CENTER  ? "Kreis bearbeiten" : "Kreis erzeugen"
        panelDraw.showIcons = this.state !== State.CENTER;
        const panelDrawCircle =  theApp.findDialogByName('PanelDrawCircle');
        panelDrawCircle.editMode = this.state === State.CENTER;
        const panelStyle = theApp.findDialogByName('PanelStyle');
        panelStyle.update(this);
        const panelCircle = theApp.findDialogByName('PanelCircle');
        panelCircle.update(this);
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}    
