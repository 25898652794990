import Logger from "@/frame/Logger";
import { Matrix4, Vector3 } from "three";
import OpFactory from "../model/OpFactory";
import Settings from "../data/Settings";
import { CommandDispatcher } from "./FltSelectAndDispatch";
import Pick from "../view/Pick";
import { BreakEvent, CommandEvent } from "@/frame/Event";
import Geometry from "../model/Geometry";
import ShapeUtils from "../model/ShapeUtils";

const logger = new Logger('SelectGraficPolygon');

export default class SelectGraficPolygon extends CommandDispatcher {

    constructor() {
        super();

        this.grafic = null;

        this.area = null;
        this.points = [];
        this.boxPoints = [];
        this.withGeoPoints = false;
        this.sendBreakEventOnVoid = true;

        this.t = new Matrix4();
        this.p = new Vector3();

        this.gotPointUp = false;
    }

    useGeoPoints (use=true) { this.withGeoPoints = use; return this; }
    sendBreakOnVoid (use=true) { this.sendBreakEventOnVoid = use; return this; }

    create (points, transform) {
        logger.log(`create( ${JSON.stringify(points)} )`);

        this.points = points;

        this.grafic = OpFactory.createGroup('Selection');

        this.area = this.createSelection(points);
        this.grafic.add(this.area);

        if (this.withGeoPoints) {
            const diameter = Settings.get('selection.grafic.geoPointDiameter');

            for (const point of points) {
                this.addBoxPoint(point, diameter);
            }    
        }

        if (transform)
            this.grafic.setTransform(transform)

        return this.grafic;
    }

    addBoxPoint(point, diameter) {
        const geoPoint = this.createGeoPoint(diameter * 0.5);
        this.t.makeTranslation(point[0], point[1], 0);
        geoPoint.setTransform(this.t);
        this.grafic.add(geoPoint);
        this.boxPoints.push(geoPoint);
    }

    dispose () {
        this.grafic?.removeFromParent();
    }

    createSelection (points) {
        const face = OpFactory.createPolygon(points);
        face.pickable = true;
        face.style.fill = Settings.get('selection.grafic.fill');
        face.style.fillOpacity = Settings.get('selection.grafic.fillOpacity');
        face.style.stroke = Settings.get('selection.grafic.stroke');
        return face;
    }

    adaptSelection (face, points) {
        const path = ShapeUtils.createPolygonPath(points, true);
        face.setShapePath(path);
    }

    /**
     * create a geo point, a handle for manipulation 
     * @param {*} size 
     * @returns 
     */
    createGeoPoint (size) {
        const r = size*.5;
        const op = OpFactory.createPolygon([[-r, -r], [-r, r], [r, r], [r, -r]]);
        //const op = OpFactory.createCircle(0, 0, r);
        op.style.fill = '#ffffff' //Settings.get('selection.grafic.fill');
        op.style.stroke = Settings.get('selection.grafic.stroke');
        return op;
    }

    adapt (points, transform) {
        this.points = points;
        
        this.adaptSelection(this.area, this.points);

        if (this.withGeoPoints) {
            points.forEach((point, index) => {
                const boxPoint = this.boxPoints[index];
                if (boxPoint) {
                    this.t.makeTranslation(point[0], point[1], 0);
                    boxPoint.setTransform(this.t);
                }
            });
        }

        if (transform)
            this.grafic.setTransform(transform);
    }

    applyTransform (transform) {
        this.angle += Geometry.getRotationAngle(transform);
        const [x, y, z] = transform ? Geometry.getTranslation(transform) : [0, 0, 0];
        logger.log(`applyTransform( , [${x}, ${y}, ${z}] ${this.angle} )`)

        this.grafic.applyTransform(transform);
    }

    getTransform () {
        return this.grafic.transform;
    }

    getRotationAngle() {
        return Geometry.getRotationAngle(this.grafic.transform);
    }

    actionPoint (event) {
        logger.log(`actionPoint`);
        this.gotPointUp = true;
        return this.findCommand(event);
    }

    actionPointUp (event) {
        logger.log(`actionPointUp`);
        const command = this.findCommand(event)
        if (this.sendBreakEventOnVoid && !command && this.gotPointUp)
            return new BreakEvent();
        return event;
    }

    actionSelection (event) {
        event.objects = event.objects.filter(op => 
                op !== this.area
            &&  !this.boxPoints.find(bp => bp === op));
        return null;
    }

    actionKey (event) {
        const raw = event.raw;
        if (raw.type === 'keydown' && raw.key === 'Delete') { return new CommandEvent('.select.delete'); }
    }

//-----------------------------------------------------------------------------------------------------------------
// private
//-----------------------------------------------------------------------------------------------------------------

    findCommand(event) {
        const hits = Pick.pick(event.view, event.raw);
        const point = event.p;

        if (this.boxPoints) {
            for (const [index, boxPoint] of this.boxPoints.entries()) {
                if (hits.find(op => op === boxPoint))
                    return new CommandEvent('.select.boxPoint', index);
            }
        }
        if (this.area && hits.find(op => op === this.area))
            return new CommandEvent('.select.area', point);

        return null;
    }

}