import OpObject from '@/visual-events/model/OpObject'

export default class OpMesh extends OpObject {

    constructor (subtype, name, mesh, url, timestamp) {
        super (subtype, name, url, timestamp);

        this.mesh = mesh;
        
    }

    copy () {

        const copy = new OpMesh(this.type, this.name, this.mesh.clone());
        
        copy.visible = this.visible;
        copy.pickable = this.pickable;
        copy.setTransform(this.transform);

        return copy;
    }

    setGeometry (geometry) {
        
        if (this.mesh.isMesh) 
            this.mesh.geometry = geometry;
        else
            this.mesh.children[0].geometry = geometry;

    }

}
