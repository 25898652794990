import * as THREE from 'three';

import OpObject from '@/visual-events/model/OpObject'

export default class OpShapePath extends OpObject {

    constructor (subtype, name, path, style) {
        super (subtype, name);

        this.path = path;
        this.style = style;
    }

    copy () {

        const shapePath = new THREE.ShapePath();
        for (const path of this.path.subPaths) {
            const p = path.clone();
            shapePath.subPaths.push(p);
        }
        shapePath.color.set(this.color);

        const style = Object.assign( {}, this['style'] ); // clone style

        const copy = new OpShapePath(this.type, this.name, shapePath, style);

        copy.visible = this.visible;
        copy.pickable = this.pickable;
        copy.setTransform(this.transform);

        return copy;
    }

    setPath(points) {

        const path = new THREE.Path();
        const oldPath = this.path.subPaths[0];
        path.autoClose = oldPath?.autoClose;

        this.path.subPaths[0] = path;

        path.moveTo( points[0][0], points[0][1] );
        for (let i = 1; i < points.length; i++) 
            path.lineTo( points[i][0], points[i][1] );

        this.invalidateBox();

        // TODO: API, um selektiv Punkte zu ändern, autoClose u.a. soll bestehen bleiben
        // TODO: notification for grafic
    }

    setShapePath(path) {
        this.path = path;
        this.invalidateBox();
    }

    /**
     * merge the option object's properties into the style of this
     * 
     * example:
     * { fill: '#000000', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}
     * 
     * fill: 'none' and stroke: 'none' means 'do not fill' resp. 'no not show the contour'
     * 
     * TODO: use Options merge method
     * 
     * @param {*} style 
     */
    setStyle (opts) {
        //TODO: use json merge method
        if (opts.fill)
            this.style.fill = opts.fill;
        if (opts.useFill !== undefined)
            this.style.useFill = opts.useFill
        if (opts.fillOpacity !== undefined)
            this.style.fillOpacity = opts.fillOpacity;
        if (opts.stroke)
            this.style.stroke = opts.stroke;
        if (opts.strokeLineCap)
            this.style.strokeLineCap = opts.strokeLineCap;
        if (opts.strokeLineJoin)
            this.style.strokeLineJoin = opts.strokeLineJoin;
        if (opts.strokeOpacity)
            this.style.strokeOpacity = opts.strokeOpacity;
        if (opts.strokeWidth)
            this.style.strokeWidth = opts.strokeWidth;

        // TODO: notification for grafic
    }
}
