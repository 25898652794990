<template>
    <div class="panel-circle border-bottom p-3">
        <fieldset>
            <InputNumberWithUnit class="col-6" label="Durchmesser" unit="cm" :min="1" :scale="10" v-model="diameter" :digits="1" @update:modelValue="onChangeDiameter" />
        </fieldset>
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import Settings from '@/visual-events/data/Settings'

export default {
  name: 'PanelCircle',
  components: {
    InputNumberWithUnit,
  },
  data () {
    return {
        diameter: 0,
    }
  },
  mounted () {
    theApp.addDialog('PanelCircle', this)
  },
  unmounted () {
    theApp.removeDialog('PanelCircle')
  },
  methods: {
    update(action) {
        this.diameter = action.radius * 2.0
    },    
    onChangeDiameter () {
        theApp.executeValueEvent("radius", this.diameter / 2.0)
        Settings.set('circle.radius', this.diameter / 2.0)
    }
  }
}
</script>

<style scoped>
</style>
