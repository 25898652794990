export default {
    // settings
    nav: {
        buttons: { 
          'Select':     { name: 'Bearbeiten',       icon: { type: 'fa', class: 'fa-solid fa-arrow-pointer'},  command: '.Ticketing.waitForUserInput'}, 
//          'Favorites':  { name: 'Favoriten',        icon: { type: 'fa', class: 'fa-solid fa-star'},           command: '.Ticketing.favorites' }, 
          'Inventory':  { name: 'Inventar',         icon: { type: 'svg', name: 'icon-chair'},                 command: '.Ticketing.inventory'},
          'Draw':       { name: 'Zeichnen',         icon: { type: 'fa', class: 'fa-solid fa-pen'},            command: '.Ticketing.drawRectangle'},
          'Floorplan':  { name: 'Grundrissplaner',  icon: { type: 'svg', name: 'icon-floor'},                 command: '.floorplan.openDialog'},
          'Settings':   { name: 'Einstellungen',    icon: { type: 'fa', class: 'fa-solid fa-gear'},           command: '.Ticketing.settings'}, 
          'SunSky':     { name: 'Sonnenstand',      icon: { type: 'fa', class: 'fa-regular fa-sun'},          command: '.Ticketing.sunsky'}, 
          'Save':       { name: 'Speichern',        icon: { type: 'fa', class: 'fa-regular fa-floppy-disk'},  command: '.io.saveAll'},
          'Download':   { name: 'PDF Download',     icon: { type: 'fa', class: 'fa-solid fa-download'},       command: '.view.printToPdf'}
        },
        startButton: '',
    },
    layout: {
      split2D3Dvertically: false
    },
    views: [
      {
        id: '2D Ansicht',
        display: true,
        iconbar: {
          buttons: {
            'FitToCanvas':  { name: 'Ansicht zurücksetzen',   icon: 'fa-solid fa-arrows-up-down-left-right',  hidden: false, style: 'transform: rotate(45deg);', command: '.view.fitToCanvas "{{viewName}}" "{{zoomToFit}}"' },
            'ZoomOut':      { name: 'Zoom -',                 icon: 'fa-solid fa-minus',                      hidden: false, style: '',                          command: '.view.zoom "{{viewName}}" {{zoomOut}}' },
            'ZoomIn':       { name: 'Zoom +',                 icon: 'fa-solid fa-plus',                       hidden: false, style: '',                          command: '.view.zoom "{{viewName}}" {{zoomIn}}' },
            'Maximize':     { name: 'Maximieren',             icon: 'bi bi-fullscreen',                       hidden: false, style: '',                          command: '.planer.maximize2D'},
            'Minimize':     { name: 'Minimieren',             icon: 'bi bi-fullscreen-exit',                  hidden: true,  style: '',                          command: '.planer.minimize2D'},
          }
        },
        zoomMin: 0.2,
        zoomMax: 20,
        zoomToFit: 0.5
      },
      {
        id: '3D Ansicht',
        display: true,
        iconbar: {
          buttons: {
            'Maximize':     { name: 'Maximieren',             icon: 'bi bi-fullscreen',                       hidden: false, style: '',                          command:  '.planer.maximize3D'},
            'Minimize':     { name: 'Minimieren',             icon: 'bi bi-fullscreen-exit',                  hidden: true,  style: '',                          command:  '.planer.minimize3D'},
          }
        },
        controlbar: {
          buttons: {
            'Home':         { name: 'Start',                  icon: 'bi bi-house',                            hidden: false, style: '',                          command:  '.planer.initCameraPosition' },
            'Forward':      { name: 'Vorwärts',               icon: 'bi bi-arrow-up',                         hidden: false, style: '',                          command:  '.planer.simulateKey KeyW' },
            'Backward':     { name: 'Rückwärts',              icon: 'bi bi-arrow-down',                       hidden: false, style: '',                          command:  '.planer.simulateKey KeyS' },
            'Left':         { name: 'Links',                  icon: 'bi bi-arrow-left',                       hidden: false, style: '',                          command:  '.planer.simulateKey KeyA' },
            'Right':        { name: 'Rechts',                 icon: 'bi bi-arrow-right',                      hidden: false, style: '',                          command:  '.planer.simulateKey KeyD' },
            'Up':           { name: 'Hoch',                   icon: 'bi bi-chevron-double-up',                hidden: false, style: '',                          command:  '.planer.simulateKey KeyR' },
            'Down':         { name: 'Runter',                 icon: 'bi bi-chevron-double-down',              hidden: false, style: '',                          command:  '.planer.simulateKey KeyF' },
            'TurnLeft':     { name: 'Drehung Links',          icon: 'bi bi-arrow-counterclockwise',           hidden: false, style: '',                          command:  '.planer.simulateKey KeyQ' },
            'TurnRight':    { name: 'Drehung Rechts',         icon: 'bi bi-arrow-clockwise',                  hidden: false, style: '',                          command:  '.planer.simulateKey KeyE' },
            'Plane':        { name: 'Flugzeugmodus',          icon: 'bi bi-airplane',                         hidden: false, style: '',                          command:  '.planer.togglePlaneMode' },
            'Person':       { name: 'Durchgangsmodus',        icon: 'bi bi-person-walking',                   hidden: true,  style: '',                          command:  '.planer.togglePlaneMode' }
          }
        }
      },
      {
        id: 'Favoriten',
        display: false,
      },
    ],
}