import Settings from '../data/Settings';
import VariantChairCircle from '@/visual-events/actions/VariantChairCircle';
import VariantChairRectangle from '@/visual-events/actions/VariantChairRectangle';
import VariantChairBanquet from '@/visual-events/actions/VariantChairBanquet';
import VariantTableCircleWithSeats from '@/visual-events/actions/VariantTableCircleWithSeats';
import VariantTableRectangleWithSeats from '@/visual-events/actions/VariantTableRectangleWithSeats';

/**
 * helper class for replacing furniture in FltPlaceBlock
 * TODO: refactoring, should be much more generic without explicit switch for variant types and attributes
 * modifying objects e.g. mit JSONPath merge
 * partly should be integrated into a more general Variant api
 * especially 
 * - distinguish VariantXXX objects and the json attributes (fromJson, toJson or so)
 * - consider cloning, avoid unintendedly changing referenced parameters when modifying variant parameters...
 */
export default class VariantReplace {

    static createModified(variant, attribute, value) {

        if (attribute === 'chairShape') {
            switch (variant.type) {
                case 'CHAIR_CIRCLE':
                case 'CHAIR_RECTANGLE':
                case 'CHAIR_BANQUET':
                    return VariantReplace.createShapeModified(value);
            }
        }

        switch (variant.type) {
            case 'CHAIR_CIRCLE': {
                switch (attribute) {
                    case 'chairDiameter': return new VariantChairCircle(value, variant.opts.color);
                    case 'chairColor':    return new VariantChairCircle(variant.opts.diameter, value);
                }
                break;
            }
            case 'CHAIR_RECTANGLE': {
                const color = variant.opts.color;
                const height = variant.opts.height;
                const width = variant.opts.width;
                switch (attribute) {
                    case 'chairColor':  return new VariantChairRectangle(width, height,  value);
                    case 'chairWidth':  return new VariantChairRectangle(value, height,  color);
                    case 'chairHeight': return new VariantChairRectangle(width, value,  color);
                }
                break;
            }
            case 'CHAIR_BANQUET': {
                const color = variant.opts.color;
                const height = variant.opts.height;
                const width = variant.opts.width;
                switch (attribute) {
                    case 'chairColor':  return new VariantChairBanquet(width, height,  value);
                    case 'chairWidth':  return new VariantChairBanquet(value, height,  color);
                    case 'chairHeight': return new VariantChairBanquet(width, value,  color);
                }
                break;
            }
            case 'TABLE_CIRCLE_WITH_SEATS': {
                const tableDiameter = variant.opts.tableDiameter;
                const chairShape = variant.opts.chairShape;
                const chairDiameter = variant.opts.chairDiameter;
                const chairWidth = variant.opts.chairWidth;
                const chairHeight = variant.opts.chairHeight;
                const tableColor = variant.opts.tableColor;
                const chairColor = variant.opts.chairColor;
                const numberOfChair = variant.opts.numberOfChair;
                const freeSpaces = variant.opts.freeSpaces;
                switch (attribute) {
                    case 'tableDiameter':   return new VariantTableCircleWithSeats(value, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, numberOfChair, freeSpaces);
                    case 'chairShape':      return new VariantTableCircleWithSeats(tableDiameter, value, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, numberOfChair, freeSpaces);
                    case 'chairDiameter':   return new VariantTableCircleWithSeats(tableDiameter, chairShape, value, chairWidth, chairHeight, tableColor, chairColor, numberOfChair, freeSpaces);
                    case 'chairWidth':      return new VariantTableCircleWithSeats(tableDiameter, chairShape, chairDiameter, value, chairHeight, tableColor, chairColor, numberOfChair, freeSpaces);
                    case 'chairHeight':     return new VariantTableCircleWithSeats(tableDiameter, chairShape, chairDiameter, chairWidth, value, tableColor, chairColor, numberOfChair, freeSpaces);
                    case 'tableColor':      return new VariantTableCircleWithSeats(tableDiameter, chairShape, chairDiameter, chairWidth, chairHeight, value, chairColor, numberOfChair, freeSpaces);
                    case 'chairColor':      return new VariantTableCircleWithSeats(tableDiameter, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, value, numberOfChair, freeSpaces);
                    case 'numberOfChair':   return new VariantTableCircleWithSeats(tableDiameter, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, value, freeSpaces);
                    case 'freeSpaces':      return new VariantTableCircleWithSeats(tableDiameter, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, numberOfChair, value);
                }
                break;
            }
            case 'TABLE_RECTANGLE_WITH_SEATS': {
                const width = variant.opts.width;
                const height = variant.opts.height;
                const chairShape = variant.opts.chairShape;
                const chairDiameter = variant.opts.chairDiameter;
                const chairWidth = variant.opts.chairWidth;
                const chairHeight = variant.opts.chairHeight;
                const tableColor = variant.opts.tableColor;
                const chairColor = variant.opts.chairColor;
                const chairTop = variant.opts.chairTop;
                const chairBottom = variant.opts.chairBottom;
                const chairLeft = variant.opts.chairLeft;
                const chairRight = variant.opts.chairRight;
                switch (attribute) {
                    case 'tableWidth':      return new VariantTableRectangleWithSeats(value, height, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, chairTop, chairBottom, chairLeft, chairRight);
                    case 'tableHeight':     return new VariantTableRectangleWithSeats(width, value, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor,chairTop, chairBottom, chairLeft, chairRight);
                    case 'chairShape':      return new VariantTableRectangleWithSeats(width, height, value, chairDiameter, chairWidth, chairHeight, tableColor, chairColor,chairTop, chairBottom, chairLeft, chairRight);
                    case 'chairDiameter':   return new VariantTableRectangleWithSeats(width, height, chairShape, value, chairWidth, chairHeight, tableColor, chairColor,chairTop, chairBottom, chairLeft, chairRight);
                    case 'chairWidth':      return new VariantTableRectangleWithSeats(width, height, chairShape, chairDiameter, value, chairHeight, tableColor, chairColor,chairTop, chairBottom, chairLeft, chairRight);
                    case 'chairHeight':     return new VariantTableRectangleWithSeats(width, height, chairShape, chairDiameter, chairWidth, value, tableColor, chairColor,chairTop, chairBottom, chairLeft, chairRight);
                    case 'tableColor':      return new VariantTableRectangleWithSeats(width, height, chairShape, chairDiameter, chairWidth, chairHeight, value, chairColor,chairTop, chairBottom, chairLeft, chairRight);
                    case 'chairColor':      return new VariantTableRectangleWithSeats(width, height, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, value,chairTop, chairBottom, chairLeft, chairRight);
                    case 'chairTop':        return new VariantTableRectangleWithSeats(width, height, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, value, chairBottom, chairLeft, chairRight);
                    case 'chairBottom':     return new VariantTableRectangleWithSeats(width, height, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, chairTop, value, chairLeft, chairRight);
                    case 'chairLeft':       return new VariantTableRectangleWithSeats(width, height, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, chairTop, chairBottom, value, chairRight);
                    case 'chairRight':      return new VariantTableRectangleWithSeats(width, height, chairShape, chairDiameter, chairWidth, chairHeight, tableColor, chairColor, chairTop, chairBottom, chairLeft, value);
                }
                break;
            }
        }
    }

    static createShapeModified (chairShape) {
        switch (chairShape) {
            case 'SHAPE_CIRCLE': {
                //TODO: refactoring, not clean to involve Settings; either from settings or from previous variant, or precedence
                const color = Settings.get('furniture.chairColor', '#FFFFFF');
                const diameter = Settings.get('furniture.chairDiameter', 500);
                return new VariantChairCircle(diameter, color);
            }
            case 'SHAPE_RECTANGLE': {
                const color = Settings.get('furniture.chairColor', '#FFFFFF');
                const height = Settings.get('furniture.chairWidthY', 500);
                const width = Settings.get('furniture.chairWidthX', 500);
                return new VariantChairRectangle(width, height, color);
            }
            case 'SHAPE_BANQUET': {
                const color = Settings.get('furniture.chairColor', '#FFFFFF');
                const width = Settings.get('furniture.chairWidthX', 500);
                const height = Settings.get('furniture.chairWidthY', 500);
                return new VariantChairBanquet(width, height,  color);
            }
        }
    }
}