<template>
    <div class="col-12">
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="table-number-enabled" v-model="displayTableNo" @change="onChangeDisplayTableNo">
            <label class=" mb-2 form-check-label" for="number-toggle">Tischnummerierung</label>
        </div>
    </div>
    <div v-if="displayTableNo">
        <div class="col-12">
            <InputNumber label="Beginnen mit Nummer" :min="1" v-model="tableStartNumber" @update:modelValue="onChangeTableStartNumber" />
        </div>   
        <div class="col-12">
            <fieldset>
                <p class="fw-bold mb-0 mt-2">In der Reihe</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-table-row" id="numeration-table-row-left" v-model="tableModeInRow" value="LEFT" @change="onChangeTableModeInRow">
                    <label class="form-check-label" for="numeration-table-row-left">
                        von links
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-table-row" id="numeration-table-row-right" v-model="tableModeInRow" value="RIGHT" @change="onChangeTableModeInRow">
                    <label class="form-check-label" for="numeration-table-row-right">
                        von rechts
                    </label>
                </div>
            </fieldset>
        </div> 
        <!-- <div class="col-12">
            <fieldset>
                <p class="fw-bold">Alternierend</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-none" v-model="modeAlternate" value="NORMAL" @change="onChangeModeAlternate">
                    <label class="form-check-label" for="numeration-alternating-none">
                        Nicht (fortlaufend)
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-outside" v-model="modeAlternate" value="OUTER" @change="onChangeModeAlternate">
                    <label class="form-check-label" for="numeration-alternating-outside">
                        Von außen
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-alternating" id="numeration-alternating-inside" v-model="modeAlternate" value="INNER" @change="onChangeModeAlternate">
                    <label class="form-check-label" for="numeration-alternating-inside">
                        Von innen
                    </label>
                </div>
            </fieldset>
        </div>  -->
        <div class="col-12">
            <fieldset>
                <p class="fw-bold mb-0 mt-1">Reihen</p>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-table-rows" id="numeration-table-rows-front" v-model="tableModeRows" value="FORWARD" @change="onChangeTableModeRows">
                    <label class="form-check-label" for="numeration-table-rows-front">
                        von vorne
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-table-rows" id="numeration-table-rows-back" v-model="tableModeRows" value="BACKWARD" @change="onChangeTableModeRows">
                    <label class="form-check-label" for="numeration-table-rows-back">
                        von hinten
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="numeration-table-rows" id="numeration-table-rows-per-row" v-model="tableModeRows" value="PER_ROW" @change="onChangeTableModeRows">
                    <label class="form-check-label" for="numeration-table-rows-per-row">
                        pro Reihe
                    </label>
                </div>
            </fieldset>
        </div>
    </div>

</template>

<script>
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputNumber from './InputNumber.vue'

export default {
  name: 'PanelRenumberTables',
  components: {
    InputNumber
  },
  data () {
    return {
        tableStartNumber : Settings.get('numeration.tableStartNumber'),
        tableModeInRow : Settings.get('numeration.tableModeInRow'),
        tableModeAlternate : Settings.get('numeration.tableModeAlternate'),
        tableModeRows : Settings.get('numeration.tableModeRows'),
        displayTableNo : Settings.get('numeration.displayTableNo'),
    }
  },
  mounted () {
    theApp.addDialog('PanelRenumberTables', this)
  },
  unmounted () {
    theApp.removeDialog('PanelRenumberTables')
  },
  methods: {
    update(action) {
        this.tableStartNumber = action.renumberTool.tableStartNumber
        this.tableModeInRow = action.renumberTool.tableModeInRow
        this.tableModeAlternate = action.renumberTool.tableModeAlternate
        this.tableModeRows = action.renumberTool.tableModeRows
        this.displayTableNo = action.renumberTool.displayTableNo
    },
    onChangeTableStartNumber() {
      theApp.executeValueEvent("tableStartNumber", this.tableStartNumber)
      Settings.set('numeration.tableStartNumber', this.tableStartNumber)
    },
    onChangeTableModeInRow () {
      theApp.executeValueEvent("tableModeInRow", this.tableModeInRow)
      Settings.set('numeration.tableModeInRow', this.tableModeInRow)
    },
    onChangeTableModeAlternate () {
      theApp.executeValueEvent("tableModeAlternate", this.tableModeAlternate)
      Settings.set('numeration.tableModeAlternate', this.tableModeAlternate)
    },
    onChangeTableModeRows () {
      theApp.executeValueEvent("tableModeRows", this.tableModeRows)
      Settings.set('numeration.tableModeRows', this.tableModeRows)
    },
    onChangeDisplayTableNo () {
        theApp.executeValueEvent("displayTableNo", this.displayTableNo)
      Settings.set('numeration.displayTableNo', this.displayTableNo)
    },
  }
}
</script>

<style scoped>
</style>