import theApp from '@/frame/Application';
import Action from '@/frame/Action';
import * as Event from '@/frame/Event';
import Pick from '@/visual-events/view/Pick';
import Logger from '../../frame/Logger';

const logger = new Logger('FltHover');

const State = Object.freeze({
    NONE:           0,
    HOVERING:       1,
    NOT_HOVERING:   2
});

export default class FltHover extends Action {

    constructor() {
        super();

        this.state = State.NONE;
        this.lastId = -1;
    }

    actionStart () {
        logger.log(`actionStart  ${this.state}`);

        this.state = State.NOT_HOVERING;

        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy    ${this.state}`);
    }

    actionBreak (event) {
        return event;
    }

    /*
        TODO: Currently there are no member variables 'hovering' and 'objects'
        in the DynamicEvent. 
        Duck typing gives us a lot of flexibility but it also makes the code
        harder to read and understand. You can't tell that a dynamic event 
        can also be used as a hover event just by looking at it.
    */
    actionDynamic (event) {
        let hits = Pick.pick(event.view, event.raw, ['XOpSymbol']);

        if (hits && hits.length === 0 && this.state === State.HOVERING) {
            this.lastId = -1;
            this.state = State.NOT_HOVERING;
            event.hovering = false;
            event.objects = null;
        } else if (hits && hits.length > 0 && hits[0].id !== this.lastId && hits[0].getAttribute('"$ticketing.item"')) {
            this.lastId = hits[0].id;
            this.state = State.HOVERING;
            event.hovering = true;
            event.objects = hits;
        }

        return event;
    }

}