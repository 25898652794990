import theApp from '@/frame/Application';

import OpFactory from '@/visual-events/model/OpFactory';

/**
 * Variant objects are intended for attaching specifying parameters to OpObjects
 * 
 * e.g. standard chairs and tables, table/chair combinations, standard parts, ..
 * 
 * type identifies the kind of variant geometry
 *   inherently it stands for a specific building rule or script, which generates 
 *   the geometry
 * 
 * version should be increased, if the variant's specification evolves over the time
 *   it preserves the option of data migration lateron
 * 
 * opts are the specific defining parameters
 * 
 * description should some meaningful text, which depicts for human readers, what kind of 
 * variant object it is.
 *  
 * An important application is the reuse of OpSymbols, i.e. once a symbol of a special kind 
 * and special defining parameters exists, it should be used in all OpReferences to the 
 * same geometry in the model. Will be utilized for WebGL instancing.
 * 
 * -------------------------------------------------------------------------------
 * remarks on inheritance from class Variant
 * 
 * Possibly it is desirable to keep additional information in the object, which must 
 * not be considered in method 'equals'.
 * 
 * If these data are to be stored in the $variant attribute, put them below json,
 * otherwise in this, e.g.
 * 
 * class VariantX {
 *   constructor (opts, data1, data2) {
 *      super('X', 1, 'example', opts);
 *      this.json.data1 = data1;
 *      this.data2 = data2;
 *   }
 *   ...
 * }
 *
 *  data1 will be stored in in symbol.getAttribute('$variant'), 
 *        but not be taken into account for equality
 *  data2 will not be stored in $variant  
 */
export default class Variant {
    constructor (type, version, description, opts) {
        this.json = {
            type: type,
            version: version,
            description: description,
            opts: opts
        }
    }

    /**
     * a variant is considered equal if type and version and opts are identical
     * @param {*} json 
     * @returns 
     */
    equals (json) {
        return json.type === this.json.type 
            && json.version === this.json.version    
            && JSON.stringify(this.json.opts) === JSON.stringify(json.opts);
    }

    attachTo (op) {
        op.setAttribute('$variant', this.json);
    }

    detachFrom (op) {
        op.removeAttribute('$variant');
    }

    static getVariant (op) {
        return op.getAttribute('$variant');
    }

    variantOf (op) {
        const json = Variant.getVariant(op);
        return json && this.equals(json);
    }

    /**
     * find in the list an OpObject of the same variant parameters
     * @param {*} opObjects 
     * @returns 
     */
    find (opObjects) {
        let opSymbol = opObjects.find(op => this.variantOf(op));
        return opSymbol;
    }

    /*------------------------------------------------------------------------------
    OpSymbol generation and reuse protocol: 

    example:
    - generate an OpReference, reuse or create OpSymbol
        const variant = new VariantChairCircle(this.diameter, this.color);
        const opReference = variant.create();
    - edit an OpReference, reuse or create OpSymbol
        const variant = new VariantChairCircle(this.diameter, this.color);
        variant.edit(opReference);

    --------------------------------------------------------------------------------
    asynchronious version, if the symbol creating potentially needs more time, e.g.
    because resources have to be loaded.

    const variant = new VariantInventory(itemId, true);
    const opReference = await variant.createAsync();

    or

    variant.createAsync().then( op => {
        ...
    };

    ------------------------------------------------------------------------------*/

    /**
     * create the OpSymbol according to the given parameters
     * @returns OpSymbol
     */
    createSymbol () {
        // override in special Variant
        return null;
    }

    /**
     * create the OpReference, reuse or create OpSymbol
     * @returns 
     */
    create () {
        const opSymbol = this.getSymbol();
        const op = OpFactory.createReference(`${this.description}`, opSymbol.symbolId);

        op.copyAttributeTexts(opSymbol, true);

        return op;
    }

    /**
     * replace the OpSymbol referenced in the OpReference, reuse or create OpSymbol
     * @returns 
     */
    edit (opReference) {
        const opSymbol = this.getSymbol();
        if (opReference.symbolId !== opSymbol.symbolId) {
            opReference.symbolId = opSymbol.symbolId;
            // delete attribute texts
            opReference.removeAttachedAttributes();
    
            opReference.copyAttributeTexts(opSymbol, true);
        }
    }

    /* private */
    getSymbol () {
        let opSymbol = theApp.model.findVariant(this);

        if (!opSymbol) {
            opSymbol = this.createSymbol();
            this.attachTo(opSymbol);
        }

        return opSymbol;
    }


    /**
     * create the OpSymbol according to the given parameters asynchroniuously
     * @returns OpSymbol
     */
    async createSymbolAsync () {
        // override in special Variant
        return null;
    }

    /**
     * create the OpReference, reuse or create OpSymbol
     * @returns OpReference, null on error
     */
    async createAsync () {
        const opSymbol = await this.getSymbolAsync();
        return opSymbol ? OpFactory.createReference(`${this.description}`, opSymbol.symbolId) : null;
    }

    /**
     * replace the OpSymbol referenced in the OpReference, reuse or create OpSymbol
     * @returns OpSymbol, null on error
     */
    async editAsync (opReference) {
        const opSymbol = await this.getSymbolAsync();
        if (opSymbol)
            opReference.symbolId = opSymbol.symbolId;
        return opSymbol
    }

    /* private */
    async getSymbolAsync () {
        let opSymbol = theApp.model.findVariant(this);

        if (!opSymbol) {
            opSymbol = await this.createSymbolAsync();
            if (opSymbol)
                this.attachTo(opSymbol);
        }

        return opSymbol;
    }

}