import * as THREE from 'three';
import { deg2Rad } from '@/frame/Useful'

    //TODO: OpObject für Lichter erfinden
export default class Lighting {

    // start diagnostics:
    // In order to activate a preliminary means to show and edit the light properties
    // add to the SideNav buttons
    //          'Lights':     { name: 'Licht',            icon: { type: 'fa', class: 'fa-solid fa-lightbulb'},      command: '.Ticketing.lights'}
    // in SidePane 
    //import PanelLights from '@/vuejs/components/PanelLights';
    // and add PanelLights to the components
    // uses command .Ticketing.lights
    static lights = [];

    static getLights () {
        const lights = []
        for(const light of Lighting.lights) {
            const entry = {
                castShadow: light.castShadow,
                shadowBias: light.shadow.bias,
                shadowRadius: light.shadow.radius
            }
            lights.push(entry)
        }
        return lights
    }
    
    static set (i, light) {
        Lighting.lights[i].castShadow = light.castShadow;
        Lighting.lights[i].shadow.bias = light.shadowBias;
        Lighting.lights[i].shadow.radius = light.shadowRadius;
    }
    // end diagnostics

    static addAmbientLight (view, opts) {
        view.ambientLight = new THREE.AmbientLight(new THREE.Color("rgb(" + opts.color[0] + "," + opts.color[1] + "," + opts.color[2] + ")"), opts.intensity);
        view.scene.add(view.ambientLight);
    }

    static addDirectionalLight (view, light, shadowStage) {

        if (light.CameraLight) {

            if (view.cameraLight)
                view.camera.remove(view.cameraLight);

            view.cameraLight = new THREE.DirectionalLight(new THREE.Color("rgb(" + light.Color[0] + ", " + light.Color[1] + ", " + light.Color[2] + ")"));
            view.scene.add(view.camera);
            view.camera.add(view.cameraLight);

            const target = new THREE.Object3D();
            view.camera.add(target);

            view.cameraLight.target = target;

            target.position.set(0, 0, -1);

            view.cameraLight.position.copy(view.camera.position);        

            return view.cameraLight;

        } else {

            var boxSize = new THREE.Vector3();
            var center = new THREE.Vector3();
            var boundingBox = view.getBoundingBox();
            boundingBox.getSize(boxSize);
            boundingBox.getCenter(center);

            var directionalLight = new THREE.DirectionalLight(new THREE.Color("rgb(" + light.Color[0] + "," + light.Color[1] + "," + light.Color[2] + ")"));
            directionalLight.position.copy(center);
            directionalLight.intensity = light.Intensity;

            var lightTarget = new THREE.Object3D();
            view.scene.add(lightTarget);
            directionalLight.target = lightTarget;

            const target = new THREE.Vector3();
            target.copy(directionalLight.position);
            target.add(new THREE.Vector3(light.Direction[0], light.Direction[1], light.Direction[2]));

            lightTarget.position.copy(target);
            lightTarget.updateMatrixWorld();

            if (light.CastShadow && shadowStage.Enabled) {

                directionalLight.castShadow = true;

                directionalLight.shadow.mapSize.width = shadowStage.ShadowMapWidth;
                directionalLight.shadow.mapSize.height = shadowStage.ShadowMapHeight;
                directionalLight.shadow.bias = light.ShadowBias;
                directionalLight.shadow.radius = light.FilterRadius;
                directionalLight.shadow.camera.near = 1;
                directionalLight.shadow.camera.far = boxSize.y;
                directionalLight.shadow.camera.top = boxSize.z;
                directionalLight.shadow.camera.bottom = -boxSize.z;
                directionalLight.shadow.camera.left = -boxSize.x;
                directionalLight.shadow.camera.right = boxSize.x;
                directionalLight.shadow.camera.updateProjectionMatrix();

                // var directionalLightHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
                // view.scene.add(directionalLightHelper);

                directionalLight.userData = 'shadow';
            }

            view.scene.add(directionalLight);

            return directionalLight;
        }
    }

    static addPointLight (view, light, shadowStage) {
        
        if (light.CameraLight) {

            console.warn('VisualEvents3DView::addLightsFromSettings PointLight not supported as camera light yet');
            return null;

          } else {

              var pointLight = new THREE.PointLight(new THREE.Color("rgb(" + light.Color[0] + "," + light.Color[1] + "," + light.Color[2] + ")"));
              pointLight.position.set(light.Position[0], light.Position[1], light.Position[2]);
              pointLight.power = light.Intensity;
              pointLight.distance = 0; //light.Distance;
              pointLight.decay = light.Decay;

              if (light.CastShadow && shadowStage.Enabled) {

                  pointLight.castShadow = true;

                  pointLight.shadow.mapSize.width = shadowStage.ShadowMapWidth;
                  pointLight.shadow.mapSize.height = shadowStage.ShadowMapHeight;
                  pointLight.shadow.bias = light.ShadowBias;
                  pointLight.shadow.radius = light.FilterRadius;
                  pointLight.shadow.camera.near = 1;
                  pointLight.shadow.camera.far = 100000;

                  // var cameraHelper = new THREE.CameraHelper(pointLight.shadow.camera);
                  // view.scene.add(cameraHelper);

                  pointLight.userData = 'shadow';
              }

              view.scene.add(pointLight);
          }
          return pointLight;
    }

    static addSpotLight (view, light, shadowStage) {

        if (light.CameraLight) {

            console.warn('VisualEvents3DView::addLightsFromSettings SpotLight not supported as camera light yet');
            return null;

          } else {

              var spotLight = new THREE.SpotLight(new THREE.Color("rgb(" + light.Color[0] + "," + light.Color[1] + "," + light.Color[2] + ")"));
              spotLight.power = light.Intensity;
              spotLight.position.set(light.Position[0], light.Position[1], light.Position[2]);

              var spotLightTarget = new THREE.Object3D();
              view.scene.add(spotLightTarget);

              spotLight.target = spotLightTarget;

              const target = new THREE.Vector3();
              target.copy(spotLight.position);
              target.add(new THREE.Vector3(light.Direction[0], light.Direction[1], light.Direction[2]));

              spotLightTarget.position.copy(target);

              spotLightTarget.updateMatrixWorld();

              spotLight.angle = deg2Rad(light.Angle);
              spotLight.distance = light.Distance;
              spotLight.decay = light.Decay;
              spotLight.penumbra = light.Penumbra;

              if (light.CastShadow && shadowStage?.Enabled) {

                  spotLight.castShadow = true;

                  spotLight.shadow.mapSize.height = shadowStage.ShadowMapHeight;
                  spotLight.shadow.mapSize.width = shadowStage.ShadowMapWidth;
                  spotLight.shadow.bias = light.ShadowBias;
                  spotLight.shadow.radius = light.FilterRadius;
                  spotLight.shadow.camera.near = 1;
                  spotLight.shadow.camera.far = 100000;

                  // var spotLightHelper = new THREE.CameraHelper(spotLight.shadow.camera);
                  // view.scene.add(spotLightHelper);

                  spotLight.userData = 'shadow';

              }

              view.scene.add(spotLight);
          }

          return spotLight;
    }

    static addLight(view, lights) {

        // Renderer settings

        var toneMapping = view.toneMapping[lights.HDRStage["ToneMapMode:"]];
        view.renderer.toneMapping = toneMapping;
        view.renderer.toneMappingExposure = lights.HDRStage["Exposure:"];
        view.renderer.toneMappingWhitePoint = lights.HDRStage["WhiteLevel:"];

        view.renderer.shadowMap.enabled = lights.ShadowStage.Enabled;
        view.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        // Ambient light
        Lighting.addAmbientLight(view, lights.AmbientLight);

        // light sources
        lights.LightSourceArray.forEach(light => {

            if (!light.Active)
                return;

            switch (light.Type) {
                case "DirectionalLight":
                    Lighting.lights.push(Lighting.addDirectionalLight(view, light, lights.ShadowStage));
                    break;
                case "PointLight":
                    Lighting.lights.push(Lighting.addPointLight(view, light, lights.ShadowStage));
                    break;
                case "SpotLight":
                    Lighting.lights.push(Lighting.addSpotLight(view, light, lights.ShadowStage));
                    break;
            }
        });
    }

    static addLightUnreal(grf, lights) {
        if (!grf)
            return;

        const opts = lights.AmbientLight;
           
        grf.emitUIInteractionAddLight({
            type: 'ambient',
            color: [opts.color[0], opts.color[1], opts.color[2]],
            intensity: opts.intensity
        });

        lights.LightSourceArray.forEach(light => {

            if (!light.Active)
                return;

            switch (light.Type) {
                case "DirectionalLight":
                    grf.emitUIInteractionAddLight({
                        type: 'directional',
                        color: [light.Color[0], light.Color[1], light.Color[2]],
                        intensity: light.Intensity,
                        direction: [light.Direction[0], light.Direction[1], light.Direction[2]],
                    });
                    break;
                case "PointLight":
                    grf.emitUIInteractionAddLight({
                        type: 'point',
                        color: [light.Color[0], light.Color[1], light.Color[2]],
                        intensity: opts.Intensity,
                        position: [light.Position[0], light.Position[1], light.Position[2]],
                        decay: light.Decay
                    });
                    break;
                case "SpotLight":
                    grf.emitUIInteractionAddLight({
                        type: 'spot',
                        color: [light.Color[0], light.Color[1], light.Color[2]],
                        intensity: light.Intensity,
                        position: [light.Position[0], light.Position[1], light.Position[2]],
                        direction: [light.Direction[0], light.Direction[1], light.Direction[2]],
                        decay: light.Decay,
                        angle: light.Angle,
                        distance: light.Distance,
                        penumbra: light.Penumbra
                    });
                    break;
            }
        });


    }
}