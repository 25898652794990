import Action from "@/frame/Action";
import theApp from '@/frame/Application';
import { Matrix4, Vector3 } from "three";
import SelectGraficPolygon from "./SelectGraficPolygon";
import FltSelectAndDispatch from "./FltSelectAndDispatch";
import Logger from "@/frame/Logger";
import CommandLineScanner from "@/frame/CommandLineScanner";
import Geometry from "../model/Geometry";
import FltPointDef from "./FltPointDef";
import { CommandEvent } from "@/frame/Event";

const logger = new Logger('FltSelectGraficPolygon');

const State = Object.freeze({
    EDIT: 2,
    DRAG_GEOPOINT: 3,
    DRAG_IN_2D: 4,
    DRAG_IN_ROTATE: 5
});

const toFixed2 = mat4 => mat4.elements.map(n => Number(n.toFixed(2)))

export default class FltSelectGraficPolygon extends Action {

    constructor (points, transform) {
        super();

        logger.log(`constructor( ${JSON.stringify(points)} )`);

        this.doDragAndDrop = true;

        this.points = [...points];
        this.transform = transform.clone();

        // current mouse position
        this.position = new Vector3(0, 0, 0);

        // working matrix
        this.t = new Matrix4();

        this.p0 = new Vector3();
        this.q0 = new Vector3();
        // differential transform drag & drop
        this.tdiff = new Matrix4();
        // rotation while drag&drop
        this.rotation = Geometry.getRotationAngle(transform);
  
        // rotation with icon
        this.rotation0 = 0;
        this.center = new Vector3(0, 0, 0);

        this.selectedIndex = undefined;

        this.view2D = theApp.findViewByName('2D Ansicht');
        this.root2D = this.view2D.getRoot().children[0];

        this.selectGrafic = new SelectGraficPolygon();

        this.state = State.EDIT;
    }

    update(points, transform) {
        if (points)
            this.points = points;

        if (transform)
            this.transform.copy(transform);

        this.selectGrafic.adapt(points, transform);
        theApp.model.changed2d = true;
    }

    useGeoPoints (use=true) { this.selectGrafic.useGeoPoints(use); return this; }
    sendBreakOnVoid (use=true) { this.selectGrafic.sendBreakOnVoid(use); return this; }

    actionStart () {
        logger.log(`actionStart`);
        this.showTemporaryGrafic(this.points);
        this.addFilter(new FltSelectAndDispatch().first(this.selectGrafic));
        this.adaptCursor();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        this.resetCursor();
        this.clearTemporaryGrafic();
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);
        switch (this.state) {
            case State.EDIT:
                // ignore
                break;
            case State.DRAG_GEOPOINT: {
                
                this.position.x = event.p[0];
                this.position.y = event.p[1];

                this.p0.set(this.position.x, this.position.y);

                Geometry.getRelativeCoords(this.selectGrafic.getTransform(), this.p0, this.q0);

                this.points[this.selectedIndex] = [ this.q0.x, this.q0.y ];
                this.points[this.points.length - 1] = this.points[0];

                this.adaptTemporaryGrafic();

                return new CommandEvent('.select.dragBoxPoint', this.selectedIndex, this.q0);
            }
            case State.DRAG_IN_2D: {
                this.position.x = event.p[0];
                this.position.y = event.p[1];

                Geometry.calcDragAndDropTransformation(this.t, this.q0, this.rotation, this.position);
                Geometry.calcDifference(this.tdiff, this.selectGrafic.getTransform(), this.t);

                this.adaptTemporaryGrafic();

                this.transform.copy(this.selectGrafic.getTransform());

                return new CommandEvent('.select.applyTransform', this.tdiff);
            }
        }

        return null;
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.EDIT:
                // ignore
                break;
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_2D:
            case State.DRAG_IN_ROTATE: {
                this.state = State.EDIT;
                this.addFilter(new FltSelectAndDispatch().first(this.selectGrafic));
            }
        }

        this.adaptCursor();
        this.handleCameraControlsEnableState();
    }

    actionCommand (event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.EDIT: {
                switch (cmd) {
                    case '.select.delete':
                    case '.select.copy':
                        return event;
                    case '.select.boxPoint': {
                        this.state = State.DRAG_GEOPOINT;
                        this.selectedIndex = event.args[0];
                        this.addFilter(new FltPointDef);
                        this.adaptCursor();
                        this.handleCameraControlsEnableState();

                        return null;
                    }
                    case '.select.area': {
                        if (this.doDragAndDrop) {
                            const p = event.args[0];
                            this.position.x = p[0];
                            this.position.y = p[1];

                            this.p0.set(this.position.x, this.position.y, 0);

                            Geometry.getRelativeCoords(this.selectGrafic.getTransform(), this.p0, this.q0);
                            this.rotation = this.selectGrafic.getRotationAngle();

                            this.state = State.DRAG_IN_2D;
                            this.addFilter(new FltPointDef());
                            this.adaptCursor();
                            this.handleCameraControlsEnableState();
                        }
                        return null;
                    }
                }
            }
        }

        return event;
    }

    showTemporaryGrafic () {
        switch (this.state) {
            case State.EDIT: {
                this.root2D.add(this.selectGrafic.create(this.points, this.transform));
                theApp.model.changed2d = true;
            }
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_2D:
            case State.DRAG_IN_ROTATE:
                // does not happen
                break;
        }
    }

    clearTemporaryGrafic () {
        this.selectGrafic?.dispose();
        this.selectGrafic = null;
        theApp.model.changed2d = true;
    }

    adaptTemporaryGrafic () {
        switch (this.state) {
            case State.EDIT:
            case State.DRAG_GEOPOINT:
            {
                this.selectGrafic.adapt(this.points, this.transform);
                theApp.model.changed2d = true;
                break;
            }
            case State.DRAG_IN_2D:
            case State.DRAG_IN_ROTATE:
            {
                this.selectGrafic.applyTransform(this.tdiff);
                theApp.model.changed2d = true;
                break;
            }
        }
    }

    /**
     * while dragging disable the CameraControls
     */
    handleCameraControlsEnableState() {
        switch (this.state) {
            case State.DRAG_IN_2D: 
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_ROTATE:
            {
                this.view2D.disableCameraControls();
                break;
            }
            default: {
                this.view2D.enableCameraControls();
                break;
            }
        }
    }

    adaptCursor () {
        switch(this.state) {
            case State.DRAG_GEOPOINT:
            case State.DRAG_IN_2D:
            case State.DRAG_IN_ROTATE: {
                theApp.findDialogByName('2D Ansicht')?.setCursor('grabbing');
                break;
            }
            case State.EDIT: {
                theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
                break;
            }
       }
    }

    resetCursor () {
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
    }

}