import {Vector3, Matrix4, Quaternion, Box3, ShapeGeometry} from 'three';
import theApp from '@/frame/Application';
import OpReference from '@/visual-events/model/OpReference'
import OpSymbol from '@/visual-events/model/OpSymbol'
import OpGroup from '@/visual-events/model/OpGroup'
import * as THREE from 'three';
import OpText from '@/visual-events/model/OpText'
import OpMesh from '@/visual-events/model/OpMesh'
import OpShapePath from '@/visual-events/model/OpShapePath'
import GrfUtils from '@/visual-events/view/GrfUtils'
import { rad2Deg, deg2Rad } from '@/frame/Useful'

// buffers
const _q1 = new Quaternion();
const _v1 = new Vector3();
const _m1 = new Matrix4();

/**
 * static convenience methods 
 * mainly for working with Three.js Math objects
 */
export default class Geometry {
 
    /**
     * determine the scaling portions of the transform in each axis
     * 
     * e.g. getScaleX is useful to determine the scale of XOpSkBDraft, which is needed in
     * actions to calculate the world coordinates from the event coordinates delivered 
     * in sheet coordinates:
     * 
     * let scale = Geometry.getScaleX(draft.transform);
     *
     * @returns scale factor
     */
     static getScaleX(t) {
        const te = t.elements;
        return _v1.set( te[ 0 ], te[ 1 ], te[ 2 ] ).length();
    }
    
    static getScaleY(t) {
        const te = t.elements;
        return _v1.set(  te[ 4], te[ 5 ], te[ 6 ] ).length();
    }

    static getScaleZ(t) {
        const te = t.elements;
        return _v1.set( te[ 8 ], te[ 9 ], te[ 10 ] ).length();
    }

    /**
     * extract the translational portion of the transform as float array
     * @param {*} t 
     * @param {*} p 
     */
    static getTranslation(t) {
        const te = t.elements;
        return [ te[ 12 ], te[ 13 ], te[ 14 ] ];
    }

    /**
     * the z value in the translational part is sometimes of special interest, namely
     * in the context of 2D 3D coupling
     * @param {*} t 
     * @returns 
     */
    static getHeight(t) {
        return t.elements[14];
    }

    static setHeight(t, z) {
        t.elements[14] = z;
    }

    /**
     * get the rotation angle in degree of the transform
     */
    static getRotationAngle(t) {
        Geometry._prepareQuaternion(t, _q1);
        return (_q1.z < 0 ? -2 : 2) * rad2Deg(Math.acos(_q1.w));
    }
    
    //TODO  static getRotationAxis(t) {

    // extracted from Three.hs Matrix4.js decompose
    static _prepareQuaternion(t) {
        const te = t.elements;

		let sx = _v1.set( te[ 0 ], te[ 1 ], te[ 2 ] ).length();
		const sy = _v1.set( te[ 4 ], te[ 5 ], te[ 6 ] ).length();
		const sz = _v1.set( te[ 8 ], te[ 9 ], te[ 10 ] ).length();

		// if determine is negative, we need to invert one scale
		const det = t.determinant();
		if ( det < 0 ) sx = - sx;

        // scale the rotation part
		_m1.copy( t );

		const invSX = 1 / sx;
		const invSY = 1 / sy;
		const invSZ = 1 / sz;

		_m1.elements[ 0 ] *= invSX;
		_m1.elements[ 1 ] *= invSX;
		_m1.elements[ 2 ] *= invSX;

		_m1.elements[ 4 ] *= invSY;
		_m1.elements[ 5 ] *= invSY;
		_m1.elements[ 6 ] *= invSY;

		_m1.elements[ 8 ] *= invSZ;
		_m1.elements[ 9 ] *= invSZ;
		_m1.elements[ 10 ] *= invSZ;

		_q1.setFromRotationMatrix( _m1 );
    }

    /**
     * given an array of [x, y, z] create a Box3, which contains all points
     * starting with an empty box
     * @param {*} points 
     */
    static makeBox(points) {
        const box = new Box3();
        box.makeEmpty();
        points.forEach(p => box.expandByPoint(new Vector3(...p)));
        return box;
    }

    /**
     * compute the Box3 für OpObject op without consideration of op.transform
     * 
     * It will recursively set op.box in order to avoid repeated calculations.
     * But: Any later transformations will not update the box!!
     * 
     * The box is calculated recursively for op elements such as groups and symbols based on
     * transformedBoxBox. This means, it is not the minimum Box3 for the union of all the contained
     * and transformed geometries. Usually it is a bit bigger.
     * 
     * TODO: provide a box method, which works precisely (optional parameter precise = true, tradeoff for performance)
     * 
     * The resulting box might be empty
     * - in case of erroneous geometries or missing OpSymbol
     * - if there are only OpGroups without any geometry OpObject.
     * OpShape, OpMesh, OpText, OpReference to existing symbols always deliver non empty boxes.
     * 
     * @param {*} op 
     * @returns box
     */
    static computeBox(op) {

        if (op.box === null) {
            if (op instanceof OpReference) {

                const opSym = theApp.model.symbols.get(op.symbolId);
                op.box = Geometry.computeBox(opSym);

            } else if (op instanceof OpGroup || op instanceof OpSymbol) {

                op.box = this.computeBoxUnion(op.children);
                
            } else if (op instanceof OpText) {

                const shapes = GrfUtils.font.generateShapes(op.text, op.fontSize);
                if (shapes.length > 0) {
                    const geometry = new THREE.ShapeGeometry(shapes);
                    geometry.computeBoundingBox();
                    op.box = geometry.boundingBox.clone();
    
                    let xOffset = 0;
                    switch (op.textAnchor) {
                      case 'start':     break;
                      case 'middle':    xOffset = -0.5 * (op.box.max.x - op.box.min.x); break;
                      case 'end':       xOffset = - (op.box.max.x - op.box.min.x); break;
                    }
                
                    let yOffset = 0;
                    switch (op.baseLine) {
                      default:
                      case 'baseline': break;
                      case 'middle':   yOffset = - 0.5 * (op.box.max.y - op.box.min.y); break;
                      case 'hanging':  yOffset = - (op.box.max.y - op.box.min.y); break;
                    }    
    
                    op.box.min.x += xOffset;
                    op.box.max.x += xOffset;
                    op.box.min.y += yOffset;
                    op.box.max.y += yOffset;
    
                    geometry.dispose();
                } else {
                    op.box = new Box3();
                    op.box.min.set(0, 0, 0);
                    op.box.max.set(0, 0, 0);
                }

            } else if (op instanceof OpMesh) {  

                //TODO: not implemented

                //this seems ok, but not tested, whether box is correctly calculated for OpMesh
                // under all circumstances, possibly need updateMatrixWorld(true)?
                // const mesh = op.mesh;
                // if (!op.box)
                //     op.box = new Box3();
                // op.box.setFromObject(mesh); // scheint teuer zu sein!
 
                // this is not correct, because the mesh might be a group of
                // meshes; would require recursion
                // const geometry = mesh.geometry;
                // geometry.computeBoundingBox();
                // op.box = geometry.boundingBox;

            } else if (op instanceof OpShapePath) {  

                op.box = new Box3();
                op.box.makeEmpty();
                if (op.path) {
                    const shapes = op.path.toShapes();

                    for ( let j = 0; j < shapes.length; j ++ ) {
                        const shape = shapes[ j ];
                        const geometry = new ShapeGeometry( shape );
                        geometry.computeBoundingBox();
                        op.box.union(geometry.boundingBox);
                        geometry.dispose();
                    }
                }
            }
        }

        return op.box;
    }

    /**
     * compute the Box3 surrounding all objects
     * @param {*} objects 
     * @returns 
     */
    static computeBoxUnion(objects) {
        const box = new Box3();
        box.makeEmpty();
        objects.forEach(op => box.union(Geometry.transformedBoxBox(op)));
        return box;
    }

    /**
     * calculate the box transformed by op's transformation, 
     * usually a rotated and scaled rectangle
     * 
     * (only in 2D)
     * @param {*} op 
     * @returns array with the four corners as Vector3
     *          [] if the box is empty
     */
    static transformedBox (op) {
        const t = op.transform;
        const vertices = [];
        const box = op.computeBox();
        if (!box.isEmpty()) {
            _v1.set(box.min.x, box.min.y, 0);
            _v1.applyMatrix4(t);
            vertices.push(_v1.clone());
            _v1.set(box.max.x, box.min.y, 0);
            _v1.applyMatrix4(t);
            vertices.push(_v1.clone());
            _v1.set(box.max.x, box.max.y, 0);
            _v1.applyMatrix4(t);
            vertices.push(_v1.clone());
            _v1.set(box.min.x, box.max.y, 0);
            _v1.applyMatrix4(t);
            vertices.push(_v1.clone());
        }
        return vertices;                
    }

    /**
     * calculate a Box3, i.e. the axisparallel box, which contains the 
     * transformedBox
     * 
     * attention: this is not the same as the bounding Box3 op's geometry
     * transformed by op.transform. Usually transformedBoxBox is bigger. 
     * @param {*} op 
     * @returns 
     */
    static transformedBoxBox (op) {
        const vertices = Geometry.transformedBox (op);
        const box = new Box3();
        box.makeEmpty();
        for (const v of vertices)
            box.expandByPoint(v);
        return box;
    }

    /**
     * set Vector3 v to the center of Box3 box transformed by transform Matrix4 t
     * @param {*} v 
     * @param {*} box 
     * @param {*} t 
     */
    static makeBoxCenter (v, box, t = new Matrix4()) {
        v.copy(box.min).add(box.max).multiplyScalar(0.5);
        v.applyMatrix4(t);
    }


    /**
     * check if box1 and box2 intersect
     * box2 may be optionally transformed, e.g.to play the role for the select grafic box
     * @param {*} box1 
     * @param {*} box2 
     * @param {*} t 
     * @returns true or false
     */
    static boxesIntersect (box1, box2, t = new Matrix4()) {

        if (box1.isEmpty() || box2.isEmpty())
            return false;

        // this log only reasonable for axis parallel boxes
        // const [x, y, z] =Geometry.getTranslation(t)
        // console.log(`boxesIntersect([${box1.min.x},${box1.max.x}]x[${box1.min.y},${box1.max.y}], [${box2.min.x+x},${box2.max.x+x}]x[${box2.min.y+y},${box2.max.y+y}])`);

        // if a corner of one box lies inside of the other
        // the boxes intersect
        _v1.set(box2.min.x, box2.min.y, 0);
        _v1.applyMatrix4(t);
        if(box1.containsPoint(_v1))
            return true;
        _v1.set(box2.max.x, box2.min.y, 0);
        _v1.applyMatrix4(t);
        if(box1.containsPoint(_v1))
            return true;
        _v1.set(box2.max.x, box2.max.y, 0);
        _v1.applyMatrix4(t);
        if(box1.containsPoint(_v1))
            return true;
        _v1.set(box2.min.x, box2.max.y, 0);
        _v1.applyMatrix4(t);
        if(box1.containsPoint(_v1))
            return true;

        _m1.copy(t);
        _m1.invert();
        _v1.set(box1.min.x, box1.min.y, 0);
        _v1.applyMatrix4(_m1);
        if(box2.containsPoint(_v1))
            return true;
        _v1.set(box1.min.x, box1.max.y, 0);
        _v1.applyMatrix4(_m1);
        if(box2.containsPoint(_v1))
            return true;
        _v1.set(box1.max.x, box1.min.y, 0);
        _v1.applyMatrix4(_m1);
        if(box2.containsPoint(_v1))
            return true;
        _v1.set(box1.max.x, box1.max.y, 0);
        _v1.applyMatrix4(_m1);
        if(box2.containsPoint(_v1))
            return true;

        // This alone is not sufficient: check for box edges' intersections

        const p0 = {x: box1.min.x, y: box1.min.y};
        const p1 = {x: box1.max.x, y: box1.min.y};
        const p2 = {x: box1.max.x, y: box1.max.y};
        const p3 = {x: box1.min.x, y: box1.max.y};

        _v1.set(box2.min.x, box2.min.y, 0);
        _v1.applyMatrix4(t);
        const q0 = _v1.clone();
        _v1.set(box2.max.x, box2.min.y, 0);
        _v1.applyMatrix4(t);
        const q1 = _v1.clone();
        _v1.set(box2.max.x, box2.max.y, 0);
        _v1.applyMatrix4(t);
        const q2= _v1.clone();
        _v1.set(box2.min.x, box2.max.y, 0);
        _v1.applyMatrix4(t);
        const q3 = _v1.clone();

        // useful console output for analysing errors l=left, r=right, b=bottom, t=top edges
        // const bb =Geometry.linesIntersect(q0, q1, p0, p1).intersects 
        // const br =Geometry.linesIntersect(q0, q1, p1, p2).intersects 
        // const bt =Geometry.linesIntersect(q0, q1, p2, p3).intersects 
        // const bl =Geometry.linesIntersect(q0, q1, p3, p0).intersects 

        // const rb =Geometry.linesIntersect(q1, q2, p0, p1).intersects 
        // const rr =Geometry.linesIntersect(q1, q2, p1, p2).intersects 
        // const rt =Geometry.linesIntersect(q1, q2, p2, p3).intersects 
        // const rl =Geometry.linesIntersect(q1, q2, p3, p0).intersects 

        // const tb =Geometry.linesIntersect(q2, q3, p0, p1).intersects 
        // const tr =Geometry.linesIntersect(q2, q3, p1, p2).intersects 
        // const tt =Geometry.linesIntersect(q2, q3, p2, p3).intersects 
        // const tl =Geometry.linesIntersect(q2, q3, p3, p0).intersects 

        // const lb =Geometry.linesIntersect(q3, q0, p0, p1).intersects 
        // const lr =Geometry.linesIntersect(q3, q0, p1, p2).intersects 
        // const lt =Geometry.linesIntersect(q3, q0, p2, p3).intersects 
        // const ll =Geometry.linesIntersect(q3, q0, p3, p0).intersects; 

        // console.log(`b ${bb} ${bt} ${bl} ${br}\nt ${tb} ${tt} ${tl} ${tr}\nr ${rb} ${rt} ${rl} ${rr}\nl ${lb} ${lt} ${ll} ${lr}`)

        return Geometry.linesIntersect(q0, q1, p0, p1).intersects 
            || Geometry.linesIntersect(q0, q1, p1, p2).intersects 
            || Geometry.linesIntersect(q0, q1, p2, p3).intersects 
            || Geometry.linesIntersect(q0, q1, p3, p0).intersects 

            || Geometry.linesIntersect(q1, q2, p0, p1).intersects 
            || Geometry.linesIntersect(q1, q2, p1, p2).intersects 
            || Geometry.linesIntersect(q1, q2, p2, p3).intersects 
            || Geometry.linesIntersect(q1, q2, p3, p0).intersects 

            || Geometry.linesIntersect(q2, q3, p0, p1).intersects 
            || Geometry.linesIntersect(q2, q3, p1, p2).intersects 
            || Geometry.linesIntersect(q2, q3, p2, p3).intersects 
            || Geometry.linesIntersect(q2, q3, p3, p0).intersects 

            || Geometry.linesIntersect(q3, q0, p0, p1).intersects 
            || Geometry.linesIntersect(q3, q0, p1, p2).intersects 
            || Geometry.linesIntersect(q3, q0, p2, p3).intersects 
            || Geometry.linesIntersect(q3, q0, p3, p0).intersects; 
    }

    /**
     * set Matrix4 t as rotation transform in the xy-plane around origin by a degrees 
     * and translate by Vector3 x, y, z 
     * 
     * This is the transform as required to place OpObjects in a 
     * - 2D position x,y 
     * - in height z 
     * - and rotated by a.
     * @param {*} t 
     * @param {*} p
     * @param {*} a 
     */
    static makePlacingTransform(t, x, y, z, a) {
        t.makeRotationZ(deg2Rad(a)).setPosition(x, y, z);
    }

    /**
     * set Matrix4 t as rotation transform in the xy-plane around Vector3 p by a degrees.
     * @param {*} t 
     * @param {*} p
     * @param {*} a 
     */
    static makeRotationZ(t, p, a) {
        t.makeRotationZ(deg2Rad(a)).setPosition(p);
        _v1.copy(p);
        _m1.makeTranslation(_v1.negate());
        t.multiply(_m1);
    }

    /**
     * set Vector3 to contain the relative coordinates of Vector3 p with respect to the
     * Matrix4 t, interpreted as local coordinate system
     * @param {*} t 
     * @param {*} p 
     * @param {*} q 
     */
    static getRelativeCoords(t, p, q) {
        _m1.copy(t);
        _m1.invert();
        _v1.copy(p);
        _v1.applyMatrix4(_m1);
        q.copy(_v1);
    }

    /**
     * set Matrix4 t to the transform, in which Vector3 p has the relative coordinates Vector3 q. 
     * t, interpreted as the local coordinate system of the geometry being dragged, is rotated by a degrees.
     * 
     * Use this method while dragging in order to keep the initial relative position of the cursor fixed
     * with respect to the geometry, event if the angle changes.
     * @param {*} t 
     * @param {*} q 
     * @param {*} a 
     * @param {*} p 
     */
    static calcDragAndDropTransformation(t, q, a, p) {

        // create a local coordinate system rotated by a in p
        Geometry.makePlacingTransform(_m1, p.x, p.y, 0, a);

        // apply the negative relative position and get in _v1 the new origin 
        // of the local coordinate system of the geometry
        _v1.copy(q);
        _v1.negate();
        _v1.applyMatrix4(_m1);

        Geometry.makePlacingTransform(t, _v1.x, _v1.y, 0, a);
    }

    /**
     * set Matrix t to the transformation which applied on t0 leads to t1
     *  t1 = tdiff o t0
     * @param {*} tdiff 
     * @param {*} t0 
     * @param {*} t1 
     */
    static calcDifference(t, t0, t1) {
        t.copy(t0);
        t.invert();
        t.premultiply(t1);
    }

    /**
     * return the closest position of p to the line v1 to v2 and the distance from p
     * @param {*} v1 
     * @param {*} v2 
     * @param {*} p 
     * @returns { x, y, d } projected point x, y and distance d from p
     */
    static closestOnLine (v1, v2, p) {

        const { x, y, b } = Geometry.projectToLine(v1, v2, p);

        _v1.x = x;
        _v1.y = y;

        const d = _v1.distanceTo(p);
        const d1 = v1.distanceTo(p);
        const d2 = v2.distanceTo(p);

        // the projected point or one of the end points
        if (b < 0) 
            return { x: v1.x, y: v1.x, d: d1 }
        else if (b > 1)
            return { x: v2.x, y: v2.x, d: d2 }
        else
            return { x: x, y: y, d: d }
    }

    /**
     * project p onto the 2D line going through v1 and v2
     * @param {*} v1 
     * @param {*} v2 
     * @param {*} p 
     * @returns { x, y, b } coordinates of the projected point and barycentric coordinate
     */
    static projectToLine (v1, v2, p) {
        const l = (v2.x - v1.x) * (v2.x - v1.x) + (v2.y - v1.y) * (v2.y - v1.y);
        if (Math.abs(l) < Number.EPSILON)
            return { x: NaN, y: NaN, b: NaN}; // error case

        const b = ((p.x - v1.x) * (v2.x - v1.x) + (p.y - v1.y) * (v2.y - v1.y)) / l;
        const x = (1 - b) * v1.x + b * v2.x;
        const y = (1 - b) * v1.y + b * v2.y;

        return { x: x, y: y, b: b };         
    }

    /**
     * angle in degree of the 2D line going through v1 and v2
     * @param {*} v1 
     * @param {*} v2 
     * @returns angle
     */
    static angleOfLine (v1, v2) {
        return  rad2Deg(Math.atan2(v2.y - v1.y, v2.x - v1.x));
    }

    /**
     * checks, if two 2D lines p0 -> p1 and q0 -> q1 intersect
     * 
     * If they intersect, the baricentric parameter of the intersection point S with respect
     * to both lines are calculated and returned:
     * p at  p0 -> p1,      S = (1-p) * P0  + p * P1  = P0 + p * (P1 - P0)
     * q at  q0 -> q1       S = (1-p) * Q0  + p * Q1  = Q0 + q * (Q1 - Q0)
     * 
     * if p in [0, 1] and q in [0, 1] the finite lines really intersect, otherwise only on the
     * underlying infinite straight
     * 
     * returns null, if the two lines are parallel.
     * Does not distinguish between whether the two lines are collinear or parallel but not collinear
     * 
     * TODO: method to return line alignments: parallel, antiparallel, collinear, neither 
     * and in case of collinear baricentric coordinates of start and end point with respect to each other
     * @param {*} p0 
     * @param {*} p1 
     * @param {*} q0 
     * @param {*} q1 
     * @returns [p, q] or [undefined, undefined], if parallel
     */
    static linesIntersect(p0, p1, q0, q1) {

        // 2D cross product
        const vx = p1.x - p0.x;
        const vy = p1.y - p0.y;
        const wx = q1.x - q0.x;
        const wy = q1.y - q0.y;
        const cross = vx * wy - vy * wx;

        if (Math.abs(cross) < Number.EPSILON)
            return [undefined, undefined, false];

        let ux = q0.x - p0.x;
        let uy = q0.y - p0.y;
        const p = (ux * wy - uy * wx) / cross;
        const q = (ux * vy - uy * vx) / cross;

        const intersects = 0 <= p && p <= 1 && 0 <= q && q <= 1;

        return { p: p, q: q, intersects: intersects };
    }

    /**
     * return the intersection of two 2D lines p0 -> p1 and q0 -> q1 intersect
     * @param {*} p0 
     * @param {*} p1 
     * @param {*} q0 
     * @param {*} q1 
     * @returns [] if no intersection, { x, y, p, q } coordinates of the intersection point and barycentric coordinate with respect to both
     */
    static linesIntersection(p0, p1, q0, q1) {
        const {p, q, intersects} = linesIntersect(p0, p1, q0, q1);
        if (!intersects)
            return [];

        return [{ x: p0.x + p * (p1.x -p0), y: p0.y + p * (p1.y - p0.y), p: p, q: q }]
    }
}