/**
 * high level events for use with 'CadAction'
 * @author Mi
 */
class Event {
}

/**
 * The intent of the break event is to actively finish the action or a sub
 * state of it, usually by typing the ESC key or pressing the right mouse
 * button.
 */
class BreakEvent extends Event {
}

/**
 *
 */
class CommandEvent extends Event {
  constructor (commandLine, ...args) {
    super();
    this.commandLine = commandLine;
    this.args = args;
  }
}

class ValueEvent extends Event {
  constructor (attribute, value) {
    super();
    this.attribute = attribute;
    this.value = value;
  }
}

class DynamicEvent extends Event {
  constructor (raw, view, x, y, z) {
    super();
    this.raw = raw;
    this.view = view;
    this.p = [x, y, z];
  }
}

class PointEvent extends Event {
  constructor (raw, view, x, y, z) {
    super();
    this.raw = raw;
    this.view = view;
    this.p = [x, y, z];
  }
}

class PointUpEvent extends Event {
  constructor (raw, view, x, y, z) {
    super();
    this.raw = raw;
    this.view = view;
    this.p = [x, y, z];
  }
}

/**
 * provides a list of picked or selected elements determined by
 * a selection filter
 *
 * In well designed actions, the same implementation can deal with selections
 * independently of the sequence of selecting elements and doing something
 * with them:
 *
 * action-selection: After starting the action the user is requested
 * to select elements. After confirming the selection the work is done.
 * As the case may be, the process of selecting and working with them
 * is repeated.
 *
 * selection-action: When the action starts, there are already elements
 * preselected in a global selection list. In this case the selection
 * event is immediately sent to the action.
 * 
 * formation is an object, which describes how it comes to the selection
 * (look at PickFilters to find out, which formation types might appear)
 */
class SelectionEvent extends Event {
  constructor (objects, formation) {
    super();
    this.objects = objects;
    this.formation = formation;
  }
}

/**
 * wraps the raw MouseEvents, which have not yet been turned into another
 * type of Event by the default filter
 */
class RawMouseEvent extends Event {
  constructor (raw) {
    super();
    this.raw = raw;
  }
}

/**
 * wraps the raw KeyEvents, which have not yet been turned into another
 * type of Event by the default filter
 */
class RawKeyEvent extends Event {
  constructor (raw) {
    super();
    this.raw = raw;
  }
}

/**
 * wraps the raw TouchEvents, which have not yet been turner into another 
 * type of Event by the default filter
 */
class RawTouchEvent extends Event {
  constructor (raw) {
    super();
    this.raw = raw;
  }
}

export { BreakEvent, CommandEvent, DynamicEvent, PointEvent, PointUpEvent, ValueEvent, SelectionEvent, RawKeyEvent, RawMouseEvent, RawTouchEvent };
