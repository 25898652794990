<template>
    <div class="dlg-floorplan">
        <div class="d-flex flex-column" style=" height: 100%; width: 100%; overflow: auto;">
            <div class="ve-row">
                <div class="col-12">
                    <div class="sticky-top ve-panel-header w-100">
                        <span>Grundrissplaner</span>
                    </div>
                    <div  class="ve-row ms-0 floorplan h-100">
                        <button class=" mb-0 buttonfloor border-bottom border-2" @click="toggleCollapse('Wall')">
                            <div>
                                Wände
                            </div>
                            <div :class=" { openarrow: showWall }">
                                <svg width="22" height="12" viewBox="0 0 22 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9.38318 10.4026L0.414307 1.43374L1.82852 0.0195312L10.8049 8.99588L20 0L21.3986 1.42962L10.7739 11.8241L9.38318 10.4026Z"
                                        fill="black" />
                                </svg>
                            </div>
                        </button>
                        <div v-if="showWall">
                            <PanelWall />
                        </div>
                        <button class="buttonfloor mb-0 border-bottom border-2" @click="toggleCollapse('Door')">
                            <div>
                                Türen
                            </div>
                            <div :class="{ openarrow: showDoor }">
                                <svg width="22" height="12" viewBox="0 0 22 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9.38318 10.4026L0.414307 1.43374L1.82852 0.0195312L10.8049 8.99588L20 0L21.3986 1.42962L10.7739 11.8241L9.38318 10.4026Z"
                                        fill="black" />
                                </svg>
                            </div>
                        </button>
                        <div v-if="showDoor">
                            <PanelDoor />
                        </div>
                        <button class="buttonfloor mb-0 border-bottom border-2" @click="toggleCollapse('Window')">
                            <div>
                                Fenster
                            </div>
                            <div :class="{ openarrow: showWindow }">
                                <svg width="22" height="12" viewBox="0 0 22 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9.38318 10.4026L0.414307 1.43374L1.82852 0.0195312L10.8049 8.99588L20 0L21.3986 1.42962L10.7739 11.8241L9.38318 10.4026Z"
                                        fill="black" />
                                </svg>
                            </div>
                        </button>
                        <div v-if="showWindow">
                            <PanelWindow />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application'
import PanelWall from '@/vuejs/components/PanelWall.vue'
import PanelDoor from '@/vuejs/components/PanelDoor.vue'
import PanelWindow from '@/vuejs/components/PanelWindow.vue'

export default {
    name: 'DlgFloorplan',
    components: {
        PanelWall,
        PanelDoor,
        PanelWindow
    },
    data() {
        return {
            showWall: true,
            showDoor: false,
            showWindow: false
        }
    },
    mounted() {
        theApp.addDialog('DlgFloorplan', this)
    },
    unmounted() {
        theApp.removeDialog('DlgFloorplan')
    },
    methods: {
        toggleCollapse(panelType) {
            this[`show${panelType}`] = !this[`show${panelType}`];
        },
        close(panelType) {
            this[`show${panelType}`] = false
        },
        open(panelType) {
            this[`show${panelType}`] = true
        }
    }
}
</script>

<style scoped>.dlg-floorplan {
    height: 100%;
    width: 100%;
}

@media (max-height: 700px) {
    .floorplan{
        height: 105%;
    }
}

.openarrow {
    transform: rotate(-0.50turn);
}</style>