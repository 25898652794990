<template>
    <div class="dlg-inventory">
        <div class="container-fluid">
            <div class="ve-row justify-content-center mb-3">
                <DlgInventory/>
            </div>
        </div>
    </div>
</template>

<script>
import DlgInventory from '@/vuejs/components/DlgInventory.vue';
export default {
  name: 'Inventory',
  components: {
    DlgInventory
  },
}
</script>

<style scoped>
.dlg-inventory {
    background: white;
    height: 100%;
}
</style>