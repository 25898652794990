import theApp from '@/frame/Application';

import OpFactory from '@/visual-events/model/OpFactory';
import Link2DTo3D from '@/visual-events/model/Link2DTo3D';

/**
 * utility class to handle the coupling of 2D and 3D objects
 * 
 * The coupling is modeled by a Link2Dto3D object, which observes the movements of the
 * 2D and 3D objects and lets the counterpart move accordingly. The height in 3D is determined
 * by either
 * - a @Event3DHeight attribute attached to the 2D symbol
 * - the area on which the 3D object is placed, also makred with an @Event3DHeight attribute
 * - the default height 0, if not on such an area
 * 
 * 'hint' is used to distinguish between different contexts, in which the placing is made.
 * currently used:
 * "Area"    
 * "Height"  
 *  
 * The coupling is made persistent by json attributes @Event3DPlacing attached to
 * the 2D and the 3D counterparts, which contain the same id.
 * 
 * @Event3DPlacing is kept permanently, while the link exists. Thus there is no need to
 * prepare the info before storing.
 */
export default class Event3DPlacing {

    /**
     * link op2D and op3D with a Link2Dto3D object
     * @param {*} op2D 
     * @param {*} op3D 
     * @param {*} hint 
     */
    /*public*/ static link2DTo3D(op2D, op3D, hint = '') {
        const link = new Link2DTo3D(op2D, op3D, hint);
        op2D.subscribe(link);
        op3D.subscribe(link);
        this.setAttributes(op2D, op3D, hint);
    }

    /**
     * find a Link2DTo3D attached to op (either 2D or 3D)
     * @param {*} op 
     * @returns 
     */
    /*public*/ static findLink2DTo3D (op) {
        return op.observers.find(o => o instanceof Link2DTo3D);
    }

    /**
     * remove a Link2DTo3D attached to op and its counterpart (either 2D or 3D)
     * @param {*} op 
     */
    /*public*/ static unlink2DFrom3D (op) {
        const link = this.findLink2DTo3D(op);
        link.op2D.unsubscribe(link);
        link.op3D.unsubscribe(link);
        this.removeAttributes(link.op2D, link.op3D);
    }

    /**
     * after load for all Event3DPlacing references below root2D build up the
     * Link2DTo3D connections
     * Always looking for @Event3DPlacing in findLink2DTo3D is too expensive.     
     * @param {*} root2D 
     */
    /*public*/ static buildLinks2DTo3D (root2D) {
        for (const op2D of root2D.children) {
            const op3D = this.findPlacingCounterpart(op2D);
            if (op3D)
                this.link2DTo3D(op2D, op3D);
            this.buildLinks2DTo3D (op2D);
        }
    }

    // privat

    /**
     * attach the @Event3DPlacing attributes to op2D and op3D in order to make the
     * linking information persistent
     * 
     * remark: On the first glimpse it seems to be more straight forward to reuse the 
     * already existing uuid in op2 or op3, if available. Actually it is simplier and
     * more robust to create a new uuid. E.g. when copying it avoids the trap to 
     * unintendedly to spoil the uniquiness of the uuid in the model.  
     * @param {*} op2D 
     * @param {*} op3D 
     * @param {*} hint 
     */
    /*private*/ static setAttributes(op2D, op3D, hint = '') {
        const uuid = OpFactory.createUUID();
        const info = {
            version: 2,
            id: uuid,
            hint: hint
        }
        op2D.setAttribute('@Event3DPlacing', info);
        op3D.setAttribute('@Event3DPlacing', info);
    }

    /**
     * remove the persistent linking information between op2D and op3D
     * @param {*} op2D 
     * @param {*} op3D 
     */
    /*private*/ static removeAttributes(op2D, op3D) {
        op2D.removeAttribute('@Event3DPlacing');
        op3D.removeAttribute('@Event3DPlacing');
    }

    /**
     * find the 3D object which is coupled to the 2D object
     * 
     * @param {*} op 
     */

    /*private*/ static findPlacingCounterpart(op2D) {
        const placingId = op2D.getAttribute('@Event3DPlacing.id');
        if (placingId) {
            const op3D = Event3DPlacing.findByPlacingId(theApp.model.space.children, placingId);
            return op3D;
        }
        return null;
    }

    /**
     * TODO: mit OpObject.traverse (oder find? oder forEach..) realisieren
     * @param {OpObject[]} children Model to search
     * @param {number} id Placing Id to search
     * @returns {(OpObject|null)} Returns the found OpObject or null
     */
    /*private*/ static findByPlacingId(children, id) {
        let result = null;

        let iterateModel = (children, id) => {
            children.some((child) => {
                let placingId = child.getAttribute('@Event3DPlacing.id');
                if (placingId && placingId == id) {
                    result = child;
                    return true;
                }
                iterateModel(child.children, id);
            });
        }

        iterateModel(children, id);
        return result;
    }


    /**
     * find the 3D objects which is coupled to the 2D objects
     * 
     * @param {*} op 
     */

    static collectLinkedIn3D(ops2D) {
        const ops3D = [];
        for (const op2D of ops2D) {
          const link = Event3DPlacing.findLink2DTo3D(op2D);
          if(link)
            ops3D.push(link.op3D);
        }

        return ops3D;
    }
}