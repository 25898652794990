import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FirstPersonControlsAdv } from './FirstPersonControls';

import { Box3, Vector3, WebGLRenderer } from 'three';

import CameraUtils from '@/visual-events/model/CameraUtils';
import GrfOpSpace from '@/visual-events/view/GrfOpSpace';
import Lighting from '@/visual-events/view/Lighting';
import View from '@/frame/View';
import { OP_TO_THREE } from '@/visual-events/model/OpCoordinates.js';
import Logger from '@/frame/Logger';

const logger = new Logger('VisualEvents3DView');

export default class VisualEvents3DView extends View {
  constructor (name, model) {
    super(name, model);
   
    this.renderer = null;
    this.scene = null;

    this.root = null;

    // the exact knowledge about how to build and update the scene is extracted to GrfXXX objects
    this.grf = new GrfOpSpace();

    this.last = 0; // last timestamp in render

    this.toneMapping = {
      "NoToneMapping": THREE.NoToneMapping,
      "LinearToneMapping": THREE.LinearToneMapping,
      "CineonToneMapping": THREE.CineonToneMapping,
      "ReinhardToneMapping": THREE.ReinhardToneMapping,
      "ACESFilmicToneMapping": THREE.ACESFilmicToneMapping,
    };

    this.mustRender = false;
  }
  
  init () {
    logger.log('init');
    if (!this.canvas) { return false; }

    this.camera = new THREE.PerspectiveCamera(45, 1, 1, 100000);

    this.scene = new THREE.Scene();

    this.renderer = new WebGLRenderer({
        preserveDrawingBuffer: false,
        antialias: true,
        logarithmicDepthBuffer: true,
    });
    this.renderer.setClearColor(0xdddddd);
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    this.renderer.useLegacyLights = false;

    this.setFirstPersonControls();
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.addEventListener( 'change', () => this.mustRender = true );
    // this.controls.update();
    this.mustRender = true;

    //this.addDiagnostics();

    return true;
  }

  getRoot () {
    return this.root;
  }

  setRoot (op) {
    this.root = op;
  }

  /**
   * calculate the bounding box of the scene graph
   * see OpCoordinates.js: here are three.js coordinates!
   * 
   * TODO: get rid of this, only used in Lighting for shadows
   * @returns 
   */
  getBoundingBox() {
    let boundingBox = new THREE.Box3();

    this.scene.traverse((child) => {
      if (child.type === 'Mesh') {
        let box = new THREE.Box3().setFromObject(child);
        boundingBox.union(box);
      }
    });

    return boundingBox;
  }


  dispose () {
    this.disposeScene();

    this.initialized = false;
  }

  disposeScene() {
    this.renderer.dispose();
    while (this.canvas.firstChild)
      this.canvas.removeChild(this.canvas.firstChild)    

    this.controls.dispose();

    const cleanMaterial = material => {
      material.dispose();

      for (const key of Object.keys(material)) {
        const value = material[key];
        if (value && typeof value == 'object' && 'minFilter' in value) {
          value.dispose();
        }
      }
    };

    this.scene.traverse(object => {
      if (!object.isMesh) return;

      object.geometry.dispose();

      if (object.material.isMaterial) {
        cleanMaterial(object.material);
      } else {
        for (const material of object.material) cleanMaterial(material);
      }
    });
  }

  clearLight () {
    const removable = [];
    this.scene.traverse(child => {
        if (child.type === 'DirectionalLight'
         || child.type === 'PointLight'
         || child.type === 'SpotLight'
         || child.type === 'AmbientLight') {
          removable.push(child);
        }
      });
  
      this.clearItems(removable);
  }

  clearScene () {
    const removable = [];
    this.scene.traverse(child => {
      if (child.type !== 'Scene' 
       && child.type !== 'DirectionalLight'
       && child.type !== 'PointLight'
       && child.type !== 'SpotLight'
       && child.type !== 'AmbientLight') {
        removable.push(child);
      }
    });

    this.clearItems(removable);
  }

  clearItems(removable) {
    removable.forEach(item => {
        if (item.geometry)
           item.geometry.dispose();
        if (item.material) {
          if (Array.isArray(item.material))
            item.material.foreach(m => m.dispose());
          else
            item.material.dispose();
        }
        this.scene.remove(item);
      });
  }

  updateScene (time) {
    //logger.log(`updateScene`, time);
    return this.grf.updateScene(this);
  }

    /**
     * set the initial camera position
     * 
     * Either get the predefined initial camera position in an OpPoint
     * or calculate the center of the 2D plan (not always reliable)
     */
    initCameraPosition() {
        if (CameraUtils.restoreInitialCameraPosition(this))
            return;

        const eye = CameraUtils.calculateCenteredEyePosition(this.model);
        const target = new THREE.Vector3(eye.x - 1, eye.y, eye.z);

        this.updateCamera(eye, target);
    }

    /**
     * TODO make an api to retrieve and set camera settings in any view
     *
     * set the camera and the orbit control to position 'eye' and look
     * at 'target'
     * @param {THREE.Vector3} eye Camera position
     * @param {THREE.Vector3} target Camera target
     */
    updateCamera (eye, target) {
        CameraUtils.logCamera(this, 'before updateCamera');

        eye.applyMatrix4(OP_TO_THREE);
        target.applyMatrix4(OP_TO_THREE);

        this.camera.position.copy(eye);

        if (this.controls instanceof FirstPersonControlsAdv)
          this.controls.lookAt(target);
        if (this.controls instanceof OrbitControls)
          this.controls.target.copy(target);
   
        this.camera.updateProjectionMatrix();

        CameraUtils.logCamera(this, 'after updateCamera');
    }

  render (time) {
    // logger.log('render' + elapsed);

    const delta = (time - this.last) / 1000;

    if (this.resized)
      this.controls.handleResize();

    // initialize or update the scene
    this.mustRender ||= this.updateScene(time);

    this.mustRender ||= this.resized;
    this.resized = false;

    this.controls.update(delta);

    this.last = time;

    // let the threejs renderer display the scene
    if (this.mustRender && this.renderer) {
      this.mustRender = false;
      this.renderer.render(this.scene, this.camera);
    }
  }
  
  // TODO: move Lighting into OpLight objects and handle it in the context of rendering
  addLight() {
    if (this.model.light)
        Lighting.addLight(this, this.model.light);
  }

  /**
   * @private
   * @preliminary
   * TODO: Api to configure threejs view with various types of camera and controls
   */
  setOrbitControls() {
    if (this.controls) {
      this.controls.dispose();
      this.controls = null;
    }
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);      
    this.controls.addEventListener( 'change', () => this.mustRender = true );

    this.initCameraPosition();
  }

  /**
   * @private
   * @preliminary
   */
  setFirstPersonControls() {
    if (this.controls) {
      this.controls.dispose();
      this.controls = null;
    }
    this.controls = new FirstPersonControlsAdv(this.camera, this.renderer.domElement);
    this.controls.activeLook = false;
    this.controls.lookVertical = false;
    this.controls.movementSpeed = 3000;
    this.controls.lookSpeed = 0.1;
    this.controls.sprintSpeed = this.controls.movementSpeed * 2;
    this.controls.sprintLookSpeed = this.controls.lookSpeed * 2;

    this.controls.addEventListener( 'change', () => this.mustRender = true );
    this.mustRender = true;
  }


}
