<template>
  <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" :id="this.$.uid" :checked="modelValue" @input="$emit('update:modelValue', $event.target.checked)">
      <label class="form-check-label" :for="this.$.uid" >{{label}}</label>
  </div>
</template>

<script>

export default {
  name: 'InputCheckBox',
  props: {
    label: String,
    modelValue: Boolean
  },
  emits: ['update:modelValue']
}

</script>

<style>
</style>