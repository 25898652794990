<template>
    <div class="panel-circle">
      <div class="col-12 fw-bold">Tisch</div>
        <div class="ve-row mb-1">
            <label class="col-sm-12 ps-3 mb-0 mt-2 col-form-label">Größen</label>
            <div class="col-sm-6">
              <select class="form-select col-sm-6" v-model="optionSize" @change="onChangeOptionSize($event)">
                    <option key="0" value=0>Freie Eingabe</option>
                    <option key="1" value=1000x500>100 x 50 cm</option>
                    <option key="2" value=2000x1000>200 x 100 cm</option>
                    <option key="3" value=3000x1500>300 x 150 cm</option>
                </select>
            </div>
        </div>
      <fieldset v-show="showInput" class="ve-row">
        <InputNumberWithUnit label="Breite" class="col-sm-6" unit="cm" :scale="10" :min="1" :step="1" :digits="1" v-model="widthX" @update:modelValue="onChangeWidthX" />
        <InputNumberWithUnit label="Tiefe" class="col-sm-6" unit="cm" :scale="10" :min="1" :step="1" :digits="1" v-model="widthY" @update:modelValue="onChangeWidthY" />
      </fieldset>
      <div class="mt-3 ms-1 mb-0">Farbe</div>
        <div class="mt-0 ms-1 mb-3">
        <InputButtonColor label="" v-model="tableColor"  @update:modelValue="onChangeTableColor" />
        <hr class="hr" />
      </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue';
import Settings from '@/visual-events/data/Settings';

export default {
  name: 'PanelTableRectangle',
  components: {
    InputNumberWithUnit,
    InputButtonColor,
  },
  data () {
    return {
      optionSize: this.findOptionSize(Settings.get('furniture.tableWidthX', 1000), Settings.get('furniture.tableWidthY', 500)),
      widthX: Settings.get('furniture.tableWidthX', 1000),
      widthY: Settings.get('furniture.tableWidthY', 500),
      tableColor: Settings.get('furniture.tableColor', '#FFFFFF'),
      showInput: this.optionSize === 0
    }
  },
  mounted () {
    theApp.addDialog('PanelTableRectangle', this)
  },
  unmounted () {
    theApp.removeDialog('PanelTableRectangle')
  },
  methods: {
    update(action) {
      this.widthY = action.height;
      this.widthX = action.width;
      this.tableColor = action.tableColor;
      this.optionSize = this.findOptionSize(this.widthX, this.widthY)
      this.showInput = this.optionSize === 0
    },
    onChangeOptionSize(event) {        
        if (parseInt(event.target.value) !== 0) {
            const token = event.target.value.split('x') 
            this.widthX = parseInt(token[0])
            this.widthY = parseInt(token[1])
            this.onChangeWidthX()
            this.onChangeWidthY()
            this.showInput = false
        } else {
            this.showInput = true
        }
    },
    onChangeWidthX() {
        theApp.executeValueEvent("tableWidth", this.widthX)
        Settings.set('furniture.tableWidthX', this.widthX)
    },
    onChangeWidthY() {
        theApp.executeValueEvent("tableHeight", this.widthY)
        Settings.set('furniture.tableWidthY', this.widthY)
    },
    onChangeTableColor () {
        theApp.executeValueEvent("tableColor", this.tableColor)
        Settings.set('furniture.tableColor', this.tableColor)
    },
    findOptionSize (widthX, widthY) {
        switch (widthX) {
            case 1000:
                return widthY === 500 ? '1000x500' : 0
            case 2000:
                return widthY === 1000 ? '2000x1000' : 0
            case 3000:
                return widthY === 1500 ? '3000x1500' : 0
            default: 
                return 0
        }
    }
  }
}
</script>

<style scoped>
</style>
