import OpObject from '@/visual-events/model/OpObject'
import { Vector3 } from 'three';

/**
 * OpPoint defines a point of interest in the model
 * 
 * This may serve diverse purposes, such as initial camera positions,
 * possibly together with the OrbitControls state, user defined camera 
 * positions, etc.
 * 
 * It is recommended to store the purpose and any additional options
 * in a programmatic attribute, such as $initialCameraPosition.
 * 
 * for more details about the initial camera position, see CameraUtils.js
 */
export default class OpPoint extends OpObject {

    constructor (name) {
        super ('OpPoint', name);
    }

    copy () {

        const copy = new OpPoint(this.type, this.name);

        copy.visible = this.visible;
        copy.pickable = this.pickable;
        copy.setTransform(this.transform);

        // copy attributes, too, because they most likely define
        // the purpose and required options 
        copy.attributes = {...this.attributes};

        return copy;
    }
}