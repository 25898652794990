export const GridMode = Object.freeze({
    SINGLE: 'SINGLE',
    RECT: 'RECT',
    SLANTED: 'SLANTED',
    ARENA: 'ARENA',
    ROUND: 'ROUND',
    CIRCLE: 'CIRCLE',
    HALF_CIRCLE: 'HALF_CIRCLE'
});

export const AlternateMode = Object.freeze({
    NONE:       0,
    RIGHT:      1,
    LEFT:       2,
    SHIFT_TRIANGLE:   3
})

export default class GridProvider {
    
    constructor () {
        this.mode = GridMode.SINGLE;
        this.cntX = 1;
        this.cntY = 1;
        this.widthX = 500.0;
        this.widthY = 500.0;
        this.distX = 0.0;
        this.distY = 500.0;
        this.angleX = 0.0;
        this.angleY = 90.0;
        this.alternateMode = AlternateMode.NONE;

        this.origin = { x: 0.0, y: 0.0 };
        this.vecX = { x: 1.0, y: 0.0 };
        this.vecY = { x: 0.0, y: 1.0 };

        this.shift = 0.5;
        this.ratioXY = Math.sin(Math.PI / 3); // 60°
        this.keepRatioXY = false;
    }

    setAngleX(a) {
        this.angleX = a;
        this.vecX.x = Math.cos(a*Math.PI/180.0);
        this.vecX.y = Math.sin(a*Math.PI/180.0);
    }

    setAngleY(a) {
        this.angleY = a;
        this.vecY.x = Math.cos(a*Math.PI/180.0);
        this.vecY.y = Math.sin(a*Math.PI/180.0);
    }

    correct(i, j) {
        switch (this.alternateMode) {
        case AlternateMode.NONE:
            return 0.0; //nothing to do
        
        case AlternateMode.RIGHT:
        case AlternateMode.SHIFT_TRIANGLE:
            return (j % 2 == 0) ? 0.0 : this.shift; 

        case AlternateMode.LEFT:
            return (j % 2 == 0) ? 0.0 : - this.shift; 
        }
    }
    
    //TODO: bei Aufstellung mit unterschiedliche Anzahlen pro Reihe getCntX(row)
    //TODO: GridMode.SINGLE raus, gehört nach außen in die Actions
    getCntX() {
        return this.mode == GridMode.SINGLE ? 1 : this.cntX;
    }

    getCntY() {
        return this.mode == GridMode.SINGLE ? 1 : this.cntY;
    }

    getOffsetX() {
        return this.distX + this.widthX;
    }

    getOffsetY() {
        return this.keepRatioXY ? this.ratioXY * this.getOffsetX() : this.distY + this.widthY;
    }

    /**
     * calculate the overall length of a block
     * if the second row is shifted left or right the length increases by shift * offsetX
     * @returns length of a block in row direction
     */
    getLengthX() {
        return (this.getCntX() - 1  + ((this.getCntX() > 1 && this.alternateMode !== AlternateMode.NONE) ? this.shift : 0)) * this.getOffsetX() + this.widthX;
    }

    getLengthY() {
        return (this.getCntY() - 1) * this.getOffsetY() + this.widthY;
    }

    getCoord(i, j) {
        const u = (i + this.correct(i, j)) * this.getOffsetX();
        const v = j * this.getOffsetY();
        return this.F(u,v);
    }

    getVectorX(i, j) {
        const u = (i + this.correct(i, j)) * this.getOffsetX();
        const v = j * this.getOffsetY();
        return this.dFdu(u,v);
    }

    getVectorY(i, j) {
        const u = (i + this.correct(i, j)) * this.getOffsetX();
        const v = j * this.getOffsetY();
        return this.dFdv(u,v);
    }

    F(u, v) {
        return [this.origin.x + u * this.vecX.x + v *this.vecY.x, this.origin.y + u * this.vecX.y + v *this.vecY.y];
    }

    dFdu(u,v) {
       return [this.vecX.x, this.vecX.y]; 
    }

    dFdv(u,v) {
        return [this.vecY.x, this.vecY.y];
     }
 }