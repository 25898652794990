import * as THREE from 'three';
import { nextTick } from 'vue'

import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import * as Event from '@/frame/Event.js';
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectAndDispatch from '@/visual-events/actions/FltSelectAndDispatch';
import VariantChairRectangle from '@/visual-events/actions/VariantChairRectangle';
import Inventory from '@/visual-events/inventory/Inventory'
import OpFactory from '@/visual-events/model/OpFactory';
import Settings from '@/visual-events/data/Settings';
import Logger from '@/frame/Logger';

const logger = new Logger('ActChairRectangle');

const State = Object.freeze({
    CREATE: 0,
    EDIT: 2
  });

  export default class ActChairRectangle extends Action {
    constructor(args) {
      super();

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.view3D = theApp.findViewByName('3D Ansicht');
      this.root3D = this.view3D?.getRoot();

      this.color = Settings.get('furniture.chairColor', '#BBBBFF');
      this.height = Settings.get('furniture.chairWidthY', 500);
      this.width = Settings.get('furniture.chairWidthX', 500);

      this.position = new THREE.Vector3(0, 0, 0);
      
      this.objects = [];
      if (args.length > 1)
        this.objects = args[1];

        this.t = new THREE.Matrix4(); // for reuse inoder to avoid frequent allocation

      this.state = this.objects.length > 0 ? State.EDIT : State.CREATE;
    }

    actionStart () {
        logger.log(`actionStart`);
        if (this.state === State.EDIT)
            this.addFilter(new FltSelectAndDispatch());
        else
            this.addFilter(new FltPointDef());
        this.evaluateSelection();
        this.connectToGUI();
        this.op2D = this.createRectangle();
        this.objects.push(this.op2D);
        this.create3DChair();

        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        switch(this.state){
            case State.CREATE: {
                if (this.op2D)
                    this.op2D.removeFromParent();
                if (this.op3D)
                    this.op3D.removeFormParent();

                theApp.model.changed2d = true;
            }
        }
        this.disconnectFromGUI();
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);

        this.position.x = event.p[0];
        this.position.y = event.p[1];

        switch(this.state){
            case State.CREATE: {
                const opReference = this.objects[0];
                this.t.makeTranslation(this.position.x, this.position.y, 0);
                opReference.setTransform(this.t);

                // the created object is permanently in the drawing, reset op2D and op3D in order
                // to avoid, that actionDestroy removes ist
                this.op2D = undefined;
                this.op3D = undefined;
                theApp.model.changed2d = true;
               
                // immediately go to the block dialog
                // remark: send .Ticketing.editBlock in actionPointUp and not in actionPoint, 
                // otherwise the PointUp event will be sent to FltPlaceBlock and start drag&drop
                return new Event.CommandEvent('.Ticketing.editBlock', [opReference]);

                // other behavior: proceed with editing
                // this.disconnectFromGUI();
                // this.state = State.EDIT;
                // this.connectToGUI();
                
                // // allow for selecting things in order to start actions
                // this.addFilter(new FltSelectAndDispatch());

                // theApp.model.changed2d = true;

                // break;
            }
        }
    }

    actionDynamic (event) {
        logger.log(`actionDynamic`);

        this.position.x = event.p[0];
        this.position.y = event.p[1];
        switch(this.state){
            case State.CREATE: {
                const opReference = this.objects[0];
                this.t.makeTranslation(this.position.x, this.position.y, 0);
                opReference.setTransform(this.t);
                theApp.model.changed2d = true;

                break;
            }
        }
    }



    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'color') {
            this.color = event.value;
            this.editChairRectangle();
            done = true;
        }

        if (event.attribute === 'width') {
            this.width = event.value;
            this.editChairRectangle()
            done = true;
        }

        if (event.attribute === 'height') {
            this.height = event.value;
            this.editChairRectangle()
            done = true;
        }

        return done ? null : event;
    }

    createRectangle()
    {
        const variant = new VariantChairRectangle(this.width, this.height, this.color);
        const opReference = variant.create();

        const translation = this.t;
        translation.makeTranslation(this.position.x, this.position.y, 0);
        //transform the axis point
        opReference.setTransform(translation);

        this.root2D.add(opReference);

        return opReference;
    }

    editChairRectangle () {
        const variant = new VariantChairRectangle(this.width, this.height, this.color);
        for (const opReference of this.objects) {
            variant.edit(opReference);
            theApp.model.changed2d = true;
        }
    }

    /**
     * preliminary solution: In case of a 3D view link the most recent used 3D chair
     * TODO: make an infrastructure to manage the mapping of 2D to 3D inventory
     */
    create3DChair () {
        if (this.root3D && Inventory.chair) {
            this.op3D = OpFactory.createReference(Inventory.chair.name, Inventory.chair.symbolId);
            const hint = '';
            Event3DPlacing.link2DTo3D(this.op2D, this.op3D, hint);
            this.op2D.setTransform(this.op2D.transform); // notify
            this.root3D.add(this.op3D);
            theApp.model.changed3d = true;
        }
    }

    evaluateSelection () {
        //TODO: wenn unterschiedliche Styles, dann was???
        for (const opReference of this.objects) {

            const symbolId  = opReference.symbolId;
            const symbol = theApp.model.symbols.get(symbolId);
            const json = symbol.getAttribute('$variant');
            
            this.color = json.opts.color;
            this.width = json.opts.width;
            this.height = json.opts.height;
        }
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(this.state === State.EDIT ? 'Select' : 'Furniture');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PanelFurniture');
        nextTick(() => {
            const panelFurniture = theApp.findDialogByName('PanelFurniture');
            panelFurniture.setTab('chairVariant');
            nextTick(() => {
                const panelChair = theApp.findDialogByName('PanelChair');
                panelChair.setShape('SHAPE_RECTANGLE');
                nextTick(() => {
                    const panelRectangle = theApp.findDialogByName('PanelChairRectangle');
                    panelRectangle?.update(this);
                })
            })
        })
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}