import Model from '@/frame/Model';
import OpSymbols from './OpSymbols';
import OpFactory from './OpFactory';
import SelectionList from './SelectionList';
import OpObject from './OpObject';

// TODO: Modellkonzept durchdenken: mehrere OpModel zulassen, die nur aus drawing(s) und space bestehen, changed Mechanismus dazu
// symbols sind übergreifend
// aber dann ist OpModel nicht direkt von Model abgeleitet, oder doch? Beachte Bezug zu Application, View
export default class OpModel extends Model {
    constructor () {
        super();

        this.changed2d = false;
        this.changedFavorites = false;
        this.changed3d = false;
        this.changedLight = false;

        this.modifiedIn2D = [];
        this.modifiedIn3D = [];
        this.modifiedInFavorites = [];

        this.symbols = new OpSymbols();

        // the plan
        this.drawing = [ OpFactory.createDrawing('Zeichnung', 210, 297, '0.000000 0.000000 297.000000 210.000000')];
        this.space = OpFactory.createSpace('3D Space');
        this.light = {};
        this.wasEmpty = null;

        // the favorites
        this.favorites2D = [];
        this.favorites3D = [];

        this.selectionList = new SelectionList();
    }

    //
    // preliminary: keep track of grafic update requirements
    // TODO: refactoring for grafic update requirements
    //
    setModifiedIn2D(...objects) {
        for (const op of objects) {
            this.modifiedIn2D.push(op);
            this.setModifiedIn2D(...op.children);
        }
    }

    needsRebuild(view) {
        if (view.name === '2D Ansicht')
            return this.changed2d;
        if (view.name === '3D Ansicht')
            return this.changed3d;
        if (view.name === 'Favorites')
            return this.changedFavorites;
    }

    getModifiedObjects(view) {
        if (view.name === '2D Ansicht')
            return this.modifiedIn2D;
        if (view.name === '3D Ansicht')
            return this.modifiedIn3D;
        if (view.name === 'Favorites')
            return this.modifiedInFavorites;
    }

    resetChanged(view) {
        if (view.name === '2D Ansicht') {
            this.changed2d = false;
            this.modifiedIn2D = [];
        }
        if (view.name === '3D Ansicht') {
            this.changed3d = false;
            this.modifiedIn3D = [];
        }
        if (view.name === 'Favorites') {
            this.changedFavorites = false;
            this.modifiedInFavorites = [];
        }
    }

    /**
     * reuse and cleanup symbols
     * 
     * While an action is creating and editing OpReferences and OpSymbols, these should be 
     * reused as much as possible, e.g.
     * 
     * if the user changes the diameter of a circle symbol
     * - first lookup, whether there is already a symbol of that kind available
     * - if yes, redirect the reference to this
     * - otherwise create a new OpSymbol
     * 
     * Thus, the action produces intermediate symbols, which finally are not required.
     * To get rid of such symbols
     * - start the action with
     *      theApp.model.markExistingSymbols();
     * - on destrop cleanup with
     *      theApp.model.removeUnusedSymbols
     */
    markExistingSymbols () {
        this.symbols.markExistingSymbols();
    }

    removeUnusedSymbols () {
        for (const root of this.drawing)
            this.symbols.markReferencedSymbols (root);
        if (this.space)
            this.symbols.markReferencedSymbols (this.space);
        this.symbols.removeUnmarkedSymbols ();
    }

    getReferencedSymbols () {
        this.symbols.unmarkAllSymbols();
        for (const root of this.drawing)
            this.symbols.markReferencedSymbols (root);
        if (this.space)
            this.symbols.markReferencedSymbols (this.space);
        return this.symbols.getMarkedSymbols();
    }

    /**
     * find an OpSymbol in the pool with the same id and defining parameter
     * @param {*} variant 
     * @returns existing opSymbol or null
     */
    findVariant (variant) {
        return this.symbols.findVariant(variant);
    }

    emptyOnLoad () {
        if (this.wasEmpty !== null)
            return this.wasEmpty;
        
        let empty = true;

        for (const draft of this.drawing) {
            for (const subDraft of draft.children) {
                empty = empty && subDraft.children.length === 0;
            }
        }
    
        empty = empty && this.space.children.length === 0;

        this.wasEmpty = empty;

        return empty;
    }

    /**
     * 
     * @param {string} attribute JsonPath style attribute name
     * @param {OpObject[]} found Found objects
     * @returns 
     */
    drawingContains (attribute, found = []) {
        for (const draft of this.drawing) {
            draft.traverse((op) => {
                if (op.getAttribute(attribute))
                    found.push(op);
            });
        }

        return found.length > 0;
    }
}
  