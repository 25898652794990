import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import OpGroup from "../model/OpGroup";
import OpMesh from "../model/OpMesh";
import { OP_TO_THREE, THREE_TO_OP } from '../model/OpCoordinates';

export default class CADdyGLTFExporter {

    constructor() {}

    async write(op) {
        let gltf = {};

        if (op instanceof OpGroup) {
            for (const child of op.children) {
                const inner = await this.write(child);
                gltf = {...gltf, ...inner}; 
            }
        }

        if (op instanceof OpMesh) {
            gltf[op.name] = await this.writeGltf(op);
        }

        return gltf;
    }

    async writeGltf(op) {
        const mesh = op.mesh;
        const exporter = new GLTFExporter();
        const gltf = await exporter.parseAsync(mesh);
        return gltf;
    }
}