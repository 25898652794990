<template>
  <div class="form-group mt-2">
      <label :for="this.$.uid" class="form-label">{{label}}</label>
      <!-- title="" to remove the tooltip from HTML -->
      <input type="number" title="" :min="min" :max="max" :step="step" class="form-control" :id="this.$.uid" :value="displayedValue" @blur="focusOut" @focus="focusIn" @input="onInput" >
  </div>
</template>

<script>
import { clamp } from '@/frame/Useful'

export default {
  name: 'InputNumber',
  props: {
    label: String,
    modelValue: Number,
    min: Number,
    max: Number,
    step: Number,
    digits: {
        type: Number,
        default: 0
    }
  },
  data () {
    return {
      editing: false,
      typingTimer: undefined,
      interval: 500,
      value: 0
    }
  },
  computed: {
    displayedValue: {
        get() {
            const value = Number.parseFloat(this.modelValue)
            if (!Number.isNaN(value))
                return this.clamp(value.toFixed(this.digits))
        }
    },
  },
  emits: ['update:modelValue'],
  methods: {
    focusIn () {
        this.editing = true
    },
    focusOut () {
        this.$forceUpdate(); 
        this.editing = false
    },
    clamp (value) {
      const min = typeof this.min === 'undefined' ? Number.NEGATIVE_INFINITY : this.min
      const max = typeof this.max === 'undefined' ? Number.POSITIVE_INFINITY : this.max
      return clamp(Number(value), min, max)
    },
    onInput (event) {
        clearTimeout(this.typingTimer);
        // accept the value only if the input is valid
        const min = typeof this.min === 'undefined' ? Number.NEGATIVE_INFINITY : this.min
        const max = typeof this.max === 'undefined' ? Number.POSITIVE_INFINITY : this.max
        if (event.target.value !== '' && event.target.value >= min && event.target.value <= max)
        {
            this.value = this.clamp(Number.parseFloat(Number(event.target.value).toFixed(this.digits)))
            this.typingTimer = setTimeout(this.doneTyping, this.interval)
        }
    },
    doneTyping () {
        this.$forceUpdate(); 
        this.$emit('update:modelValue', this.value)
    }
  }
}

</script>

<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
</style>