import Logger from '@/frame/Logger';

const logger = new Logger('RestAPI');

export default class RestAPI {

    //TODO: the interface is yet well defined:
    // depending on, whether the Content-Type is application/json is returns the parsed object
    // otherwise the fetch Response. This is an unpredictable behavior for the caller.
    static async getResponse(endpoint, method, header) {
        logger.log(`getResponse(${endpoint} ${method} header)`)
        const response = await fetch(
            endpoint,
            {
                method: method,
                headers: header,
            }
        );
        if (!response.ok) {
            let error = '';
            try {
                error = await response.json();
            } catch {
                error = await response.text();
            }
            throw new Error(`HTTP error! status: ${response.status} ${response.statusText}  ${error}`);
        }

        const type = response.headers.get('Content-Type');
        if (type && type.includes('application/json')) {
            logger.log('application/json')
            try {
                return await response.json();
            } catch (err) {
                logger.log('error in response.json()')
                throw new Error(`JSON error! ${err.message}`);
            }

        }

        return response;
    }

    static async postResponse(endpoint, method, header, post_data = {}) {
        const response = await fetch(
            endpoint,
            {
                method: method,
                headers: header,
                body: JSON.stringify(post_data),
            }
        );
        if (!response.ok) {
            let error = '';
            try {
                error = await response.json();
            } catch {
                error = await response.text();
            }
            throw new Error(`HTTP error! status: ${response.status} ${response.statusText}  ${error}`);
        }
        const type = response.headers.get('Content-Type');
        if (type && type.includes('application/json'))
            return await response.json();

        return response;
    }
}