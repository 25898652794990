<template>
    <div class="panel-rectangle border-bottom p-3">
        <fieldset class="ve-row">
            <div Class="ve-FloorPlanSVG pe-auto  p-3 mb-5 bg-body-tertiary" style="width: 64px; height: 64px;">
                <svg @click="onPlaceWindow('ONE_CASEMENT')" width="72" height="72" viewBox="0 0 72 72" :fill=fill1
                    xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_231_317)">
                        <rect width="64" height="64" rx="5" transform="matrix(-1 0 0 1 68 0)" fill="white" />
                        <rect x="-0.5" y="0.5" width="63" height="63" rx="4.5" transform="matrix(-1 0 0 1 67 0)"
                            stroke="#D9D9D9" />
                    </g>
                    <rect x="-0.5" y="0.5" width="56.6" height="9.66667" :fill=fill1 transform="matrix(-1 0 0 1 63.7998 26.6666)"
                         :stroke=stroke1 />
                    <line x1="13.0332" y1="27.7333" x2="13.0332" y2="36.2666" :stroke=stroke1 />
                    <line x1="58.8999" y1="27.7333" x2="58.8999" y2="36.2666" :stroke=stroke1 />
                    <defs>
                        <filter id="filter0_d_231_317" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_231_317" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_231_317" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div Class="ve-FloorPlanSVG pe-auto p-3 mb-5 bg-body-tertiary rounded" style="width: 64px; height: 64px;">
                <svg @click="onPlaceWindow('TWO_CASEMENT')" width="72" height="72" viewBox="0 0 72 72" :fill=fill2
                    xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_231_318)">
                        <rect width="64" height="64" rx="5" transform="matrix(-1 0 0 1 68 0)" :fill=fill2 />
                        <rect x="-0.5" y="0.5" width="63" height="63" rx="4.5" transform="matrix(-1 0 0 1 67 0)"
                            stroke="#D9D9D9" />
                    </g>
                    <rect x="-0.5" y="0.5" width="56.5997" height="9.66667" :fill=fill2 transform="matrix(-1 0 0 1 63.8 26.6666)"
                        :stroke=stroke2 />
                    <line x1="10.9004" y1="27.7333" x2="10.9004" y2="36.2666" :stroke=stroke2 />
                    <line x1="61.0337" y1="27.7333" x2="61.0337" y2="36.2666" :stroke=stroke2 />
                    <line x1="34.3672" y1="27.7333" x2="34.3672" y2="36.2666" :stroke=stroke2 />
                    <line x1="37.5669" y1="27.7333" x2="37.5669" y2="36.2666" :stroke=stroke2 />
                    <defs>
                        <filter id="filter0_d_231_318" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_231_318" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_231_318" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div Class="ve-FloorPlanSVG pe-auto p-3 mb-5 bg-body-tertiary rounded" style="width: 64px; height: 64px;">
                <svg @click="onPlaceWindow('THREE_CASEMENT')" width="72" height="72" viewBox="0 0 72 72" :fill=fill3 xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_231_319)">
                    <rect width="64" height="64" rx="5" transform="matrix(-1 0 0 1 68 0)" :fill=fill3 />
                    <rect x="-0.5" y="0.5" width="63" height="63" rx="4.5" transform="matrix(-1 0 0 1 67 0)" stroke="#D9D9D9"/>
                </g>
                <rect x="9.5" y="27.5" width="53" height="9" :stroke=stroke3 />
                <line x1="30.5" y1="27" x2="30.5" y2="37" :stroke=stroke3 />
                <line x1="27.5" y1="27" x2="27.5" y2="37" :stroke=stroke3 />
                <line x1="11.9668" y1="27.7333" x2="11.9668" y2="36.2666" :stroke=stroke3 />
                <line x1="59.9668" y1="27.7333" x2="59.9668" y2="36.2666" :stroke=stroke3 />
                <line x1="41.5" y1="28" x2="41.5" y2="36.5333" :stroke=stroke3 />
                <line x1="44.6333" y1="28" x2="44.6333" y2="36.5333" :stroke=stroke3 />
                <defs>
                    <filter id="filter0_d_231_319" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_231_319"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_231_319" result="shape"/>
                    </filter>
                </defs>
                </svg>
            </div>
            <div Class="ve-FloorPlanSVG pe-auto p-3 mb-5 bg-body-tertiary rounded" style="width: 64px; height: 64px;">
                <svg @click="onPlaceWindow('FOUR_CASEMENT')" width="72" height="72" viewBox="0 0 72 72" :fill=fill4
                    xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_231_320)">
                        <rect width="64" height="64" rx="5" transform="matrix(-1 0 0 1 68 0)" :fill=fill4 />
                        <rect x="-0.5" y="0.5" width="63" height="63" rx="4.5" transform="matrix(-1 0 0 1 67 0)"
                            stroke="#D9D9D9" />
                    </g>
                    <rect x="9.5" y="27.5" width="53" height="9" :stroke=stroke4 />
                    <line x1="24.7002" y1="27.7333" x2="24.7002" y2="36.2666" :stroke=stroke4 />
                    <line x1="11.9668" y1="27.7333" x2="11.9668" y2="36.2666" :stroke=stroke4 />
                    <line x1="59.9668" y1="27.7333" x2="59.9668" y2="36.2666" :stroke=stroke4 />
                    <line x1="21.5669" y1="27.7333" x2="21.5669" y2="36.2666" :stroke=stroke4 />
                    <line x1="37.5" y1="27.7333" x2="37.5" y2="36.2666" :stroke=stroke4 />
                    <line x1="34.5" y1="27.7333" x2="34.5" y2="36.2666" :stroke=stroke4 />
                    <line x1="47.2334" y1="27.7333" x2="47.2334" y2="36.2666" :stroke=stroke4 />
                    <line x1="50.3667" y1="27.7333" x2="50.3667" y2="36.2666" :stroke=stroke4 />
                    <defs>
                        <filter id="filter0_d_231_320" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_231_320" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_231_320" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </fieldset>
        <fieldset class="ve-row">
            <InputNumberWithUnit title=" " class="col-6" default="100" label="Breite" unit="cm" :scale="10" :min=1 :digits="1"
                v-model="width" @update:modelValue="onChangeWidth" />
        </fieldset>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import {WindowType} from '@/visual-events/actions/VariantWindow';
import ActWindow from '@/visual-events/actions/ActWindow'

export default {
    name: "PanelWindow",
    components: {
        InputNumberWithUnit,
    },
    data() {
        const id = Settings.get('floorplan.window.id', WindowType.ONE_CASEMENT);
        return {
            id: id,
            width: Settings.getOption('floorplan.window.variants', id, 'action.width', 1180),
            casement: Settings.getOption('floorplan.window.variants', id, 'casement'),
            fill1:"white",
            fill2:"white",
            fill3:"white",
            fill4:"white",
            stroke1:"black",
            stroke2:"black",
            stroke3:"black",
            stroke4:"black"
        }
    },
    mounted() {
        theApp.addDialog('PanelWindow', this)
    },
    unmounted() {
        theApp.removeDialog('PanelWindow')
    },
    methods: {
        update(action) {
            this.width = action.width
            
        },
        onChangeWidth() {
            theApp.executeValueEvent("window.width", this.width)
            Settings.setValue('floorplan.window.variants', this.id, 'width', this.width)
        },
        onPlaceWindow(type) {
            this.id = WindowType[type];
            this.casement = Settings.getOption('floorplan.window.variants', this.id, 'casement');
            Settings.set('floorplan.window.id', this.id)
            theApp.executeValueEvent("window.casement", this.casement)
            theApp.executeCommand('.floorplan.placeWindow')
        },
        setActiveButton(casement) {
            switch (casement) {
                case 1:{
                    this.fill1 = "#f5f5f5"
                    this.fill2 = "white"
                    this.fill3 = "white"
                    this.fill4 = "white"
                    this.stroke1 = "#009aa3"
                    this.stroke2 = "black"
                    this.stroke3 = "black"
                    this.stroke4 = "black"
                    break;
                }
                case 2:{
                    this.fill2 = "#f5f5f5"
                    this.fill1 = "white"
                    this.fill3 = "white"
                    this.fill4 = "white"
                    this.stroke1 = "black"
                    this.stroke2 = "#009aa3"
                    this.stroke3 = "black"
                    this.stroke4 = "black"
                    break;
                }
                case 3:{
                    this.fill3 = "#f5f5f5"
                    this.fill1 = "white"
                    this.fill2 = "white"
                    this.fill4 = "white"
                    this.stroke1 = "black"
                    this.stroke2 = "black"
                    this.stroke3 = "#009aa3"
                    this.stroke4 = "black"
                    break;
                }
                case 4:{
                    this.fill4 = "#f5f5f5"
                    this.fill1 = "white"
                    this.fill2 = "white"
                    this.fill3 = "white"
                    this.stroke1 = "black"
                    this.stroke2 = "black"
                    this.stroke3 = "black"
                    this.stroke4 = "#009aa3"
                    break;
                }
            }
        },
        setDeactiveButton () {
            this.fill1 = "white"
            this.fill2 = "white"
            this.fill3 = "white"
            this.fill4 = "white"
            this.stroke1 = "black"
            this.stroke2 = "black"
            this.stroke3 = "black"
            this.stroke4 = "black"
        }
    }   
}
</script>

<style>
SVG {
        cursor: pointer;
    }
</style>