import * as THREE from 'three';
import theApp from '@/frame/Application';
import Geometry from '@/visual-events/model/Geometry.js';
import GrfUtils from '@/visual-events/view/GrfUtils';
import GrfStrategy from '@/visual-events/view/GrfStrategy';
import Lighting from '@/visual-events/view/Lighting';
import Logger from '@/frame/Logger';
import OpMesh from '@/visual-events/model/OpMesh'
import OpShapePath from '@/visual-events/model/OpShapePath'
import OpText from '@/visual-events/model/OpText'
import OpReference from '@/visual-events/model/OpReference'
import { OP_TO_THREE } from '@/visual-events/model/OpCoordinates.js';

const logger = new Logger('GrfOpSpace')

const toFixed2 = mat4 => mat4.elements.map(n => Number(n.toFixed(2)))

export default class GrfOpSpace {
  constructor () {
    logger.log('constructor');

    // for debug logs indentation
    this.indent = '';
    this.indentStack = [];

    // attach OpObject ids to the THREE objects' userData for picking
    // We need a stack for the OpReferences.
    this.idStack = [];

    // a strategy object handles the specific drawing depending on state, e.g.
    // highlighting, booking colors etc.
    this.grfStrategy = new GrfStrategy()

    // sheet scale needed for dimensions which are paper related, such as strokewidth
    this.sheet = null; // op
    this.scale = 1.0;

    // reuse helper objects in order to avoid massive amounts of allocations
    this.vector = new THREE.Vector3();
    this.m = new THREE.Matrix4();
    this.t = new THREE.Matrix4();

    this.font = GrfUtils.font;
  }

  setGrfStrategy(strategy) {
      this.grfStrategy = strategy;
  }

  getGrfStrategy() {
    return this.grfStrategy;
  }

  /**
   * update the scene graph in view if required
   * 
   * Whether it is required to rebuild or modify the scene is determined as follows
   * 
   * - model.changed3d:  
   *    set true will cause a complete reconstruction of the scene graph
   * 
   * - model.modifiedIn3D:
   *    is a list of OpObjects, the scene items of which need a modification
   *    mostly transform has changed
   * 
   * The modification list is only applied, if changed3d was false.
   * 
   * TODO: more specific modification hints, such as transform, style, geometry/mesh ... 
   * could be helpful for further optimization
   * @param {*} view 
   * @returns true, if something has been changed since last time, i.e. a render call is required
   */
  updateScene (view) {
    // logger.log('updateScene');

    const model = view.model;

    const opRoot = view.getRoot();
    if (!opRoot)
      return false;

    if (model.changedLight) {
        //HACK: light applies only on 3D, relying on name '3D Ansicht' is not sustainable, obsolete when OpLight objects are available
        if (view.name === '3D Ansicht') {
            view.clearLight();
            Lighting.addLight(view, theApp.model.light);
            model.changedLight = false;
        }
    }

    if (model.needsRebuild(view)) {
      
      view.clearScene();

      // one root item representing the different interpretation of threejs and CAD coordinate system
      // s. OpCoordinates
      const group = new THREE.Group();
      group.applyMatrix4(OP_TO_THREE);
      view.scene.add(group);

      this.buildScene(group, opRoot.children);
      model.resetChanged(view);

      return true;
    } else {
      
      const render = model.getModifiedObjects(view).length > 0;
      model.getModifiedObjects(view).forEach(op => this.updateItem(view, op));
      model.resetChanged(view);
      
      return render;
    }
  }

  //TODO: use instancing for OpReferences and possibly for identical OpMeshes
  buildScene(scene, children) {

      if (this.indent === '')
        logger.log(`buildScene`);
    
      const sheetLevel = false;

      children.forEach(op => {

        this.pushIndentation();
        this.logOpObject(this.indent, op);

        if (op.visible) {
            let obj3D;
            let opTree = op;
            let isAttributeText = false;
            if (op instanceof OpReference) 
            {
              opTree = theApp.model.symbols.get(op.symbolId);
              if (opTree)
                obj3D = new THREE.Group();
              else
                console.log(`missing symbol in OpReference ${op.id} ${op.name} ${op.symbolId}`)
            } 
            else if (op instanceof OpMesh)
            {
              if (!op.mesh)
                console.log(`missing mesh in OpMesh ${op.id} ${op.name}`)
              obj3D = op.mesh?.clone();
            }
            else if (op instanceof OpShapePath)
            {
              obj3D = GrfUtils.createShapePath(op, this.scale, this.grfStrategy);
            }
            else if (op instanceof OpText) 
            {
              //skip attribute texts at symbols    
              isAttributeText = op.isAttributeTextAtSymbol();
              if (!isAttributeText) {
                const origin = new THREE.Vector3();
                obj3D = GrfUtils.createLabel(op.text, origin, op.fontSize, op.style, op.textAnchor, op.baseLine);  
                if (!obj3D) 
                  console.log(`error: no grafic for ${op.type} ${op.id} ${op.name}`);
                logger.log(     `${JSON.stringify(obj3D.matrixWorld)}`)
              }
            }
            else // OpGroup
            {
              obj3D = new THREE.Group();
    
              if (!this.sheet) {
                this.scale = Geometry.getScaleX(this.calcTransform(op));
                this.sheet = op;
                this.sheetLevel = true;
              }
            }
    
            if (!obj3D) {
                if (!isAttributeText)
                    console.log(`error: no grafic for ${op.type} ${op.id} ${op.name}`);
              return;
            }
    
            obj3D.applyMatrix4(op.transform);
            obj3D.userData['opId'] = op.id;
            obj3D.userData['pickId'] = this.idStack.length > 0 ? this.idStack[0] : op.id;
            scene.add(obj3D);
    
            if (op instanceof OpReference) {
              this.idStack.push(op.id);
              this.grfStrategy.push(op);
            }
    
            this.buildScene(obj3D, opTree.children);
    
            if (op instanceof OpReference) {
              this.grfStrategy.pop();
              this.idStack.pop();
            }
    
            //HACK: the children of the reference - namely attribute texts of the symbol reference - are not 
            // underlying the transform of the OpReference.
            // Thus they must not be children of the current obj3D but of scene.
            // This is a bit confusing because different to all other OpObjects.
            // better structure possible?
            if (op instanceof OpReference)
              this.buildScene(scene, op.children);
        }

        this.popIndentation();
    });

    if (sheetLevel) {
      this.sheet = null;
      this.scale = 1.0;
    }

  }

  calcTransform(op) {

    
      let parent = op.parent;
      const stack = [ op ];
      while (parent && parent.parent) {
        stack.unshift(parent);
        parent = parent.parent;
      }

      this.t.identity();
      stack.forEach (op => {
        this.t.multiply(op.transform);
      })

      return this.t;
  }

  updateItem(view, op) {  

    logger.log(`updateItem(${op.id} ${op.type} ${op.symbolId})`);

    view.scene.traverse(obj3D => {
      if (obj3D.userData.opId === op.id) {
        logger.log(`${obj3D.id} ${obj3D.children.length}`);

        //obj3D.matrix.copy(op.transform) does not update all Object3D internal
        this.m.copy(obj3D.matrix);
        this.m.invert();
        this.m.premultiply(op.transform);
  
        obj3D.applyMatrix4(this.m);
        obj3D.visible = op.visible;
        
        this.updateStyle(obj3D, op, this.grfStrategy);
      }
    });
  }

  /**
   * 
   * @param {*} item 
   * @param {*} op 
   * @param {*} grfStrategy 
   * @returns 
   */
  updateStyle (item, op, grfStrategy) {

    if (!op.attributes)
      return;

    // HACK: beruht auf zu vielen impliziten Voraussetzungen:
    // - op ist ein Symbol
    // - die Fläche ist opSym.children[0].children[0]
    // Die Umrandungskontur kann so gar nicht berücksichtigt werden
    if (op instanceof OpReference) {
      const opSym = theApp.model.symbols.get(op.symbolId);
      if (opSym && opSym.children.length > 0 && opSym.children[0].children.length > 0) {
        const face = opSym.children[0].children[0];

        //s.o. HACK: threejs Struktur analog zu Op
        if (item.children.length > 0)   
          item = item.children[0];      //OpSym
        if (item.children.length > 0)   
          item = item.children[0];      //OpGroup
        if (item.children.length > 0)   
          item = item.children[0];      //OpShapePath

        if (face instanceof OpShapePath && item.isMesh) {
          grfStrategy.push(op);
          const fillColor = grfStrategy.fillColor(face)
          item.material.color = new THREE.Color(fillColor);
          const fillOpacity = grfStrategy.fillOpacity(face);
          item.material.opacity = fillOpacity;
          grfStrategy.pop();
        }
      }
    }

    if (op instanceof OpMesh) {
      if (item.isMesh) {
        item.geometry = op.mesh.geometry;
      } else {
        if (op.mesh.isMesh)
          item.children[0].geometry = op.mesh.geometry;
        else
          item.children[0].geometry = op.mesh.children[0].geometry;
      }
    }
  }

  // createSceneItemInstancedMesh() {
    // var GetSceneItemTransform = function (parent, id, list) {
    //   parent.forEach((child) => {

    //     if (child.refId === id)
    //         list.push(child.transform);

    //     GetSceneItemTransform(child.children, id, list);

    //   });
    // }

    // const mesh = view.model.meshes[t.symbolId];

    // if (!mesh.alreadyLoaded) {
    //   var list = [];
    //   GetSceneItemTransform(view.model.space, t.refId, list);

    //   var finalObject = new THREE.Object3D();

    //   mesh.traverse((child) => {
    //     if (child.isMesh) {
    //       var inst = this.createInstancedMesh(child, list);
    //       finalObject.add(inst);
    //     }
    //   });

    //   view.scene.add(finalObject);
    //   view.model.meshes[t.symbolId].alreadyLoaded = true;
    // }
  // }

  createInstancedMesh(child, list) {
    var count = list.length === 0 ? 1 : list.length;

    // var objectBoundingBox = new Box3().setFromObject(child);
    // scope.boundingBox = scope.boundingBox.union(objectBoundingBox);

    var bufferGeometry = child.geometry.clone();
    var material = null;

    var castShadow = true;

    if (Array.isArray(child.material)) {

        var matArray = [];

        child.material.forEach((mat) => {
            var cloneMat = mat.clone();
            matArray.push(cloneMat);

            if (cloneMat.transparent)
                castShadow = false;
        });

        material = matArray;

    } else {

        material = child.material.clone();
        
        if (material.transparent)
            castShadow = false;

    }

    var mesh = new THREE.InstancedMesh(bufferGeometry, material, count);
    
    if (mesh) {

        mesh.frustumCulled = false;

        if (castShadow)
            mesh.castShadow = true;
            mesh.receiveShadow = true;

        // Clone matrix from GLTF imported object
        var originalMatrix = child.matrix.clone();

        var p1Matrix = new THREE.Matrix4();
        var p1MatrixInverse = new THREE.Matrix4();

        // Set rotation matrix and inverse
        var kMatrix = new THREE.Matrix4();
        kMatrix.set(1.0, 0.0, 0.0, 0.0,
                    0.0, 0.0, 1.0, 0.0,
                    0.0,-1.0, 0.0, 0.0,
                    0.0, 0.0, 0.0, 1.0);

        var kMatrixInverse = new THREE.Matrix4();
        kMatrixInverse.copy(kMatrix).invert();

        for (var i = 0; i < count; i++) {

            if (i === 0) {

                // Set the position matrix from the original object (P1) and its inverse
                var strOriginal = list[i];
                if (!strOriginal || strOriginal.length === 0)
                    continue;
                var aOriginal = strOriginal.split(',');
                p1Matrix.set( aOriginal[0], aOriginal[1], aOriginal[2], aOriginal[3],
                              aOriginal[4], aOriginal[5], aOriginal[6], aOriginal[7],
                              aOriginal[8], aOriginal[9], aOriginal[10], aOriginal[11],
                              aOriginal[12], aOriginal[13], aOriginal[14], aOriginal[15] );

                p1MatrixInverse.copy(p1Matrix).invert();

                // First object uses the matrix from GLTF
                mesh.setMatrixAt(i, originalMatrix);

            } else {

                // Clone matrix of the first object
                var originalMatrixClone = originalMatrix.clone();

                // Remove rotation then position (P1)
                originalMatrixClone.premultiply(kMatrixInverse);
                originalMatrixClone.premultiply(p1MatrixInverse);

                // Get position matrix of the next object (P2)
                var strP2Matrix = list[i];
                var aP2Matrix = strP2Matrix.split(',');
                var p2Matrix = new THREE.Matrix4();
                p2Matrix.set(aP2Matrix[0], aP2Matrix[1], aP2Matrix[2], aP2Matrix[3],
                              aP2Matrix[4], aP2Matrix[5], aP2Matrix[6], aP2Matrix[7],
                              aP2Matrix[8], aP2Matrix[9], aP2Matrix[10], aP2Matrix[11],
                              aP2Matrix[12], aP2Matrix[13], aP2Matrix[14], aP2Matrix[15] );

                // Add P2 and rotation
                originalMatrixClone.premultiply(p2Matrix);
                originalMatrixClone.premultiply(kMatrix);

                p2Matrix.premultiply(kMatrix);

                mesh.setMatrixAt(i, p2Matrix);

            }

        }

        mesh.instanceMatrix.needsUpdate = true;

    }

    return mesh;
  }

  /**
   * increase or decrease indentation
   */
  pushIndentation() {
    this.indentStack.push(this.indent);
    this.indent += '  ';
  }

  popIndentation() {
    this.indent = this.indentStack.pop();
  }

  /**
   * log op properties with indentation
   * TODO: indented output of OpObject tree maybe useful in several contexts, -> Utility or OpObject.log, configurable ?
   * @param {*} indent 
   * @param {*} op 
   */
  logOpObject (indent, op) {

    if (op instanceof OpReference) 
    {
      logger.log(`${indent}OpReference ${op.id}  ${op.symbolId ? op.symbolId : ''}`);
      logger.log(`${indent}${toFixed2(op.transform)}`);
    } 
    else if (op instanceof OpMesh)
    {
      logger.log(`${indent}OpMesh ${op.id}`);
      logger.log(`${indent}${toFixed2(op.transform)}`);
    }
    else if (op instanceof OpShapePath)
    {
      logger.log(`${indent}OpShapePath ${op.id}`);
      logger.log(`${indent}${toFixed2(op.transform)}`);
    }
    else if (op instanceof OpText) 
    {
      logger.log(`${indent}OpText ${op.id}  ${op.text}`);
      logger.log(`${indent}${toFixed2(op.transform)}`);
    }
    else // OpGroup
    {
      logger.log(`${indent}OpGroup ${op.id} ${op.name}`);
      logger.log(`${indent}${toFixed2(op.transform)}`);
    }
  } 
}
