<template>
  <form>
    <div class="form-group">
      <label for="time" class="form-label" >Time {{time}} o'clock</label>
      <input type="range" class="form-range mb-2" id="time" :min="0" :max="24" v-model="time"  @change="onChangeTime">
    </div>
    <div class="form-group">
        <label for="day">Tag</label>
        <input type="number" class="form-control mb-2" id="day" placeholder="" :min="1" :max="31" v-model="day" @change="onChangeDay">
    </div>
    <select class="form-select form-select mb-2" v-model="month" @change="onChangeMonth">
      <option selected>Monat</option>
      <option value="1">Januar</option>
      <option value="2">Februar</option>
      <option value="3">März</option>
      <option value="4">April</option>
      <option value="5">Mai</option>
      <option value="6">Juni</option>
      <option value="7">Juli</option>
      <option value="8">August</option>
      <option value="9">September</option>
      <option value="10">Oktober</option>
      <option value="11">November</option>
      <option value="12">Dezember</option>
    </select>    
    <!-- <button type="submit" class="btn btn-primary mb-2 ">Submit</button> -->
  </form>    
</template>

<script>
import theApp from '@/frame/Application'
import VisualEventsUnrealView from '@/visual-events/view/VisualEventsUnrealView'

export default {
  name: 'SunSky',
  components: {
  },
  data () {
    return {
      time: 12,
      day: 10,
      month: 7,
    }
  },
  mounted () {
  },
  unmounted () {
  },
  methods: {
    onChangeTime () {
      const view3D = theApp.findViewByName('3D Ansicht')
      if (view3D instanceof VisualEventsUnrealView)
        view3D.setSunSkyTime(this.time)
    },
    onChangeDay () {
      const view3D = theApp.findViewByName('3D Ansicht')
      if (view3D instanceof VisualEventsUnrealView)
        view3D.setSunSkyDay(this.day)
    },
    onChangeMonth () {
      const view3D = theApp.findViewByName('3D Ansicht')
      if (view3D instanceof VisualEventsUnrealView)
        view3D.setSunSkyMonth(this.month)
    }
  }
}
</script>

<style scoped>
</style>
