// radian to degree
export const rad2Deg = rad => rad * 180.0 / Math.PI; 

// degree to radion
export const deg2Rad = deg => deg * Math.PI / 180.0;

// clamp num in interval [a,b]
export const clamp = (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

// clamp periodically to [min, max)
//
// useful to normalize angles e.g. 
// clampPeriodic(270, -180, 180) =  -90 
// clampPeriodic(deg2Rad(-45), 0, Math.PI) =  deg2Rad(315) 
// clampPeriodic(deg2Rad(-45), -Math.PI, Math.PI) = deg2Rad(-45)
export const periodic = (num, min, max) => min + modulo(num - min, max - min);

// the remainder operator a % b works like sign(a) * (abs(a) % b)
//   1 % 10 = 1
//  10 % 10 = 0
//  11 % 10 = 1
//  21 & 10 = 1
//  -1 % 10 = -1
// -11 % 10 = -1
// in many applications we need the relative offset in the repeated interval, such as
//  -1 % 9
// -11 % 9
export const modulo = (a, b) => a % b + (a % b < 0 ? b : 0); 

// check for object === {}
export const isEmpty = obj => Object.keys(obj).length === 0;

// check numbers a, b for equality in magnitude near 100000 mm (buildings usually are < 100 m), see: 
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/EPSILON
export const equals = (a, b, magnitude = 100000) => Math.abs(b - a) < magnitude * Number.EPSILON;
export const equalsZero = (a, magnitude = 100000) => Math.abs(a) < magnitude * Number.EPSILON;

// get the value next to n * step
export const nextValue = (num, step) => num >= 0 && num <= 0.5 * step ? 0 : (Math.floor((num - 0.5 * step) / step) * step + step);